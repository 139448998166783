import React, { Component } from 'react'
import { Row, Col } from "react-bootstrap";
import { Button } from "@material-ui/core";
import { obterStarsClient } from "../../../../../../../actions/myihtpActions";
import { ToastContainer } from "react-toastr";
import DialogStars from "../../../Helper/DialogStars";

let container;
export default class StarsMesAnterior extends Component {

    constructor(props) {
        super(props);

        this.state = {
            stars: [],
        }

    };

    //Funcao para obter stars mensais do mes anterior
    obterStars = () => async e => {
        e.stopPropagation();
        obterStarsClient()
            .then(data => {
                console.log(data);
                if (data.messageType === "success") {
                    this.handleClickOpenStars();
                    this.setState({ stars: data.data });
                } else {
                    container.clear();
                    container.error(data.message);
                }
            })
            .catch(error => { });
    };

    handleClickOpenStars = () => {
        this.setState({
            openStars: true
        });
    };

    handleCloseStars = value => {
        this.setState({ selectedValueStars: value, openStars: false });
    };

    render() {
        return (
            <div>

                <DialogStars
                    selectedValue={this.state.selectedValueStars}
                    open={this.state.openStars}
                    onClose={this.handleCloseStars}
                    stars={this.state.stars}
                />

                <ToastContainer ref={ref => (container = ref)} className="toast-bottom-right" />

                {/* ROW para obter stars mensais do mes anterior*/}
                <Row style={{ textAlign: "left" }}>
                    <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center" }}>
                        <span style={{ textAlign: "center" }}>
                            <b>Obter stars mês anterior</b>
                        </span>
                        <div className="form-group">
                            <br />
                            <Row xs={12} sm={12} md={12} lg={12}>
                                <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }}>
                                    <Button variant="contained" color="secondary" style={{ minWidth: "20%" }} onClick={this.obterStars()}>
                                        Obter Stars
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}
