import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
// import Footer from "../../../components/Footer/Footer";
import { connect } from "react-redux";
import HeaderDesktop from "../../../components/Desktop/Header/Header";
import SocialMedia from "../../../components/Desktop/Home/SocialMedia";
import Footer from "../../../components/Footer/Footer";
import Routes from "../../../helpers/Routes";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      muted: true,
      playing: true
    };
  }

  onStartVideo(event) {
    if (this.state.muted === true) {
      var that = this;
      setInterval(function () {
        that.setState({
          muted: false
        });
        that.setState({
          playing: true
        });
      }, 4000);
    }
  }

  render() {
    return (
      <div className="App">
        <HeaderDesktop />
        <br />
        <br />
        <Container
          style={{
            position: "relative",
            width: "90vw",
            top: "60px"
          }}
        >
          <Row
            style={{
              textAlign: "center"
            }}
          >
            <Col xs="12" sm="12" md="12" lg="12">
              <h3>Verificamos que não encontrou o que pretendia.</h3>
              <br />
              <h3>
                Para nos ajudar a resolver o problema, envie um email para o
                nosso suporte.
              </h3>
              <h3>
                <a href="mailto:ihavethepower@solfut.com?Assunto=Erro%20Pagina404">
                  ihavethepower@solfut.com
                </a>
              </h3>
              <br />
              <h3>Obrigado pela sua disponibilidade</h3>
              <br />

              <SocialMedia>
              </SocialMedia>

              <br></br>

              <h5 style={{ lineHeight: "20px" }}>
                Aproveite a oportunidade de saber mais{" "}
                <a href={Routes.empresa.sobreNos}>sobre nós</a>,
                <br />
                melhorar o seu dia com as nossas{" "}
                <a href={Routes.recursosGratis.frases}>Frases inspiradoras</a>
                <br /> e de comprar algo na nossa{" "}
                <a href={Routes.shop.home}>Loja</a>
              </h5>
            </Col>
          </Row>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <Footer />
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(Register);
