import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { getAllNextCourses } from "../../../../../../actions/myihtpActions";
import Configs from "../../../../../../helpers/Configs";

class ProximosCourses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //NextCourses:[],
      nextCourses: [],
      noDataGetCourses: null,
      showPaginationGetCourses: false,
      defaultPageSizeGetCourses: 5,
      academyManagers: []
    };
  }

  componentDidMount() {
    getAllNextCourses().then(data => {
      if (
        data.nextCourses.message !== "Não tem cursos disponiveis." &&
        !data.nextCourses.error
      ) {
        this.setState({ nextCourses: data.nextCourses });
      }
    });

  }

  render() {
    const { user } = this.props;
    var colunas = Configs.myIHTP.tableConfig.proximoscursosmanagers.columns;

    return (
      <div>
        <hr />
        <ReactTable
          className="-highlight tableReact"
          data={this.state.nextCourses}
          getTheadProps={(state, rowInfo, column) => {
            return {
              style: {
                fontWeight: Configs.myIHTP.tableConfig.fontHeightHeader,
                fontSize: Configs.myIHTP.tableConfig.fontSizeHeader,
                height: Configs.myIHTP.tableConfig.heightHeader,
                color: Configs.myIHTP.tableConfig.colorHeader,
                backgroundColor:
                  Configs.myIHTP.tableConfig.backgroundColorHeader
              }
            };
          }}
          getPaginationProps={state => {
            return {
              style: {
                color: Configs.myIHTP.tableConfig.colorHeader,
                backgroundColor:
                  Configs.myIHTP.tableConfig.backgroundColorHeader,
                fontSize: Configs.myIHTP.tableConfig.fontSizeRowBody
              }
            };
          }}
          getTrProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                "text-align": "center",
                "font-size": Configs.myIHTP.tableConfig.fontSizeRowBody
              }
            };
          }}
          defaultPageSize={Configs.myIHTP.tableConfig.defaultPageSize}
          showPagination={
            this.state.nextCourses.length >
              Configs.myIHTP.tableConfig.defaultPageSize
              ? Configs.myIHTP.tableConfig.showPagination
              : false
          }
          previousText={Configs.myIHTP.tableConfig.buttonCopyAnterior}
          nextText={Configs.myIHTP.tableConfig.buttonCopyProximo}
          noDataText={Configs.myIHTP.tableConfig.noDataText}
          pageText={Configs.myIHTP.tableConfig.pageText}
          ofText={Configs.myIHTP.tableConfig.ofText}
          rowsText={Configs.myIHTP.tableConfig.rowsText}
          columns={colunas}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

export default withRouter(connect(mapStateToProps)(ProximosCourses));
