
export function getConsultantById(id_consultor) {
    return new Promise((resolve, reject) => {
        return fetch("/consultant/getConsultantById", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                ID_Consultor: id_consultor
            }),
            credentials: "include"
        })
            .then(res => res.json())
            .then(json => {
                resolve(json);
            })
            .catch(function (error) {
                reject(error);
            });
    });
};
