import React, { Component } from 'react';
import { Col, Row } from "react-bootstrap";
import ImageUploader from "react-images-upload";
import { ToastContainer } from "react-toastr";
import { uploadFicheiro } from "../../../../../../../actions/myihtpActions";

let container;
export default class UploadFicheiro extends Component {

    //Funcao para dar upload de ficheiro para cdn
    uploadFile = event => {

        //Correção para não fazer upload se não for detetado nenhum evento
        if (event.length <= 0) return;

        uploadFicheiro(event)
            .then(data => {
                if (data.success === true) {
                    container.clear();
                    container.success(data.message);
                } else {
                    container.clear();
                    container.error(data.message);
                }
            })
            .catch(error => {
                console.log(error.message);
                container.clear();
                container.error("Erro ao fazer upload do Ficheiro");
            });
    };

    render() {
        return (
            <div>
                <ToastContainer ref={ref => (container = ref)} className="toast-bottom-right" />

                {/* ROW de upload de ficheiro para cdn*/}
                <Row style={{ textAlign: "left" }}>
                    <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center" }} >
                        <span style={{ textAlign: "center" }}>
                            <b>Carregar ficheiro CDN</b>
                        </span>
                        <div className="form-group">
                            <br />
                            <Row xs={12} sm={12} md={12} lg={12}>
                                <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }} >
                                    <ImageUploader
                                        withIcon={false}
                                        withPreview={false}
                                        style={{ maxWidth: "100%" }}
                                        fileSizeError="Tamanho demasiado grande"
                                        fileTypeError="Ficheiro não é suportado"
                                        type="file"
                                        name="sampleFile"
                                        accept="*"
                                        label="Tamanho máximo 50MB"
                                        id="sampleFile"
                                        ref="sampleFile"
                                        buttonText={"Escolha um ficheiro"}
                                        onChange={this.uploadFile}
                                        imgExtension={[]}
                                        maxFileSize={53477376}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

            </div>
        )
    }
}
