import { deleteContact } from "../../../../../actions/myihtpActions";

export function removeContact(id) {
  return new Promise((resolve, reject) => {
    deleteContact(id)
      .then(data => {
        if (data.result.message === "Sucesso ao remover o seu contacto.") {
          resolve(true);
        } else {
          resolve(false);
        }
      })
      .catch(error => {
        resolve(error);
      });
  });
}
