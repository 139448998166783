import React, { Component } from "react";
import { withCookies } from "react-cookie";
import { connect } from "react-redux";

class Instagram extends Component {
  render() {
    return (
      <div
        style={{ display: "inline-flex" }}
        onClick={() => {
          window.open(
            "https://www.instagram.com/ihavethepowerglobal/",
            "_blank"
          );
        }}
      >
        <div style={{ padding: "5px" }}>
          <svg
            className="instaIcon"
            width="30px"
            height="31px"
            viewBox="0 0 30 31"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xlink="http://www.w3.org/1999/xlink"
          >
            <defs>
              <linearGradient
                x1="50%"
                y1="99.7089109%"
                x2="50%"
                y2="0.776721582%"
                id="linearGradient-1"
              >
                <stop stop-color="#E09B3D" offset="0%" />
                <stop stop-color="#C74C4D" offset="30%" />
                <stop stop-color="#C21975" offset="60%" />
                <stop stop-color="#7024C4" offset="100%" />
              </linearGradient>
              <linearGradient
                x1="50%"
                y1="146.098873%"
                x2="50%"
                y2="-45.1596833%"
                id="linearGradient-2"
              >
                <stop stop-color="#E09B3D" offset="0%" />
                <stop stop-color="#C74C4D" offset="30%" />
                <stop stop-color="#C21975" offset="60%" />
                <stop stop-color="#7024C4" offset="100%" />
              </linearGradient>
              <linearGradient
                x1="50%"
                y1="658.140556%"
                x2="50%"
                y2="-140.029283%"
                id="linearGradient-3"
              >
                <stop stop-color="#E09B3D" offset="0%" />
                <stop stop-color="#C74C4D" offset="30%" />
                <stop stop-color="#C21975" offset="60%" />
                <stop stop-color="#7024C4" offset="100%" />
              </linearGradient>
            </defs>
            <g
              id="Menu"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g id="XS" transform="translate(-232.000000, -584.000000)">
                <g id="instagram" transform="translate(232.000000, 584.000000)">
                  <g>
                    <path
                      d="M21.0628382,0 L8.93716177,0 C4.00919,0 0,4.14282966 0,9.23506716 L0,21.7649328 C0,26.8571703 4.00919,31 8.93716177,31 L21.0628382,31 C25.99081,31 30,26.8571703 30,21.7649328 L30,9.23506716 C29.9999456,4.14282966 25.9907556,0 21.0628382,0 Z M26.9820011,21.7649328 C26.9820011,25.1429367 24.3318743,27.8814011 21.0628382,27.8814011 L8.93716177,27.8814011 C5.66812574,27.8814011 3.01799889,25.1429367 3.01799889,21.7649328 L3.01799889,9.23506716 C3.01799889,5.85700701 5.66812574,3.11859885 8.93716177,3.11859885 L21.0628382,3.11859885 C24.3318743,3.11859885 26.9820011,5.85700701 26.9820011,9.23506716 L26.9820011,21.7649328 Z"
                      id="Shape"
                      fill="url(#linearGradient-1)"
                      fill-rule="nonzero"
                    />
                    <path
                      d="M14.8148148,7.27160494 C10.5261413,7.27160494 7.03703704,10.8770253 7.03703704,15.3086138 C7.03703704,19.7402023 10.5261413,23.345679 14.8148148,23.345679 C19.1034884,23.345679 22.5925926,19.7402587 22.5925926,15.3086138 C22.5925926,10.8769689 19.1034884,7.27160494 14.8148148,7.27160494 Z M14.8148148,20.2196054 C12.1900597,20.2196054 10.0623136,18.0209266 10.0623136,15.3086702 C10.0623136,12.5964137 12.1901143,10.397735 14.8148148,10.397735 C17.4395699,10.397735 19.5673161,12.5964137 19.5673161,15.3086702 C19.5673161,18.0208702 17.4395153,20.2196054 14.8148148,20.2196054 Z"
                      id="Shape"
                      fill="url(#linearGradient-2)"
                      fill-rule="nonzero"
                    />
                    <ellipse
                      id="Oval"
                      fill="url(#linearGradient-3)"
                      cx="22.5925926"
                      cy="7.65432099"
                      rx="1.85185185"
                      ry="1.91358025"
                    />
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isVerified: state.isVerified,
    user: state.user
  };
};

export default withCookies(connect(mapStateToProps)(Instagram));
