import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import "react-table/react-table.css";
import ComissoesAnuais from "./ComissoesAnuais/ComissoesAnuais";
import Pagamentos from "./Pagamentos/Pagamentos";
import Resumo from "./Resumo/Resumo";

class Comissoes extends Component {
  componentDidMount() { }

  render() {
    return (
      <Row>
        <Col xs="12" sm="12" md="12" lg="12">
          <Row style={{ textAlign: "left" }}>
            <Col xs="12" sm="12" md="12" lg="12">
              <Resumo />
              <ComissoesAnuais />
              <Pagamentos />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}
export default Comissoes;
