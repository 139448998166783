import React, { Component } from 'react'
import MudarConsultorEmpresa from './Components/MudarConsultorEmpresa'
import VerificarEmpresaAtribuidaBackOffice from './Components/VerificarEmpresaAtribuidaBackOffice'
import ProcurarEmpresas from './Components/ProcurarEmpresas'

export default class Empresas extends Component {
    render() {
        return (
            <div>
                <br />
                <br />
                <VerificarEmpresaAtribuidaBackOffice />
                <br />
                <hr />
                <br />
                <MudarConsultorEmpresa />
                <br />
                <hr />
                <br />
                <ProcurarEmpresas />
                <br />
                <br />
            </div>
        )
    }
}
