import React, { Component } from "react";
import { withCookies } from "react-cookie";
import { connect } from "react-redux";

class FaturaBotao extends Component {
  render() {
    return (
      //   <a href={this.props.url} target="_new">
      <a href={this.props.url} target="_blank">
        <svg
          height="20px"
          viewBox="0 0 512 512.00115"
          width="25px"
          xmlns="http://www.w3.org/2000/svg"
          id="botaoFatura"
        >
          <g>
            <g>
              <path
                fill=""
                d="M474.994,246.001h-74.012v-236c0-4.044-2.437-7.691-6.173-9.239c-3.738-1.549-8.039-0.691-10.898,2.167l-28.329,28.328
L327.255,2.93c-3.905-3.905-10.237-3.905-14.143,0l-28.328,28.328L256.459,2.93c-3.905-3.905-10.237-3.905-14.143,0
l-28.328,28.328L185.659,2.93c-3.905-3.905-10.237-3.905-14.142,0l-28.328,28.328L114.863,2.93
c-3.905-3.905-10.237-3.905-14.143,0L72.392,31.258L44.065,2.93c-3.881-3.881-10.158-3.901-14.073-0.055
c-2.056,2.021-3.053,4.714-2.984,7.389v449.738c0,28.672,23.326,51.998,51.998,51.998h353.976c0.002,0,0.003,0,0.005,0
c0.003,0,0.006,0,0.009,0c28.672,0,51.998-23.326,51.998-51.998V256C484.994,250.477,480.517,246.001,474.994,246.001z
M79.006,492c-17.645,0-31.999-14.355-31.999-31.999V34.156L65.321,52.47c3.905,3.905,10.237,3.905,14.143,0l28.328-28.328
L136.12,52.47c3.905,3.905,10.237,3.905,14.143,0l28.328-28.328L206.92,52.47c3.905,3.905,10.237,3.905,14.142,0l28.328-28.328
l28.328,28.328c3.905,3.905,10.237,3.905,14.143,0l28.328-28.328l28.328,28.328c3.906,3.905,10.238,3.905,14.142,0l18.329-18.328
V256c0,0.091,0.011,0.18,0.014,0.271v203.73c0,12.062,4.14,23.168,11.057,31.999H79.006z M464.994,460.001
c0,17.644-14.355,31.999-31.999,31.999s-31.999-14.355-31.999-31.999V266h63.998V460.001z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                fill=""
                d="M213.995,158.855c-9.925,0-17.999-6.914-17.999-15.411c0-8.498,8.075-15.412,17.999-15.412
c9.925,0,17.999,6.914,17.999,15.412c0,5.523,4.477,10,10,10c5.523,0,10-4.477,10-10c0-16.301-11.884-30.055-27.999-34.157v-6.774
c0-5.523-4.477-10-10-10s-10,4.477-10,10v6.774c-16.116,4.102-27.999,17.857-27.999,34.157c0,19.525,17.047,35.41,37.999,35.41
c9.925,0,17.999,6.914,17.999,15.412s-8.074,15.411-17.999,15.411c-9.925,0-17.999-6.914-17.999-15.411c0-5.523-4.477-10-10-10
s-10,4.477-10,10c0,16.301,11.884,30.055,27.999,34.157v8.16c0,5.523,4.477,10,10,10s10-4.477,10-10v-8.16
c16.116-4.102,27.999-17.856,27.999-34.157C251.994,174.741,234.947,158.855,213.995,158.855z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                fill=""
                d="M314.461,158.856h-19.49c-5.523,0-10,4.477-10,10s4.477,10,10,10h19.49c5.523,0,10-4.477,10-10
S319.984,158.856,314.461,158.856z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                fill=""
                d="M133.02,158.856h-19.49c-5.523,0-10,4.477-10,10s4.477,10,10,10h19.49c5.523,0,10-4.477,10-10
S138.543,158.856,133.02,158.856z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                fill=""
                d="M319.52,270.007H161.097c-5.523,0-10,4.477-10,10c0,5.523,4.477,10,10,10H319.52c5.523,0,10-4.477,10-10
C329.519,274.484,325.043,270.007,319.52,270.007z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                fill=""
                d="M115.538,272.937c-1.86-1.86-4.44-2.93-7.07-2.93c-2.63,0-5.21,1.07-7.07,2.93c-1.86,1.86-2.93,4.44-2.93,7.07
c0,2.63,1.07,5.21,2.93,7.07c1.86,1.86,4.44,2.93,7.07,2.93c2.63,0,5.21-1.07,7.07-2.93c1.86-1.86,2.93-4.44,2.93-7.07
C118.468,277.377,117.398,274.797,115.538,272.937z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                fill=""
                d="M319.52,336.005H161.097c-5.523,0-10,4.477-10,10c0,5.523,4.477,10,10,10H319.52c5.523,0,10-4.477,10-10
C329.519,340.482,325.043,336.005,319.52,336.005z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                fill=""
                d="M115.538,338.935c-1.86-1.86-4.44-2.93-7.07-2.93c-2.63,0-5.21,1.07-7.07,2.93c-1.86,1.86-2.93,4.44-2.93,7.07
c0,2.63,1.07,5.21,2.93,7.07c1.86,1.86,4.44,2.93,7.07,2.93c2.63,0,5.21-1.07,7.07-2.93c1.86-1.86,2.93-4.44,2.93-7.07
C118.468,343.375,117.398,340.795,115.538,338.935z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                fill=""
                d="M319.52,402.003H161.097c-5.523,0-10,4.477-10,10s4.477,10,10,10H319.52c5.523,0,10-4.477,10-10
S325.043,402.003,319.52,402.003z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                fill=""
                d="M115.538,404.933c-1.86-1.86-4.44-2.93-7.07-2.93c-2.63,0-5.21,1.07-7.07,2.93c-1.86,1.86-2.93,4.44-2.93,7.07
s1.07,5.21,2.93,7.07c1.86,1.86,4.44,2.93,7.07,2.93c2.63,0,5.21-1.07,7.07-2.93c1.86-1.86,2.93-4.44,2.93-7.07
S117.398,406.793,115.538,404.933z"
              />
            </g>
          </g>
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
        </svg>
      </a>
    );
  }
}

const mapStateToProps = state => {
  return {
    isVerified: state.isVerified,
    user: state.user
  };
};

export default withCookies(connect(mapStateToProps)(FaturaBotao));
