import React, { Component } from "react";
import { FormattedDate, FormattedMessage, FormattedNumber } from "react-intl";
import { connect } from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { getAllComissions, getComissionsDetails, getOrderDetailsBackOffice } from "../../../../../../actions/myihtpActions";
import Configs from "../../../../../../helpers/Configs";
import DialogEncomenda from "../Helper/DialogEncomenda";
import InformationIcon from "../Helper/InformationIcon";

class ComissoesAnuais extends Component {
  constructor(props) {
    super(props);
    this.state = {
      commissions: [],
      detailsComissions: [],
      noDataGetcommissions: null,
      showPaginationGetcommissions: false,
      defaultPageSizeGetcommissions: 5,
      expanded: {},
      encomenda: {},
    };
  }

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = value => {
    this.setState({ selectedValue: value, open: false });
  };

  componentDidMount() {
    getAllComissions()
      .then(data => {
        if (!data.data.error) {
          this.setState({ commissions: data.data.ComissionsLastYear });
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  getComissionsDetailsData = (mes, ano) => async e => {
    getComissionsDetails(mes, ano)
      .then(data => {
        this.setState({ detailsComissions: data.data.comissoes });
      })
      .catch(error => {
        console.log(error);
      });
  };

  handleRowExpanded(rowsState, index) {
    this.setState({
      expanded: {
        [index[0]]: !this.state.expanded[index[0]],
      },
    });
  }

  getOrderDetailsData(id) {
    getOrderDetailsBackOffice(id)
      .then(data => {
        if (
          data.data.details.message !==
          "Não tem itens associados à encomenda que pediu."
        ) {
          this.setState({ details: data.data.details.Detalhes[0] });
          this.setState({ itens: data.data.details.Itens });
          this.setState({ estados: data.data.details.Estados });
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    const { currencies } = this.props;
    const comissoes = {
      columns: [
        {
          Header: <FormattedMessage id="myihtp.comissoes.ano" />,
          accessor: "Ano",
          resizable: false,
        },
        {
          Header: <FormattedMessage id="myihtp.comissoes.mes" />,
          accessor: "Mes",
          resizable: false,
        },
        {
          Header: () => {
            return (
              <span className="altText" title="NetPoints Cliente">
                NPC
              </span>
            );
          },
          accessor: "NPC",
          resizable: false,
        },
        {
          Header: () => {
            return (
              <span className="altText" title="NetPoints Pessoal">
                NPP
              </span>
            );
          },
          accessor: "NPP",
          resizable: false,
        },
        {
          Header: () => {
            return (
              <span className="altText" title="NetPoints Rede">
                NPR
              </span>
            );
          },
          accessor: "NPR",
          resizable: false,
        },
        {
          Header: () => {
            return (
              <span className="altText" title="Créditos">
                CRD
              </span>
            );
          },
          accessor: "CRD",
          resizable: false,
        },
        {
          Header: () => {
            return (
              <span className="altText" title="Valor de Comissão">
                VCM
              </span>
            );
          },
          accessor: "VCM",
          resizable: false,
          Cell: row => {
            return (
              <FormattedNumber
                value={row.original.VCM}
                style="currency"
                currency={
                  currencies
                    ? currencies.selected
                      ? currencies.selected
                      : null
                    : null
                }
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
            );
          },
        },
        {
          Header: () => {
            return (
              <span className="altText" title="Nº de Encomendas">
                NEN
              </span>
            );
          },
          accessor: "NEN",
          resizable: false,
        },
        {
          Header: () => {
            return (
              <span className="altText" title="Valor das Encomendas">
                VEN
              </span>
            );
          },
          accessor: "VEN",
          resizable: false,
          Cell: row => {
            return (
              <FormattedNumber
                value={row.original.VEN}
                style="currency"
                currency={
                  currencies
                    ? currencies.selected
                      ? currencies.selected
                      : null
                    : null
                }
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
            );
          },
        },
        {
          Header: () => {
            return (
              <span className="altText" title="NetPoints Total">
                NPT
              </span>
            );
          },
          accessor: "NPT",
          resizable: false,
        },
        {
          Header: () => {
            return (
              <span className="altText" title="Valor Total">
                VTL
              </span>
            );
          },
          accessor: "VTL",
          resizable: false,
          Cell: row => {
            return (
              <FormattedNumber
                value={row.original.VTL}
                style="currency"
                currency={
                  currencies
                    ? currencies.selected
                      ? currencies.selected
                      : null
                    : null
                }
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
            );
          },
        },
      ],
    };
    const detalheCom = {
      columns: [
        {
          Header: "Tipo Comissão",
          accessor: "tipoCom",
          resizable: false,
        },
        {
          Header: "Data",
          accessor: "Data",
          resizable: false,
          Cell: row => {
            return <FormattedDate value={new Date(row.original.Data)} />;
          },
        },
        {
          Header: "ID Encomenda",
          accessor: "ID_Encomenda",
          resizable: false,
          Cell: row => {
            return (
              <span
                class="fake-link"
                id="fake-link-1"
                onClick={() => {
                  this.handleClickOpen();
                  this.getOrderDetailsData(row.original.ID_Encomenda);
                }}
              >
                {row.original.ID_Encomenda}{" "}
                {row.original.ID_Encomenda ? <InformationIcon /> : null}
              </span>
            );
          },
        },
        {
          Header: "Creditos",
          accessor: "Creditos",
          resizable: false,
          Cell: row => {
            return (
              <FormattedNumber
                value={row.original.Creditos ? row.original.Creditos : 0}
                style="decimal"
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
            );
          },
        },
        {
          Header: "Net Points Pessoais",
          accessor: "NetPointsPessoal",
          resizable: false,
          Cell: row => {
            return (
              <FormattedNumber
                value={
                  row.original.NetPointsPessoal
                    ? row.original.NetPointsPessoal
                    : 0
                }
                style="decimal"
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
            );
          },
        },
        {
          Header: "Net Points Cliente",
          accessor: "NetPointsCliente",
          resizable: false,
          Cell: row => {
            return (
              <FormattedNumber
                value={
                  row.original.NetPointsCliente
                    ? row.original.NetPointsCliente
                    : 0
                }
                style="decimal"
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
            );
          },
        },
        {
          Header: "Net Points Rede",
          accessor: "NetPointsRede",
          resizable: false,
          Cell: row => {
            return (
              <FormattedNumber
                value={
                  row.original.NetPointsRede ? row.original.NetPointsRede : 0
                }
                style="decimal"
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
            );
          },
        },
        {
          Header: "Valor de Comissão",
          accessor: "ValorComissao",
          resizable: false,
          Cell: row => {
            return (
              <FormattedNumber
                value={
                  row.original.ValorComissao ? row.original.ValorComissao : 0
                }
                style="currency"
                currency={
                  currencies
                    ? currencies.selected
                      ? currencies.selected
                      : null
                    : null
                }
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
            );
          },
        },
        {
          Header: "Observações",
          accessor: "Observacoes",
          resizable: false,
          Cell: row => {
            return (
              <span>
                {row.original.Observacoes ? row.original.Observacoes : "-"}
              </span>
            );
          },
        },
      ],
    };
    return (
      <div>
        <hr />
        <DialogEncomenda
          selectedValue={this.state.selectedValue}
          open={this.state.open}
          onClose={this.handleClose}
          details={this.state.details ? this.state.details : []}
          itens={this.state.itens ? this.state.itens : []}
          estados={this.state.estados ? this.state.estados : []}
        />
        <ReactTable
          className="-highlight tableReact"
          data={this.state.commissions}
          expanded={this.state.expanded}
          onExpandedChange={(newExpanded, index, event) =>
            this.handleRowExpanded(newExpanded, index, event)
          }
          getTheadProps={(state, rowInfo, column) => {
            return {
              style: {
                fontWeight: Configs.myIHTP.tableConfig.fontHeightHeader,
                fontSize: Configs.myIHTP.tableConfig.fontSizeHeader,
                height: Configs.myIHTP.tableConfig.heightHeader,
                color: Configs.myIHTP.tableConfig.colorHeader,
                backgroundColor:
                  Configs.myIHTP.tableConfig.backgroundColorHeader,
              },
            };
          }}
          getPaginationProps={state => {
            return {
              style: {
                color: Configs.myIHTP.tableConfig.colorHeader,
                backgroundColor:
                  Configs.myIHTP.tableConfig.backgroundColorHeader,
                fontSize: Configs.myIHTP.tableConfig.fontSizeRowBody,
              },
            };
          }}
          getTrProps={(state, rowInfo, column, instance) => {
            var mes;
            var ano;
            if (rowInfo) {
              mes = rowInfo.original.Mes;
              ano = rowInfo.original.Ano;
            }
            return {
              onClick: this.getComissionsDetailsData(mes, ano),
              style: {
                "text-align": "center",
                "font-size": Configs.myIHTP.tableConfig.fontSizeRowBody,
              },
            };
          }}
          defaultPageSize={Configs.myIHTP.tableConfig.defaultPageSize}
          showPagination={
            this.state.commissions.length >
              Configs.myIHTP.tableConfig.defaultPageSize
              ? Configs.myIHTP.tableConfig.showPagination
              : false
          }
          previousText={Configs.myIHTP.tableConfig.buttonCopyAnterior}
          nextText={Configs.myIHTP.tableConfig.buttonCopyProximo}
          noDataText={Configs.myIHTP.tableConfig.noDataText}
          pageText={Configs.myIHTP.tableConfig.pageText}
          ofText={Configs.myIHTP.tableConfig.ofText}
          rowsText={Configs.myIHTP.tableConfig.rowsText}
          columns={comissoes.columns}
          // SubComponent={row => <span>TESTE</span>}
          SubComponent={row => {
            return (
              <div
                style={{
                  padding: "10px",
                  backgroundColor: "#f8f9fa",
                }}
              >
                <ReactTable
                  getTheadProps={(state, rowInfo, column) => {
                    return {
                      style: {
                        color: "black",
                        textAlign: "center",
                        backgroundColor: "#ffe4c4",
                      },
                    };
                  }}
                  getTrProps={(state, rowInfo, column, instance) => {
                    return {
                      style: {
                        "text-align": "center",
                        "font-size": Configs.myIHTP.tableConfig.fontSizeRowBody,
                      },
                    };
                  }}
                  getPaginationProps={state => {
                    return {
                      style: {
                        color: Configs.myIHTP.tableConfig.colorHeader,
                        backgroundColor:
                          Configs.myIHTP.tableConfig.backgroundColorHeader,
                        fontSize: Configs.myIHTP.tableConfig.fontSizeRowBody,
                      },
                    };
                  }}
                  data={this.state.detailsComissions}
                  columns={detalheCom.columns}
                  showPagination={Configs.myIHTP.tableConfig.showPagination}
                  defaultPageSize={Configs.myIHTP.tableConfig.defaultPageSize}
                />
              </div>
            );
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currencies: state.currencies,
  };
};

export default connect(mapStateToProps)(ComissoesAnuais);
