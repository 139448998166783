import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import "react-table/react-table.css";
import Cursos from "./Cursos/Cursos";
import Produtos from "./Produtos/Produtos";
import ProdutosRevenda from "./ProdutosRevenda/ProdutosRevenda";
import Subscricoes from "./Subscricoes/Subscricoes";


class MeusProdutos extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() { }

  render() {
    const { user } = this.props;
    return (
      <Row>
        <Col xs="12" sm="12" md="12" lg="12">
          <Row style={{ textAlign: "left" }}>
            <Col xs="12" sm="12" md="12" lg="12">
              <b>
                <FormattedMessage id="myihtp.subscricoes.title" />
              </b>
              <Subscricoes />
              <hr />
              <b>
                <FormattedMessage id="myihtp.cursos.title" />
              </b>
              <Cursos />
              <hr />
              <b>
                <FormattedMessage id="myihtp.produtos.title" />
              </b>
              <Produtos />
              {user.userType === "CS" ? (
                <span>
                  <hr />
                  <b>
                    <FormattedMessage id="myihtp.produtosrevenda.title" />
                  </b>
                  <ProdutosRevenda />
                </span>
              ) : null}
            </Col>
          </Row>
        </Col>
      </Row>
      // <div className="myaccount-content">
      //   <Subscricoes />
      //   <Cursos />
      //   <Produtos />
      //   <ProdutosRevenda />
      // </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user
  };
};
export default connect(mapStateToProps)(MeusProdutos);
