import React, { Component } from "react";

import ReactTable from "react-table";
import "react-table/react-table.css";

import Configs from "../../../../../../helpers/Configs";
import { getCourses } from "../../../../../../actions/myihtpActions";

class Cursos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //courses:[],
      courses: [],
      noDataGetCourses: null,
      showPaginationGetCourses: false,
      defaultPageSizeGetCourses: 5
    };
  }

  componentDidMount() {
    getCourses()
      .then(data => {
        if (data.courses.length > 0) {
          this.setState({ courses: data.courses });
        }
      })
      .catch(() => {
        this.setState({ courses: [] });
      });
  }

  render() {
    return (
      <div>
        <ReactTable
          className="-highlight tableReact"
          data={this.state.courses}
          getTheadProps={(state, rowInfo, column) => {
            return {
              style: {
                fontWeight: Configs.myIHTP.tableConfig.fontHeightHeader,
                fontSize: Configs.myIHTP.tableConfig.fontSizeHeader,
                height: Configs.myIHTP.tableConfig.heightHeader,
                color: Configs.myIHTP.tableConfig.colorHeader,
                backgroundColor:
                  Configs.myIHTP.tableConfig.backgroundColorHeader
              }
            };
          }}
          getPaginationProps={state => {
            return {
              style: {
                color: Configs.myIHTP.tableConfig.colorHeader,
                backgroundColor:
                  Configs.myIHTP.tableConfig.backgroundColorHeader,
                fontSize: Configs.myIHTP.tableConfig.fontSizeRowBody
              }
            };
          }}
          getTrProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                "text-align": "center",
                "font-size": Configs.myIHTP.tableConfig.fontSizeRowBody
              }
            };
          }}
          defaultPageSize={Configs.myIHTP.tableConfig.defaultPageSize}
          showPagination={
            this.state.courses.length >
            Configs.myIHTP.tableConfig.defaultPageSize
              ? Configs.myIHTP.tableConfig.showPagination
              : false
          }
          previousText={Configs.myIHTP.tableConfig.buttonCopyAnterior}
          nextText={Configs.myIHTP.tableConfig.buttonCopyProximo}
          noDataText={Configs.myIHTP.tableConfig.noDataText}
          pageText={Configs.myIHTP.tableConfig.pageText}
          ofText={Configs.myIHTP.tableConfig.ofText}
          rowsText={Configs.myIHTP.tableConfig.rowsText}
          columns={Configs.myIHTP.tableConfig.cursos.columns}
        />
      </div>
    );
  }
}
export default Cursos;
