import React, { Component } from 'react'
import CriarLead from './Components/CriarLead'
import ProcurarLeads from './Components/ProcurarLeads'

export default class Leads extends Component {
    render() {
        return (
            <div>
                <br />
                <br />
                <CriarLead />
                <br />
                <hr />
                <br />
                <ProcurarLeads isTabActive={this.props.active} />
                <br />
                <br />
            </div>
        )
    }
}
