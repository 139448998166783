import React, { Component } from 'react'
import { Row, Col } from "react-bootstrap";
import { TextField, Button } from "@material-ui/core";
import { isMobile } from "react-device-detect";
import { adicionarProduto } from "../../../../../../../actions/myihtpActions";
import { ToastContainer } from "react-toastr";

let container;
export default class AdicionarProdutoCarrinho extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id_cliente: '',
            id_produto: '',
            quantidade: ''
        }

        this.handleInput = this.handleInput.bind(this);
    };

    handleInput(e) {
        let value = e.target.value;
        let name = e.target.name;
        this.setState(
            prevState => ({
                ...prevState,
                [name]: value
            })
        );
    }

    //Funcao para fazer adicionar produto ao carrinho
    adicionarProdutoCarrinho = () => async e => {
        e.stopPropagation();

        //Validar id de cliente
        if (this.state.id_cliente == null || this.state.id_cliente.trim().length <= 0) {
            container.clear();
            container.error("Id de Cliente deve ser inserido");
            return;
        }

        //Validar id de produto
        if (this.state.id_produto == null || this.state.id_produto.trim().length <= 0) {
            container.clear();
            container.error("Id de Produto deve ser inserido");
            return;
        }

        //Validar quantidade
        if (this.state.quantidade == null || this.state.quantidade <= 0) {
            container.clear();
            container.error("Quantidade deve ser superior a 0");
            return;
        }

        adicionarProduto(this.state)
            .then(data => {
                if (data.message.statusMessage === "success") {
                    container.clear();
                    container.success(data.message.Message);

                    this.setState({
                        id_cliente: '',
                        id_produto: '',
                        quantidade: ''
                    });
                } else {
                    container.clear();
                    container.error(data.message.Message);
                }
            })
            .catch(error => {
                container.clear();
                container.error("Erro ao adicionar produto ao carrinho");
            });
    };

    render() {
        return (
            <div>

                <ToastContainer ref={ref => (container = ref)} className="toast-bottom-right" />

                {/* ROW de para adicionar produto ao carrinho do cliente*/}
                <Row style={{ textAlign: "left" }}>
                    <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center" }}>
                        <span style={{ textAlign: "center" }}>
                            <b>Adicionar Produto ao Carrinho do Cliente</b>
                        </span>
                        <div className="form-group">
                            <br />
                            <Row xs={12} sm={12} md={12} lg={12}>
                                <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }}>
                                    <TextField
                                        placeholder="ID Cliente" name={"id_cliente"}
                                        style={{ maxWidth: isMobile ? "100%" : "50%" }} type="text" fullWidth={true}
                                        value={this.state.id_cliente} onChange={this.handleInput}
                                    />
                                </Col>
                            </Row>
                            <br />
                            <Row xs={12} sm={12} md={12} lg={12}>
                                <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }}>
                                    <TextField
                                        placeholder="ID Produto" name={"id_produto"}
                                        style={{ maxWidth: isMobile ? "100%" : "50%" }} type="text" fullWidth={true}
                                        value={this.state.id_produto} onChange={this.handleInput}
                                    />
                                </Col>
                            </Row>
                            <br />
                            <Row xs={12} sm={12} md={12} lg={12}>
                                <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }}>
                                    <TextField
                                        placeholder="Quantidade" name={"quantidade"}
                                        style={{ maxWidth: isMobile ? "100%" : "50%" }} type="text" fullWidth={true}
                                        value={this.state.quantidade} onChange={this.handleInput}
                                    />
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center" }}>
                                    <Button variant="contained" color="secondary" style={{ minWidth: "20%" }} onClick={this.adicionarProdutoCarrinho()}>
                                        Adicionar Produto
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

            </div>
        )
    }
}
