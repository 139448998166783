import { Button, FormGroup, FormHelperText, TextField } from "@material-ui/core";
import NativeSelect from "@material-ui/core/NativeSelect";
import React, { Component } from 'react';
import { Col, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "react-table/react-table.css";
import { ToastContainer } from "react-toastr";
import { getPaises } from '../../../../../../../actions/miniCartActions';
import { createCampaign, getLeadTypes, dispatchAllLeadTypes } from "../../../../../../../actions/myihtpActions";

let container;

const initialState = {
    id_campaign: '',
    description: '',
    id_leadtype: '',
    country: 'PRT',
    campaignMoosend: {
        id_platform: 1,
        id_ad: '',
        name_ad: ''
    },
    campaignFacebook: {
        id_platform: 2,
        id_ad: '',
        name_ad: ''
    }
}
class CriarCampanha extends Component {

    constructor(props) {
        super(props)

        this.state = {
            leadTypes: [],
            countries: [],
            campaign: initialState
        };

        this.handleInput = this.handleInput.bind(this);
        this.handleInputMoosend = this.handleInputMoosend.bind(this);
        this.handleInputFacebook = this.handleInputFacebook.bind(this);
        this.createCampaignButtonClick = this.createCampaignButtonClick.bind(this);
    }

    handleInput(e) {
        let value = e.target.value;
        let name = e.target.name;
        this.setState(
            prevState => ({
                ...prevState,
                campaign: {
                    ...prevState.campaign,
                    [name]: value
                }
            })
        );
    }

    //Carregar os tipos de lead da BD
    loadLeadTypes() {
        getLeadTypes()
            .then(result => {
                this.setState(
                    prevState => ({
                        ...prevState,
                        leadTypes: result
                    })
                );

                this.props.dispatch(dispatchAllLeadTypes(result));
            })
            .catch(err => {
                container.clear();
                container.error("Erro ao buscar tipos de Leads");
            })
    }

    //Carregar countries
    loadCountries() {
        this.props.dispatch(getPaises())
            .then(result => {
                this.setState(
                    prevState => ({
                        ...prevState,
                        countries: result.data.paises
                    })
                );
            })
            .catch(err => {
            })
    }

    componentDidUpdate(prevProps) {

        if (prevProps.isTabActive === false && this.props.isTabActive === true) {
            this.loadLeadTypes();
            this.loadCountries();
        }
    }

    handleInputMoosend(e) {
        let value = e.target.value;
        let name = e.target.name;
        this.setState(
            prevState => ({
                ...prevState,
                campaign: {
                    ...prevState.campaign,
                    campaignMoosend: {
                        ...prevState.campaign.campaignMoosend,
                        [name]: value
                    }
                }
            })
        );
    }

    handleInputFacebook(e) {
        let value = e.target.value;
        let name = e.target.name;
        this.setState(
            prevState => ({
                ...prevState,
                campaign: {
                    ...prevState.campaign,
                    campaignFacebook: {
                        ...prevState.campaign.campaignFacebook,
                        [name]: value
                    }
                }
            })
        );
    }

    validateCampaignFields(id_campaign, description, id_leadtype, country) {

        //Validar se Id de Campanha está preenchido
        if (!id_campaign || id_campaign == null || id_campaign.trim().length <= 0) {
            return "Id de Campanha deve estar preenchido";
        }

        //Validar se Descrição de Campanha está preenchido
        if (!description || description == null || description.trim().length <= 0) {
            return "Descrição de Campanha deve estar preenchido";
        }

        //Validar se País pré-definido está preenchido
        if (!country || country == null || country.trim().length <= 0) {
            return "País pré-definido deve estar preenchido";
        }

        //Validar Tipo Lead preenchido
        if (!id_leadtype || id_leadtype == null || id_leadtype.trim().length <= 0) {
            return "Tipo de Lead deve estar preenchido";
        }

        return "";
    }

    validateMoosendFields(id_ad, name_ad) {

        //Validar se Id de Anúncio está preenchido
        if (!id_ad || id_ad == null || id_ad.trim().length <= 0) {
            return "Id de Mailing List do Moosend deve estar preenchido";
        }

        //Validar se Nome Anúncio está preenchido
        if (!name_ad || name_ad == null || name_ad.trim().length <= 0) {
            return "Nome de Mailing List do Moosend deve estar preenchido";
        }

        return "";
    }

    validateFacebookFields(id_ad, name_ad) {

        //Validar se Id de Anúncio está preenchido
        if (!id_ad || id_ad == null || id_ad.trim().length <= 0) {
            return "Id de Anúncio do Facebook deve estar preenchido";
        }

        //Validar se Nome Anúncio está preenchido
        if (!name_ad || name_ad == null || name_ad.trim().length <= 0) {
            return "Nome de Anúncio do Facebook deve estar preenchido";
        }

        return "";
    }

    createCampaignButtonClick() {

        var state = { ...this.state.campaign };

        //Validamos os Campos da Campanha
        var result = this.validateCampaignFields(state.id_campaign, state.description, state.id_leadtype, state.country);
        if (result.length > 0) {
            container.clear();
            container.error(result);
            return;
        }

        //Validamos os dados de Anúncio Moosend
        result = this.validateMoosendFields(state.campaignMoosend.id_ad, state.campaignMoosend.name_ad);
        if (result.length > 0) {
            container.clear();
            container.error(result);
            return;
        }

        //Validamos os dados de Anúncio Facebook
        result = this.validateFacebookFields(state.campaignFacebook.id_ad, state.campaignFacebook.name_ad);
        if (result.length > 0) {
            container.clear();
            container.error(result);
            return;
        }


        var plataformas = [];
        plataformas.push({
            Plataforma: state.campaignMoosend.id_platform,
            Anuncio: state.campaignMoosend.id_ad,
            NomeAnuncio: state.campaignMoosend.name_ad
        });

        plataformas.push({
            Plataforma: state.campaignFacebook.id_platform,
            Anuncio: state.campaignFacebook.id_ad,
            NomeAnuncio: state.campaignFacebook.name_ad
        });

        this.props.dispatch(createCampaign(state.id_campaign, state.description, state.id_leadtype, state.country, plataformas))
            .then(result => {

                container.clear();
                if (result.success === true) {
                    container.success(result.message);
                    this.setState(
                        prevState => ({
                            ...prevState,
                            campaign: initialState
                        })
                    );
                } else {
                    container.error(result.message);
                }

            }).catch(err => {
                container.clear();
                container.error("Erro ao comunicar com servidor para Criar Campanha");
            });
    }

    render() {
        return (
            <div>
                <ToastContainer ref={ref => (container = ref)} className="toast-bottom-right" />

                {/* ROW para mudar consultor de lead*/}
                <Row style={{ textAlign: "left" }}>
                    <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center" }}>
                        <span style={{ textAlign: "center" }}>
                            <b>Criar Campanha</b>
                        </span>
                        <div className="form-group">
                            <br />
                            <Row xs={12} sm={12} md={12} lg={12}>
                                <Col xs={12} sm={12} md={6} lg={6} style={{ textAlign: "right" }} >
                                    <TextField
                                        placeholder="ID de Campanha que pretende"
                                        name={"id_campaign"}
                                        style={{ maxWidth: isMobile ? "100%" : "50%" }} type="text" fullWidth={true}
                                        value={this.state.campaign.id_campaign} onChange={this.handleInput}
                                    />
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6} style={{ textAlign: "left" }} >
                                    <FormGroup>
                                        <NativeSelect name="country" id="country" placeholder="País" style={{ maxWidth: isMobile ? "100%" : "50%" }} fullWidth={true} onChange={this.handleInput} value={this.state.campaign.country}>
                                            <option value="">País</option>
                                            {this.state.countries && this.state.countries.length > 0 ?
                                                (this.state.countries.map((result, j) => {
                                                    return (
                                                        <option value={result.alpha3}>
                                                            {result.name}
                                                        </option>
                                                    );
                                                })
                                                ) : null}

                                        </NativeSelect>
                                        <FormHelperText>País Pré-Definido Campanha</FormHelperText>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <br />
                            <Row xs={12} sm={12} md={12} lg={12}>
                                <Col xs={12} sm={12} md={6} lg={6} style={{ textAlign: "right" }} >
                                    <TextField
                                        placeholder="Descrição Campanha"
                                        name={"description"}
                                        style={{ maxWidth: isMobile ? "100%" : "50%" }} type="text" fullWidth={true}
                                        value={this.state.campaign.description}
                                        onChange={this.handleInput}
                                    />
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6} style={{ textAlign: "left" }} >
                                    <FormGroup>
                                        <NativeSelect name={"id_leadtype"} id={"id_leadtype"} style={{ maxWidth: isMobile ? "100%" : "50%" }} fullWidth={true} onChange={this.handleInput} value={this.state.campaign.id_leadtype}>
                                            <option value="">Tipo Lead</option>
                                            {
                                                this.state.leadTypes.length > 0 ?
                                                    (this.state.leadTypes.map(type => {
                                                        return (<option value={type.Id}>{type.Descricao}</option>)
                                                    }))
                                                    : null
                                            }
                                        </NativeSelect>
                                        <FormHelperText>Tipo de Lead Campanha</FormHelperText>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <br />
                            <br />
                            <Row>
                                <Col xs={12} sm={12} md={6} lg={6}>
                                    {/* Campo para colocar a informação do Anúncio no Moosend*/}
                                    <Row style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center" }}>
                                        <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center", maxWidth: "90%", }}>
                                            <div style={{ border: "2px solid #F88158", borderRadius: "10px", padding: "10px" }}>
                                                <span style={{ textAlign: "center" }}>
                                                    Anúncio <span style={{ fontWeight: "600" }}> Moosend</span>
                                                </span>
                                                <br />
                                                <TextField
                                                    placeholder="Id Mailing List" name={"id_ad"}
                                                    style={{ maxWidth: isMobile ? "100%" : "85%" }} type="text" fullWidth={true}
                                                    value={this.state.campaign.campaignMoosend.id_ad} onChange={this.handleInputMoosend}
                                                />
                                                <br />
                                                <br />
                                                <TextField
                                                    placeholder="Nome Mailing List" name={"name_ad"}
                                                    style={{ maxWidth: isMobile ? "100%" : "85%" }} type="text" fullWidth={true}
                                                    value={this.state.campaign.campaignMoosend.name_ad} onChange={this.handleInputMoosend}
                                                />
                                                <br />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6}>
                                    {/* Campo para colocar a informação do Anúncio no Facebook*/}
                                    <Row style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center" }}>
                                        <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center", maxWidth: "90%", }}>
                                            <div style={{ border: "2px solid #0080FE", borderRadius: "10px", padding: "10px" }}>
                                                <span style={{ textAlign: "center" }}>
                                                    Anúncio <span style={{ fontWeight: "600" }}> Facebook</span>
                                                </span>
                                                <br />
                                                <TextField
                                                    placeholder="Id Anúncio" name={"id_ad"}
                                                    style={{ maxWidth: isMobile ? "100%" : "85%" }} type="text" fullWidth={true}
                                                    value={this.state.campaign.campaignFacebook.id_ad} onChange={this.handleInputFacebook}
                                                />
                                                <br />
                                                <br />
                                                <TextField
                                                    placeholder="Nome Anúncio" name={"name_ad"}
                                                    style={{ maxWidth: isMobile ? "100%" : "85%" }} type="text" fullWidth={true}
                                                    value={this.state.campaign.campaignFacebook.name_ad} onChange={this.handleInputFacebook}
                                                />
                                                <br />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <br />
                            <br />
                            <Row>
                                <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center" }}>
                                    <Button variant="contained" color="secondary" style={{ minWidth: "20%" }} onClick={this.createCampaignButtonClick}>
                                        Criar Campanha
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};

export default withRouter(connect(mapStateToProps)(CriarCampanha));