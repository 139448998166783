import React, { Component } from "react";

import ReactTable from "react-table";
import "react-table/react-table.css";
import { FormattedMessage, FormattedDate } from "react-intl";

import Configs from "../../../../../../helpers/Configs";
import {
  getAllDaySchedules,
  getUserContactsRede
} from "../../../../../../actions/myihtpActions";
import DialogInformation from "../../Helper/DialogInformation";
import InformationIcon from "../../Comissoes/Helper/InformationIcon";

class Agendamentos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      daySchedules: [],
      noDataGetDaySchedules: null,
      showPaginationGetDaySchedules: false,
      defaultPageSizeGetDaySchedules: 5
    };
  }

  componentDidMount() {
    getAllDaySchedules().then(data => {
      if (
        data.daySchedules.message !== "Não tem eventos disponiveis." &&
        !data.daySchedules.error
      ) {
        this.setState({ daySchedules: data.daySchedules });
      }
    });
  }

  getUserContacts(id_cliente) {
    getUserContactsRede(id_cliente)
      .then(data => {
        this.setState({ user: data });
      })
      .catch(err => {
        console.log(err);
      });
  }

  handleClickOpen = () => {
    this.setState({
      open: true
    });
  };

  handleClose = value => {
    this.setState({ selectedValue: value, open: false });
  };

  render() {
    const columnsDaySchedules = [
      {
        Header: "",
        resizable: false,
        width: 32,
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              background:
                rowInfo && rowInfo.original.ID_Cliente
                  ? "rgba(180, 206, 239, 0.54)"
                  : null
            }
          };
        },
        Cell: row => {
          return (
            <span
              class="fake-link"
              id="fake-link-1"
              onClick={() => {
                this.handleClickOpen();
                this.getUserContacts(row.original.ID_Cliente);
              }}
            >
              <InformationIcon />
            </span>
          );
        }
      },
      {
        Header: (
          <FormattedMessage id="myihtp.agendamentosDia.table.dataAgendamento" />
        ),

        accessor: "dataAgendamento",
        resizable: false,
        sortable: true,
        Cell: row => {
          return (
            <FormattedDate value={new Date(row.original.dataAgendamento)} />
          );
        }
      },
      {
        Header: (
          <FormattedMessage id="myihtp.agendamentosDia.table.tipoEventoContacto" />
        ),
        accessor: "tipoEvento",
        resizable: false
      },
      {
        Header: <FormattedMessage id="myihtp.agendamentosDia.table.cliente" />,
        accessor: "cliente",
        resizable: false
      },
      {
        Header: (
          <FormattedMessage id="myihtp.agendamentosDia.table.observacao" />
        ),
        accessor: "observacao",
        resizable: false
      }
    ];
    return (
      <div>
        <hr />
        <DialogInformation
          selectedValue={this.state.selectedValue}
          open={this.state.open}
          onClose={this.handleClose}
          user={this.state.user}
        />
        <ReactTable
          className="-highlight tableReact"
          data={this.state.daySchedules}
          getTheadProps={(state, rowInfo, column) => {
            return {
              style: {
                fontWeight: Configs.myIHTP.tableConfig.fontHeightHeader,
                fontSize: Configs.myIHTP.tableConfig.fontSizeHeader,
                height: Configs.myIHTP.tableConfig.heightHeader,
                color: Configs.myIHTP.tableConfig.colorHeader,
                backgroundColor:
                  Configs.myIHTP.tableConfig.backgroundColorHeader
              }
            };
          }}
          getPaginationProps={state => {
            return {
              style: {
                color: Configs.myIHTP.tableConfig.colorHeader,
                backgroundColor:
                  Configs.myIHTP.tableConfig.backgroundColorHeader,
                fontSize: Configs.myIHTP.tableConfig.fontSizeRowBody
              }
            };
          }}
          getTrProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                "text-align": "center",
                "font-size": Configs.myIHTP.tableConfig.fontSizeRowBody
              }
            };
          }}
          defaultPageSize={Configs.myIHTP.tableConfig.defaultPageSize}
          showPagination={
            this.state.daySchedules.length >
            Configs.myIHTP.tableConfig.defaultPageSize
              ? Configs.myIHTP.tableConfig.showPagination
              : false
          }
          previousText={Configs.myIHTP.tableConfig.buttonCopyAnterior}
          nextText={Configs.myIHTP.tableConfig.buttonCopyProximo}
          noDataText={Configs.myIHTP.tableConfig.noDataText}
          pageText={Configs.myIHTP.tableConfig.pageText}
          ofText={Configs.myIHTP.tableConfig.ofText}
          rowsText={Configs.myIHTP.tableConfig.rowsText}
          columns={columnsDaySchedules}
        />
      </div>
    );
  }
}
export default Agendamentos;
