import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
import $ from "jquery";
import React, { Component } from "react";
import { withCookies } from "react-cookie";
import { connect } from "react-redux";

class SearchButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      textoPesquisa: {
        texto: ""
      }
    };

    this.handleInput = this.handleInput.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  onchangeSearchShop = () => {
    if (this.state.open) {
      $(".select-css").css("display", "");
      $(".cartIcon").css("display", "inline-flex");
      $(".loginIcon").css("display", "");
      $(".seguirButtton").css("display", "none");
      $(".pesquisarTexto").css("display", "none");
      $(".pesquisarTexto").css("width", "0%");
      $(".pesquisar").css("background", "rgba(3, 66, 117, 1)");
      $(".svgColor").css("fill", "white");
      $(".pesquisar").css("min-width", "");
      this.setState({ open: false });
    } else {
      $(".select-css").css("display", "none");
      $(".cartIcon").css("display", "none");
      $(".loginIcon").css("display", "none");
      $(".seguirButtton").css("display", "");
      $(".pesquisarTexto").css("display", "");
      $(".pesquisarTexto").css("margin-left", "8px");
      $(".pesquisarTexto").css("width", "61%");
      $(".pesquisarTexto").css("flex", "1");
      $(".pesquisar").css("min-width", "82%");
      $(".pesquisar").css("background", "white");
      $(".svgColor").css("fill", "black");
      this.setState({ open: true });
    }
  };

  pesquisarFunc = () => {
    this.search();
  };

  search() {
    window.location = "https://shop.ihavethepower.net/Loja/Catalogo/PES?search=" + this.state.textoPesquisa.texto;
  }

  handleInput(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState(
      prevState => ({
        textoPesquisa: {
          ...prevState.textoPesquisa,
          [name]: value
        }
      }));
  }

  handleKeyPress(target) {
    if (target.charCode == 13) {
      this.search();
    }
  }

  render() {
    return (
      <Paper
        style={{
          //mudar a cor do header para saber se tem impersonate ou nao - cor para consultor 5% rgba(3, 66, 117, 1)
          background:
            this.props.user.persona != this.props.user.userID
              ? this.props.user.persona
                ? "#05ef00"
                : "rgba(3, 66, 117, 1)"
              : this.props.user.ConsultantType == 0
                ? "#e2e220"
                : "rgba(3, 66, 117, 1)",
          boxShadow: "none"
        }}
        className="pesquisar"
        elevation={1}
      >
        <InputBase
          placeholder="Pesquisar"
          name="texto"
          className="pesquisarTexto"
          style={{ display: "none" }}
          onChange={this.handleInput}
          onKeyPress={this.handleKeyPress}
        />
        <IconButton
          className="seguirButtton"
          style={{ display: "none" }}
          onClick={this.onchangeSearchShop}
        >
          <svg
            height="25px"
            viewBox="0 0 174.239 174.239"
            width="25px"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <path
                fill=""
                d="M87.12,0C39.082,0,0,39.082,0,87.12s39.082,87.12,87.12,87.12s87.12-39.082,87.12-87.12S135.157,0,87.12,0z M87.12,159.305
      c-39.802,0-72.185-32.383-72.185-72.185S47.318,14.935,87.12,14.935s72.185,32.383,72.185,72.185S126.921,159.305,87.12,159.305z"
              />
              <path
                fill=""
                d="M120.83,53.414c-2.917-2.917-7.647-2.917-10.559,0L87.12,76.568L63.969,53.414c-2.917-2.917-7.642-2.917-10.559,0
      s-2.917,7.642,0,10.559l23.151,23.153L53.409,110.28c-2.917,2.917-2.917,7.642,0,10.559c1.458,1.458,3.369,2.188,5.28,2.188
      c1.911,0,3.824-0.729,5.28-2.188L87.12,97.686l23.151,23.153c1.458,1.458,3.369,2.188,5.28,2.188c1.911,0,3.821-0.729,5.28-2.188
      c2.917-2.917,2.917-7.642,0-10.559L97.679,87.127l23.151-23.153C123.747,61.057,123.747,56.331,120.83,53.414z"
              />
            </g>
          </svg>
        </IconButton>
        <IconButton
          onClick={
            this.state.open ? this.pesquisarFunc : this.onchangeSearchShop
          }
        >
          <svg
            height="25px"
            viewBox="0 0 56.966 56.966"
            width="25px"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="svgColor"
              fill="white"
              d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23
s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92
c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17
s-17-7.626-17-17S14.61,6,23.984,6z"
            />
          </svg>
        </IconButton>
      </Paper>
    );
  }
}

const mapStateToProps = state => {
  return {
    isVerified: state.isVerified,
    user: state.user
  };
};

export default withCookies(connect(mapStateToProps)(SearchButton));
