import { FormControl, FormGroup, FormLabel } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import NativeSelect from "@material-ui/core/NativeSelect";
import TextField from "@material-ui/core/TextField";
import { instanceOf } from "prop-types";
import React, { Component } from "react";
import { Cookies, withCookies } from "react-cookie";
import DatePicker from "react-date-picker";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastr";
import { Col, Row } from "reactstrap";
import { getPaises, register } from "../../../actions/miniCartActions";
import { funnelStep } from "../../../Analytics/DataLayer";
import Routes from "../../../helpers/Routes";
import RegisterDialog from "./RegisterDialog";
import { registerWithFacebook, registerWithGoogle } from "../../../actions/userActions";
import "./Register.css";
import { FormattedHTMLMessage } from "react-intl";
import { FaFacebookF } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import CrossDomainEventHandler from "../../../helpers/CrossDomainEventHandler";

let container;

const headerStyle = {
  textAlign: "center",
  fontSize: "25px",
  marginTop: "10px"
};

const buttonStyle = {
  backgroundColor: "#CA006A",
  color: "white",
  width: "25vw",
  fontFamily: "Montserrat",
  fontWeight: "500"
};

class Register extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  constructor(props) {
    super(props);

    this.state = {
      novoUtilizador: {
        nomeCompleto: "",
        email: "",
        emailRecomendacao: "",
        pais: "",
        telefone: "",
        nacionalidade: "",
        dataNascimento: null,
        refer: "",
        gdpr: "N"
      },
      isRegisterFromFacebook: false,
      isRegisterFromGoogle: false,
      open: false,
      paisesOptions: [],
      facebookData: {
        id: null,
        accessToken: null
      },
      googleData: {
        accessToken: null,
        refreshToken: null,
        idToken: null
      }
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleClearForm = this.handleClearForm.bind(this);
    this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleDataNascimentoChange = this.handleDataNascimentoChange.bind(this);
  }

  componentDidMount() {
    if (this.captchaDemo) {
      this.captchaDemo.reset();
      this.captchaDemo.execute();
    }

    const { cookies } = this.props;
    this.setState(
      prevState => ({
        novoUtilizador: {
          ...prevState.novoUtilizador,
          refer: cookies.get("refer")
        }
      })
    );

    this.props.dispatch(getPaises()).then(data => {
      if (!data.data.error) {
        const paises = data.data.paises.map(item => {
          item.id = item.alpha3;
          item.title = item.name;
          return item;
        });
        this.setState({ paisesOptions: paises });
      }
    });


    //Se tivermos a informação do Facebook
    if (this.props.facebookData !== null) {
      this.setState(prevState => ({
        ...prevState,
        isRegisterFromFacebook: true,
        isRegisterFromGoogle: false,
        facebookData: {
          id: this.props.facebookData.id,
          accessToken: this.props.facebookData.accessToken
        },
        novoUtilizador: {
          ...prevState.novoUtilizador,
          email: this.props.facebookData.email,
          nomeCompleto: this.props.facebookData.name
        }
      }))
    }

    //Se tivermos a informação do Google
    if (this.props.googleData !== null) {
      this.setState(prevState => ({
        ...prevState,
        isRegisterFromFacebook: false,
        isRegisterFromGoogle: true,
        googleData: {
          accessToken: this.props.googleData.accessToken,
          refreshToken: this.props.googleData.refreshToken,
          idToken: this.props.googleData.idToken,
        },
        novoUtilizador: {
          ...prevState.novoUtilizador,
          email: this.props.googleData.email,
          nomeCompleto: this.props.googleData.name
        }
      }))
    }
  }

  handleClickOpen = id => {
    this.setState({
      open: true
    });
  };

  handleClose = value => {
    this.setState({ selectedValue: value, open: false });
  };

  handleInput(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState(
      prevState => ({
        novoUtilizador: {
          ...prevState.novoUtilizador,
          [name]: value,
        }
      })
    );
  }

  handleDataNascimentoChange = dataNascimento => {
    this.setState(prevState => {
      return {
        ...prevState,
        novoUtilizador: {
          ...prevState.novoUtilizador,
          dataNascimento: dataNascimento
        }
      }
    })
  }

  handleCheck(e) {
    this.setState(
      prevState => ({
        novoUtilizador: {
          ...prevState.novoUtilizador,
          gdpr: this.state.novoUtilizador.gdpr === "N" ? "S" : "N"
        }
      })
    );
  }

  handleFormSubmit(e) {
    e.preventDefault();

    //Se o utilizador tiver preenchido que aceita o RGPD
    if (this.props.isVerified) {

      if (this.state.isRegisterFromFacebook === true) {

        this.props.dispatch(registerWithFacebook(this.state.novoUtilizador, this.state.facebookData.id, this.state.facebookData.accessToken))
          .then(result => {
            console.log(result);
            if (result.success === true) {
              container.success("Registado com Sucesso");

              CrossDomainEventHandler.sendEvent();
              setTimeout(function () {
                window.location.href = "/dashboard";
              }, 1200);


            } else {
              container.clear();
              container.error(result.message);
            }
          })
          .catch(err => {
            container.clear();
            container.error("Erro ao registar utilizador usando a conta do Facebook");
          });

      } else if (this.state.isRegisterFromGoogle === true) {

        this.props.dispatch(registerWithGoogle(this.state.novoUtilizador, this.state.googleData.accessToken, this.state.googleData.refreshToken, this.state.googleData.idToken))
          .then(result => {
            console.log(result);
            if (result.success === true) {
              container.success("Registado com Sucesso");

              CrossDomainEventHandler.sendEvent();
              setTimeout(function () {
                window.location.href = "/dashboard";
              }, 1200);


            } else {
              container.clear();
              container.error(result.message);
            }
          })
          .catch(err => {
            container.clear();
            container.error("Erro ao registar utilizador usando a conta do Google");
          });

      } else {

        this.props.dispatch(register(this.state.novoUtilizador))
          .then(data => {
            if (data.message === "Email de confirmação de conta enviado com sucesso!") {
              funnelStep(1);
              this.setState(prevState => ({
                ...prevState,
                open: true,
                novoUtilizador: {
                  ...prevState.novoUtilizador,
                  nomeCompleto: "",
                  email: "",
                  emailRecomendacao: "",
                  pais: "",
                  telefone: "",
                  nacionalidade: "",
                  dataNascimento: "",
                  gdpr: "N"
                }
              }));
            } else {
              container.clear();
              container.error(`${data.message}`);
            }
          }).catch(err => {
            container.clear();
            container.error("Erro ao registar o utilizador. Por favor tente mais tarde.");
          })
      }

      //Se o utilizador não tiver aceite o rgpd
    } else {
      container.clear();
      container.info(`Tem a certeza que preencheu todos os dados?`);
    }
  }

  handleClearForm(e) {
    e.preventDefault();
    this.setState({
      novoUtilizador: {
        nomeCompleto: "",
        email: "",
        emailRecomendacao: "",
        pais: "",
        telefone: "",
        nacionalidade: "",
        dataNascimento: "",
        gdpr: "N",
        isVerified: false
      }
    });
  }

  verifyCallback(response) {
    if (response) {
      this.setState(
        prevState => ({
          novoUtilizador: {
            ...prevState.novoUtilizador,
            isVerified: true
          }
        })
      );
    } else {
      this.captchaDemo.reset();
      this.captchaDemo.execute();
    }
  }

  onLoadRecaptcha() {
    if (this.captchaDemo) {
      this.captchaDemo.reset();
      this.captchaDemo.execute();
    }
  }

  handleOptionChange(e) {
    var value = e.target.value;
    this.setState(
      prevState => ({
        novoUtilizador: {
          ...prevState.novoUtilizador,
          gdpr: value
        }
      })
    );
  }

  render() {
    return (
      <div style={{ width: "100%", height: "auto", top: "60px", margin: "0px", display: "block", position: "relative" }}>

        <RegisterDialog
          selectedValue={this.state.selectedValue}
          open={this.state.open}
          //open={true}
          onClose={this.handleClose}
          comentarios={this.state.Comentarios}
          updateMoradasFunction={this.updateMoradas}
        />
        <ToastContainer
          ref={ref => (container = ref)}
          className="toast-bottom-right"
        />
        <div style={{ width: "75%", margin: "auto" }} >
          <Row >
            <Col>
              {/* Header de Registo */}
              <Row style={{ margin: "10px 0px 15px 0px" }}>
                <Col style={headerStyle} lg={12}>
                  {this.state.isRegisterFromGoogle === true ?
                    (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <FormattedHTMLMessage id="registar.tituloG" />
                      <FcGoogle style={{ fontSize: "25px", marginLeft: "10px" }} />
                    </div>
                    ) : (
                      this.state.isRegisterFromFacebook === true ? (
                        (
                          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <FormattedHTMLMessage id="registar.tituloFB" />
                            <FaFacebookF style={{ color: "#3B5998", fontSize: "25px", marginLeft: "5px" }} />
                          </div>
                        )
                      ) : (
                          <FormattedHTMLMessage id="registar.titulo" />
                        ))}

                </Col>
              </Row>

              {/* 1º linha (Nome Completo e Email)*/}
              <Row style={{ marginBottom: "18px" }}>
                <Col xs={12} sm={6} md={6} lg={6}>
                  <TextField id="nome" label={"Insira o nome completo"} className="left textFieldFontStyle"
                    required={true}
                    fullWidth={true}
                    name={"nomeCompleto"}
                    value={this.state.novoUtilizador.nomeCompleto}
                    onChange={this.handleInput}
                    type="email"
                  />
                </Col>
                <Col xs={12} sm={6} md={6} lg={6}>
                  <TextField
                    id="email"
                    name={"email"}
                    fullWidth={true}
                    required={true}
                    label={"Insira o seu email"}
                    className="right textFieldFontStyle"
                    value={this.state.novoUtilizador.email}
                    onChange={this.handleInput}
                    disabled={(this.state.isRegisterFromFacebook || this.state.isRegisterFromGoogle)}
                  />
                </Col>
              </Row>

              {/* 2º linha (Email Recomendação)*/}
              <Row style={{ marginBottom: "18px" }}>
                <Col xs={12} sm={6} md={6} lg={6}>
                  <TextField
                    id="emailrecomendacao"
                    name={"emailRecomendacao"}
                    className="left textFieldFontStyle"
                    fullWidth={true}
                    label={"Insira o email de recomendação"}
                    value={this.state.novoUtilizador.emailRecomendacao}
                    onChange={this.handleInput}
                  />
                </Col>
                <Col xs={12} sm={6} md={6} lg={6}>
                </Col>
              </Row>

              {/* 3º linha (País e Número de Telefone)*/}
              <Row style={{ marginBottom: "18px" }}>
                <Col xs={12} sm={6} md={6} lg={6}>
                  <NativeSelect style={{ marginTop: "14px" }}
                    id="pais"
                    name={"pais"}
                    className="left textFieldFontStyle"
                    fullWidth={true}
                    value={this.state.novoUtilizador.pais}
                    onChange={this.handleInput}
                  >
                    <option value="" disabled>
                      {"Escolha o seu país"}
                    </option>
                    {this.state.paisesOptions.map((address, j) => {
                      return (
                        <option value={address.id}>
                          {address.title}
                        </option>
                      );
                    })}
                  </NativeSelect>
                </Col>
                <Col xs={12} sm={6} md={6} lg={6}>
                  <TextField
                    id="telefone"
                    name={"telefone"}
                    className="left textFieldFontStyle"
                    fullWidth={true}
                    label={"Insira o seu número de telefone"}
                    value={this.state.novoUtilizador.telefone}
                    onChange={this.handleInput}
                  />
                </Col>
              </Row>

              {/* 4º linha (Localidade e Data Nascimento)*/}
              <Row style={{ marginBottom: "25px" }}>
                <Col xs={12} sm={6} md={6} lg={6} style={{ marginBottom: "25px" }}>
                  <TextField
                    id="localidade"
                    name={"localidade"}
                    className="right textFieldFontStyle"
                    fullWidth={true}
                    label={"Insira a sua localidade"}
                    value={this.state.novoUtilizador.localidade}
                    onChange={this.handleInput}
                  />
                </Col>
                <Col xs={12} sm={6} md={6} lg={6}>
                  <FormControl fullWidth={true} className="right textFieldFontStyle">
                    <FormLabel style={{ textAlign: "left" }}>Data de Nascimento</FormLabel>
                    <FormGroup>
                      <DatePicker
                        id="dataNascimento"
                        name={"dataNascimento"}
                        label={"Insira a sua data de nascimento"}
                        value={this.state.novoUtilizador.dataNascimento}
                        onChange={this.handleDataNascimentoChange}
                      />
                    </FormGroup>
                  </FormControl>
                </Col>
              </Row>

              {/* Politica de Dados */}
              <Row style={{ margin: "auto", textAlign: "center", marginBottom: "10px" }}>
                <Col>
                  <input type="checkbox" name="checkboxPolitica" id="checkboxPolitica" checked={(this.state.novoUtilizador.gdpr === "S")} onChange={this.handleCheck} />{" "}
                  <span className="justifyText" style={{ lineHeight: "2.5" }}>
                    Aceito a política de dados. Para obter mais detalhes, consulte a nossa{" "}
                    <a href={Routes.empresa.protecaoDados} target="_blank">política de dados</a>.</span>
                </Col>
              </Row>

              {/* Registar */}
              <Row style={{ margin: "auto", textAlign: "center", marginBottom: "15px" }}>
                <Col>
                  {this.props.loading === true ? (
                    <div>
                      <Button disabled={true} onClick={this.handleFormSubmit} style={buttonStyle}>
                        <CircularProgress size={24} className="buttonProgress" />
                      </Button>
                    </div>
                  ) : (
                      <div>
                        <Button onClick={this.handleFormSubmit} style={buttonStyle}>Registar</Button>
                      </div>
                    )}
                </Col>
              </Row>

              {/* Tem contra, entre aqui */}
              <Row style={{ margin: "auto", textAlign: "center" }}>
                <Col>
                  <a href={Routes.account.login}>Já tem conta? Entre aqui!</a>
                </Col>
              </Row>
            </Col>
          </Row>
        </div >
      </div >
    );
  }
}

const mapStateToProps = state => {
  return {
    isVerified: state.isVerified,
    user: state.user,
    loading: state.loading
  };
};

export default withCookies(connect(mapStateToProps)(Register));
