import { FormControl, InputLabel, OutlinedInput, Select, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import postalCodes from 'postal-codes-js';
import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { ToastContainer } from "react-toastr";
import { getPaises } from "../../../../../../actions/miniCartActions";
import { addMoradas, editMoradaInfo, getMoradas, getTipoMoradas, removeAddress } from "../../../../../../actions/myihtpActions";
import Configs from "../../../../../../helpers/Configs";
import DialogNovaMorada from "./DialogNovaMorada";

let container;
var that;
class Moradas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      moradas: [],
      noDataGetMoradas: null,
      showPaginationGetMoradas: false,
      defaultPageSizeGetMoradas: 5,
      editInfo: {
        ID_Tipo_Morada: "",
        TipoMorada: "",
        ID_Cliente_Morada: "",
        Morada: "",
        Localidade: "",
        CodigoPostal: "",
        ID_Pais: "",
        Pais: "",
        Nome: "",
        Nif: "",
        Principal: "",
        PrincipalDesc: ""
      },
      addMorada: {
        ID_Tipo_Morada: "",
        Localidade: "",
        CodigoPostal: "",
        ID_Pais: "",
        Nome: "",
        Nif: "",
        Principal: ""
      },
      tipoMoradas: [],
      paises: [],
      expanded: {},
      activeIndex: ""
    };
    that = this;
    this.updateMoradas = this.updateMoradas;
    this.handleInput = this.handleInput.bind(this);
    this.handleAddInput = this.handleAddInput.bind(this);
  }

  handleRowExpanded(rowsState, index) {


    this.setState({
      expanded: {
        [index[0]]: !this.state.expanded[index[0]]
      },
      activeIndex: [index[0]]
    });
  }

  componentDidMount() {
    getMoradas().then(data => {
      if (
        !data.data.error &&
        data.data.message !== "Não tem moradas disponiveis."
      ) {
        this.setState({ moradas: data.data });
        getTipoMoradas().then(moradas => {
          if (!moradas.moradas.error) {
            this.setState({ tipoMoradas: moradas.moradas });
            this.props.dispatch(getPaises()).then(data => {
              if (!data.data.error) {
                const paises = data.data.paises.map(item => {
                  item.id = item.alpha3;
                  item.title = item.name;
                  return item;
                });
                this.setState({ paises: paises });
              }
            });
          }
        });
      }
    });
  }

  updateMoradas = () => {
    getMoradas().then(data => {
      this.setState({ moradas: data.data });
      getTipoMoradas().then(moradas => {
        this.setState({ tipoMoradas: moradas.moradas });
        this.props.dispatch(getPaises()).then(data => {
          const paises = data.data.paises.map(item => {
            item.id = item.alpha3;
            item.title = item.name;
            return item;
          });

          this.setState({ paises: paises });
        });
      });
    });
  };

  editMorada = id => async e => {
    e.stopPropagation();

    //Se código postal não estiver definido não deve poder continaur
    if (!this.state.editInfo.CodigoPostal) {
      container.clear();
      container.error(`Adicione um código postal valido`);
      return;
    }

    //Se Pais estiver definido não deve poder continaur
    if (!this.state.editInfo.ID_Pais) {
      container.clear();
      container.error(`Adicione um pais na morada`);
      return;
    }

    var resultValidate = postalCodes.validate(this.state.editInfo.ID_Pais, this.state.editInfo.CodigoPostal);
    if (resultValidate !== true) {
      container.clear();
      container.error(`Adicione um código postal valido`);
      return;
    }

    let regex = new RegExp(/^\d{4}([-]\d{3})$/g);
    if (this.state.editInfo.ID_Pais === 'PRT' && regex.test(this.state.editInfo.CodigoPostal) === false) {
      container.clear();
      container.error(`Adicione um código postal válido para Portugal na morada`);
      return;
    }

    editMoradaInfo(this.state.editInfo, id)
      .then(data => {

        if (data.data.message === "Sucesso ao modificar a sua morada.") {
          getMoradas().then(data => {
            this.setState({ moradas: data.data });
          });
          this.handleRowExpanded('', this.state.activeIndex);
          container.clear();
          container.success(`Morada alterada com sucesso`);
        } else {
          container.clear();
          container.error(`${data.data.messageError}`);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  addMorada = () => async e => {
    e.stopPropagation();
    addMoradas(this.state.addInfo)
      .then(data => {
        if (data.data.message === "Sucesso ao adicionar a sua morada.") {
          getMoradas().then(data => {
            this.setState({ moradas: data.data });
          });
          container.clear();
          container.success(`Morada adicionada com sucesso`);

        } else {
          container.clear();
          container.error(`${data.data.messageError}`);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  handleInput(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState(
      prevState => ({
        editInfo: {
          ...prevState.editInfo,
          [name]: value
        },
      }),
      () => console.log(this.state.editInfo)
    );
  }

  handleAddInput(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState(
      prevState => ({
        addMorada: {
          ...prevState.addMorada,
          [name]: value
        }
      }),
      () => console.log(this.state.addMorada)
    );
  }

  handleClickOpen = () => {
    this.setState({
      open: true
    });
  };

  handleClose = value => {
    this.setState({ selectedValue: value, open: false });
  };

  render() {
    return (
      <div>
        <hr />
        <ToastContainer
          ref={ref => (container = ref)}
          className="toast-bottom-right"
        />
        <Row style={{ textAlign: "center" }}>
          <Col xs="12" sm="12" md="12" lg="12">
            <b>
              <FormattedMessage id="myihtp.infopessoal.moradas.titulo" />
            </b>
            <Button color="primary" onClick={this.handleClickOpen}>
              <svg
                height="25px"
                viewBox="0 0 512 512"
                width="25px"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="m256 512c-141.160156 0-256-114.839844-256-256s114.839844-256 256-256 256 114.839844 256 256-114.839844 256-256 256zm0-475.429688c-120.992188 0-219.429688 98.4375-219.429688 219.429688s98.4375 219.429688 219.429688 219.429688 219.429688-98.4375 219.429688-219.429688-98.4375-219.429688-219.429688-219.429688zm0 0" />
                <path d="m256 365.714844c-10.097656 0-18.285156-8.1875-18.285156-18.285156v-182.859376c0-10.097656 8.1875-18.285156 18.285156-18.285156s18.285156 8.1875 18.285156 18.285156v182.859376c0 10.097656-8.1875 18.285156-18.285156 18.285156zm0 0" />
                <path d="m347.429688 274.285156h-182.859376c-10.097656 0-18.285156-8.1875-18.285156-18.285156s8.1875-18.285156 18.285156-18.285156h182.859376c10.097656 0 18.285156 8.1875 18.285156 18.285156s-8.1875 18.285156-18.285156 18.285156zm0 0" />
              </svg>
            </Button>{" "}
          </Col>
        </Row>
        <DialogNovaMorada
          selectedValue={this.state.selectedValue}
          open={this.state.open}
          onClose={this.handleClose}
          updateMoradasFunction={this.updateMoradas}
        />
        <ReactTable
          className="-highlight tableReact"
          data={this.state.moradas}
          expanded={this.state.expanded}
          onExpandedChange={(newExpanded, index, event) =>
            this.handleRowExpanded(newExpanded, index, event)
          }
          getTheadProps={(state, rowInfo, column) => {
            return {
              style: {
                fontWeight: Configs.myIHTP.tableConfig.fontHeightHeader,
                fontSize: Configs.myIHTP.tableConfig.fontSizeHeader,
                height: Configs.myIHTP.tableConfig.heightHeader,
                color: Configs.myIHTP.tableConfig.colorHeader,
                backgroundColor:
                  Configs.myIHTP.tableConfig.backgroundColorHeader
              }
            };
          }}
          getPaginationProps={state => {
            return {
              style: {
                color: Configs.myIHTP.tableConfig.colorHeader,
                backgroundColor:
                  Configs.myIHTP.tableConfig.backgroundColorHeader,
                fontSize: Configs.myIHTP.tableConfig.fontSizeRowBody
              }
            };
          }}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              onClick: (e, handleOriginal) => {
                if (column.id === "ID_MoradaCliente") {
                  var confirm = window.confirm(
                    this.props.intl.formatMessage({
                      id: "myihtp.infopessoal.moradas.eliminar"
                    })
                  );
                  if (confirm) {
                    removeAddress(rowInfo.original.ID_Cliente_Morada).then(
                      data => {
                        if (
                          data.result.message ===
                          "Sucesso ao remover a sua morada."
                        ) {
                          that.updateMoradas();
                          container.clear();
                          container.success(`Morada removida com sucesso`);
                        } else {
                          container.clear();
                          container.error(`${data.result.messageError}`);
                        }
                      }
                    );
                  }
                } else {
                  if (handleOriginal) {
                    handleOriginal();
                    this.setState({
                      editInfo: {
                        ID_Tipo_Morada: rowInfo.original.ID_Tipo_Morada,
                        ID_Cliente_Morada: rowInfo.original.ID_Cliente_Morada,
                        Morada: rowInfo.original.Morada,
                        Localidade: rowInfo.original.Localidade,
                        CodigoPostal: rowInfo.original.CodigoPostal,
                        ID_Pais: rowInfo.original.ID_Pais,
                        Pais: rowInfo.original.Pais,
                        Nome: rowInfo.original.Nome,
                        Nif: rowInfo.original.Nif,
                        Principal: rowInfo.original.Principal
                      }
                    });
                  }
                }
              },
              style: {
                "text-align": "center",
                "font-size": Configs.myIHTP.tableConfig.fontSizeRowBody
              }
            };
          }}
          defaultPageSize={Configs.myIHTP.tableConfig.defaultPageSize}
          showPagination={
            this.state.moradas.length >
              Configs.myIHTP.tableConfig.defaultPageSize
              ? Configs.myIHTP.tableConfig.showPagination
              : false
          }
          previousText={Configs.myIHTP.tableConfig.buttonCopyAnterior}
          nextText={Configs.myIHTP.tableConfig.buttonCopyProximo}
          noDataText={Configs.myIHTP.tableConfig.noDataText}
          pageText={Configs.myIHTP.tableConfig.pageText}
          ofText={Configs.myIHTP.tableConfig.ofText}
          rowsText={Configs.myIHTP.tableConfig.rowsText}
          columns={Configs.myIHTP.tableConfig.moradasinfopessoais.columns}
          SubComponent={row => {
            return (
              <Container
                style={{
                  marginTop: "15px",
                  marginBottom: "15px",
                  marginLeft: "15px",
                  marginRight: "15px",
                  alignItems: "center"
                }}
              >
                <Row>
                  <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center" }}>

                    {/* 1º linha */}
                    <Row style={{ alignItems: "center" }}>

                      {/* Tipo de Morada */}
                      <Col xs="12" sm="12" md="3" lg="3" style={{ textAlign: isMobile ? "left" : "center" }}>
                        {this.state.tipoMoradas.length > 0 ? (
                          <FormControl
                            variant="outlined"
                            style={{ width: isMobile ? "67vw" : "80%" }}
                          >
                            <InputLabel
                              ref={ref => {
                                this.InputLabelRef = ref;
                              }}
                              htmlFor="outlined-age-native-simple"
                            >
                              <FormattedMessage id="myihtp.infopessoal.moradas.ID_TipoMorada" />
                            </InputLabel>
                            <Select
                              native
                              onChange={this.handleInput}
                              input={
                                <OutlinedInput
                                  name="ID_Tipo_Morada"
                                  labelWidth={127}
                                  id="outlined-age-native-simple"
                                />
                              }
                              value={this.state.editInfo.ID_Tipo_Morada}
                            >
                              {this.state.tipoMoradas.map((morada) => {
                                return (

                                  <option value={morada.ID_TipoMorada}>
                                    {morada.Descricao}
                                  </option>
                                );
                              })}
                            </Select>
                          </FormControl>
                        ) : null}
                      </Col>

                      {/* Nome Morada */}
                      <Col xs="12" sm="12" md="3" lg="3" style={{ textAlign: isMobile ? "left" : "center" }}>
                        <TextField
                          id="Nome"
                          type="text"
                          label={this.props.intl.formatMessage({
                            id: "myihtp.infopessoal.moradas.nome"
                          })}
                          name="Nome"
                          value={this.state.editInfo.Nome}
                          onChange={this.handleInput}
                          margin="normal"
                          variant="outlined"
                          style={{ fontSize: "12px" }}
                          style={{ width: isMobile ? "67vw" : "80%" }}
                        />
                      </Col>

                      {/* Morada */}
                      <Col xs="12" sm="12" md="3" lg="3" style={{ textAlign: isMobile ? "left" : "center" }}>
                        <TextField
                          id="Morada"
                          type="text"
                          value={this.state.editInfo.Morada}
                          label={this.props.intl.formatMessage({
                            id: "myihtp.infopessoal.moradas.Morada"
                          })}
                          name="Morada"
                          onChange={this.handleInput}
                          margin="normal"
                          variant="outlined"
                          style={{ fontSize: "12px" }}
                          style={{ width: isMobile ? "67vw" : "80%" }}
                        />
                      </Col>
                      <br />
                      {/* Localidade*/}
                      <Col xs="12" sm="12" md="3" lg="3" style={{ textAlign: isMobile ? "left" : "center" }}>
                        <TextField
                          id="Localidade"
                          type="text"
                          label={this.props.intl.formatMessage({
                            id: "myihtp.infopessoal.moradas.Localidade"
                          })}
                          value={this.state.editInfo.Localidade}
                          name="Localidade"
                          onChange={this.handleInput}
                          margin="normal"
                          variant="outlined"
                          style={{ fontSize: "12px" }}
                          style={{ width: isMobile ? "67vw" : "80%" }}
                        />
                      </Col>

                    </Row>

                    {/* 2º Linha */}
                    <Row style={{ alignItems: "center", marginTop: "7px" }}>

                      {/* Código Postal */}
                      <Col xs="12" sm="12" md="3" lg="3" style={{ textAlign: isMobile ? "left" : "center" }}>
                        <TextField
                          id="CodigoPostal"
                          type="text"
                          label={this.props.intl.formatMessage({
                            id: "myihtp.infopessoal.moradas.CodigoPostal"
                          })}
                          value={this.state.editInfo.CodigoPostal}
                          name="CodigoPostal"
                          onChange={this.handleInput}
                          margin="normal"
                          variant="outlined"
                          style={{ fontSize: "12px" }}
                          style={{ width: isMobile ? "67vw" : "80%" }}
                        />
                      </Col>
                      <br />
                      {/* Paises  */}
                      <Col xs="12" sm="12" md="3" lg="3" style={{ textAlign: isMobile ? "left" : "center" }}>
                        {this.state.paises.length > 0 ? (
                          <FormControl
                            variant="outlined"
                            style={{ width: isMobile ? "67vw" : "80%" }}
                          >
                            <InputLabel
                              ref={ref => {
                                this.InputLabelRef = ref;
                              }}
                              htmlFor="outlined-age-native-simple"
                            >
                              <FormattedMessage id="myihtp.infopessoal.moradas.Pais" />
                            </InputLabel>
                            <Select
                              native
                              onChange={this.handleInput}
                              input={
                                <OutlinedInput
                                  name="ID_Pais"
                                  labelWidth={127}
                                  id="outlined-age-native-simple"
                                />
                              }
                              value={this.state.editInfo.ID_Pais}
                            >
                              <option value="" />
                              {this.state.paises.map((paises, j) => {
                                return (
                                  <option value={paises.id}>
                                    {paises.name}
                                  </option>
                                );
                              })}
                            </Select>
                          </FormControl>
                        ) : null}
                      </Col>

                      {/* Nif*/}
                      <Col xs="12" sm="12" md="3" lg="3" style={{ textAlign: isMobile ? "left" : "center" }}>
                        <TextField
                          id="Nif"
                          type="text"
                          label={this.props.intl.formatMessage({
                            id: "myihtp.infopessoal.moradas.nif"
                          })}
                          name="Nif"
                          value={this.state.editInfo.Nif}
                          onChange={this.handleInput}
                          margin="normal"
                          variant="outlined"
                          style={{ fontSize: "12px" }}
                          style={{ width: isMobile ? "67vw" : "80%" }}
                        />
                      </Col>
                      <br />
                      {/* Morada Principal*/}
                      <Col xs="12" sm="12" md="3" lg="3" style={{ textAlign: isMobile ? "left" : "center" }}>
                        <FormControl
                          variant="outlined"
                          style={{ width: isMobile ? "67vw" : "80%" }}
                        >
                          <InputLabel
                            ref={ref => {
                              this.InputLabelRef = ref;
                            }}
                            htmlFor="outlined-age-native-simple"
                          >
                            <FormattedMessage id="myihtp.infopessoal.moradas.moradaPrincipal" />
                          </InputLabel>
                          <Select
                            native
                            onChange={this.handleInput}
                            input={
                              <OutlinedInput
                                name="Principal"
                                labelWidth={127}
                                id="outlined-age-native-simple"
                              />
                            }
                            value={this.state.editInfo.Principal}
                          >
                            <option value={"S"}>Sim</option>
                            <option value={"N"}>Não</option>
                          </Select>
                        </FormControl>
                      </Col>
                    </Row>
                    <br />

                    {/*3º linha */}
                    <Row style={{ alignItems: "center" }}>
                      <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: isMobile ? "left" : "center" }}>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={this.editMorada(
                            row.original.ID_Cliente_Morada
                          )}
                        >
                          <FormattedMessage id="myihtp.infopessoal.botao.guardar" />
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            );
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

export default injectIntl(connect(mapStateToProps)(Moradas));
