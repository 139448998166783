import { validateCode } from "../../../../../actions/myihtpActions";

export function activateCode(id) {
  return new Promise((resolve, reject) => {
    validateCode(id)
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        resolve(error);
      });
  });
}
