import { Avatar, Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import ImageUploader from "react-images-upload";
import { FormattedMessage, injectIntl } from "react-intl";
import "react-table/react-table.css";
import { ToastContainer } from "react-toastr";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { logout } from "../../../../../../actions/miniCartActions";
import { changeInfoPessoal, getUserInformacoes, uploadFoto } from "../../../../../../actions/myihtpActions";
import CrossDomainEventHandler from "../../../../../../helpers/CrossDomainEventHandler";

let container;
let initialEmail = null;
class Informacoes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      informacao: {
        nomecompleto: "",
        datanascimento: "",
        email: "",
        idFicheiroFoto: "",
        Particular: "",
        id_sexo: ""
      },
      isWarningToRemoveSocialLoginOpen: false,
      TipoInformacao: "Informacoes",
      pictures: []
    };

    this.onDrop = this.onDrop.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.hideWarningToSaveChanges = this.hideWarningToSaveChanges.bind(this);
    this.showWarningToSaveChanges = this.showWarningToSaveChanges.bind(this);
    this.guardarInformacoesPessoais = this.guardarInformacoesPessoais.bind(this);
    this.validatePersonalInfoBeforeSaving = this.validatePersonalInfoBeforeSaving.bind(this);
  }

  onDrop(picture) {
    this.setState({
      pictures: this.state.pictures.concat(picture)
    });
  }

  uploadImage = event => {
    uploadFoto(event)
      .then(data => {
        if (data.success === true) {
          container.clear();
          container.success(data.message);

          setTimeout(() => {
            getUserInformacoes()
              .then(data => {
                this.setState({ informacao: data.data });
              })
              .catch(error => {
                console.log(error);
              });
          }, 500);

        } else {
          container.clear();
          container.error(data.message);
        }
      })
      .catch(error => {
        container.clear();
        container.error("Erro inesperado ao atualizar foto de Perfil!");
      });
  };

  //Quando o componente carregar
  componentDidMount() {
    getUserInformacoes()
      .then(data => {
        this.setState({ informacao: data.data });
        initialEmail = data.data.email;
      })
      .catch(error => {
        console.log(error);
      });
  }

  //Lidar com o input nos dados
  handleInput(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState(
      prevState => ({
        informacao: {
          ...prevState.informacao,
          [name]: value
        },
        ID_Sexo: value
      })
    );
  }

  //Método para validar método de informações pessoais
  validatePersonalInfoBeforeSaving() {

    //Verificamos se o email que está colocado é diferente do email que tinhamos no inicio
    if (this.state.informacao.email !== initialEmail) {
      this.showWarningToSaveChanges();
      return;
    } else {
      this.guardarInformacoesPessoais();
    }
  }

  // Guarda dados na base de dados
  guardarInformacoesPessoais() {
    changeInfoPessoal(this.state.informacao, this.state.TipoInformacao)
      .then(data => {
        if (data.success === true) {

          container.clear();
          container.success(data.message);

          //Se fizemos uma alteração de email, forçamos logout
          if (this.state.informacao.email !== initialEmail) {

            this.props.dispatch(logout()).then(() => {
              CrossDomainEventHandler.sendEvent();
              setTimeout(function () {
                window.location = "/Account/LogOn";
              }, 1200);
            });

          } else {
            getUserInformacoes()
              .then(data => {
                this.setState({ informacao: data.data });
              })
              .catch(error => {
                console.log(error);
              });
          }

          this.hideWarningToSaveChanges();
        } else {
          container.clear();
          container.error(data.message);
          this.hideWarningToSaveChanges();
        }
      }
      );
  };

  //Método para mostrar aviso para confirmar ou cancelar as alterações
  showWarningToSaveChanges() {
    this.setState(prevState => ({
      ...prevState,
      isWarningToRemoveSocialLoginOpen: true
    }))
  }

  //Método para esconder aviso a dizer que iremos remover associação com logins sociais
  hideWarningToSaveChanges() {
    this.setState(prevState => ({
      ...prevState,
      isWarningToRemoveSocialLoginOpen: false
    }))
  }

  render() {
    return (
      <div>
        <ToastContainer
          ref={ref => (container = ref)}
          className="toast-bottom-right"
        />

        <Modal isOpen={this.state.isWarningToRemoveSocialLoginOpen} toggle={this.hideWarningToSaveChanges}>
          <ModalHeader toggle={this.hideWarningToSaveChanges}><FormattedMessage id="myihtp.infopessoal.alterarEmail.titulo" /></ModalHeader>
          <ModalBody>
            <FormattedMessage id="myihtp.infopessoal.alterarEmail.texto" />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.guardarInformacoesPessoais}><FormattedMessage id="myihtp.infopessoal.alterarEmail.sim" /></Button>{' '}
            <Button color="secondary" onClick={this.hideWarningToSaveChanges}><FormattedMessage id="myihtp.infopessoal.alterarEmail.nao" /></Button>
          </ModalFooter>
        </Modal>

        <Row>
          <Col xs="12" sm="12" md="12" lg="12">
            <Row style={{ textAlign: "left" }}>
              <Col xs="12" sm="12" md="12" lg="12">
                <div className="form-group">
                  <br />
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    style={{ textAlign: "center" }}
                  >
                    <Avatar
                      src={
                        this.state.informacao.NomeFisico && this.state.informacao.NomeFisico != null
                          ? "https://doremon.ihavethepower.net/assets/uploads/" +
                          this.state.informacao.NomeFisico
                          : "https://doremon.ihavethepower.net/assets/uploads/thumbnails/man-user.png"
                      }
                      // src="https://doremon.ihavethepower.net/assets/uploads/thumbnails/man-user.png"
                      style={{
                        width: isMobile ? "100px" : "150px",
                        height: isMobile ? "100px" : "150px",
                        boxShadow:
                          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                        left: "0px",
                        right: "0px",
                        margin: "auto"
                      }}
                    />
                  </Col>
                  <br />
                  {this.state.Fotografia_url ? (
                    <Row xs={12} sm={12} md={12} lg={12}>
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        style={{ textAlign: "center" }}
                      >
                        <Button
                          variant="contained"
                          color="secondary"
                          style={{ minWidth: "20%" }}
                          onClick={this.guardarInformacoesPessoais}
                        >
                          <FormattedMessage id="myihtp.infopessoal.botao.removerFoto" />
                        </Button>
                      </Col>
                    </Row>
                  ) : null}
                  <Col xs="12" sm="12" md="12" lg="12">
                    <Row style={{ textAlign: "left" }}>
                      <Col xs="12" sm="12" md="12" lg="12">
                        <div className="form-group">
                          <br />
                          <Row>
                            <Col xs={4} sm={4} md={4} lg={4}>
                              <label className="boldMyIHTP">
                                <FormattedMessage id="myihtp.infopessoal.particular" />
                              </label>
                            </Col>
                            <Col xs={4} sm={4} md={4} lg={4}>
                              <input
                                id="Particular"
                                name="Particular"
                                type="radio"
                                value="S"
                                checked={
                                  this.state.informacao.Particular === "S"
                                }
                                onChange={this.handleInput}
                              />{" "}
                              Sim
                            </Col>
                            <Col xs={4} sm={4} md={4} lg={4}>
                              <input
                                id="Particular"
                                name="Particular"
                                type="radio"
                                value="N"
                                checked={
                                  this.state.informacao.Particular === "N"
                                }
                                onChange={this.handleInput}
                              />{" "}
                              Não
                            </Col>
                          </Row>
                          <br />
                          <Row>
                            <Col xs={4} sm={4} md={4} lg={4}>
                              <label>
                                <FormattedMessage id="myihtp.infopessoal.sexo" />
                              </label>
                            </Col>
                            <Col xs={4} sm={4} md={4} lg={4}>
                              <input
                                id="ID_Sexo"
                                name="id_sexo"
                                type="radio"
                                value="M"
                                checked={this.state.informacao.id_sexo === "M"}
                                onChange={this.handleInput}
                              />{" "}
                              <FormattedMessage id="myihtp.infopessoal.masculino" />
                            </Col>
                            <Col xs={4} sm={4} md={4} lg={4}>
                              <input
                                id="ID_Sexo"
                                name="id_sexo"
                                type="radio"
                                value="F"
                                checked={this.state.informacao.id_sexo === "F"}
                                onChange={this.handleInput}
                              />{" "}
                              <FormattedMessage id="myihtp.infopessoal.feminino" />
                            </Col>
                          </Row>
                          <br />
                          <Row>
                            <Col xs={4} sm={4} md={4} lg={4}>
                              <label
                                for="confirmacaoPassword"
                                className="boldMyIHTP"
                              >
                                <FormattedMessage id="myihtp.infopessoal.nomeCompleto" />
                              </label>
                            </Col>
                            <Col xs={8} sm={8} md={8} lg={8}>
                              <TextField
                                placeholder={this.props.intl.formatMessage({
                                  id:
                                    "myihtp.infopessoal.nomeCompletoPlaceholder"
                                })}
                                name={"nomecompleto"}
                                fullWidth={true}
                                value={this.state.informacao.nomecompleto}
                                onChange={this.handleInput}
                              />
                            </Col>
                          </Row>
                          <br />
                          <Row>
                            <Col xs={4} sm={4} md={4} lg={4}>
                              <label className="boldMyIHTP">
                                <FormattedMessage id="myihtp.infopessoal.dataNascimento" />
                              </label>
                            </Col>
                            <Col xs={8} sm={8} md={8} lg={8}>
                              <TextField
                                id="DataNascimento"
                                name={"datanascimento"}
                                type="date"
                                onChange={this.handleInput}
                                value={this.state.informacao.datanascimento}
                                InputLabelProps={{
                                  shrink: true
                                }}
                              />
                            </Col>
                          </Row>
                          <br />
                          <Row>
                            <Col xs={4} sm={4} md={4} lg={4}>
                              <label className="boldMyIHTP">
                                <FormattedMessage id="myihtp.infopessoal.email" />
                              </label>
                            </Col>
                            <Col xs={8} sm={8} md={8} lg={8}>
                              <TextField
                                placeholder={this.props.intl.formatMessage({
                                  id: "myihtp.infopessoal.emailPlaceholder"
                                })}
                                name={"email"}
                                fullWidth={true}
                                value={this.state.informacao.email}
                                onChange={this.handleInput}
                              />
                            </Col>
                          </Row>
                          <br />{" "}
                          <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                              <ImageUploader
                                withIcon={false}
                                withPreview={false}
                                style={{ maxWidth: "100%" }}
                                type="file"
                                name="sampleFile"
                                id="sampleFile"
                                ref="sampleFile"
                                buttonText={
                                  <FormattedMessage id="myihtp.infopessoal.textoAdicionarImagem" />
                                }
                                onChange={this.uploadImage}
                                imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                                maxFileSize={5242880}
                                singleImage={true}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              style={{ textAlign: "center" }}
                            >
                              <Button
                                variant="contained"
                                color="secondary"
                                style={{ minWidth: "20%" }}
                                onClick={this.validatePersonalInfoBeforeSaving}
                              >
                                <FormattedMessage id="myihtp.infopessoal.botao.guardar" />
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div >
    );
  }
}

const mapStateToProps = state => {
  return {

  };
};
export default injectIntl(connect(mapStateToProps)(Informacoes));

