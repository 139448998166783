import React, { Component } from "react";

import "react-table/react-table.css";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import { injectIntl } from "react-intl";
import {
  getPaises,
  getBillingAddresses
} from "../../../../../../actions/miniCartActions";
import { Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import {
  changeOutraInfo,
  getUserInformacoes,
  getDocumentsTypes
} from "../../../../../../actions/myihtpActions";

import { ToastContainer } from "react-toastr";
import { FormattedMessage } from "react-intl";
let container;

class OutrasInformacoes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      outraInformacao: {
        id_nacionalidade: "",
        distritonacionalidade: "",
        concelhonacionalidade: "",
        naturalidade: "",
        empresa: "",
        cargo: "",
        nif: "",
        id_tipodocumento: "",
        numerodocumento: "",
        datavalidadedocumento: "",
        CV: "",
        idFicheiroCV: "",
        preferencias: "",
        numerosigo: ""
      },
      paisesOptions: [],
      tiposDocumento: [],
      TipoInformacao: "Outras"
    };

    this.handleInput = this.handleInput.bind(this);
  }
  componentDidMount() {
    this.props.dispatch(getBillingAddresses());
    this.props.dispatch(getPaises()).then(data => {
      if (!data.data.error) {
        const paises = data.data.paises.map(item => {
          item.id = item.alpha3;
          item.title = item.name;
          return item;
        });
        this.setState({ paisesOptions: paises });
      }

      getUserInformacoes()
        .then(data => {
          this.setState({ outraInformacao: data.data });
        })
        .catch(error => {
          console.log(error);
        });
      getDocumentsTypes().then(data => {
        this.setState({ tiposDocumento: data.data });
      });
    });
  }

  handleInput(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState(
      prevState => ({
        outraInformacao: {
          ...prevState.outraInformacao,
          [name]: value
        }
      })
    );
  }

  guardarOutrasInfoPessoais = async e => {
    e.stopPropagation();
    changeOutraInfo(this.state.outraInformacao, this.state.TipoInformacao).then(
      data => {
        if (data.data.message === "Sucesso a alterar os dados do seu perfil") {
          getUserInformacoes()
            .then(data => {
              this.setState({ informacao: data.data });
            })
            .catch(error => {
              console.log(error);
            });
          container.clear();
          container.success(data.data.message);
        } else {
          container.clear();
          container.warning(data.data.message);
        }
      }
    );
  };

  render() {
    return (
      <div>
        <ToastContainer
          ref={ref => (container = ref)}
          className="toast-bottom-right"
        />
        <Row>
          <Col xs="12" sm="12" md="12" lg="12">
            <Row style={{ textAlign: "left" }}>
              <Col xs="12" sm="12" md="12" lg="12">
                <div className="form-group">
                  <br />
                  <Row xs={12} sm={12} md={12} lg={12}>
                    <Col xs={4} sm={4} md={4} lg={4}>
                      <label>
                        <FormattedMessage id="myihtp.infopessoal.nacionalidade" />
                      </label>
                    </Col>
                    <Col xs={8} sm={8} md={8} lg={8}>
                      <FormControl style={{ width: "100%" }}>
                        {this.state.paisesOptions.length > 0 ? (
                          <NativeSelect
                            name={"id_nacionalidade"}
                            value={this.state.outraInformacao.id_nacionalidade}
                            onChange={this.handleInput}
                          >
                            <option value="" disabled>
                              {" "}
                              {this.props.intl.formatMessage({
                                id: "myihtp.infopessoal.nacionalidadeMenssage"
                              })}
                            </option>
                            {this.state.paisesOptions.map((address, j) => {
                              return (
                                <option value={address.id}>
                                  {address.title}
                                </option>
                              );
                            })}
                          </NativeSelect>
                        ) : null}
                      </FormControl>
                    </Col>
                  </Row>
                  <br />
                  <Row xs={12} sm={12} md={12} lg={12}>
                    <Col xs={4} sm={4} md={4} lg={4}>
                      <label>
                        <FormattedMessage id="myihtp.infopessoal.distritonacionalidade" />
                      </label>
                    </Col>
                    <Col xs={8} sm={8} md={8} lg={8}>
                      <TextField
                        placeholder={this.props.intl.formatMessage({
                          id:
                            "myihtp.infopessoal.distritonacionalidadePlaceholder"
                        })}
                        name={"distritonacionalidade"}
                        fullWidth={true}
                        value={this.state.outraInformacao.distritonacionalidade}
                        onChange={this.handleInput}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row xs={12} sm={12} md={12} lg={12}>
                    <Col xs={4} sm={4} md={4} lg={4}>
                      <label>
                        <FormattedMessage id="myihtp.infopessoal.concelhonacionalidade" />
                      </label>
                    </Col>
                    <Col xs={8} sm={8} md={8} lg={8}>
                      <TextField
                        placeholder={this.props.intl.formatMessage({
                          id:
                            "myihtp.infopessoal.concelhonacionalidadePlaceholder"
                        })}
                        name={"concelhonacionalidade"}
                        fullWidth={true}
                        value={this.state.outraInformacao.concelhonacionalidade}
                        onChange={this.handleInput}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row xs={12} sm={12} md={12} lg={12}>
                    <Col xs={4} sm={4} md={4} lg={4}>
                      <label>
                        <FormattedMessage id="myihtp.infopessoal.naturalidade" />
                      </label>
                    </Col>
                    <Col xs={8} sm={8} md={8} lg={8}>
                      <TextField
                        placeholder={this.props.intl.formatMessage({
                          id: "myihtp.infopessoal.naturalidadePlaceholder"
                        })}
                        name={"naturalidade"}
                        fullWidth={true}
                        value={this.state.outraInformacao.naturalidade}
                        onChange={this.handleInput}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row xs={12} sm={12} md={12} lg={12}>
                    <Col xs={4} sm={4} md={4} lg={4}>
                      <label>
                        <FormattedMessage id="myihtp.infopessoal.Empresa" />
                      </label>
                    </Col>
                    <Col xs={8} sm={8} md={8} lg={8}>
                      <TextField
                        placeholder={this.props.intl.formatMessage({
                          id: "myihtp.infopessoal.EmpresaPlaceholder"
                        })}
                        name={"empresa"}
                        fullWidth={true}
                        value={this.state.outraInformacao.empresa}
                        onChange={this.handleInput}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row xs={12} sm={12} md={12} lg={12}>
                    <Col xs={4} sm={4} md={4} lg={4}>
                      <label>
                        <FormattedMessage id="myihtp.infopessoal.Cargo" />
                      </label>
                    </Col>
                    <Col xs={8} sm={8} md={8} lg={8}>
                      <TextField
                        placeholder={this.props.intl.formatMessage({
                          id: "myihtp.infopessoal.CargoPlaceholder"
                        })}
                        name={"cargo"}
                        fullWidth={true}
                        value={this.state.outraInformacao.cargo}
                        onChange={this.handleInput}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row xs={12} sm={12} md={12} lg={12}>
                    <Col xs={4} sm={4} md={4} lg={4}>
                      <label>
                        <FormattedMessage id="myihtp.infopessoal.nif" />
                      </label>
                    </Col>
                    <Col xs={8} sm={8} md={8} lg={8}>
                      <TextField
                        placeholder={this.props.intl.formatMessage({
                          id: "myihtp.infopessoal.nifPlaceholder"
                        })}
                        name={"nif"}
                        fullWidth={true}
                        value={this.state.outraInformacao.nif}
                        onChange={this.handleInput}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row xs={12} sm={12} md={12} lg={12}>
                    <Col xs={4} sm={4} md={4} lg={4}>
                      <label>
                        <FormattedMessage id="myihtp.infopessoal.tipoDocumento" />
                      </label>
                    </Col>
                    <Col xs={8} sm={8} md={8} lg={8}>
                      <FormControl style={{ width: "100%" }}>
                        {this.state.tiposDocumento.length > 0 ? (
                          <NativeSelect
                            name={"id_pais"}
                            onChange={this.handleInput}
                          >
                            <option value="" disabled>
                              {" "}
                              {this.props.intl.formatMessage({
                                id:
                                  "myihtp.infopessoal.tipoDocumentoPlaceholder"
                              })}
                            </option>
                            {this.state.tiposDocumento.map((documento, j) => {
                              return (
                                <option value={documento.ID_TipoDocumento}>
                                  {documento.Descricao}
                                </option>
                              );
                            })}
                          </NativeSelect>
                        ) : null}
                      </FormControl>
                    </Col>
                  </Row>
                  <br />
                  <Row xs={12} sm={12} md={12} lg={12}>
                    <Col xs={4} sm={4} md={4} lg={4}>
                      <label>
                        {" "}
                        <FormattedMessage id="myihtp.infopessoal.numeroDocumento" />
                      </label>
                    </Col>
                    <Col xs={8} sm={8} md={8} lg={8}>
                      <TextField
                        placeholder={this.props.intl.formatMessage({
                          id: "myihtp.infopessoal.numeroDocumentoPlaceholder"
                        })}
                        name={"numerodocumento"}
                        fullWidth={true}
                        value={this.state.outraInformacao.numerodocumento}
                        onChange={this.handleInput}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row xs={12} sm={12} md={12} lg={12}>
                    <Col xs={4} sm={4} md={4} lg={4}>
                      <label>
                        {" "}
                        <FormattedMessage id="myihtp.infopessoal.dataValidadeDocumento" />
                      </label>
                    </Col>
                    <Col xs={8} sm={8} md={8} lg={8}>
                      <TextField
                        id="DataValidadeDocumento"
                        name="datavalidadedocumento"
                        type="date"
                        value={this.state.outraInformacao.datavalidadedocumento}
                        onChange={this.handleInput}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row xs={12} sm={12} md={12} lg={12}>
                    <Col xs={4} sm={4} md={4} lg={4}>
                      <label>
                        {" "}
                        <FormattedMessage id="myihtp.infopessoal.cv" />
                      </label>
                    </Col>
                    <Col xs={8} sm={8} md={8} lg={8}>
                      <TextField
                        placeholder={this.props.intl.formatMessage({
                          id: "myihtp.infopessoal.cvPlaceholder"
                        })}
                        name={"CV"}
                        fullWidth={true}
                        value={this.state.outraInformacao.CV}
                        onChange={this.handleInput}
                        multiline={true}
                        rows={4}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row xs={12} sm={12} md={12} lg={12}>
                    <Col xs={4} sm={4} md={4} lg={4}>
                      <label>
                        {" "}
                        <FormattedMessage id="myihtp.infopessoal.preferencias" />
                      </label>
                    </Col>
                    <Col xs={8} sm={8} md={8} lg={8}>
                      <TextField
                        placeholder={this.props.intl.formatMessage({
                          id: "myihtp.infopessoal.preferenciasPlaceholder"
                        })}
                        name={"preferencias"}
                        fullWidth={true}
                        value={this.state.outraInformacao.preferencias}
                        onChange={this.handleInput}
                        multiline={true}
                        rows={4}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row xs={12} sm={12} md={12} lg={12}>
                    <Col xs={4} sm={4} md={4} lg={4}>
                      <label>
                        {" "}
                        <FormattedMessage id="myihtp.infopessoal.numeroSIGO" />
                      </label>
                    </Col>
                    <Col xs={8} sm={8} md={8} lg={8}>
                      <TextField
                        placeholder={this.props.intl.formatMessage({
                          id: "myihtp.infopessoal.numeroSIGOPlaceholder"
                        })}
                        name={"numerosigo"}
                        fullWidth={true}
                        value={this.state.outraInformacao.numerosigo}
                        onChange={this.handleInput}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      style={{ textAlign: "center" }}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        style={{ minWidth: "20%" }}
                        onClick={this.guardarOutrasInfoPessoais}
                      >
                        <FormattedMessage id="myihtp.infopessoal.botao.guardar" />
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    billingAddresses: state.billingAddresses
  };
};

export default injectIntl(connect(mapStateToProps)(OutrasInformacoes));
