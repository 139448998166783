import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { connect } from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { forecastRede, getCurrentMonthForecastRede } from "../../../../../../actions/myihtpActions";
import Configs from "../../../../../../helpers/Configs";


class PrevisoesRede extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //Registers:[]
      forecastRede: [],
      monthForecast: [],
      noDataGetRecords: null,
      showPaginationGetRecords: false,
      defaultPageSizeGetRecords: 5,
      expanded: {},
    };
  }

  handleRowExpanded(rowsState, index) {
    this.setState({
      expanded: {
        [index[0]]: !this.state.expanded[index[0]],
      },
    });
  }

  componentDidMount() {
    forecastRede().then(data => {
      if (
        data.forecastRede.message !== "Não tem registos disponiveis." &&
        !data.forecastRede.error
      ) {
        this.setState({ forecastRede: data.forecastRede });
      }
    });
  }

  getMonthForecastRede = id_cliente => async e => {
    getCurrentMonthForecastRede(id_cliente)
      .then(data => {
        console.log(data.monthForecast);
        this.setState({ monthForecast: data.monthForecast });
      })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    const { currencies } = this.props;
    function BusinessCircleState(state) {
      if (state < 0.25) {
        return (
          <span>
            <svg
              height="15px"
              viewBox="0 0 512 512.00115"
              width="15px"
              xmlns="http://www.w3.org/2000/svg"
              style={{ fill: "#FF0000" }}
            >
              <path d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z" />
            </svg>
          </span>
        );
      } else if (state >= 0.25 && state < 0.5) {
        return (
          <span>
            <svg
              height="15px"
              viewBox="0 0 512 512.00115"
              width="15px"
              xmlns="http://www.w3.org/2000/svg"
              style={{ fill: "#FC8701" }}
            >
              <path d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z" />
            </svg>
          </span>
        );
      } else if (state >= 0.5 && state < 0.75) {
        return (
          <span>
            <svg
              height="15px"
              viewBox="0 0 512 512.00115"
              width="15px"
              xmlns="http://www.w3.org/2000/svg"
              style={{ fill: "#FCFF00" }}
            >
              <path d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z" />
            </svg>
          </span>
        );
      } else if (state >= 0.75 && state < 1) {
        return (
          <span>
            <svg
              height="15px"
              viewBox="0 0 512 512.00115"
              width="15px"
              xmlns="http://www.w3.org/2000/svg"
              style={{ fill: "#1600FF" }}
            >
              <path d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z" />
            </svg>
          </span>
        );
      } else if (state >= 1) {
        return (
          <span>
            <svg
              height="15px"
              viewBox="0 0 512 512.00115"
              width="15px"
              xmlns="http://www.w3.org/2000/svg"
              style={{ fill: "#01FF01" }}
            >
              <path d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z" />
            </svg>
          </span>
        );
      }
    }
    const previsaomensalatual = {
      columns: [
        {
          Header: "ID_Forecast",
          accessor: "ID_Forecast",
          show: false,
          resizable: false,
        },
        {
          Header: <FormattedMessage id="myihtp.previsaoMesAtual.table.Ano" />,
          accessor: "Ano",
          width: 50,
          resizable: false,
        },
        {
          Header: <FormattedMessage id="myihtp.previsaoMesAtual.table.Mes" />,
          accessor: "Mes",
          width: 50,
          resizable: false,
        },
        {
          Header: "Volume Pessoal",
          accessor: "VolumePessoal_P",
          show: false,
          resizable: false,
          Cell: row => {
            return (
              <FormattedNumber
                value={row.original.VolumePessoal_P}
                style="currency"
                currency={
                  currencies
                    ? currencies.selected
                      ? currencies.selected
                      : null
                    : null
                }
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
            );
          },
        },
        {
          Header: "Volume Pessoal R",
          accessor: "VolumePessoal_R",
          show: false,
          resizable: false,
          Cell: row => {
            return (
              <FormattedNumber
                value={row.original.VolumePessoal_R}
                style="currency"
                currency={
                  currencies
                    ? currencies.selected
                      ? currencies.selected
                      : null
                    : null
                }
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
            );
          },
        },
        {
          Header: "Volume Pessoal Percentagem Alcancada",
          accessor: "VolumePessoalPercentagemAlcancada",
          show: false,
          resizable: false,
          Cell: row => {
            return (
              <FormattedNumber
                value={row.original.VolumePessoalPercentagemAlcancada}
                style="currency"
                currency={
                  currencies
                    ? currencies.selected
                      ? currencies.selected
                      : null
                    : null
                }
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
            );
          },
        },
        {
          Header: (
            <FormattedMessage id="myihtp.previsaoMesAtual.table.VolumesPessoais" />
          ),
          filterable: false,
          sortable: false,
          Cell: row => {
            return (
              <Row>
                <Col
                  xs="12"
                  sm="12"
                  md="12"
                  lg="12"
                  style={{ textAlign: "center" }}
                >
                  <Row>
                    <Col
                      xs="12"
                      sm="12"
                      md="12"
                      lg="12"
                      style={{ textAlign: "center" }}
                    >
                      <FormattedNumber
                        value={row.original.VolumePessoal_P}
                        style="currency"
                        currency={
                          currencies
                            ? currencies.selected
                              ? currencies.selected
                              : null
                            : null
                        }
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                      />
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col
                      xs="12"
                      sm="12"
                      md="4"
                      lg="4"
                      style={{ textAlign: "center" }}
                    >
                      <FormattedNumber
                        value={row.original.VolumePessoal_R}
                        style="currency"
                        currency={
                          currencies
                            ? currencies.selected
                              ? currencies.selected
                              : null
                            : null
                        }
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                      />
                    </Col>
                    <Col
                      xs="12"
                      sm="12"
                      md="4"
                      lg="4"
                      style={{ textAlign: "center" }}
                    >
                      {BusinessCircleState(
                        row.row.VolumePessoalPercentagemAlcancada
                      )}
                    </Col>
                    <Col
                      xs="12"
                      sm="12"
                      md="4"
                      lg="4"
                      style={{ textAlign: "center" }}
                    >
                      <FormattedNumber
                        value={row.original.VolumePessoalPercentagemAlcancada}
                        style="percent"
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            );
          },
          resizable: false,
        },
        {
          Header: "Volume Kits",
          accessor: "VolumeKits_P",
          show: false,
          resizable: false,
        },
        {
          Header: "Volume Kits R",
          accessor: "VolumeKits_R",
          show: false,
          resizable: false,
        },
        {
          Header: "Volume Kits Percentagem Alcancada",
          accessor: "VolumeKitsPercentagemAlcancada",
          show: false,
          resizable: false,
        },
        {
          Header: (
            <FormattedMessage id="myihtp.previsaoMesAtual.table.VolumesKits" />
          ),
          filterable: false,
          sortable: false,
          Cell: row => {
            return (
              <Row>
                <Col
                  xs="12"
                  sm="12"
                  md="12"
                  lg="12"
                  style={{ textAlign: "center" }}
                >
                  <Row>
                    <Col
                      xs="12"
                      sm="12"
                      md="12"
                      lg="12"
                      style={{ textAlign: "center" }}
                    >
                      <FormattedNumber
                        value={row.original.VolumeKits_P}
                        style="currency"
                        currency={
                          currencies
                            ? currencies.selected
                              ? currencies.selected
                              : null
                            : null
                        }
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                      />
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col
                      xs="12"
                      sm="12"
                      md="4"
                      lg="4"
                      style={{ textAlign: "center" }}
                    >
                      <FormattedNumber
                        value={row.original.VolumeKits_R}
                        style="currency"
                        currency={
                          currencies
                            ? currencies.selected
                              ? currencies.selected
                              : null
                            : null
                        }
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                      />
                    </Col>
                    <Col
                      xs="12"
                      sm="12"
                      md="4"
                      lg="4"
                      style={{ textAlign: "center" }}
                    >
                      {BusinessCircleState(
                        row.row.VolumeKitsPercentagemAlcancada
                      )}
                    </Col>
                    <Col
                      xs="12"
                      sm="12"
                      md="4"
                      lg="4"
                      style={{ textAlign: "center" }}
                    >
                      <FormattedNumber
                        value={row.original.VolumeKitsPercentagemAlcancada}
                        style="percent"
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            );
          },
          resizable: false,
        },
        {
          Header: "Volume Clientes",
          accessor: "VolumeClientes_P",
          show: false,
          resizable: false,
        },
        {
          Header: "Volume Clientes R",
          accessor: "VolumeClientes_R",
          show: false,
          resizable: false,
        },
        {
          Header: "Volume Clientes Percentagem Alcancada",
          accessor: "VolumeClientesPercentagemAlcancada",
          show: false,
          resizable: false,
        },
        {
          Header: (
            <FormattedMessage id="myihtp.previsaoMesAtual.table.VolumesClientes" />
          ),
          filterable: false,
          sortable: false,
          Cell: row => {
            return (
              <Row>
                <Col
                  xs="12"
                  sm="12"
                  md="12"
                  lg="12"
                  style={{ textAlign: "center" }}
                >
                  <Row>
                    <Col
                      xs="12"
                      sm="12"
                      md="12"
                      lg="12"
                      style={{ textAlign: "center" }}
                    >
                      <FormattedNumber
                        value={row.original.VolumeClientes_P}
                        style="currency"
                        currency={
                          currencies
                            ? currencies.selected
                              ? currencies.selected
                              : null
                            : null
                        }
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                      />
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col
                      xs="12"
                      sm="12"
                      md="4"
                      lg="4"
                      style={{ textAlign: "center" }}
                    >
                      <FormattedNumber
                        value={row.original.VolumeClientes_R}
                        style="currency"
                        currency={
                          currencies
                            ? currencies.selected
                              ? currencies.selected
                              : null
                            : null
                        }
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                      />
                    </Col>
                    <Col
                      xs="12"
                      sm="12"
                      md="4"
                      lg="4"
                      style={{ textAlign: "center" }}
                    >
                      {BusinessCircleState(
                        row.row.VolumeClientesPercentagemAlcancada
                      )}
                    </Col>
                    <Col
                      xs="12"
                      sm="12"
                      md="4"
                      lg="4"
                      style={{ textAlign: "center" }}
                    >
                      <FormattedNumber
                        value={row.original.VolumeClientesPercentagemAlcancada}
                        style="percent"
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            );
          },
          resizable: false,
        },
        {
          Header: "Totais",
          accessor: "Totais_P",
          show: false,
          resizable: false,
        },
        {
          Header: "Totais R",
          accessor: "Totais_R",
          show: false,
          resizable: false,
        },
        {
          Header: "Total Percentagem Alcancada",
          accessor: "TotalPercentagemAlcancada",
          show: false,
          resizable: false,
        },
        {
          Header: (
            <FormattedMessage id="myihtp.previsaoMesAtual.table.Totais" />
          ),
          filterable: false,
          sortable: false,
          Cell: row => {
            return (
              <Row>
                <Col
                  xs="12"
                  sm="12"
                  md="12"
                  lg="12"
                  style={{ textAlign: "center" }}
                >
                  <Row>
                    <Col
                      xs="12"
                      sm="12"
                      md="12"
                      lg="12"
                      style={{ textAlign: "center" }}
                    >
                      <FormattedNumber
                        value={row.original.Totais_P}
                        style="currency"
                        currency={
                          currencies
                            ? currencies.selected
                              ? currencies.selected
                              : null
                            : null
                        }
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                      />
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col
                      xs="12"
                      sm="12"
                      md="4"
                      lg="4"
                      style={{ textAlign: "center" }}
                    >
                      <FormattedNumber
                        value={row.original.Totais_R}
                        style="currency"
                        currency={
                          currencies
                            ? currencies.selected
                              ? currencies.selected
                              : null
                            : null
                        }
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                      />
                    </Col>
                    <Col
                      xs="12"
                      sm="12"
                      md="4"
                      lg="4"
                      style={{ textAlign: "center" }}
                    >
                      {BusinessCircleState(row.row.TotalPercentagemAlcancada)}
                    </Col>
                    <Col
                      xs="12"
                      sm="12"
                      md="4"
                      lg="4"
                      style={{ textAlign: "center" }}
                    >
                      <FormattedNumber
                        value={row.original.TotalPercentagemAlcancada}
                        style="percent"
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            );
          },
          resizable: false,
        },
      ],
    };
    const columnsLastRecords = [
      {
        Header: "Nome consultor",
        accessor: "NomeCompleto",
        resizable: false,
      },
    ];

    return (
      <div>
        <hr />
        <ReactTable
          className="-highlight tableReact"
          data={this.state.forecastRede}
          expanded={this.state.expanded}
          onExpandedChange={(newExpanded, index, event) =>
            this.handleRowExpanded(newExpanded, index, event)
          }
          getTheadProps={(state, rowInfo, column) => {
            return {
              style: {
                fontWeight: Configs.myIHTP.tableConfig.fontHeightHeader,
                fontSize: Configs.myIHTP.tableConfig.fontSizeHeader,
                height: Configs.myIHTP.tableConfig.heightHeader,
                color: Configs.myIHTP.tableConfig.colorHeader,
                backgroundColor:
                  Configs.myIHTP.tableConfig.backgroundColorHeader,
              },
            };
          }}
          getPaginationProps={state => {
            return {
              style: {
                color: Configs.myIHTP.tableConfig.colorHeader,
                backgroundColor:
                  Configs.myIHTP.tableConfig.backgroundColorHeader,
                fontSize: Configs.myIHTP.tableConfig.fontSizeRowBody,
              },
            };
          }}
          getTrProps={(state, rowInfo, column, instance) => {
            var id_cliente = null;
            if (rowInfo) {
              id_cliente = rowInfo.original.ID_Cliente;
            }
            return {
              onClick: this.getMonthForecastRede(id_cliente),
              style: {
                "text-align": "center",
                "font-size": Configs.myIHTP.tableConfig.fontSizeRowBody,
              },
            };
          }}
          defaultPageSize={Configs.myIHTP.tableConfig.defaultPageSize}
          showPagination={
            this.state.forecastRede.length >
              Configs.myIHTP.tableConfig.defaultPageSize
              ? Configs.myIHTP.tableConfig.showPagination
              : false
          }
          previousText={Configs.myIHTP.tableConfig.buttonCopyAnterior}
          nextText={Configs.myIHTP.tableConfig.buttonCopyProximo}
          noDataText={Configs.myIHTP.tableConfig.noDataText}
          pageText={Configs.myIHTP.tableConfig.pageText}
          ofText={Configs.myIHTP.tableConfig.ofText}
          rowsText={Configs.myIHTP.tableConfig.rowsText}
          columns={columnsLastRecords}
          SubComponent={row => {
            return (
              <div
                style={{
                  "font-size": "14px",
                  padding: "10px",
                  backgroundColor: "#f8f9fa",
                }}
              >
                <ReactTable
                  className="-highlight  tableReact"
                  data={this.state.monthForecast}
                  getTheadProps={(state, rowInfo, column) => {
                    return {
                      style: {
                        fontSize: Configs.myIHTP.tableConfig.fontSizeHeader,
                        color: "black",
                        backgroundColor: "rgb(255, 228, 196)",
                      },
                    };
                  }}
                  getPaginationProps={state => {
                    return {
                      style: {
                        color: Configs.myIHTP.tableConfig.colorHeader,
                        backgroundColor:
                          Configs.myIHTP.tableConfig.backgroundColorHeader,
                        fontSize: Configs.myIHTP.tableConfig.fontSizeRowBody,
                      },
                    };
                  }}
                  getTrProps={(state, rowInfo, column, instance) => {
                    return {
                      style: {
                        "text-align": "center",
                        "font-size": Configs.myIHTP.tableConfig.fontSizeRowBody,
                        "align-items": "center",
                      },
                    };
                  }}
                  defaultPageSize={Configs.myIHTP.tableConfig.defaultPageSize}
                  showPagination={
                    this.state.monthForecast.length >
                      Configs.myIHTP.tableConfig.defaultPageSize
                      ? Configs.myIHTP.tableConfig.showPagination
                      : false
                  }
                  previousText={Configs.myIHTP.tableConfig.buttonCopyAnterior}
                  nextText={Configs.myIHTP.tableConfig.buttonCopyProximo}
                  noDataText={Configs.myIHTP.tableConfig.noDataText}
                  pageText={Configs.myIHTP.tableConfig.pageText}
                  ofText={Configs.myIHTP.tableConfig.ofText}
                  rowsText={Configs.myIHTP.tableConfig.rowsText}
                  columns={previsaomensalatual.columns}
                />
              </div>
            );
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currencies: state.currencies,
  };
};

export default connect(mapStateToProps)(PrevisoesRede);
