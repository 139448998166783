import React, { Component } from 'react'
import { Row, Col } from "react-bootstrap";
import { TextField, Button } from "@material-ui/core";
import { isMobile } from "react-device-detect";
import { createVoucherClient } from "../../../../../../../actions/myihtpActions";
import { ToastContainer } from "react-toastr";

let container;
export default class CriarValeCliente extends Component {


    constructor(props) {
        super(props);

        this.state = {
            id_cliente: '',
            valor: ''
        }

        this.handleInput = this.handleInput.bind(this);
    };

    //Funcao para fazer criar um vale na conta do cliente
    adicionarValeCliente = () => async e => {
        e.stopPropagation();

        //Validar id de cliente
        if (this.state.id_cliente == null || this.state.id_cliente.trim().length <= 0) {
            container.clear();
            container.error("Id de Cliente deve ser inserido");
            return;
        }

        //Validar valor de vale
        if (this.state.valor == null || this.state.valor <= 0) {
            container.clear();
            container.error("Valor de Vale deve ser superior a 0");
            return;
        }

        createVoucherClient(this.state)
            .then(data => {
                if (data.message.statusMessage === "success") {
                    container.clear();
                    container.success(data.message.Message);

                    this.setState({
                        id_cliente: '',
                        valor: ''
                    });
                } else {
                    container.clear();
                    container.error(data.message.Message);
                }
            })
            .catch(error => { });
    };

    handleInput(e) {
        let value = e.target.value;
        let name = e.target.name;
        this.setState(
            prevState => ({
                ...prevState,
                [name]: value
            })
        );
    }

    render() {
        return (
            <div>

                <ToastContainer ref={ref => (container = ref)} className="toast-bottom-right" />

                {/* ROW para criar um vale na conta do cliente*/}
                <Row style={{ textAlign: "left" }}>
                    <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center" }}>
                        <span style={{ textAlign: "center" }}>
                            <b>Criar vale na conta do cliente</b>
                        </span>
                        <div className="form-group">
                            <br />
                            <Row xs={12} sm={12} md={12} lg={12}>
                                <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }}>
                                    <TextField
                                        placeholder="ID cliente" name={"id_cliente"}
                                        style={{ maxWidth: isMobile ? "100%" : "50%" }} type="text" fullWidth={true}
                                        value={this.state.id_cliente} onChange={this.handleInput}
                                    />
                                </Col>
                            </Row>
                            <br />
                            <Row xs={12} sm={12} md={12} lg={12}>
                                <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }}>
                                    <TextField
                                        placeholder="Valor do Vale" name={"valor"}
                                        style={{ maxWidth: isMobile ? "100%" : "50%" }} type="text" fullWidth={true}
                                        value={this.state.valor} onChange={this.handleInput}
                                    />
                                </Col>
                            </Row>
                            <br />
                            <br />
                            <Row>
                                <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center" }} >
                                    <Button variant="contained" color="secondary" style={{ minWidth: "20%" }} onClick={this.adicionarValeCliente()}>
                                        Criar vale
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}
