import React, { Component } from "react";

import PrevisaoMesAtual from "./PrevisaoMesAtual/PrevisaoMesAtual";
import ComparativoMensalDashboard from "./ComparativoMensalDashboard/ComparativoMensalDashboard";
import ComparativoAnualDashboard from "./ComparativoAnualDashboard/ComparativoAnualDashboard";
import { FormattedMessage } from "react-intl";

class IndicadoresDashboard extends Component {
  render() {
    return (
      <div className="myaccount-content">
        <PrevisaoMesAtual currency={this.props.currency} />
        <hr />
        <b>
          <FormattedMessage id="myihtp.comparativomensal.title" />
        </b>
        <ComparativoMensalDashboard currency={this.props.currency} />
        <hr />
        <b>
          <FormattedMessage id="myihtp.comparativoanual.title" />
        </b>
        <ComparativoAnualDashboard currency={this.props.currency} />
      </div>
    );
  }
}
export default IndicadoresDashboard;

//<ComparativoAnualDashboard />
//<ComparativoMensalDashboard />;
