import React, { Component } from 'react'
import CriarValeCliente from './Components/CriarValeCliente'
import AdicionarProdutoCarrinho from './Components/AdicionarProdutoCarrinho'
import GestaoAvisos from './Components/GestaoAvisos'

export default class Clientes extends Component {
    render() {
        return (
            <div>
                <br />
                <br />
                <CriarValeCliente />
                <br />
                <hr />
                <br />
                <AdicionarProdutoCarrinho />
                <br />
                <hr />
                <br />
                {this.props.active ? (
                    <GestaoAvisos />
                ) : null}
                <br />
                <br />
            </div>
        )
    }
}
