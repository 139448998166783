import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
import $ from "jquery";
import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { changeCurrencyFE, logout, sideBarMenu } from "../../../actions/miniCartActions";
import Routes from "../../../helpers/Routes"; // Caminhos para as páginas
import SocialMedia from "../../SocialMedia/SocialMedia";
import "./Menu.scss";
import CrossDomainEventHandler from "../../../helpers/CrossDomainEventHandler";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currencies: [],
      textoPesquisa: {
        texto: ""
      }
    };
    this.handleInput = this.handleInput.bind(this);
    this.handleInputPesquisar = this.handleInputPesquisar.bind(this);
  }

  logoutAccount() {

    this.props.dispatch(logout()).then(() => {
      CrossDomainEventHandler.sendEvent();
      window.location = "/"
    });
  }

  handleInput(e) {
    let value = e.target.value;
    this.props.dispatch(changeCurrencyFE(value));
  }

  handleInputPesquisar(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState(
      prevState => ({
        textoPesquisa: {
          ...prevState.textoPesquisa,
          [name]: value
        }
      }),
      () => console.log(this.state.textoPesquisa)
    );
  }

  onSetSidebarOpen(open) {
    this.props.dispatch(sideBarMenu(open));
    $("#sidebarMenu").css("height", "0px");
  }

  onchangeSearchShop = () => {
    window.location =
      "/Loja/Catalogo/PES?search=" + this.state.textoPesquisa.texto;
  };

  render() {
    const { user, open, currencies } = this.props;
    return (
      <div className="bp3-md-menu">
        <Row style={{ textAlign: "center" }}>
          <Col xs="2" sm="2" md="4" lg="4" />
          <Col xs="8" sm="8" md="4" lg="4">
            <a href={Routes.home.home}>
              <img
                src="/assets/images/md-logo-ihtpweb-05sombra-1.png"
                class="bp3-md-menu-logoihtpweb05sombra1"
              />
            </a>
          </Col>
          <Col
            xs="1"
            sm="1"
            md="4"
            lg="4"
            onClick={() => this.onSetSidebarOpen(false)}
            style={{ textAlign: "right", right: "5vw", top: "3vw" }}
          >
            <svg
              width="33px"
              height="33px"
              viewBox="0 0 33 33"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                id="Menu"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g
                  id="MD"
                  transform="translate(-1311.000000, -21.000000)"
                  fill="#000000"
                >
                  <g
                    id="close-(1)"
                    transform="translate(1311.000000, 21.000000)"
                  >
                    <path
                      d="M32.6096072,0.390441109 C32.0890191,-0.130147036 31.245005,-0.130147036 30.7244169,0.390441109 L0.390441109,30.7243524 C-0.130147036,31.2449405 -0.130147036,32.0889546 0.390441109,32.6095428 C0.650702954,32.8699335 0.991917965,33 1.33306852,33 C1.67421908,33 2.01536964,32.8698691 2.27569594,32.6094783 L32.6096072,2.27556703 C33.1301309,1.75504334 33.1301309,0.911029253 32.6096072,0.390441109 Z"
                      id="Path"
                    />
                    <path
                      d="M32.6095985,30.7243524 L2.27557092,0.390441109 C1.75504634,-0.130147036 0.910966357,-0.130147036 0.390441776,0.390441109 C-0.130147259,0.9109648 -0.130147259,1.75497888 0.390441776,2.27556703 L30.7244694,32.6095428 C30.9847317,32.8698691 31.3259472,33 31.6670984,33 C32.0082495,33 32.3494651,32.8698691 32.609663,32.6096072 C33.1301231,32.0889546 33.1301231,31.2449405 32.6095985,30.7243524 Z"
                      id="Path"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </Col>
        </Row>
        <br />
        <br />
        <br />
        <Container>
          {user.userID ? (
            <div>
              <Row style={{ textAlign: "left" }}>
                <Col xs="4" sm="4" md="5" lg="5" />
                <Col xs="5" sm="5" md="3" lg="3">
                  <Row>
                    <Col xs="1" sm="1" md="1" lg="1">
                      <a href="/Dashboard">
                        <svg
                          width="28px"
                          height="28px"
                          viewBox="0 0 25 24"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g
                            id="Symbols"
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                          >
                            <g
                              id="HeaderSM"
                              transform="translate(-689.000000, -18.000000)"
                              fill="#034275"
                              fill-rule="nonzero"
                            >
                              <g
                                id="profile"
                                transform="translate(689.000000, 18.000000)"
                              >
                                <path
                                  d="M13,14 C16.8650557,14 20,10.8601998 20,6.98926095 C20,3.11832213 16.8650093,0 13,0 C9.13499068,0 6,3.13980023 6,6.98926095 C6,10.8387217 9.13494427,14 13,14 Z M13,1.8279627 C15.8343402,1.8279627 18.1533922,4.15052599 18.1533922,6.98926095 C18.1533922,9.82799591 15.8343402,12.1505127 13,12.1505127 C10.1656598,12.1505127 7.84660783,9.82794942 7.84660783,6.98921446 C7.84660783,4.1504795 10.1656134,1.8279627 13,1.8279627 Z"
                                  id="Shape"
                                />
                                <path
                                  d="M0.882585237,24 L24.1174148,24 C24.6100225,24 25,23.6216874 25,23.1438144 C25,18.6637221 21.2438546,15 16.6050796,15 L8.3949204,15 C3.77668921,15 0,18.6437928 0,23.1438144 C0,23.6216874 0.389977459,24 0.882585237,24 Z M8.3949204,16.7123713 L16.6050796,16.7123713 C19.9712475,16.7123713 22.7421774,19.1415952 23.1732425,22.2875857 L1.82675754,22.2875857 C2.25777825,19.1615245 5.02875246,16.7123713 8.3949204,16.7123713 Z"
                                  id="Shape"
                                />
                              </g>
                            </g>
                          </g>
                        </svg>
                      </a>
                    </Col>
                    <Col xs="4" sm="4" md="5" lg="5">
                      {"  "}
                      <a
                        href="/Dashboard"
                        class="bp3-md-menu-frases-poderosas"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          textAlign: "left"
                        }}
                      >
                        <FormattedMessage id="menu.MyIHTP" />
                      </a>
                    </Col>
                  </Row>
                </Col>
                <Col xs="3" sm="3" md="4" lg="4" />
              </Row>
              <br />
              <Row style={{ textAlign: "left" }}>
                <Col xs="4" sm="4" md="5" lg="5" />
                <Col xs="5" sm="5" md="3" lg="3">
                  <Row>
                    <Col xs="1" sm="1" md="1" lg="1">
                      <svg
                        width="28px"
                        height="28px"
                        viewBox="0 0 612 612"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() => this.logoutAccount()}
                      >
                        <g>
                          <g id="_x37__32_">
                            <g>
                              <path
                                fill="#034275"
                                d="M497.25,497.25c0,21.114-17.117,38.25-38.25,38.25H76.5c-21.133,0-38.25-17.136-38.25-38.25v-382.5
				c0-21.133,17.117-38.25,38.25-38.25H459c21.133,0,38.25,17.117,38.25,38.25v57.375h38.25V114.75c0-42.247-34.253-76.5-76.5-76.5
				H76.5C34.253,38.25,0,72.503,0,114.75v382.5c0,42.247,34.253,76.5,76.5,76.5H459c42.247,0,76.5-34.253,76.5-76.5v-57.375h-38.25
				V497.25z M592.875,286.875H180.043l100.272-100.272c7.478-7.458,7.478-19.584,0-27.042c-7.478-7.478-19.584-7.478-27.042,0
				L121.329,291.522c-3.997,3.978-5.699,9.256-5.432,14.478c-0.268,5.221,1.435,10.5,5.413,14.478l131.943,131.943
				c7.458,7.478,19.584,7.478,27.042,0c7.478-7.459,7.478-19.584,0-27.043L180.043,325.125h412.832
				c10.557,0,19.125-8.568,19.125-19.125C612,295.443,603.432,286.875,592.875,286.875z"
                              />
                            </g>
                          </g>
                        </g>
                      </svg>
                      {/* <img
                        onClick={() => this.logoutAccount()}
                        src="/assets/images/md-shape-6@2x.png"
                        class="bp3-md-menu-shape"
                      /> */}
                    </Col>
                    <Col
                      xs="4"
                      sm="4"
                      md="5"
                      lg="5"
                      onClick={() => this.logoutAccount()}
                    >
                      {"  "}
                      <span
                        class="bp3-md-menu-frases-poderosas"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          textAlign: "left",
                          cursor: "pointer"
                        }}
                      >
                        <FormattedMessage id="menu.Sair" />
                      </span>
                      {"  "}
                    </Col>
                  </Row>
                </Col>
                <Col xs="3" sm="3" md="4" lg="4" />
              </Row>
            </div>
          ) : (
              <div>
                <Row style={{ textAlign: "left" }}>
                  <Col xs="4" sm="4" md="5" lg="5" />
                  <Col xs="5" sm="5" md="3" lg="3">
                    <Row>
                      <Col xs="1" sm="1" md="1" lg="1">
                        <a href={Routes.account.login}>
                          <img
                            src="/assets/images/md-shape-6@2x.png"
                            class="bp3-md-menu-shape"
                          />
                        </a>
                      </Col>
                      <Col xs="4" sm="4" md="5" lg="5">
                        {"  "}
                        <a
                          href={Routes.account.login}
                          class="bp3-md-menu-frases-poderosas"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            textAlign: "left"
                          }}
                        >
                          <FormattedMessage id="menu.Entrar" />
                        </a>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs="3" sm="3" md="4" lg="4" />
                </Row>
                <br />
                <Row style={{ textAlign: "left" }}>
                  <Col xs="4" sm="4" md="5" lg="5" />
                  <Col xs="5" sm="5" md="3" lg="3">
                    <Row>
                      <Col xs="1" sm="1" md="1" lg="1">
                        <a href={Routes.account.registo}>
                          <img
                            src="/assets/images/md-shape-6@2x.png"
                            class="bp3-md-menu-shape"
                          />
                        </a>
                      </Col>
                      <Col xs="4" sm="4" md="5" lg="5">
                        {"  "}
                        <a
                          href={Routes.account.registo}
                          class="bp3-md-menu-frases-poderosas"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            textAlign: "left"
                          }}
                        >
                          <FormattedMessage id="menu.Registar" />
                        </a>
                        {"  "}
                      </Col>
                    </Row>
                  </Col>
                  <Col xs="3" sm="3" md="4" lg="4" />
                </Row>
              </div>
            )}
        </Container>
        <br />
        <br />
        <Container>
          <Row style={{ textAlign: "left" }}>
            <Col xs="4" sm="4" md="5" lg="5" />
            <Col xs="5" sm="5" md="3" lg="3">
              <Row>
                <Col xs="1" sm="1" md="1" lg="1">
                  <a href={Routes.home.home}>
                    <svg
                      height="28px"
                      viewBox="0 0 512 512"
                      width="28px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g>
                        <g>
                          <g>
                            <path
                              fill="#034275"
                              d="M487.083,225.514l-75.08-75.08V63.704c0-15.682-12.708-28.391-28.413-28.391c-15.669,0-28.377,12.709-28.377,28.391
				v29.941L299.31,37.74c-27.639-27.624-75.694-27.575-103.27,0.05L8.312,225.514c-11.082,11.104-11.082,29.071,0,40.158
				c11.087,11.101,29.089,11.101,40.172,0l187.71-187.729c6.115-6.083,16.893-6.083,22.976-0.018l187.742,187.747
				c5.567,5.551,12.825,8.312,20.081,8.312c7.271,0,14.541-2.764,20.091-8.312C498.17,254.586,498.17,236.619,487.083,225.514z"
                            />
                            <path
                              fill="#034275"
                              d="M257.561,131.836c-5.454-5.451-14.285-5.451-19.723,0L72.712,296.913c-2.607,2.606-4.085,6.164-4.085,9.877v120.401
				c0,28.253,22.908,51.16,51.16,51.16h81.754v-126.61h92.299v126.61h81.755c28.251,0,51.159-22.907,51.159-51.159V306.79
				c0-3.713-1.465-7.271-4.085-9.877L257.561,131.836z"
                            />
                          </g>
                        </g>
                      </g>
                    </svg>
                  </a>
                </Col>
                <Col xs="4" sm="4" md="5" lg="5">
                  {"  "}
                  <a
                    href={Routes.home.home}
                    class="bp3-md-menu-frases-poderosas"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      textAlign: "left"
                    }}
                  >
                    Homepage
                  </a>
                  {"  "}
                </Col>
              </Row>
            </Col>
            <Col xs="3" sm="3" md="4" lg="4" />
          </Row>
          <p />
          <Row style={{ textAlign: "left" }}>
            <Col xs="4" sm="4" md="5" lg="5" />
            <Col xs="5" sm="5" md="3" lg="3">
              <Row>
                <Col xs="1" sm="1" md="1" lg="1">
                  <a href={Routes.shop.home}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 489.4 489.4"
                      version="1.1"
                      width="28px"
                      height="28px"
                    >
                      <g id="surface1">
                        <path
                          fill="#034275"
                          d="M347.7,263.75h-66.5c-18.2,0-33,14.8-33,33v51c0,18.2,14.8,33,33,33h66.5c18.2,0,33-14.8,33-33v-51
			C380.7,278.55,365.9,263.75,347.7,263.75z M356.7,347.75c0,5-4.1,9-9,9h-66.5c-5,0-9-4.1-9-9v-51c0-5,4.1-9,9-9h66.5
			c5,0,9,4.1,9,9V347.75z"
                        />
                        <path
                          fill="#034275"
                          d="M489.4,171.05c0-2.1-0.5-4.1-1.6-5.9l-72.8-128c-2.1-3.7-6.1-6.1-10.4-6.1H84.7c-4.3,0-8.3,2.3-10.4,6.1l-72.7,128
			c-1,1.8-1.6,3.8-1.6,5.9c0,28.7,17.3,53.3,42,64.2v211.1c0,6.6,5.4,12,12,12h66.3c0.1,0,0.2,0,0.3,0h93c0.1,0,0.2,0,0.3,0h221.4
			c6.6,0,12-5.4,12-12v-209.6c0-0.5,0-0.9-0.1-1.3C472,224.55,489.4,199.85,489.4,171.05z M91.7,55.15h305.9l56.9,100.1H34.9
			L91.7,55.15z M348.3,179.15c-3.8,21.6-22.7,38-45.4,38c-22.7,0-41.6-16.4-45.4-38H348.3z M232,179.15c-3.8,21.6-22.7,38-45.4,38
			s-41.6-16.4-45.5-38H232z M24.8,179.15h90.9c-3.8,21.6-22.8,38-45.5,38C47.5,217.25,28.6,200.75,24.8,179.15z M201.6,434.35h-69
			v-129.5c0-9.4,7.6-17.1,17.1-17.1h34.9c9.4,0,17.1,7.6,17.1,17.1v129.5H201.6z M423.3,434.35H225.6v-129.5
			c0-22.6-18.4-41.1-41.1-41.1h-34.9c-22.6,0-41.1,18.4-41.1,41.1v129.6H66v-193.3c1.4,0.1,2.8,0.1,4.2,0.1
			c24.2,0,45.6-12.3,58.2-31c12.6,18.7,34,31,58.2,31s45.5-12.3,58.2-31c12.6,18.7,34,31,58.1,31c24.2,0,45.5-12.3,58.1-31
			c12.6,18.7,34,31,58.2,31c1.4,0,2.7-0.1,4.1-0.1L423.3,434.35L423.3,434.35z M419.2,217.25c-22.7,0-41.6-16.4-45.4-38h90.9
			C460.8,200.75,441.9,217.25,419.2,217.25z"
                        />
                      </g>
                    </svg>
                  </a>
                </Col>
                <Col xs="4" sm="4" md="5" lg="5">
                  {"  "}
                  <a
                    href={Routes.shop.home}
                    class="bp3-md-menu-frases-poderosas"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      textAlign: "left"
                    }}
                  >
                    <FormattedMessage id="menu.Loja" />
                  </a>
                  {"  "}
                </Col>
              </Row>
            </Col>
            <Col xs="3" sm="3" md="4" lg="4" />
          </Row>
          <p />
          <Row style={{ textAlign: "left" }}>
            <Col xs="4" sm="4" md="5" lg="5" />
            <Col xs="5" sm="5" md="3" lg="3">
              <Row>
                <Col xs="1" sm="1" md="1" lg="1">
                  <a href="https://blog.ihavethepower.net" target="_new">
                    <svg
                      height="28px"
                      viewBox="0 0 512 512"
                      width="28px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g>
                        <g>
                          <g>
                            <path
                              fill="#034275"
                              id="WordPress"
                              d="M0,215.059c0,118.786,96.274,215.059,215.059,215.059c118.759,0,215.059-96.272,215.059-215.059
		C430.117,96.303,333.817,0,215.059,0C96.274,0,0,96.303,0,215.059z M16.572,215.059c0-28.775,6.196-56.082,17.191-80.78
		l94.679,259.398C62.242,361.541,16.572,293.616,16.572,215.059z M215.059,413.541c-19.48,0-38.3-2.838-56.077-8.06l59.568-173.032
		l61.008,167.128c0.396,0.989,0.887,1.862,1.4,2.702C260.348,409.564,238.156,413.541,215.059,413.541z M375.466,211.337
		c11.323-28.317,15.112-50.955,15.112-71.084c0-7.309-0.49-14.09-1.372-20.421c15.495,28.287,24.307,60.711,24.307,95.232
		c0,73.235-39.671,137.151-98.662,171.538L375.466,211.337z M332.669,150.299c7.533,13.212,16.377,30.203,16.377,54.742
		c0,17-5.055,38.366-15.107,64.175l-19.807,66.123l-71.733-213.328c11.976-0.651,22.71-1.895,22.71-1.895
		c10.697-1.27,9.418-16.96-1.246-16.372c0,0-32.161,2.539-52.913,2.539c-19.508,0-52.264-2.539-52.264-2.539
		c-10.702-0.588-11.943,15.751-1.272,16.372c0,0,10.146,1.244,20.846,1.895l30.894,84.727L165.73,336.977L93.435,122.011
		c11.974-0.651,22.736-1.895,22.736-1.895c10.704-1.27,9.395-16.96-1.272-16.372c0,0-32.135,2.539-52.913,2.539
		c-3.72,0-8.093-0.098-12.757-0.231c35.5-53.884,96.506-89.475,165.831-89.475c51.674,0,98.723,19.767,134.02,52.131
		c-0.849-0.067-1.698-0.168-2.548-0.168c-19.541,0-33.342,16.972-33.342,35.209C313.193,120.122,322.621,133.959,332.669,150.299z"
                            />
                          </g>
                        </g>
                      </g>
                    </svg>
                  </a>
                </Col>
                <Col xs="4" sm="4" md="5" lg="5">
                  {"  "}
                  <a
                    href="https://blog.ihavethepower.net"
                    target="_new"
                    class="bp3-md-menu-frases-poderosas"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      textAlign: "left"
                    }}
                  >
                    <FormattedMessage id="menu.Blog" />
                  </a>
                  {"  "}
                </Col>
              </Row>
            </Col>
            <Col xs="3" sm="3" md="4" lg="4" />
          </Row>
          <p />
          <Row style={{ textAlign: "left" }}>
            <Col xs="4" sm="4" md="5" lg="5" />
            <Col xs="7" sm="6" md="4" lg="3">
              <Row>
                <Col xs="1" sm="1" md="1" lg="1">
                  <a href={Routes.consultor.consultor}>
                    <img
                      src="/assets/images/md-shape-8@2x.png"
                      class="bp3-md-menu-shape"
                    />
                  </a>
                </Col>
                <Col xs="9" sm="9" md="7" lg="6">
                  <a
                    href={Routes.consultor.consultor}
                    class="bp3-md-menu-frases-poderosas"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      textAlign: "left"
                    }}
                  >
                    <FormattedMessage id="menu.Serconsultor" />
                  </a>
                  {"  "}
                </Col>
              </Row>
            </Col>
            <Col xs="3" sm="3" md="4" lg="4" />
          </Row>
          <p />
          <Row style={{ textAlign: "left" }}>
            <Col xs="4" sm="4" md="5" lg="5" />
            <Col xs="5" sm="5" md="3" lg="3">
              <Row>
                <Col xs="1" sm="1" md="1" lg="1">
                  <a href={Routes.contactos.contactos}>
                    <img
                      src="/assets/images/md-path@2x.png"
                      class="bp3-md-menu-shape"
                    />
                  </a>
                </Col>
                <Col xs="4" sm="4" md="5" lg="5">
                  {"  "}
                  <a
                    href={Routes.contactos.contactos}
                    class="bp3-md-menu-frases-poderosas"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      textAlign: "left"
                    }}
                  >
                    <FormattedMessage id="menu.Contactos" />
                  </a>
                  {"  "}
                </Col>
              </Row>
            </Col>
            <Col xs="3" sm="3" md="4" lg="4" />
          </Row>
          <p />
          <br />
          <Row
            style={{ textAlign: "left", display: isMobile ? "flex" : "none" }}
          >
            <Col xs="4" sm="4" md="5" lg="5" />
            <Col xs="5" sm="5" md="3" lg="3">
              <Row style={{ textAlign: "center" }}>
                <select
                  onChange={this.handleInput}
                  name="currency"
                  class="select-css"
                  style={{
                    display: isMobile ? "block" : "none",
                    textAlignLast: "center"
                  }}
                >
                  {currencies.selected ? (
                    <option value={currencies.selected}>
                      {currencies.selectedDescription}{" "}
                    </option>
                  ) : null}
                  {currencies.list
                    ? currencies.list.map((currency, j) => {
                      return currency.ID_Moeda ===
                        currencies.selected ? null : (
                          <option value={currency.ID_Moeda}>
                            {currency.Descricao}
                          </option>
                        );
                      // );
                    })
                    : null}
                </select>
                {/* <FormControl
                  variant="outlined"
                  style={{
                    width: isMobile ? "67vw" : "30%",
                    marginTop: "7px"
                  }}
                >
                  <Select native onChange={this.handleInput}>
                    <option value="" />
                    {this.state.currencies.map((currency, j) => {
                      return (
                        <option value={currency.ID_Moeda}>
                          {currency.Descricao}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl> */}
              </Row>
            </Col>
            <Col xs="3" sm="3" md="4" lg="4" />
          </Row>
          <br />
          <Row
            style={{ textAlign: "left", display: isMobile ? "flex" : "none" }}
          >
            <Col xs="4" sm="4" md="5" lg="5" />
            <Col xs="5" sm="5" md="3" lg="3">
              <Row style={{ textAlign: "center" }}>
                <Paper
                  style={{
                    boxShadow: "none"
                  }}
                  className="pesquisar"
                  elevation={1}
                >
                  <InputBase
                    placeholder="Pesquisar"
                    name="texto"
                    className="pesquisarTexto"
                    onChange={this.handleInputPesquisar}
                    style={{ width: "50%" }}
                  />
                  <IconButton
                    className="seguirButtton"
                    onClick={this.onchangeSearchShop}
                  >
                    <svg
                      height="25px"
                      viewBox="0 0 56.966 56.966"
                      width="25px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23
s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92
c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17
s-17-7.626-17-17S14.61,6,23.984,6z"
                      />
                    </svg>
                  </IconButton>
                </Paper>
                {/* <FormControl
                  variant="outlined"
                  style={{
                    width: isMobile ? "67vw" : "30%",
                    marginTop: "7px"
                  }}
                >
                  <Select native onChange={this.handleInput}>
                    <option value="" />
                    {this.state.currencies.map((currency, j) => {
                      return (
                        <option value={currency.ID_Moeda}>
                          {currency.Descricao}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl> */}
              </Row>
            </Col>
            <Col xs="3" sm="3" md="4" lg="4" />
          </Row>
        </Container>
        <br />
        <br />
        <Row>
          <Col md="1" lg="1" />
          <Col xs="12" sm="12" md="5" lg="5" className="sobrenos">
            <a
              href={Routes.empresa.sobreNos}
              style={{ color: "rgba(3, 66, 117, 1.0)" }}
              class="bp3-md-menu-frases-poderosas"
            >
              <FormattedMessage id="menu.Sobrenos" />
            </a>
          </Col>
          <Col xs="12" sm="12" md="5" lg="5" className="protecaodados">
            <a
              href={Routes.empresa.protecaoDados}
              style={{ color: "rgba(3, 66, 117, 1.0)" }}
              class="bp3-md-menu-frases-poderosas"
            >
              <FormattedMessage id="menu.protecaodados" />
            </a>
          </Col>
          <Col md="1" lg="1" />
        </Row>
        <Row>
          <Col md="1" lg="1" />
          <Col xs="12" sm="12" md="5" lg="5" className="fundador">
            <a
              href={Routes.empresa.fundador}
              style={{ color: "rgba(3, 66, 117, 1.0)" }}
              class="bp3-md-menu-frases-poderosas"
            >
              <FormattedMessage id="menu.Fundador" />
            </a>
          </Col>
          <Col xs="12" sm="12" md="5" lg="5" className="cancelamentoegarantia">
            <a
              href={Routes.empresa.cancelamentoGarantia}
              style={{ color: "rgba(3, 66, 117, 1.0)" }}
              class="bp3-md-menu-frases-poderosas"
            >
              <FormattedMessage id="menu.cancelamento" />
            </a>
          </Col>
          <Col md="1" lg="1" />
        </Row>
        <Row>
          <Col md="1" lg="1" />
          <Col xs="12" sm="12" md="5" lg="5" className="testemunhos">
            <a
              href={Routes.empresa.testemunhos}
              style={{ color: "rgba(3, 66, 117, 1.0)" }}
              class="bp3-md-menu-frases-poderosas"
            >
              <FormattedMessage id="menu.Testemunhos" />
            </a>
          </Col>
          <Col xs="12" sm="12" md="5" lg="5" className="desafios">
            <a
              href={Routes.recursosGratis.desafios}
              style={{ color: "rgba(3, 66, 117, 1.0)" }}
              class="bp3-md-menu-frases-poderosas"
            >
              <FormattedMessage id="menu.desafios" />
            </a>
          </Col>
          <Col md="1" lg="1" />
        </Row>
        <Row>
          <Col md="1" lg="1" />
          <Col xs="12" sm="12" md="5" lg="5" className="noticias">
            <a
              href={Routes.empresa.noticias}
              style={{ color: "rgba(3, 66, 117, 1.0)" }}
              class="bp3-md-menu-frases-poderosas"
            >
              <FormattedMessage id="menu.Noticias" />
            </a>
          </Col>
          <Col xs="12" sm="12" md="5" lg="5" className="afiliados">
            <a
              href={Routes.recursosGratis.afiliados}
              style={{ color: "rgba(3, 66, 117, 1.0)" }}
              class="bp3-md-menu-frases-poderosas"
            >
              <FormattedMessage id="menu.afiliados" />
            </a>
          </Col>
          <Col md="1" lg="1" />
        </Row>
        <Row>
          <Col md="1" lg="1" />
          <Col xs="12" sm="12" md="5" lg="5" className="tvradio">
            <a
              href={Routes.empresa.imprensa}
              style={{ color: "rgba(3, 66, 117, 1.0)" }}
              class="bp3-md-menu-frases-poderosas"
            >
              <FormattedMessage id="menu.tvradioimprensa" />
            </a>
          </Col>
          <Col xs="12" sm="12" md="5" lg="5" className="frasespoderosas">
            <a
              href={Routes.recursosGratis.frases}
              style={{ color: "rgba(3, 66, 117, 1.0)" }}
              class="bp3-md-menu-frases-poderosas"
            >
              <FormattedMessage id="menu.frases" />
            </a>
          </Col>
          <Col md="1" lg="1" />
        </Row>
        <br />
        <br />
        <Row style={{ textAlign: "center" }}>
          <Col xs="12" sm="12" md="12" lg="12">
            <SocialMedia />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currencies: state.currencies,
    user: state.user
  };
};

export default connect(mapStateToProps)(Home);
