import React, { Component } from "react";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastr";
import Campanhas from "./Campanhas/Campanhas";
import Clientes from "./Clientes/Clientes";
import Consultores from "./Consultores/Consultores";
import Empresas from "./Empresas/Empresas";
import Geral from "./Geral/Geral";
import Leads from "./Leads/Leads";
import Multimedia from "./Multimedia/Multimedia";
import SubscricaoLeads from "./SubscricaoLeads/SubscricaoLeads";

let container;
class FerramentasBackOffice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "geral"
    };
  }

  render() {
    return (
      <div>
        <Row>
          <Col xs="12" sm="12" md="12" lg="12">
            <Tabs activeKey={this.state.tab} onSelect={(k) => this.setState({ tab: k })}>
              <Tab eventKey="geral" title="Geral">
                <Geral user={this.props.user} />
              </Tab>
              <Tab eventKey="clientes" title="Clientes">
                <Clientes active={(this.state.tab === 'clientes') ? true : false} />
              </Tab>
              <Tab eventKey="campanhas" title="Campanhas">
                <Campanhas active={(this.state.tab === 'campanhas') ? true : false} />
              </Tab>
              <Tab eventKey="consultores" title="Consultores">
                <Consultores />
              </Tab>
              <Tab eventKey="empresas" title="Empresas">
                <Empresas />
              </Tab>
              <Tab eventKey="multimedia" title="Multimédia">
                <Multimedia />
              </Tab>
              <Tab eventKey="subscricao" title="Subscrição Leads">
                <SubscricaoLeads active={(this.state.tab === 'subscricao') ? true : false} />
              </Tab>
              <Tab eventKey="leads" title="Negócios (Leads)">
                <Leads active={(this.state.tab === 'leads') ? true : false} />
              </Tab>
            </Tabs>
          </Col>
        </Row>

        <ToastContainer ref={ref => (container = ref)} className="toast-bottom-right" />

      </div >
    );
  }
}

const mapStateToProps = state => {
  return {
    currencies: state.currencies,
    user: state.user
  };
};
export default injectIntl(connect(mapStateToProps)(FerramentasBackOffice));
