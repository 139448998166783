import React, { Component } from 'react'
import { Row, Col } from "react-bootstrap";
import { TextField, Button } from "@material-ui/core";
import { isMobile } from "react-device-detect";
import { getCompany, changeCompanyConsultant } from "../../../../../../../actions/myihtpActions";
import { ToastContainer } from "react-toastr";

let container;
export default class MudarConsultorEmpresa extends Component {

    constructor(props) {
        super(props);

        this.state = {
            nif: '',
            id_consultor: '',
            mensagem: '',
        }

        this.handleInput = this.handleInput.bind(this);
        this.changeCompanyConsultantButtonClick = this.changeCompanyConsultantButtonClick.bind(this);
        this.getCompanyNameOnTextChange = this.getCompanyNameOnTextChange.bind(this);
    };

    handleInput(e) {
        let value = e.target.value;
        let name = e.target.name;
        this.setState(
            prevState => ({
                ...prevState,
                [name]: value
            })
        );
    }

    //Função para buscar o nome da empresa
    getCompanyNameOnTextChange() {

        console.log("Entrei Aqui 2");
        getCompany(this.state.nif)
            .then(result => {
                if (result.success === true) {

                    var nome = "";
                    if (result.obj.length > 0) {
                        nome = result.obj[0].Nome;
                    } else {
                        nome = "Não existe nenhuma empresa com esse NIF";
                    }

                    //Alteramos o valor do campo         
                    this.setState(
                        prevState => ({
                            ...prevState,
                            mensagem: nome
                        })
                    );
                }
            })
            .catch(err => {

            })

    }

    //Função para mudar o Consultor de uma lead
    changeCompanyConsultantButtonClick = () => async e => {

        //Validamos se o Campo NIF está preenchido
        if (this.state.nif.length <= 0) {
            container.clear();
            container.error("NIF de Empresa deve estar preenchido");
            return;
        }

        //Validamos se o Campo Consultor está preenchido
        if (this.state.id_consultor == null || this.state.id_consultor.trim().length <= 0) {
            container.clear();
            container.error("Campo Consultor deve estar preenchido");
            return;
        }

        changeCompanyConsultant(this.state.nif, this.state.id_consultor)
            .then(result => {

                container.clear();
                if (result.success === true) {
                    container.success(result.message);

                    this.setState({
                        nif: '',
                        id_consultor: '',
                        mensagem: '',
                    });

                } else {

                    if (typeof result.message === 'undefined') {
                        container.error("Erro ao comunicar com servidor para mudar Consultor de Lead");
                    } else {
                        container.error(result.message);
                    }
                }
            })
            .catch(err => {
                container.clear();
                container.error("Erro ao comunicar com servidor para mudar Consultor de Lead");
            })
    }


    render() {
        return (
            <div>

                <ToastContainer ref={ref => (container = ref)} className="toast-bottom-right" />

                {/* ROW alterar empresa a que consultor está atribuido*/}
                <Row style={{ textAlign: "left" }}>
                    <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center" }}>
                        <span style={{ textAlign: "center" }}>
                            <b>Mudar Consultor de Empresa</b>
                        </span>
                        <div className="form-group">
                            <br />

                            <Row xs={12} sm={12} md={12} lg={12}>
                                <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }} >
                                    <TextField placeholder="NIF Empresa" name={"nif"} id={"nif"} style={{ maxWidth: isMobile ? "100%" : "50%" }} type="text" fullWidth={true}
                                        onChange={this.handleInput}
                                        onBlur={this.getCompanyNameOnTextChange}
                                        value={this.state.nif}
                                    />
                                </Col>
                            </Row>

                            <br />

                            <Row xs={12} sm={12} md={12} lg={12}>
                                <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }} >
                                    <label style={{ margin: "0px 5px 0px" }}>Nome Empresa:</label><br />
                                    <label style={{ width: "100%", maxWidth: isMobile ? "100%" : "50%", border: "1px solid grey", color: "grey", padding: "5px", marginTop: "5px" }}><b>{(this.state.mensagem.length <= 0) ? "Nome Empresa" : this.state.mensagem}</b></label>
                                </Col>
                            </Row>

                            <br />

                            <Row xs={12} sm={12} md={12} lg={12}>
                                <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }} >
                                    <TextField placeholder="ID Consultor" name={"id_consultor"} style={{ maxWidth: isMobile ? "100%" : "50%" }} type="text" fullWidth={true}
                                        onChange={this.handleInput}
                                        value={this.state.id_consultor}
                                    />
                                </Col>
                            </Row>

                            <br />
                            <br />

                            <Row>
                                <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center" }}>
                                    <Button variant="contained" color="secondary" style={{ minWidth: "20%" }} onClick={this.changeCompanyConsultantButtonClick()}>
                                        Mudar Consultor
                      </Button>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}
