import React, { Component } from 'react'
import CriarCampanha from './Components/CriarCampanha'
import VerCampanhas from './Components/VerCampanhas'

export default class Campanhas extends Component {
    render() {
        return (
            <div>
                <br />
                <br />
                <CriarCampanha isTabActive={this.props.active} />
                <br />
                <hr />
                <br />
                <VerCampanhas isTabActive={this.props.active} />
                <br />
                <br />
            </div>
        )
    }
}
