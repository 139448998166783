import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { injectIntl } from "react-intl";

let container;

class DialogInformation extends Component {

  handleClose = () => {
    this.props.onClose(this.props.selectedValue);
  };

  handleListItemClick = value => {
    this.props.onClose(value);
  };

  render() {
    const { classes, onClose, selectedValue, user, ...other } = this.props;
    return (
      <div>
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="simple-dialog-title"
          style={{ minHeight: "100%" }}
          {...other}
          fullWidth={true}
          maxWidth={"sm"}
        >
          <Container>
            <Row>
              <Col xs="10" sm="10" md="10" lg="10">
                <DialogTitle id="simple-dialog-title">
                  Contactos do cliente
                </DialogTitle>
              </Col>
              <Col
                xs="2"
                sm="2"
                md="2"
                lg="2"
                onClick={this.handleClose}
                style={{
                  display: "flex",
                  alignItems: "center",
                  right: "10px",
                }}
              >
                <svg
                  width="33px"
                  height="33px"
                  viewBox="0 0 33 33"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    id="Menu"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <g
                      id="MD"
                      transform="translate(-1311.000000, -21.000000)"
                      fill="#000000"
                    >
                      <g
                        id="close-(1)"
                        transform="translate(1311.000000, 21.000000)"
                      >
                        <path
                          d="M32.6096072,0.390441109 C32.0890191,-0.130147036 31.245005,-0.130147036 30.7244169,0.390441109 L0.390441109,30.7243524 C-0.130147036,31.2449405 -0.130147036,32.0889546 0.390441109,32.6095428 C0.650702954,32.8699335 0.991917965,33 1.33306852,33 C1.67421908,33 2.01536964,32.8698691 2.27569594,32.6094783 L32.6096072,2.27556703 C33.1301309,1.75504334 33.1301309,0.911029253 32.6096072,0.390441109 Z"
                          id="Path"
                        />
                        <path
                          d="M32.6095985,30.7243524 L2.27557092,0.390441109 C1.75504634,-0.130147036 0.910966357,-0.130147036 0.390441776,0.390441109 C-0.130147259,0.9109648 -0.130147259,1.75497888 0.390441776,2.27556703 L30.7244694,32.6095428 C30.9847317,32.8698691 31.3259472,33 31.6670984,33 C32.0082495,33 32.3494651,32.8698691 32.609663,32.6096072 C33.1301231,32.0889546 33.1301231,31.2449405 32.6095985,30.7243524 Z"
                          id="Path"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </Col>
            </Row>
            {user ? (
              !user.data.message ? (
                <Row>
                  <Col
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    style={{ textAlign: "center" }}
                  >
                    {user.data.map(contact => {
                      return (
                        <span>
                          {" "}
                          {contact.TipoContacto + ": " + contact.Descricao}{" "}
                          <br />
                        </span>
                      );
                    })}
                  </Col>
                </Row>
              ) : (
                  <Row>
                    <Col
                      xs="12"
                      sm="12"
                      md="12"
                      lg="12"
                      style={{ textAlign: "center" }}
                    >
                      "O cliente não tem contactos disponiveis"
                  </Col>
                  </Row>
                )
            ) : null}
            <br />
          </Container>
        </Dialog>
      </div>
    );
  }
}
export default injectIntl(DialogInformation);
