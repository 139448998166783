import { Button, TextField } from "@material-ui/core";
import NativeSelect from "@material-ui/core/NativeSelect";
import dateFormat from "dateformat";
import React, { Component } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { ToastContainer } from "react-toastr";
import { getLeadOriginTypes, getLeads, getLeadTypes } from "../../../../../../../actions/myihtpActions";
import Configs from "../../../../../../../helpers/Configs";

const columns = [
    {
        Header: "Nome Consultor",
        accessor: "NomeConsultor",
        resizable: false,
        width: 200
    }, {
        Header: "Nome Cliente",
        accessor: "NomeCliente",
        resizable: false,
    },
    {
        Header: "Tipo Lead",
        accessor: "TipoLead",
        resizable: false
    },
    {
        Header: "Origem",
        accessor: "OrigemLead",
        resizable: false
    },
    {
        Header: "Data Registo",
        accessor: "DataRegisto",
        resizable: false,
        Cell: row => {
            return (<label>{dateFormat(row.value, 'dd-mm-yyyy HH:MM')}</label>);
        }
    },
]
let container;
class ProcurarLeads extends Component {

    constructor(props) {
        super(props)

        this.state = {
            id_lead: '',
            id_consultor: '',
            id_company: '',
            id_typelead: '',
            id_origin: '',
            expanded: {},
            leads: [],
            leadTypes: [],
            leadOrigins: [],
            activeIndex: "",
            loading: false,
            pages: 0,
            page: 0,
            pageSize: 5,
            sortedField: null,
            sortedAsc: null,
            currentLead: {
                nameConsultor: '',
                createDate: '',
                nameClient: '',
                emailClient: '',
                nameCompany: '',
                obs: '',
                event: '',
                startDateEvent: '',
                endDateEvent: ''
            }
        }

        this.handleInput = this.handleInput.bind(this);
        this.loadLeads = this.loadLeads.bind(this);
        this.loadLeadsResetPage = this.loadLeadsResetPage.bind(this);
        this.loadLeadTypes = this.loadLeadTypes.bind(this);
    }

    handleInput(e) {
        let value = e.target.value;
        let name = e.target.name;
        this.setState(
            prevState => ({
                ...prevState,
                [name]: value
            })
        );
    }

    //Lidar com as atualizações de componentes de Props
    componentDidUpdate(prevProps) {

        if (prevProps.isTabActive === false && this.props.isTabActive === true) {
            this.loadLeads();
            this.loadLeadTypes();
            this.loadLeadOriginTypes();
        }

        if (prevProps.newSubscription !== this.props.newSubscription) {
            this.loadLeads();
        }
    }

    //Ação do botão para procurar leads
    loadLeadsResetPage() {

        var reactState = { ...this.state };
        this.loadLeads(0, reactState.pageSize, true);
    }

    //Método para procurar leads
    loadLeads(page, pageSize, resetCurrentPage, sorted) {

        //Se a tab não estiver ativa não procuramos pelas leads
        if (this.props.isTabActive !== true) {
            return;
        }

        //Colocamos que a pagina está a carregar e colocamos o page size atual e se é necessário resetar a paginacao
        this.setState(
            prevState => ({
                ...prevState,
                loading: true,
                page: (resetCurrentPage === true) ? 0 : page,
                pageSize: pageSize,
                sortedField: sorted != null ? sorted.id : null,
                sortedAsc: sorted != null ? !sorted.desc : null
            }), () => {
                //Depois fazemos um pedido ao BackEnd para buscar leads paginadas
                getLeads(this.state.id_lead, this.state.id_consultor, this.state.id_company, this.state.id_typelead, this.state.id_origin,
                    this.state.page, this.state.pageSize, this.state.sortedField, this.state.sortedAsc)
                    .then(result => {
                        if (result.success === true) {

                            this.closeRowExpanded(this.state.activeIndex);

                            //Com a resposta do BackOffice, dizemos que já chegou a resposta
                            //colocamos o numero de paginas que existe a informacao
                            this.setState(
                                prevState => ({
                                    ...prevState,
                                    pages: result.obj.count,
                                    loading: false,
                                    leads: result.obj.data,
                                })
                            );
                        } else {
                            container.clear();
                            container.error(result.message);
                        }
                    })
                    .catch(err => {
                        container.clear();
                        container.error("Erro ao buscar Leads");
                    })
            }
        );
    }

    //Carregar os tipos de lead da BD
    loadLeadTypes() {
        getLeadTypes()
            .then(result => {
                this.setState(
                    prevState => ({
                        ...prevState,
                        leadTypes: result
                    })
                );
            })
            .catch(err => {
                container.clear();
                container.error("Erro ao buscar tipos de Leads");
            })
    }

    //Carregar as origens da lead da BD
    loadLeadOriginTypes() {
        getLeadOriginTypes()
            .then(result => {
                this.setState(
                    prevState => ({
                        ...prevState,
                        leadOrigins: result
                    })
                );
            })
            .catch(err => {
                container.clear();
                container.error("Erro ao buscar origens de Leads");
            })
    }

    //Lidar com o aumento da linha
    handleRowExpanded(rowsState, index) {
        this.setState({
            expanded: {
                [index[0]]: !this.state.expanded[index[0]]
            },
            activeIndex: [index[0]]
        });
    }

    //Lidar com o diminuir linha
    closeRowExpanded(index) {
        this.setState({
            expanded: {
                [index[0]]: false
            },
            activeIndex: ''
        });
    }

    render() {
        return (
            <div>
                <ToastContainer ref={ref => (container = ref)} className="toast-bottom-right" />

                {/* ROW alterar empresa a que consultor está atribuido*/}
                <Row style={{ textAlign: "left" }}>
                    <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center" }}>
                        <span style={{ textAlign: "center" }}>
                            <b>Procurar Negócios (Leads)</b>
                        </span>
                        <div className="form-group">
                            <br />

                            <Row xs={12} sm={12} md={12} lg={12} style={{ display: "flex", justifyContent: "center", alignItems: "center", alignContent: "center", textAlign: "center" }}>
                                <Col xs={12} sm={12} md={3} lg={3} style={{ marginBottom: "5px" }}>
                                    <TextField placeholder="Id Negócio (Lead)" name={"id_lead"} id={"id_lead"} type="text" fullWidth={true}
                                        onChange={this.handleInput} value={this.state.id_lead}
                                    />
                                </Col>
                                <Col xs={12} sm={12} md={3} lg={3} style={{ marginBottom: "5px" }}>
                                    <TextField placeholder="Id Consultor" name={"id_consultor"} id={"id_consultor"} type="text" fullWidth={true}
                                        onChange={this.handleInput} value={this.state.id_consultor}
                                    />
                                </Col>
                                <Col xs={12} sm={12} md={2} lg={2} style={{ marginBottom: "5px" }}>
                                    <TextField placeholder="Id Empresa" name={"id_company"} id={"id_company"} type="text" fullWidth={true}
                                        onChange={this.handleInput} value={this.state.id_company}
                                    />
                                </Col>
                                <Col xs={12} sm={12} md={2} lg={2} style={{ marginBottom: "5px" }}>
                                    <NativeSelect name={"id_typelead"} id={"id_typelead"} fullWidth={true} onChange={this.handleInput} value={this.state.id_typelead}>
                                        <option value="">Tipo Lead</option>
                                        {
                                            this.state.leadTypes.length > 0 ?
                                                (this.state.leadTypes.map(type => {
                                                    return (<option value={type.Id}>{type.Descricao}</option>)
                                                }))
                                                : null
                                        }
                                    </NativeSelect>
                                </Col>
                                <Col xs={12} sm={12} md={2} lg={2} style={{ marginBottom: "5px" }}>
                                    <NativeSelect name={"id_origin"} fullWidth={true} onChange={this.handleInput} value={this.state.id_origin}>
                                        <option value="">Origem Lead</option>
                                        {
                                            this.state.leadOrigins.length > 0 ?
                                                (this.state.leadOrigins.map(type => {
                                                    return (<option value={type.Id}>{type.Descricao}</option>)
                                                }))
                                                : null
                                        }
                                    </NativeSelect>
                                </Col>

                                <Col xs={12} sm={12} md={3} lg={3} style={{ marginBottom: "5px", marginTop: "15px" }}>
                                    <Button variant="contained" color="secondary" style={{ minWidth: "20%" }} onClick={this.loadLeadsResetPage}>
                                        Pesquisar Leads
                                    </Button>
                                </Col>
                            </Row>

                            <br />

                            <ReactTable
                                className="-highlight tableReact"
                                data={this.state.leads}
                                page={this.state.page}
                                pages={this.state.pages}
                                pageSize={this.state.pageSize}
                                expanded={this.state.expanded}
                                loading={this.state.loading}
                                showPageJump={false}
                                onExpandedChange={(newExpanded, index, event) =>
                                    this.handleRowExpanded(newExpanded, index, event)
                                }
                                getTheadProps={(state, rowInfo, column) => {
                                    return {
                                        style: {
                                            fontWeight: Configs.myIHTP.tableConfig.fontHeightHeader,
                                            fontSize: Configs.myIHTP.tableConfig.fontSizeHeader,
                                            height: Configs.myIHTP.tableConfig.heightHeader,
                                            color: Configs.myIHTP.tableConfig.colorHeader,
                                            backgroundColor:
                                                Configs.myIHTP.tableConfig.backgroundColorHeader
                                        }
                                    };
                                }}
                                getPaginationProps={state => {
                                    return {
                                        style: {
                                            color: Configs.myIHTP.tableConfig.colorHeader,
                                            backgroundColor:
                                                Configs.myIHTP.tableConfig.backgroundColorHeader,
                                            fontSize: Configs.myIHTP.tableConfig.fontSizeRowBody
                                        }
                                    };
                                }}
                                getTdProps={(state, rowInfo, column, instance) => {
                                    return {
                                        onClick: (e, handleOriginal) => {

                                            if (handleOriginal) {
                                                handleOriginal();
                                                this.setState(
                                                    prevState => ({
                                                        ...prevState,
                                                        currentLead: {
                                                            ...prevState.currentLead,
                                                            nameConsultor: rowInfo.original.NomeConsultor,
                                                            createDate: rowInfo.original.DataRegisto,
                                                            nameClient: (rowInfo.original.NomeCliente) ? rowInfo.original.NomeCliente : " - ",
                                                            emailClient: (rowInfo.original.Email) ? rowInfo.original.Email : " - ",
                                                            nameCompany: (rowInfo.original.NomeEmpresa) ? rowInfo.original.NomeEmpresa : " - ",
                                                            obs: rowInfo.original.Observacao,
                                                            event: rowInfo.original.FaseEvolucaoAtual,
                                                            startDateEvent: rowInfo.original.DataInicioEvolucaoAtual,
                                                            endDateEvent: rowInfo.original.DataFimEvolucaoAtual
                                                        }
                                                    })
                                                );

                                            }

                                        },
                                        style: {
                                            "text-align": "center",
                                            "font-size": Configs.myIHTP.tableConfig.fontSizeRowBody
                                        }
                                    };
                                }}
                                defaultSorted={[
                                    {
                                        id: "DataRegisto",
                                        desc: true
                                    }
                                ]}
                                defaultPageSize={Configs.myIHTP.tableConfig.defaultPageSize}
                                showPagination={true}
                                showPaginationTop={false}
                                showPaginationBottom={true}
                                previousText={Configs.myIHTP.tableConfig.buttonCopyAnterior}
                                nextText={Configs.myIHTP.tableConfig.buttonCopyProximo}
                                noDataText={Configs.myIHTP.tableConfig.noDataText}
                                pageText={Configs.myIHTP.tableConfig.pageText}
                                ofText={Configs.myIHTP.tableConfig.ofText}
                                rowsText={Configs.myIHTP.tableConfig.rowsText}
                                columns={columns}
                                pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                                manual
                                onFetchData={(state, instance) => {
                                    this.loadLeads(state.page, state.pageSize, false, state.sorted[0]);
                                }
                                }

                                SubComponent={row => {
                                    return (
                                        <Container style={{ marginTop: "15px", marginBottom: "15px", marginLeft: "15px", marginRight: "15px", alignItems: "center" }}>
                                            <Row>

                                                {/* Nome do Consultor e Nome do Cliente */}
                                                <Col xs="12" sm="12" md="4" lg="4">
                                                    <Row>
                                                        <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: isMobile ? "left" : "center" }}>
                                                            <TextField type="text" value={this.state.currentLead.nameConsultor}
                                                                label={"Nome Consultor"} margin="normal"
                                                                variant="outlined" style={{ fontSize: "12px", width: isMobile ? "67vw" : "90%" }}
                                                                readonly={true} disabled
                                                            />
                                                        </Col>
                                                        <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: isMobile ? "left" : "center" }}>
                                                            <TextField type="text" label={"Nome Cliente "}
                                                                value={this.state.currentLead.nameClient} readonly={true} disabled
                                                                margin="normal" variant="outlined" style={{ fontSize: "12px", width: isMobile ? "67vw" : "90%" }}
                                                            />
                                                        </Col>
                                                        <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: isMobile ? "left" : "center" }}>
                                                            <TextField
                                                                type="text" label={"Fase Evolução Atual"}
                                                                margin="normal" variant="outlined" style={{ fontSize: "12px", width: isMobile ? "67vw" : "90%" }}
                                                                readonly={true} disabled
                                                                value={this.state.currentLead.event}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>

                                                {/* Data Registo e Email do Cliente */}
                                                <Col xs="12" sm="12" md="4" lg="4">
                                                    <Row>
                                                        <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: isMobile ? "left" : "center" }}>
                                                            <TextField
                                                                type="text" label={"Data Registo"}
                                                                margin="normal"
                                                                variant="outlined" style={{ fontSize: "12px", width: isMobile ? "67vw" : "90%" }}
                                                                readonly={true} disabled
                                                                value={dateFormat(this.state.currentLead.createDate, "dd-mm-yyyy HH:MM:ss")}
                                                            />
                                                        </Col>
                                                        <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: isMobile ? "left" : "center" }}>
                                                            <TextField
                                                                type="text" label={"Email Cliente"}
                                                                margin="normal" variant="outlined" style={{ fontSize: "12px", width: isMobile ? "67vw" : "90%" }}
                                                                readonly={true} disabled
                                                                value={this.state.currentLead.emailClient}
                                                            />
                                                        </Col>
                                                        <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: isMobile ? "left" : "center" }}>
                                                            <TextField
                                                                type="text" label={"Data Início Evolução"}
                                                                margin="normal" variant="outlined" style={{ fontSize: "12px", width: isMobile ? "67vw" : "90%" }}
                                                                readonly={true} disabled
                                                                value={dateFormat(this.state.currentLead.startDateEvent, "dd-mm-yyyy HH:MM:ss")}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>

                                                {/* Observações e Nome Empresa */}
                                                <Col xs="12" sm="12" md="4" lg="4">
                                                    <Row>
                                                        <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: isMobile ? "left" : "center" }}>
                                                            <TextField
                                                                type="text" label={"Observações"}
                                                                margin="normal" variant="outlined"
                                                                style={{ fontSize: "12px", width: isMobile ? "67vw" : "90%" }}
                                                                readonly={true} disabled
                                                                value={this.state.currentLead.obs}
                                                            />
                                                        </Col>
                                                        <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: isMobile ? "left" : "center" }}>
                                                            <TextField
                                                                type="text" label={"Nome Empresa"}
                                                                margin="normal" variant="outlined"
                                                                style={{ fontSize: "12px", width: isMobile ? "67vw" : "90%" }}
                                                                readonly={true} disabled
                                                                value={this.state.currentLead.nameCompany}
                                                            />
                                                        </Col>
                                                        <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: isMobile ? "left" : "center" }}>
                                                            <TextField
                                                                type="text" label={"Data Fim Evolução"}
                                                                margin="normal" variant="outlined" style={{ fontSize: "12px", width: isMobile ? "67vw" : "90%" }}
                                                                readonly={true} disabled
                                                                value={dateFormat(this.state.currentLead.endDateEvent, "dd-mm-yyyy HH:MM:ss")}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <br />
                                        </Container>
                                    );
                                }}></ReactTable>

                            <br />
                            <br />

                            <Row>

                            </Row>
                        </div>
                    </Col>
                </Row>

            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
        newSubscription: state.newSubscription
    };
};

export default injectIntl(connect(mapStateToProps)(ProcurarLeads));