import { calcelSubscription } from "../../../../../actions/myihtpActions";

export function cancelSubscription(id) {
  return new Promise((resolve, reject) => {
    calcelSubscription(id)
      .then(data => {
        if (data.data.message === "Subscrição cancelada com sucesso") {
          resolve(true);
        } else {
          resolve(false);
        }
      })
      .catch(error => {
        resolve(error);
      });
  });
}
