import { Button, TextField } from "@material-ui/core";
import NativeSelect from "@material-ui/core/NativeSelect";
import React, { Component } from 'react';
import { Col, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { ToastContainer } from "react-toastr";
import { insertCreditNote, insertInvoice, insertInvoiceBusiness, insertReceipt } from "../../../../../../../actions/myihtpActions";

let container;
export default class GerarFaturaRecibo extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id_encomenda: '',
            tipo_emissao: 'FE'
        }

        this.handleInput = this.handleInput.bind(this);
    };

    handleInput(e) {
        let value = e.target.value;
        let name = e.target.name;
        this.setState(
            prevState => ({
                ...prevState,
                [name]: value
            })
        );
    }

    emitirDoc = () => async e => {
        e.stopPropagation();

        //Validar Id de Encomenda
        if (this.state.id_encomenda == null || !this.state.id_encomenda || this.state.id_encomenda.trim().length <= 0) {
            container.clear();
            container.error("Id de Encomenda deve estar preenchido");
            return;
        }

        //Validar Tipo de Emissao
        if (this.state.tipo_emissao == null || !this.state.tipo_emissao || this.state.tipo_emissao.trim().length <= 0) {
            container.clear();
            container.error("Tipo de Emissão deve estar preenchida");
            return;
        }

        var success = false;
        if (this.state.tipo_emissao === "FE") {
            insertInvoiceBusiness(this.state.id_encomenda).then(data => {
                if (data.success === true) {
                    container.clear();
                    container.success(data.message);
                    success = true;
                } else {
                    container.clear();
                    container.error(data.message);
                }
            });
        } else {
            if (this.state.tipo_emissao === "FT") {
                insertInvoice(this.state.id_encomenda).then(data => {
                    if (data.success === true) {
                        container.clear();
                        container.success("Fatura inserida com sucesso");
                        success = true;
                    } else {
                        container.clear();
                        container.error(data.message);
                    }
                });
            } else {
                if (this.state.tipo_emissao === "RB") {
                    insertReceipt(this.state.id_encomenda).then(data => {
                        if (data.success === true) {
                            container.clear();
                            container.success(data.message);
                            success = true;
                        } else {
                            container.clear();
                            container.error(data.message);
                        }
                    });
                } else {
                    if (this.state.tipo_emissao === "NC") {
                        insertCreditNote(this.state.id_encomenda).then(data => {
                            console.log(data);
                            if (data.success === true) {
                                container.clear();
                                container.success(data.message);
                                success = true;
                            } else {
                                container.clear();
                                container.error(data.message);
                            }
                        });
                    }
                }
            }
        }

        //Se tiver inserido com sucesso
        if (success) {
            this.setState({
                id_encomenda: '',
                tipo_emissao: 'FE'
            });
        }
    };

    render() {
        return (
            <div>

                <ToastContainer ref={ref => (container = ref)} className="toast-bottom-right" />

                {/* ROW de geracao de documentos*/}
                <Row style={{ textAlign: "left" }}>
                    <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center" }} >
                        <span style={{ textAlign: "center" }}>
                            <b>Gerar Fatura/Recibo/Notas de Crédito</b>
                        </span>
                        <div className="form-group">
                            <br />
                            <Row xs={12} sm={12} md={12} lg={12}>
                                <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }}>
                                    <TextField
                                        placeholder="ID Encomenda para gerar fatura" name={"id_encomenda"}
                                        style={{ maxWidth: isMobile ? "100%" : "50%" }} type="text" fullWidth={true}
                                        onChange={this.handleInput}
                                    />
                                </Col>
                            </Row>
                            <br />
                            <Row xs={12} sm={12} md={12} lg={12}>
                                <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }} >
                                    <NativeSelect name={"tipo_emissao"} style={{ maxWidth: isMobile ? "100%" : "50%" }} fullWidth={true}
                                        onChange={this.handleInput}>

                                        <option value="FE"> Fatura para cliente empresarial </option>
                                        {/* MUDAR TEXTO PARA O TEXTO CORRETO DA FUNCAO */}
                                        <option value="FT">Emitir Fatura e Recibo</option>
                                        <option value="RB">Emitir Recibo</option>
                                        {/* ADICIONAR OPCAO PARA NOTA DE CREDITO */}
                                        <option value="NC">Emitir Nota de Credito</option>

                                    </NativeSelect>
                                </Col>
                            </Row>
                            <br />
                            <br />
                            <Row>
                                <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center" }}>
                                    <Button variant="contained" color="secondary" style={{ minWidth: "20%" }} onClick={this.emitirDoc()}>
                                        Gerar Documento
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}
