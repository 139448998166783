import React, { Component } from "react";

import "./Fotografia.css";
import Avatar from "@material-ui/core/Avatar";
import { Row, Col } from "react-bootstrap";
import { getBaseInformation } from "../../../../../../../actions/myihtpActions";
import { isMobile } from "react-device-detect";
import Configs from "../../../../../../../helpers/Configs";

class Fotografia extends Component {
  constructor(props) {
    super(props);
    this.state = {
      NomeCompleto: null,
      Fotografia_url: null,
      email: null,
      telemovel: null
    };
  }
  componentDidMount() {
    getBaseInformation()
      .then(data => {
        if (!data.information.message && !data.information.error) {
          this.setState({
            NomeCompleto: data.information.nomecompleto,
            Fotografia_url: data.information.nomefisico,
            email: data.information.email,
            telemovel: data.information.contacto
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    return (
      <div>
        <Row>
          <Col xs={4} sm={4} md={12} lg={12} style={{ textAlign: "center" }}>
            <Avatar
              src={
                "https://doremon.ihavethepower.net/assets/uploads/" +
                this.state.Fotografia_url
              }
              style={{
                width: isMobile ? "100px" : "150px",
                height: isMobile ? "100px" : "150px",
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                left: "0px",
                right: "0px",
                margin: "auto"
              }}
            />
          </Col>
          <Col
            xs={8}
            sm={8}
            md={12}
            lg={12}
            style={
              isMobile
                ? { top: "0px", bottom: "0px", margin: "auto" }
                : { marginTop: "20px" }
            }
          >
            <Row>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ textAlign: "center" }}
              >
                <p
                  style={isMobile ? { fontSize: "15px" } : { fontSize: "20px" }}
                >
                  {this.state.NomeCompleto}
                </p>
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ textAlign: "center" }}
              >
                <p
                  style={isMobile ? { fontSize: "15px" } : { fontSize: "20px" }}
                >
                  {Configs.myIHTP.informacaoBase.consultor}
                </p>
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ textAlign: "center" }}
              >
                <p
                  style={isMobile ? { fontSize: "15px" } : { fontSize: "20px" }}
                >
                  {
                    <a href={"mailto:" + this.state.email}>
                      {this.state.email}
                    </a>
                  }
                </p>
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ textAlign: "center" }}
              >
                <p
                  style={isMobile ? { fontSize: "15px" } : { fontSize: "20px" }}
                >
                  <a href={"tel:" + this.state.telemovel}>
                    {this.state.telemovel}
                  </a>
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Fotografia;
