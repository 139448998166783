import { FormattedMessage } from "react-intl";
import React from "react";
import { Row, Col } from "react-bootstrap";
import ArrowStatus from "../../components/Desktop/MyIHTP/Conteudo/Dashboard/IndicadoresDashboard/ArrowStatus";

import "moment/locale/pt";
var moment = require("moment");
moment.locale("pt");

class IndicadoresConfig {
  constructor() {
    // Tabela de Comparativo Mensal
    this.comparativomensal = {
      columns: [
        {
          Header: (
            <FormattedMessage id="myihtp.comparativoMensal.table.AnoMes" />
          ),
          accessor: "Ano",
          resizable: false,
          Cell: row => (
            <div>
              <span title={row.value}>{row.value}</span>
            </div>
          )
        },
        {
          Header: () => {
            return (
              <span className="altText" title="NetPoints Cliente">
                NPC
              </span>
            );
          },
          accessor: "NPC",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.NPC} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusNPC} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: () => {
            return (
              <span className="altText" title="NetPoints Pessoal">
                NPP
              </span>
            );
          },
          accessor: "NPP",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.NPP} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusNPP} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: () => {
            return (
              <span className="altText" title="NetPoints Rede">
                NPR
              </span>
            );
          },
          accessor: "NPR",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.NPR} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusNPR} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: () => {
            return (
              <span className="altText" title="Créditos">
                CRD
              </span>
            );
          },
          accessor: "CRD",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.CRD} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusCRD} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: () => {
            return (
              <span className="altText" title="Valor de Comissão">
                VCM
              </span>
            );
          },
          accessor: "VCM",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.VCM + " €"} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusVCM} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: () => {
            return (
              <span className="altText" title="Nº de Encomendas">
                NEN
              </span>
            );
          },
          accessor: "NEN",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.NEN} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusNEN} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: () => {
            return (
              <span className="altText" title="Valor das Encomendas">
                VEN
              </span>
            );
          },
          accessor: "VEN",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.VEN + " €"} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusVEN} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: () => {
            return (
              <span className="altText" title="NetPoints Total">
                NPT
              </span>
            );
          },
          accessor: "NPT",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.NPT} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusNPT} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: () => {
            return (
              <span className="altText" title="Taxa de Bonús Group">
                TBG
              </span>
            );
          },
          accessor: "TBG",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.TBG} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusTBG} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: () => {
            return (
              <span className="altText" title="Valor de Bonús Group">
                VBG
              </span>
            );
          },
          accessor: "VBG",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.VBG} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusVBG} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: () => {
            return (
              <span className="altText" title="Nº de Redes Top">
                NRT
              </span>
            );
          },
          accessor: "NRT",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.NRT} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusNRT} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: () => {
            return (
              <span className="altText" title="Taxa de Bonús Lider">
                TBL
              </span>
            );
          },
          accessor: "TBL",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.TBL} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusTBL} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: () => {
            return (
              <span className="altText" title="Valor de Bonús Lider Dream">
                VBLD
              </span>
            );
          },
          accessor: "VBLD",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.VBLD} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusVBLD} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: () => {
            return (
              <span className="altText" title="Valor de Bonús Lider">
                VBL
              </span>
            );
          },
          accessor: "VBL",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.VBL} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusVBL} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: () => {
            return (
              <span className="altText" title="Nº de Leadership Points">
                NLB
              </span>
            );
          },
          accessor: "NLB",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.NLB} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusNLB} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: () => {
            return (
              <span className="altText" title="Valor de Leader Builder">
                VLB
              </span>
            );
          },
          accessor: "VLB",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.VLB} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusVLB} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: () => {
            return (
              <span className="altText" title="Valor Total">
                VTL
              </span>
            );
          },
          accessor: "VTL",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.VTL + " €"} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusVTL} />
                ) : null}
              </div>
            );
          }
        }
      ]
    };

    this.comparativoanual = {
      columns: [
        {
          Header: <FormattedMessage id="myihtp.comparativoAnual.table.Ano" />,
          accessor: "Ano",
          resizable: false
        },
        {
          Header: () => {
            return (
              <span className="altText" title="NetPoints Cliente">
                NPC
              </span>
            );
          },
          accessor: "NPC",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.NPC} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusNPC} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: () => {
            return (
              <span className="altText" title="NetPoints Pessoal">
                NPP
              </span>
            );
          },
          accessor: "NPP",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.NPP} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusNPP} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: () => {
            return (
              <span className="altText" title="NetPoints Rede">
                NPR
              </span>
            );
          },
          accessor: "NPR",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.NPR} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusNPR} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: () => {
            return (
              <span className="altText" title="Créditos">
                CRD
              </span>
            );
          },
          accessor: "CRD",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.CRD} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusCRD} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: () => {
            return (
              <span className="altText" title="Valor de Comissão">
                VCM
              </span>
            );
          },
          accessor: "VCM",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.VCM + " €"} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusVCM} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: () => {
            return (
              <span className="altText" title="Nº de Encomendas">
                NEN
              </span>
            );
          },
          accessor: "NEN",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.NEN} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusNEN} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: () => {
            return (
              <span className="altText" title="Valor das Encomendas">
                VEN
              </span>
            );
          },
          accessor: "VEN",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.VEN + " €"} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusVEN} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: () => {
            return (
              <span className="altText" title="NetPoints Total">
                NPT
              </span>
            );
          },
          accessor: "NPT",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.NPT} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusNPT} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: () => {
            return (
              <span className="altText" title="Taxa de Bonús Group">
                TBG
              </span>
            );
          },
          accessor: "TBG",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.TBG} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusTBG} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: () => {
            return (
              <span className="altText" title="Valor de Bonús Group">
                VBG
              </span>
            );
          },
          accessor: "VBG",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.VBG} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusVBG} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: () => {
            return (
              <span className="altText" title="Nº de Redes Top">
                NRT
              </span>
            );
          },
          accessor: "NRT",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.NRT} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusNRT} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: () => {
            return (
              <span className="altText" title="Taxa de Bonús Lider">
                TBL
              </span>
            );
          },
          accessor: "TBL",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.TBL} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusTBL} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: () => {
            return (
              <span className="altText" title="Valor de Bonús Lider Dream">
                VBLD
              </span>
            );
          },
          accessor: "VBLD",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.VBLD} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusVBLD} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: () => {
            return (
              <span className="altText" title="Valor de Bonús Lider">
                VBL
              </span>
            );
          },
          accessor: "VBL",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.VBL} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusVBL} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: () => {
            return (
              <span className="altText" title="Nº de Leadership Points">
                NLB
              </span>
            );
          },
          accessor: "NLB",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.NLB} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusNLB} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: () => {
            return (
              <span className="altText" title="Valor de Leader Builder">
                VLB
              </span>
            );
          },
          accessor: "VLB",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.VLB} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusVLB} />
                ) : null}
              </div>
            );
          }
        },
        {
          Header: () => {
            return (
              <span className="altText" title="Valor Total">
                VTL
              </span>
            );
          },
          accessor: "VTL",
          resizable: false,
          Cell: row => {
            return (
              <div>
                {row.original.VTL + " €"} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {row.index === 0 ? (
                  <ArrowStatus status={row.original.ArrowStatusVTL} />
                ) : null}
              </div>
            );
          }
        }
      ]
    };

    this.faturacaomescorrente = {
      columns: [
        {
          Header: (
            <FormattedMessage id="myihtp.faturacaomescorrente.NomeCompleto" />
          ),
          accessor: "NomeCompleto",
          resizable: false
        },
        {
          Header: (
            <FormattedMessage id="myihtp.faturacaomescorrente.FacturacaoPessoal" />
          ),
          accessor: "FacturacaoPessoal",
          resizable: false,
          width: 150
        },
        {
          Header: (
            <FormattedMessage id="myihtp.faturacaomescorrente.FacturacaoClientes" />
          ),
          accessor: "FacturacaoClientes",
          resizable: false,
          width: 150
        },
        {
          Header: (
            <FormattedMessage id="myihtp.faturacaomescorrente.FacturacaoTalentos" />
          ),
          accessor: "FacturacaoTalentos",
          resizable: false,
          width: 150
        },
        {
          Header: (
            <FormattedMessage id="myihtp.faturacaomescorrente.FacturacaoTotal" />
          ),
          accessor: "FacturacaoTotal",
          resizable: false,
          width: 150
        },
        {
          Header: (
            <FormattedMessage id="myihtp.faturacaomescorrente.AcimaMinimo" />
          ),
          accessor: "AcimaMinimo",
          resizable: false,
          width: 150
        },
        {
          Header: (
            <FormattedMessage id="myihtp.faturacaomescorrente.Reconhecimento" />
          ),
          accessor: "Reconhecimento",
          resizable: false,
          width: 150,
          Cell: row => {
            return (
              <span>
                {row.original.Reconhecimento ? (
                  <span>{row.original.Reconhecimento} + &nbsp; STARS </span>
                ) : null}
              </span>
            );
          }
        },
        {
          Header: <FormattedMessage id="myihtp.faturacaomescorrente.Cor" />,
          accessor: "Semaforo",
          resizable: false,
          width: 75,
          Cell: row => {
            let faturacaoClientes2 = parseFloat(
              row.original.FacturacaoClientes
            );
            let faturacaoPessoal2 = parseFloat(row.original.FacturacaoPessoal);
            let total = faturacaoClientes2 + faturacaoPessoal2;
            if (total <= 125) {
              return (
                <div>
                  <a>
                    <svg
                      height="20px"
                      viewBox="0 0 512 512.00115"
                      width="20px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z"
                        style={{ fill: "black" }}
                      />
                    </svg>
                  </a>
                </div>
              );
            } else if (total > 125 && total <= 250) {
              return (
                <div>
                  <a>
                    <svg
                      height="20px"
                      viewBox="0 0 512 512.00115"
                      width="20px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z"
                        style={{ fill: "red" }}
                      />
                    </svg>
                  </a>
                </div>
              );
            } else if (total > 250 && total <= 1000) {
              return (
                <div>
                  <a>
                    <svg
                      height="20px"
                      viewBox="0 0 512 512.00115"
                      width="20px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z"
                        style={{ fill: "yellow" }}
                      />
                    </svg>
                  </a>
                </div>
              );
            } else if (total > 1000) {
              return (
                <div>
                  <a>
                    <svg
                      height="20px"
                      viewBox="0 0 512 512.00115"
                      width="20px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z"
                        style={{ fill: "green" }}
                      />
                    </svg>
                  </a>
                </div>
              );
            }
          }
        }
      ]
    };

    this.faturacaoacumulada = {
      columns: [
        {
          Header: (
            <FormattedMessage id="myihtp.faturacaoacumulada.NomeCompleto" />
          ),
          accessor: "NomeCompleto",
          resizable: false
        },
        {
          Header: (
            <FormattedMessage id="myihtp.faturacaoacumulada.FacturacaoTrimestre1" />
          ),
          accessor: "FacturacaoTrimestre1",
          resizable: false,
          width: 150
        },
        {
          Header: (
            <FormattedMessage id="myihtp.faturacaoacumulada.FacturacaoTrimestre2" />
          ),
          accessor: "FacturacaoTrimestre2",
          resizable: false,
          width: 150
        },
        {
          Header: (
            <FormattedMessage id="myihtp.faturacaoacumulada.FacturacaoTrimestre3" />
          ),
          accessor: "FacturacaoTrimestre3",
          resizable: false,
          width: 150
        },
        {
          Header: (
            <FormattedMessage id="myihtp.faturacaoacumulada.FacturacaoTrimestre4" />
          ),
          accessor: "FacturacaoTrimestre4",
          resizable: false,
          width: 150
        },
        {
          Header: <FormattedMessage id="myihtp.faturacaoacumulada.Total" />,
          accessor: "Total",
          resizable: false,
          width: 150
        }
      ]
    };

    this.faturacao1trimestre = {
      columns: [
        {
          Header: (
            <FormattedMessage id="myihtp.faturacao1trimestre.NomeCompleto" />
          ),
          accessor: "NomeCompleto",
          resizable: false
        },
        {
          Header: (
            <FormattedMessage id="myihtp.faturacao1trimestre.FacturacaoPessoal" />
          ),
          accessor: "FacturacaoPessoal",
          resizable: false,
          width: 150
        },
        {
          Header: (
            <FormattedMessage id="myihtp.faturacao1trimestre.FacturacaoClientes" />
          ),
          accessor: "FacturacaoClientes",
          resizable: false,
          width: 150
        },
        {
          Header: (
            <FormattedMessage id="myihtp.faturacao1trimestre.FacturacaoTalentos" />
          ),
          accessor: "FacturacaoTalentos",
          resizable: false,
          width: 150
        },
        {
          Header: (
            <FormattedMessage id="myihtp.faturacao1trimestre.FacturacaoTotal" />
          ),
          accessor: "FacturacaoTotal",
          resizable: false,
          width: 150
        },
        {
          Header: (
            <FormattedMessage id="myihtp.faturacao1trimestre.AcimaMinimo" />
          ),
          accessor: "AcimaMinimo",
          resizable: false,
          width: 150
        },
        {
          Header: (
            <FormattedMessage id="myihtp.faturacao1trimestre.Reconhecimento" />
          ),
          accessor: "Reconhecimento",
          resizable: false,
          width: 150,
          Cell: row => {
            return (
              <span>
                {row.original.Reconhecimento ? (
                  <span> {row.original.Reconhecimento} &nbsp; STARS </span>
                ) : null}{" "}
              </span>
            );
          }
        },
        {
          Header: <FormattedMessage id="myihtp.faturacao1trimestre.Cor" />,
          accessor: "Semaforo",
          resizable: false,
          width: 75,
          Cell: row => {
            let faturacaoClientes2 = parseFloat(
              row.original.FacturacaoClientes
            );
            let faturacaoPessoal2 = parseFloat(row.original.FacturacaoPessoal);
            let total = faturacaoClientes2 + faturacaoPessoal2;
            if (total <= 375) {
              return (
                <div>
                  <a>
                    <svg
                      height="20px"
                      viewBox="0 0 512 512.00115"
                      width="20px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z"
                        style={{ fill: "black" }}
                      />
                    </svg>
                  </a>
                </div>
              );
            } else if (total > 375 && total <= 750) {
              return (
                <div>
                  <a>
                    <svg
                      height="20px"
                      viewBox="0 0 512 512.00115"
                      width="20px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z"
                        style={{ fill: "red" }}
                      />
                    </svg>
                  </a>
                </div>
              );
            } else if (total > 750 && total <= 3000) {
              return (
                <div>
                  <a>
                    <svg
                      height="20px"
                      viewBox="0 0 512 512.00115"
                      width="20px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z"
                        style={{ fill: "yellow" }}
                      />
                    </svg>
                  </a>
                </div>
              );
            } else if (total > 3000) {
              return (
                <div>
                  <a>
                    <svg
                      height="20px"
                      viewBox="0 0 512 512.00115"
                      width="20px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z"
                        style={{ fill: "green" }}
                      />
                    </svg>
                  </a>
                </div>
              );
            }
          }
        }
      ]
    };

    this.niveisnegocio = {
      columns: [
        {
          Header: <FormattedMessage id="myihtp.niveisnegocio.NomeCompleto" />,
          accessor: "NomeCompleto",
          resizable: false
        },
        {
          Header: <FormattedMessage id="myihtp.niveisnegocio.Pais" />,
          accessor: "Pais",
          resizable: false,
          width: 150
        },
        {
          Header: <FormattedMessage id="myihtp.niveisnegocio.Bonus" />,
          accessor: "Bonus",
          resizable: false,
          width: 150
        }
      ]
    };

    this.rentabilidademes = {
      columns: [
        {
          Header: (
            <FormattedMessage id="myihtp.rentabilidademes.NomeCompleto" />
          ),
          accessor: "NomeCompleto",
          resizable: false
        },
        {
          Header: <FormattedMessage id="myihtp.rentabilidademes.Pais" />,
          accessor: "Pais",
          resizable: false,
          width: 150
        },
        {
          Header: <FormattedMessage id="myihtp.rentabilidademes.Bonus" />,
          accessor: "ValorTotal",
          resizable: false,
          width: 150
        }
      ]
    };

    this.consumopessoalmes = {
      columns: [
        {
          Header: (
            <FormattedMessage id="myihtp.consumopessoalmes.NomeCompleto" />
          ),
          accessor: "NomeCompleto",
          resizable: false
        },
        {
          Header: <FormattedMessage id="myihtp.consumopessoalmes.Pais" />,
          accessor: "Pais",
          resizable: false,
          width: 150
        },
        {
          Header: (
            <FormattedMessage id="myihtp.consumopessoalmes.QuantoFaltaSubir" />
          ),
          accessor: "QuantoFaltaSubir",
          resizable: false,
          width: 300
        }
      ]
    };

    this.recomendacao = {
      columns: [
        {
          Header: <FormattedMessage id="myihtp.recomendacao.NomeCompleto" />,
          accessor: "NomeCompleto",
          resizable: false
        },
        {
          Header: <FormattedMessage id="myihtp.recomendacao.Pais" />,
          accessor: "pais",
          resizable: false,
          width: 150
        },
        {
          Header: (
            <FormattedMessage id="myihtp.recomendacao.QuantoFaltaSubir" />
          ),
          accessor: "NumeroRecomendacoes",
          resizable: false,
          width: 300
        }
      ]
    };

    this.volumeacumuladomes = {
      columns: [
        {
          Header: (
            <FormattedMessage id="myihtp.volumeacumuladomes.NomeCompleto" />
          ),
          accessor: "NomeCompleto",
          resizable: false
        },
        {
          Header: <FormattedMessage id="myihtp.volumeacumuladomes.Pais" />,
          accessor: "Pais",
          resizable: false,
          width: 150
        },
        {
          Header: (
            <FormattedMessage id="myihtp.volumeacumuladomes.QuantoFaltaSubir" />
          ),
          accessor: "QuantoFaltaSubir",
          resizable: false,
          width: 300
        }
      ]
    };
    this.rentabilidadeatehoje = {
      columns: [
        {
          Header: (
            <FormattedMessage id="myihtp.rentabilidadeatehoje.NomeCompleto" />
          ),
          accessor: "NomeCompleto",
          resizable: false
        },
        {
          Header: <FormattedMessage id="myihtp.rentabilidadeatehoje.Pais" />,
          accessor: "Pais",
          resizable: false,
          width: 150
        },
        {
          Header: (
            <FormattedMessage id="myihtp.rentabilidadeatehoje.ValorTotalAnual" />
          ),
          accessor: "ValorTotalAnual",
          resizable: false,
          width: 150
        }
      ]
    };
  }
}

var Config = new IndicadoresConfig();

export default Config;
