// Obter detalhes de produtos
import ReactGA from "react-ga";

export function sendUserID(id) {
    return new Promise((resolve, reject) => {
        return fetch("/sendUserID", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({
                UserID: id
            })
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                return json.product;
            })
            .catch(error => console.log(error));
    });
}

// Login
export function login(email, password) {
    return (dispatch, getState) => {
        dispatch(loading(true));
        return new Promise((resolve, reject) => {
            if (true) {
                return fetch("/user/login", {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    credentials: "include",
                    body: JSON.stringify({
                        email: email,
                        password: password
                    })
                })
                    .then(handleErrors)
                    .then(res => res.json())
                    .then(json => {

                        if (json.obj != null && typeof json.obj.user !== 'undefined') {
                            ReactGA.set({ userId: json.obj.user.ID_Cliente });
                            ReactGA.set({ dimension1: json.obj.user.ID_TipoCliente });
                            dispatch(loginAccount(json.obj.user));
                            dispatch(loading(false));
                            resolve(json);
                        } else {
                            resolve(json);
                            dispatch(loading(false));
                        }
                    })
                    .catch(error => {
                        resolve({
                            success: false,
                            message: "Erro ao conectar ao servidor"
                        })
                    });
            }
        });
    };
}

export function recoverPassword(email) {
    return (dispatch, getState) => {
        dispatch(loading(true));
        return new Promise((resolve, reject) => {
            if (true) {
                return fetch("/user/recoverPassword", {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    credentials: "include",
                    body: JSON.stringify({
                        email: email
                    })
                })
                    .then(handleErrors)
                    .then(res => res.json())
                    .then(json => {
                        resolve(json);
                        dispatch(loading(false));
                    })
                    .catch(error => {

                        console.log(error.message);
                        resolve({
                            success: false,
                            message: "Erro ao conectar ao servidor"
                        })

                        dispatch(loading(false));
                    });
            }
        });
    };
}

export function logout() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            return fetch("/logout", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include"
            })
                .then(handleErrors)
                .then(res => res.json())
                .then(json => {
                    resolve({});
                    dispatch(logoutAccount());
                })
                .catch(error => console.log(error));
        });
    };
}

export function register(novoUtilizador) {
    return (dispatch, getState) => {
        dispatch(loading(true));
        return new Promise((resolve, reject) => {
            return fetch("/user/validateRegister", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({
                    NomeCompleto: novoUtilizador.nomeCompleto,
                    Email: novoUtilizador.email,
                    EmailRecomendacao: novoUtilizador.emailRecomendacao,
                    Telefone: novoUtilizador.telefone,
                    DataNascimento: novoUtilizador.dataNascimento,
                    Pais: novoUtilizador.pais,
                    Localidade: novoUtilizador.localidade,
                    Refer: novoUtilizador.refer,
                    GDPR: novoUtilizador.gdpr
                })
            })
                .then(handleErrors)
                .then(res => res.json())
                .then(json => {
                    dispatch(registerAccount());
                    resolve({ message: json.message });
                    dispatch(loading(false));
                    return json;
                })
                .catch(error => console.log(error));
        });
    };
}

export function verifyEmail(verifyUser) {
    return (dispatch, getState) => {
        dispatch(loading(true));
        return new Promise((resolve, reject) => {
            return fetch("/user/setPassword", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({
                    Link: verifyUser.link,
                    Password: verifyUser.password,
                    ConfirmPassword: verifyUser.confirmPassword
                })
            })
                .then(handleErrors)
                .then(res => res.json())
                .then(json => {
                    dispatch(loading(false));
                    resolve(json);
                    return json.user;
                })
                .catch(error => {
                    dispatch(loading(false));
                    resolve({ success: false, message: "Erro ao comunicar com o servidor, por favor tente mais tarde" });
                });
        });
    };
}

export function changePassword(changeUserPassword) {
    return (dispatch, getState) => {
        dispatch(loading(true));
        return new Promise((resolve, reject) => {
            return fetch("/user/changePassword", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({
                    Link: changeUserPassword.link,
                    Password: changeUserPassword.password,
                    ConfirmPassword: changeUserPassword.confirmPassword
                })
            })
                .then(handleErrors)
                .then(res => res.json())
                .then(json => {
                    dispatch(loading(false));
                    resolve(json);
                    return json;
                })
                .catch(error => {
                    var response = { success: false, message: "Erro ao comunicar com Servidor para Definir Nova Palavra-Passe!" }
                    resolve(response);
                    return response;
                });
        });
    };
}

export function getUser() {
    return new Promise((resolve, reject) => {
        return fetch("/get/user", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include"
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                resolve(json.user);
            })
            .catch(error => console.log(error));
    });
}

// Dados de utilizador
export function fetchUser() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            return fetch("/get/user", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include"
            })
                .then(handleErrors)
                .then(res => res.json())
                .then(json => {
                    dispatch(loginAccount(json.user));
                    resolve({ user: json.user });
                    return json.user;
                })
                .catch(error => resolve({ message: "Erro ao obter Utilizador" }));
        });
    };
}

export function fetchCartNumberItems() {
    return dispatch => {
        return new Promise((resolve, reject) => {
            return fetch("/user/getCart", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include"
            })
                .then(handleErrors)
                .then(res => res.json())
                .then(json => {

                    //Se conseguirmos fazer o pedido com sucesso
                    if (json.success === true) {

                        dispatch(fetchCartNumber(json.obj.Itens));
                    } else {
                        dispatch(fetchCartFailure(json.message));
                    }

                    resolve(json);
                    return json;
                })
                .catch(error => {
                    resolve({
                        success: false,
                        message: "Erro ao comunicar com o servidor para ir buscar os Produtos do Carrinho"
                    });
                    dispatch(fetchCartFailure("Erro ao comunicar com o servidor para ir buscar os Produtos do Carrinho"));
                });
        });
    };
}

// Obter idiomas
// Obsoleto
export function fetchLanguageFunction() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            return fetch("/geral/getLanguages", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include"
            })
                .then(res => res.json())
                .then(json => {
                    dispatch(changeLanguage(json.languages));
                    // resolve({ data: json });
                })
                .catch(error => console.log(error));
        });
    };
}

// Mudar idioma
// Obsoleto
export function changeLanguageFE(id) {
    return dispatch => {
        return fetch("/geral/chooseLanguage", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({ id: id })
        })
            .then(res => res.json())
            .then(json => {
                dispatch(changeLanguage(json.languages));
                return json.languages;
            })
            .catch(error => console.log(error));
    };
}

// Obter moedas
export function fetchCurrencyFunction() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            return fetch("/geral/getAvailableCurrency", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include"
            })
                .then(res => res.json())
                .then(json => {
                    dispatch(changeCurrency(json.currencies));
                    // dispatch(fetchCart());
                    return json.currencies;
                })
                .catch(error => console.log(error));
        });
    };
}

export function changeCurrencyFE(id) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            return fetch("/geral/chooseCurrency", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({ id: id })
            })
                .then(res => res.json())
                .then(json => {

                    dispatch(changeCurrency(json.currencies));
                    dispatch(sideBarMenu(false));

                    return json.currencies;
                })
                .catch(error => console.log(error));
        });
    };
}

export function menuToggleGetState() {
    return (dispatch, getState) => {
        dispatch(menuToggle(!getState().menuToggleState));
    };
}

export function cartToggleGetState() {
    return (dispatch, getState) => {
        dispatch(cartToggle(!getState().cartToggleState));
    };
}

export function profileToggleGetState() {
    return (dispatch, getState) => {
        dispatch(profileToggle(!getState().profileToggleState));
    };
}

export function getSearch(pesquisa) {
    return dispatch => {
        dispatch(loading(true));
        return new Promise((resolve, reject) => {
            return fetch("/products/getAllByCategory", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({
                    pesquisa: pesquisa
                })
            })
                .then(res => res.json())
                .then(json => {
                    dispatch(fetchProducts(json.products));
                    dispatch(loading(false));
                    resolve({ products: json.products });
                    return json.products;
                })
                .catch(error => console.log(error));
        });
    };
}

export function getPaymentTypes() {
    return dispatch => {
        return fetch("/checkout/getPaymentMethods", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include"
        })
            .then(res => res.json())
            .then(json => {
                json.paymentTypes.map(obj => {
                    obj.Selected = false;
                    return obj;
                });
                dispatch(fetchPaymentTypes(json.paymentTypes));
                return json.paymentTypes;
            })
            .catch(error => console.log(error));
    };
}

export function getShippingAddresses() {
    return dispatch => {
        return fetch("/checkout/getDeliveryAddress", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include"
        })
            .then(res => res.json())
            .then(json => {
                dispatch(fetchDeliveryAddresses(json.deliveryAddresses));
                return json.deliveryAddresses;
            })
            .catch(error => console.log(error));
    };
}

export function getBillingAddresses() {
    return dispatch => {
        return fetch("/checkout/getBillingAddress", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include"
        })
            .then(res => res.json())
            .then(json => {
                dispatch(fetchBillingAddresses(json.billingAddresses));
                return json.billingAddresses;
            })
            .catch(error => console.log(error));
    };
}

export function paymentPaypalConfirmed(id_encomenda) {
    return (dispatch, getState) => {
        dispatch(loading(true));
        return new Promise((resolve, reject) => {
            return fetch("/payment/paypalConfirmed", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({
                    orderID: id_encomenda
                })
            })
                .then(res => res.json())
                .then(json => {
                    resolve({ data: json });
                    dispatch(loading(false));
                })
                .catch(error => console.log(error));
        });
    };
}

export function getOrderPayment(hash) {
    return new Promise((resolve, reject) => {
        return fetch("/payment/getOrderPayment", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({
                hash: hash
            })
        })
            .then(res => res.json())
            .then(json => {
                resolve({ data: json });
            })
            .catch(error => console.log(error));
    });
}

export function paymentPaypalCanceled(id_encomenda) {
    return (dispatch, getState) => {
        dispatch(loading(true));
        return new Promise((resolve, reject) => {
            return fetch("/payment/paypalCanceled", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({
                    orderID: id_encomenda
                })
            })
                .then(res => res.json())
                .then(json => {
                    resolve({ data: json });
                    dispatch(loading(false));
                })
                .catch(error => console.log(error));
        });
    };
}

export function getPaises() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            return fetch("/geral/getCountries", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include"
            })
                .then(res => res.json())
                .then(json => {
                    dispatch(allCountries(json.paises));
                    resolve({ data: json });
                })
                .catch(error => console.log(error));
        });
    };
}

//FUNCAO PARA RECOMENDAR I HAVE THE POWER
export function recomendarWebsite(emails) {
    return new Promise((resolve, reject) => {
        return fetch("/user/recomendarWebsite", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({
                emails: emails
            })
        })
            .then(res => res.json())
            .then(json => {
                resolve({ data: json });
            })
            .catch(error => {
                reject(error);
            });
    });
}

//FUNCAO PARA ALTERAR TIPO DE PAGAMENTO
export function changePayment(TipoPagamento, encomendaID, hashEncomenda, NumParcelas, NumeroTelemovelPagamento) {
    return new Promise((resolve, reject) => {
        return fetch("/payment/changePayment", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({
                TipoPagamento: TipoPagamento,
                encomendaID: encomendaID,
                hashEncomenda: hashEncomenda,
                NumParcelas: NumParcelas,
                NumeroTelemovelPagamento: NumeroTelemovelPagamento
            })
        })
            .then(res => res.json())
            .then(json => {
                resolve(json);
            })
            .catch(error => {
                console.log(error);
                reject(error);
            });
    });
}

export function getEbanxCheckoutURL() {
    return new Promise((resolve, reject) => {
        return fetch("/ebanx/getCheckoutURL", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include"
        })
            .then(res => res.json())
            .then(json => {
                resolve(json);
            })
            .catch(error => console.log(error));
    });
}

export function removeStockNotification(hash) {
    return new Promise((resolve, reject) => {
        return fetch("/products/removeStockNotification", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                Hash: hash
            }),
            credentials: "include"
        })
            .then(res => res.json())
            .then(json => {
                resolve(json);
            })
            .catch(error => {
                console.log(error);
                reject(error);
            });
    });
}

export function removePersonalStockNotification(hash) {
    return new Promise((resolve, reject) => {
        return fetch("/products/removePersonalStockNotification", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                Hash: hash
            }),
            credentials: "include"
        })
            .then(res => res.json())
            .then(json => {
                resolve(json);
            })
            .catch(error => {
                console.log(error);
                reject(error);
            });
    });
}

// Handle HTTP errors since fetch won't.
function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

export const LOADING = "LOADING";
export const GETCART = "GETCART";
export const LOADINGCART = "LOADINGCART";
export const ISVERIFIEDRECAPTCHA = "ISVERIFIEDRECAPTCHA";
export const SIDEBARMENU = "SIDEBARMENU";

export const FETCH_CART_NUMBER_ITEMS = "FETCH_CART_NUMBER_ITEMS";

export const CHECKOUT_PANEL_BILLING_ADDRESS = "CHECKOUT_PANEL_BILLING_ADDRESS";
export const CHECKOUT_PANEL_SHIPPING_ADDRESS =
    "CHECKOUT_PANEL_SHIPPING_ADDRESS";
export const CHECKOUT_PANEL_SHIPPING_METHOD = "CHECKOUT_PANEL_SHIPPING_METHOD";
export const CHECKOUT_PANEL_PAYMENT_METHOD = "CHECKOUT_PANEL_PAYMENT_METHOD";

export const FETCH_PRODUCTS_DETAILS = "FETCH_PRODUCTS_DETAILS";

export const LOGIN = "LOGIN";

export const GETUSER = "GETUSER";

export const FETCH_CART_BEGIN = "FETCH_CART_BEGIN";
export const FETCH_CART_SUCCESS = "FETCH_CART_SUCCESS";
export const FETCH_CART_FAILURE = "FETCH_CART_FAILURE";

export const INCREMENTAR_PRODUCT_CART = "INCREMENTAR_PRODUCT_CART";
export const DECREMENTAR_PRODUCT_CART = "DECREMENTAR_PRODUCT_CART";
export const REMOVER_PRODUCT_CART = "REMOVER_PRODUCT_CART";
export const LIMPAR_CART = "LIMPAR_CART";

export const FETCH_LANGUAGE = "FETCH_LANGUAGE";
export const FETCH_CURRENCY = "FETCH_CURRENCY";
export const FETCH_PRODUCTS_FILTERS = "FETCH_PRODUCTSFILTERS";

export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const CHANGE_CURRENCY = "CHANGE_CURRENCY";
export const CHANGE_FILTER = "CHANGE_FILTER";

export const FETCH_CATEGORIES = "FETCH_CATEGORIES";

export const FETCH_PRODUCTS = "FETCH_PRODUCTS";

export const MENU_TOGGLE = "MENU_TOGGLE";

export const CART_TOGGLE = "CART_TOGGLE";

export const PROFILE_TOGGLE = "PROFILE_TOGGLE";

export const FETCH_PAYMENT_TYPES = "FETCH_PAYMENT_TYPES";
export const FETCH_SHIPMENT_TYPES = "FETCH_SHIPMENT_TYPES";

export const SELECT_SHIPMENT_TYPE = "SELECT_SHIPMENT_TYPE";
export const SELECT_PAYMENT_TYPE = "SELECT_PAYMENT_TYPE";
export const SELECT_PAYMENT_INSTALLMENTS = "SELECT_PAYMENT_INSTALLMENTS";
export const SELECT_PAYMENT_PHONE_NUMBER = "SELECT_PAYMENT_PHONE_NUMBER";

export const FETCH_DELIVERY_ADDRESSES = "FETCH_DELIVERY_ADDRESSES";
export const FETCH_BILLING_ADDRESSES = "FETCH_BILLING_ADDRESSES";

export const FETCH_CHECKOUT_DATA = "FETCH_CHECKOUT_DATA";

export const LOGOUT = "LOGOUT";

export const REGISTER = "REGISTER";

export const sideBarMenu = sideBarMenuState => ({
    type: SIDEBARMENU,
    payload: { sideBarMenuState }
});

export const isVerifiedRecaptcha = isVerifiedState => ({
    type: ISVERIFIEDRECAPTCHA,
    payload: { isVerifiedState }
});

export const loading = loadingState => ({
    type: LOADING,
    payload: { loadingState }
});

export const loadingCart = loadingCartState => ({
    type: LOADINGCART,
    payload: { loadingCartState }
});

export const getCart = getCartState => ({
    type: GETCART,
    payload: { getCartState }
});

export const loginAccount = user => ({
    type: LOGIN,
    payload: { user }
});

export const getUserFetch = user => ({
    type: GETUSER,
    payload: { user }
});

export const logoutAccount = () => ({
    type: LOGOUT
});

export const registerAccount = () => ({
    type: REGISTER
});

export const fetchCartNumber = cartNumberItems => ({
    type: FETCH_CART_NUMBER_ITEMS,
    payload: { cartNumberItems }
});

export const fetchCartFailure = cartLoadError => ({
    type: FETCH_CART_FAILURE,
    payload: { cartLoadError }
});

export const fetchLanguage = language => ({
    type: FETCH_LANGUAGE,
    payload: { language }
});

export const fetchCurrency = currency => ({
    type: FETCH_CURRENCY,
    payload: { currency }
});

export const changeLanguage = language => ({
    type: CHANGE_LANGUAGE,
    payload: { language }
});

export const changeCurrency = currency => ({
    type: CHANGE_CURRENCY,
    payload: { currency }
});

export const changeFilter = filters => ({
    type: CHANGE_FILTER,
    payload: { filters }
});
export const fetchCategories = categories => ({
    type: FETCH_CATEGORIES,
    payload: { categories }
});

export const fetchProducts = products => ({
    type: FETCH_PRODUCTS,
    payload: { products }
});

export const menuToggle = menuToggleState => ({
    type: MENU_TOGGLE,
    payload: { menuToggleState }
});

export const cartToggle = cartToggleState => ({
    type: CART_TOGGLE,
    payload: { cartToggleState }
});

export const profileToggle = profileToggleState => ({
    type: PROFILE_TOGGLE,
    payload: { profileToggleState }
});

export const fetchPaymentTypes = paymentTypes => ({
    type: FETCH_PAYMENT_TYPES,
    payload: { paymentTypes }
});

export const fetchDeliveryAddresses = deliveryAddresses => ({
    type: FETCH_DELIVERY_ADDRESSES,
    payload: { deliveryAddresses }
});

export const fetchBillingAddresses = billingAddresses => ({
    type: FETCH_BILLING_ADDRESSES,
    payload: { billingAddresses }
});


export const ALL_COUNTRIES = "ALL_COUNTRIES";
export const allCountries = allPaises => ({
    type: ALL_COUNTRIES,
    payload: { allPaises }
});