import { instanceOf } from "prop-types";
import React, { Component } from "react";
import { Cookies, withCookies } from "react-cookie";
import { loadReCaptcha } from "react-recaptcha-google";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { sendUserID } from "../actions/miniCartActions";
import ListeningToEvent from "../components/ListeningToEvent/ListeningToEvent";
import ChangePassword from "../components/Login/ChangePassword/ChangePassword";
import Payment from "../components/Payment/Payment";
import ValidateEmail from "../components/Register/ValidateEmail";
import VerifyEmail from "../components/Register/VerifyEmail";
// Caminhos para as páginas
import Routes from "../helpers/Routes";
import "./App.css";
import Login from "./views/Login/Login";
import MaintenanceMode from "./views/MaintenanceMode/MaintenanceMode";
import Comissoes from "./views/MyIHTP/Conteudo/Comissoes/Comissoes";
import Dashboard from "./views/MyIHTP/Conteudo/Dashboard/Dashboard";
import Downloads from "./views/MyIHTP/Conteudo/Downloads/Downloads";
import Encomendas from "./views/MyIHTP/Conteudo/Encomendas/Encomendas";
import EncomendasBackOffice from "./views/MyIHTP/Conteudo/FerramentasBackOffice/EncomendasBackOffice";
import FerramentasBackOffice from "./views/MyIHTP/Conteudo/FerramentasBackOffice/FerramentasBackOffice";
import FerramentasConsultor from "./views/MyIHTP/Conteudo/FerramentasConsultor/FerramentasConsultor";
import GerirConta from "./views/MyIHTP/Conteudo/GerirConta/GerirConta";
import IncentivosRegras from "./views/MyIHTP/Conteudo/IncentivosRegras/IncentivosRegras";
import InformacoesPessoais from "./views/MyIHTP/Conteudo/InformacoesPessoais/InformacoesPessoais";
import MediaCenter from "./views/MyIHTP/Conteudo/MediaCenter/MediaCenter";
import MeusProdutos from "./views/MyIHTP/Conteudo/MeusProdutos/MeusProdutos";
import NotificacoesEmail from "./views/MyIHTP/Conteudo/NotificacoesEmail/NotificacoesEmail";
import Premios from "./views/MyIHTP/Conteudo/Premios/Premios";
import Subscricoes from "./views/MyIHTP/Conteudo/Subscricoes/Subscricoes";
import SuccessBox from "./views/MyIHTP/Conteudo/SuccessBox/SuccessBox";
import Vales from "./views/MyIHTP/Conteudo/Vales/Vales";
import Page404 from "./views/Page404/Page404";
import RecoverPassword from "./views/RecoverPassword/RecoverPassword";
import Register from "./views/Register/Register";

var uniqid = require("uniqid");

//ReactGA.initialize("UA-465340-11");
//ReactGA.pageview(window.location.pathname + window.location.search);

function getAllUrlParams(url) {
  // get query string from url (optional) or window
  var queryString = url ? url.split("?")[1] : window.location.search.slice(1);

  // we'll store the parameters here
  var obj = {};

  // if query string exists
  if (queryString) {
    // stuff after # is not part of query string, so get rid of it
    queryString = queryString.split("#")[0];

    // split our query string into its component parts
    var arr = queryString.split("&");

    for (var i = 0; i < arr.length; i++) {
      // separate the keys and the values
      var a = arr[i].split("=");

      // set parameter name and value (use 'true' if empty)
      var paramName = a[0];
      var paramValue = typeof a[1] === "undefined" ? true : a[1];

      // (optional) keep case consistent
      paramName = paramName.toLowerCase();
      if (typeof paramValue === "string") paramValue = paramValue.toLowerCase();

      // if the paramName ends with square brackets, e.g. colors[] or colors[2]
      if (paramName.match(/\[(\d+)?\]$/)) {
        // create key if it doesn't exist
        var key = paramName.replace(/\[(\d+)?\]/, "");
        if (!obj[key]) obj[key] = [];

        // if it's an indexed array e.g. colors[2]
        if (paramName.match(/\[\d+\]$/)) {
          // get the index value and add the entry at the appropriate position
          var index = /\[(\d+)\]/.exec(paramName)[1];
          obj[key][index] = paramValue;
        } else {
          // otherwise add the value to the end of the array
          obj[key].push(paramValue);
        }
      } else {
        // we're dealing with a string
        if (!obj[paramName]) {
          // if it doesn't exist, create property
          obj[paramName] = paramValue;
        } else if (obj[paramName] && typeof obj[paramName] === "string") {
          // if property does exist and it's a string, convert it to an array
          obj[paramName] = [obj[paramName]];
          obj[paramName].push(paramValue);
        } else {
          // otherwise add the property
          obj[paramName].push(paramValue);
        }
      }
    }
  }

  return obj;
}

const PrivateRoute = ({ component: Component, loggedIn, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      loggedIn ? (
        <Component {...props} />
      ) : (
          <Redirect to={Routes.account.login} />
        )
    }
  />
);

const CustomLoginRoute = ({
  component: Component,
  loggedIn,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      loggedIn ? (
        <Redirect to={Routes.home.home} />
      ) : (
          <Component {...props} />
        )
    }
  />
);

class App extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);
    const { cookies } = this.props;
    this.state = {
      loggedIn: false,
      timestamp: "Loading"
    };

    // Para fazer set ao refer
    if (getAllUrlParams(window.location.search).refer) {
      cookies.set("refer", getAllUrlParams(window.location.search).refer, {
        path: "/",
        maxAge: 2147483647,
        domain: process.env.REACT_APP_HTTP_DOMAIN,
      });
    }

    // Caso exista userID envia para o servidor, senão cria e envia na mesma
    if (!cookies.get("userID")) {
      var userID = uniqid() + uniqid() + uniqid();
      cookies.set("userID", userID, {
        domain: process.env.REACT_APP_HTTP_DOMAIN,
        path: "/",
        maxAge: 1800
      });
      sendUserID(userID).then(data => { });
    } else {
      sendUserID(cookies.get("userID")).then(data => { });
    }
  }

  componentDidMount() {
    loadReCaptcha();
  }

  render() {
    const App = () => (
      <div>
        <ListeningToEvent />
        <Switch>

          <PrivateRoute loggedIn={this.props.userID} exact path={Routes.home.home} component={Dashboard} />

          <PrivateRoute loggedIn={this.props.userID} exact path="/informacoespessoais" component={InformacoesPessoais} />

          <PrivateRoute loggedIn={this.props.userID} exact path="/Dashboard" component={Dashboard} />

          <PrivateRoute loggedIn={this.props.userID} exact path="/meusprodutos" component={MeusProdutos} />

          <PrivateRoute loggedIn={this.props.userID} exact path="/notificacoesemail" component={NotificacoesEmail} />

          <PrivateRoute loggedIn={this.props.userID} exact path="/Subscricoes" component={Subscricoes} />

          <PrivateRoute loggedIn={this.props.userID} exact path="/vales" component={Vales} />

          <PrivateRoute loggedIn={this.props.userID} exact path="/premios" component={Premios} />

          <PrivateRoute loggedIn={this.props.userID} exact path="/incentivosregras" component={IncentivosRegras} />

          <PrivateRoute loggedIn={this.props.userID} exact path="/comissoes" component={Comissoes} />

          <PrivateRoute loggedIn={this.props.userID} exact path="/gerirconta" component={GerirConta} />

          <PrivateRoute loggedIn={this.props.userID} exact path="/ferramentasconsultor" component={FerramentasConsultor} />

          <PrivateRoute loggedIn={this.props.userID} exact path="/FerramentasBackOffice" component={FerramentasBackOffice} />

          <PrivateRoute loggedIn={this.props.userID} exact path="/EncomendasBackOffice" component={EncomendasBackOffice} />

          <PrivateRoute loggedIn={this.props.userID} exact path="/downloads" component={Downloads} />

          <PrivateRoute loggedIn={this.props.userID} exact path="/encomendas" component={Encomendas} />

          <PrivateRoute loggedIn={this.props.userID} exact path="/mediacenter" component={MediaCenter} />

          <PrivateRoute loggedIn={this.props.userID} exact path="/successbox" component={SuccessBox} />

          {/* -- Route antiga de fazer login
           <Route exact path={Routes.account.login} render={(props) => <Login {...props} />} /> 
           */}
          <CustomLoginRoute exact path={Routes.account.login} loggedIn={this.props.userID} component={Login} />

          <Route exact path="/register/validateEmail" component={ValidateEmail} />
          <Route exact path="/verify" component={VerifyEmail} />
          <Route exact path={Routes.account.registo} render={(props) => <Register {...props} />} />

          <Route exact path={Routes.account.recuperar} component={RecoverPassword} />
          <Route exact path="/changePassword" component={ChangePassword} />

          <Route exact path={"/Loja"} render={() => (window.location = Routes.shop.home)} />
          <Route exact path={Routes.shop.packs} render={() => (window.location = Routes.shop.home)} />
          <Route exact path={Routes.shop.subscricoes} render={() => (window.location = Routes.shop.home)} />
          <Route exact path={Routes.shop.promocoes} render={() => (window.location = Routes.shop.home)} />
          <Route exact path={Routes.shop.novidades} render={() => (window.location = Routes.shop.home)} />

          <Route exact path={"/Loja/Catalogo/:category"}
            render={(props) => (window.location = process.env.REACT_APP_SHOP_WEBSITE_URL + Routes.shop.catalogo + props.match.params.category)}
          />

          <Route path="/Curso/:id/:name"
            render={props => (<Redirect to={"/Loja/DetalheProduto/" + props.match.params.id + "/" + props.match.params.name} />)} />

          <Route path="/Produto/:id/:name"
            render={props => (<Redirect to={"/Loja/DetalheProduto/" + props.match.params.id + "/" + props.match.params.name} />)} />

          <Route path="/Pack/:id/:name"
            render={props => (<Redirect to={"/Loja/DetalheProduto/" + props.match.params.id + "/" + props.match.params.name} />)} />

          <Route exact path="/shop/payment/:hash" component={Payment} render={props => <Payment {...props.match.params} />} />

          {/* FIM DAS LANDING PAGES*/}
          <Route component={Page404} />
        </Switch>
      </div>
    );

    if (this.state.timestamp === "Loading") {
      return (
        <Switch>
          <App />
        </Switch>
      );
    } else if (this.state.timestamp === "Online") {
      return (
        <Switch>
          <App />
        </Switch>
      );
    } else if (this.state.timestamp === "Offline") {
      return <MaintenanceMode />;
    }
  }
}

const mapStateToProps = state => {
  return {
  };
};

export default withCookies(connect(mapStateToProps)(App));