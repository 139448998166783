import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { FormattedMessage } from "react-intl";
import RoutesHelper from "../../helpers/Routes";
import SocialMedia from "../SocialMedia/SocialMedia";
import "./Footer.css";

class Footer extends Component {
  render() {
    return (
      <Container style={{ marginTop: "60px" }} className="footer">
        <Row>
          <Col xs="12" sm="12" md="12" lg="12">
            <Row>
              <Col
                xs="12"
                sm="12"
                md="12"
                lg="12"
                style={{ textAlign: "center" }}
              >
                <hr />
                <SocialMedia />
              </Col>
            </Row>
            <br />
            <Row>
              <Col
                xs="12"
                sm="3"
                md="3"
                lg="3"
                style={{ textAlign: "center", marginTop: "15px" }}
              >
                <h6 style={{ color: "#CA006A" }}>
                  <FormattedMessage id="footer.title.Empresa" />
                </h6>
                <br />
                <p style={{ lineHeight: "1em !important" }}>
                  <a href={RoutesHelper.empresa.sobreNos}>
                    <FormattedMessage id="footer.title.sobrenos" />
                  </a>
                </p>
                <p>
                  <a href={RoutesHelper.empresa.fundador}>
                    <FormattedMessage id="footer.title.fundador" />
                  </a>
                </p>
                <p>
                  <a href={RoutesHelper.empresa.testemunhos}>
                    <FormattedMessage id="footer.title.testemunhos" />
                  </a>
                </p>
                <p>
                  <a href={RoutesHelper.empresa.noticias}>
                    <FormattedMessage id="footer.title.noticias" />
                  </a>
                </p>
                <p>
                  <a href={RoutesHelper.empresa.imprensa}>
                    <FormattedMessage id="footer.title.radio" />
                  </a>
                </p>
                <p>
                  <a href={RoutesHelper.empresa.protecaoDados}>
                    <FormattedMessage id="footer.title.protecaodados" />
                  </a>
                </p>
                <p>
                  <a href={RoutesHelper.empresa.cancelamentoGarantia}>
                    <FormattedMessage id="footer.title.cancelamento" />
                  </a>
                </p>
                <p>
                  <a href={RoutesHelper.empresa.associacao}>
                    Responsabilidade social
                  </a>
                </p>
                <hr
                  style={{
                    display: isMobile ? "block" : "none",
                    marginTop: isMobile ? "15px" : "0px"
                  }}
                />
              </Col>
              <Col
                xs="12"
                sm="3"
                md="3"
                lg="3"
                style={{ textAlign: "center", marginTop: "15px" }}
              >
                <h6 style={{ color: "#CA006A" }}>
                  <FormattedMessage id="footer.title.academias" />
                </h6>
                <br />
                <p>
                  <a href={RoutesHelper.home.home}>
                    <FormattedMessage id="footer.title.excelencia" />
                  </a>
                </p>
                <p>
                  <a href={RoutesHelper.shop.home}>
                    <FormattedMessage id="footer.title.wellness" />
                  </a>
                </p>
                <p>
                  <a href="https://blog.ihavethepower.net/" target="_new">
                    <FormattedMessage id="footer.title.desporto" />
                  </a>
                </p>
                <p>
                  <a href={RoutesHelper.consultor.consultor}>
                    <FormattedMessage id="footer.title.lideranca" />
                  </a>
                </p>
                <p>
                  <a href={RoutesHelper.contactos.contactos}>
                    <FormattedMessage id="footer.title.desenvolcimento" />
                  </a>
                </p>

                <hr
                  style={{
                    display: isMobile ? "block" : "none",
                    marginTop: isMobile ? "15px" : "0px"
                  }}
                />
              </Col>
              <Col
                xs="12"
                sm="3"
                md="3"
                lg="3"
                style={{ textAlign: "center", marginTop: "15px" }}
              >
                <h6 style={{ color: "#CA006A" }}>
                  <FormattedMessage id="footer.title.recursos" />
                </h6>
                <br />
                <p>
                  <a href={RoutesHelper.recursosGratis.desafios}>
                    <FormattedMessage id="footer.title.desafios" />
                  </a>
                </p>
                <p>
                  <a href={RoutesHelper.recursosGratis.afiliados}>
                    <FormattedMessage id="footer.title.afiliados" />
                  </a>
                </p>
                <p>
                  <a href={RoutesHelper.recursosGratis.frases}>
                    <FormattedMessage id="footer.title.frases" />
                  </a>
                </p>
                <hr
                  style={{
                    display: isMobile ? "block" : "none",
                    marginTop: isMobile ? "15px" : "0px"
                  }}
                />
              </Col>
              <Col
                xs="12"
                sm="3"
                md="3"
                lg="3"
                style={{ textAlign: "center", marginTop: "15px" }}
              >
                <h6 style={{ color: "#CA006A" }}>
                  <FormattedMessage id="footer.title.orgulho" />
                </h6>
                <Row>
                  <Col
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      className="solfut"
                      onClick={() => {
                        window.open("https://solfut.com", "_blank");
                      }}
                      style={{ width: "30%" }}
                      src="https://doremon.ihavethepower.net/assets/images/logo/logoSolfutDeitado.png"
                      alt=""
                    />
                  </Col>
                  <Col
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      className="businessconsulting"
                      onClick={() => {
                        window.open(
                          RoutesHelper.nossoorgulho.businessconsulting,
                          "_blank"
                        );
                      }}
                      style={{ width: "30%" }}
                      src="https://doremon.ihavethepower.net/assets/images/logo/businessConsulting.png"
                      alt=""
                    />
                  </Col>
                </Row>
                <Row>
                  <Col
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      className="wonderlives"
                      onClick={() => {
                        window.open(
                          RoutesHelper.nossoorgulho.wonderlives,
                          "_blank"
                        );
                      }}
                      style={{ width: "30%" }}
                      src="https://doremon.ihavethepower.net/assets/images/logo/wonderLives.png"
                      alt=""
                    />
                  </Col>
                  <Col
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      className="nascidosparatriunfar"
                      onClick={() => {
                        window.open(
                          RoutesHelper.nossoorgulho.nascidosparatriunfar,
                          "_blank"
                        );
                      }}
                      style={{ width: "30%" }}
                      src="https://doremon.ihavethepower.net/assets/images/logo/nascidosTriunfar.png"
                      alt=""
                    />
                  </Col>
                </Row>
                <hr
                  style={{
                    display: isMobile ? "block" : "none",
                    marginTop: isMobile ? "15px" : "0px"
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col
                xs="12"
                sm="12"
                md="12"
                lg="12"
                style={{ textAlign: "center" }}
              >
                <div style={{ display: "inline-flex" }}>
                  <div style={{ padding: "15px 5px 15px 0px" }}>
                    © <a href="https://solfut.com">Solfut LDA</a> Todos os direitos reservados.
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Footer;
