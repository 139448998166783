import React, { Component } from "react";
import { Row, Col, FormGroup } from "react-bootstrap";
import {
  getNotificacoesEmail,
  cancelNotification,
  getDesafios
} from "../../../../../actions/myihtpActions";
import { ToastContainer } from "react-toastr";
import {
  OutlinedInput,
  Select,
  InputLabel,
  FormControl
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { cancelNotificacao, subscribeNotification } from "./Helper";
import { FormattedMessage } from "react-intl";

let container;
class NotificacoesEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notificacoes: [],
      desafios: [],
      addInfo: {
        TipoDesafio: ""
      }
    };
    this.cancelNotificacao = this.cancelNotificacao;
    this.handleAddInput = this.handleAddInput.bind(this);
  }

  componentDidMount() {
    getNotificacoesEmail()
      .then(data => {
        if (data.notificacoes.length > 0) {
          getDesafios()
            .then(data => {
              this.setState({ desafios: data.desafios });
            })
            .catch(err => {
              console.log(err);
            });
          this.setState({ notificacoes: data.notificacoes });
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  handleAddInput(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState(
      prevState => ({
        addInfo: {
          ...prevState.addInfo,
          [name]: value
        }
      }),
      () => console.log(this.state.addInfo)
    );
  }

  cancelNotificacao = id => {
    cancelNotification(id)
      .then(data => {
        if (data.result.mensagem === "Subscrição cancelada com sucesso") {
          getNotificacoesEmail()
            .then(data => {
              this.setState({ notificacoes: data.notificacoes });
              container.clear();
              container.success(`Subscrição cancelada com sucesso`);
            })
            .catch(error => {
              console.log(error);
            });
        } else {
          container.clear();
          container.warning(`Erro ao cancelar subscrição`);
        }
      })
      .catch(err => {
        container.clear();
        container.warning(`Erro ao cancelar subscrição`);
      });
  };

  subscribeNotification = type => {

    //Se não for do tipo dia da semana
    if (type !== "DDS") {
      this.state.addInfo = '';
    }

    subscribeNotification(type, this.state.addInfo)
      .then(data => {
        if (data.result.message === "Subscrição subscrita com sucesso") {
          getNotificacoesEmail()
            .then(data => {
              this.setState({ notificacoes: data.notificacoes });
              container.clear();
              container.success(`Subscrição efetuada com sucesso`);
            })
            .catch(error => {
              console.log(error);
            });
        } else {
          container.clear();
          container.warning(`Erro ao subscrever`);
        }
      })
      .catch(err => {
        container.clear();
        container.warning(`Erro ao subscrever`);
      });
  };

  render() {
    var that = this;
    return (
      <Row>
        <ToastContainer
          ref={ref => (container = ref)}
          className="toast-bottom-right"
        />
        <Col xs="12" sm="12" md="12" lg="12">
          <Row style={{ textAlign: "left" }}>
            <Col xs="12" sm="12" md="12" lg="12">
              {this.state.notificacoes.map(function (item) {
                return (
                  <div>
                    <Row>
                      <Col xs="12" sm="12" md="7" lg="7">
                        <h5>{item.Descricao}</h5>
                      </Col>
                      {item.datadesactivacao ? null : (
                        <Col xs="12" sm="12" md="5" lg="5">
                          <FormattedMessage id="myihtp.notificacoes.subscrito" />{" "}
                          {item.datacriacao}
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col xs="12" sm="12" md="12" lg="12">
                        <p>{item.Texto}</p>
                      </Col>
                      <br />
                      <br />
                      <br />
                      {item.datadesactivacao ? (
                        <Col
                          xs="12"
                          sm="12"
                          md="12"
                          lg="12"
                          style={{
                            alignItems: "center",
                            textAlign: "right"
                          }}
                        >
                          {that.state.desafios.length > 0 ? (
                            item.id_tipoalertasubscrito === "DDS" ? (
                              <FormControl variant="outlined">
                                <InputLabel
                                  ref={ref => {
                                    that.InputLabelRef = ref;
                                  }}
                                  htmlFor="outlined-age-native-simple"
                                >
                                  <FormattedMessage id="myihtp.notificacoes.desafio" />{" "}
                                </InputLabel>
                                <Select
                                  native
                                  // value={this.state.desafio}
                                  onChange={that.handleAddInput}
                                  input={
                                    <OutlinedInput
                                      name="TipoDesafio"
                                      labelWidth={70}
                                      id="outlined-age-native-simple"
                                    />
                                  }
                                >
                                  <option value="" />
                                  {that.state.desafios.map((desafio, j) => {
                                    return (
                                      <option value={desafio.ID_Desafio}>
                                        {desafio.Descricao}
                                      </option>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            ) : null
                          ) : null}
                          <Button
                            variant="contained"
                            color="secondary"
                            style={{ background: "green", marginTop: "9px" }}
                            onClick={() =>
                              that.subscribeNotification(
                                item.id_tipoalertasubscrito
                              )
                            }
                          >
                            <FormattedMessage id="myihtp.notificacoes.subscribe" />{" "}
                          </Button>
                        </Col>
                      ) : (
                          <Col
                            xs="12"
                            sm="12"
                            md="12"
                            lg="12"
                            style={{ textAlign: "right" }}
                          >
                            <Button
                              variant="contained"
                              color="secondary"
                              style={{ background: "red" }}
                              onClick={() =>
                                that.cancelNotificacao(item.id_alerta)
                              }
                            >
                              <FormattedMessage id="myihtp.notificacoes.removerSubscricao" />{" "}
                            </Button>
                          </Col>
                        )}
                    </Row>
                    <hr />
                  </div>
                );
              })}
              <br />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}
export default NotificacoesEmail;
