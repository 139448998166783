import React, { Component } from "react";
import ArrowUpStatus from "./ArrowUpStatus";
import ArrowDownStatus from "./ArrowDownStatus";
import EqualStatus from "./EqualStatus";

class ArrowStatus extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    if (this.props.status === "green") {
      return <ArrowUpStatus />;
    } else if (this.props.status === "red") {
      return <ArrowDownStatus />;
    } else {
      return <EqualStatus />;
    }
  }
}

export default ArrowStatus;
