import React, { Component } from "react";

import { Row, Col, Container, Button } from "react-bootstrap";

import "./InformacoesBase.css";

import Configs from "../../../../../../helpers/Configs";
import Fotografia from "./Fotografia/Fotografia";
import { getContractValidate } from "../../../../../../actions/myihtpActions";

import { isMobile } from "react-device-detect";
import Subtitle from "../../Modules/Subtitle";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

var moment = require("moment");

class InformacoesBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataValidade: null,
      refer: null
    };
  }
  componentDidMount() {
    getContractValidate().then(data => {
      if (
        data.contractValidate.message !==
          "Não tem nenhum contrato disponivel." &&
        !data.contractValidate.error
      ) {
        this.setState({
          dataValidade: data.contractValidate[0].DataValidade,
          refer: data.contractValidate[0].ID_Cliente
        });
      }
    });
  }

  render() {
    const { user } = this.props;
    return (
      <Container>
        {user.userID !== 1 ? (
          <div>
            {user.userType === "CS" ? (
              <Subtitle text={Configs.myIHTP.informacaoBase.seuUpline} />
            ) : (
              <Subtitle text={Configs.myIHTP.informacaoBase.gestorConta} />
            )}
            <Row style={{ marginBottom: "15px" }}>
              <Col xs={12} sm={12} md={12} lg={12}>
                <br />
                <Fotografia />
              </Col>
            </Row>
          </div>
        ) : null}
        {this.props.user.userType === "CS" ? (
          <span>
            <Subtitle text={Configs.myIHTP.informacaoBase.seusDadosConsultor} />
          </span>
        ) : null}
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "left" }}>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <p style={{ marginTop: "5px", textAlign: "center" }}>
                  <a
                    href={"https://www.ihavethepower.net/?refer=" + user.userID}
                  >
                    {"https://www.ihavethepower.net/?refer=" + user.userID}
                  </a>
                </p>
              </Col>
            </Row>
            {this.props.user.userType === "CS" ? (
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <p style={{ marginTop: "15px", textAlign: "center" }}>
                    <b>{Configs.myIHTP.informacaoBase.dataValidade}</b>
                  </p>
                  <p
                    style={{
                      marginTop: "5px",
                      textAlign: "center",
                      // Se a diferença de datas validade e hoje for inferior a 31 dias mete valor a vermelho
                      color:
                        Math.abs(
                          moment().diff(
                            moment(this.state.dataValidade).format(
                              "DD-MM-YYYY"
                            ),
                            "days"
                          )
                        ) <= 31
                          ? "red"
                          : null
                    }}
                  >
                    {this.state.dataValidade}
                    {// Mensagem de fim de contrato
                    Math.abs(
                      moment().diff(
                        moment(this.state.dataValidade).format("DD-MM-YYYY"),
                        "days"
                      )
                    ) <= 31
                      ? " (Falta menos de 1 mês para renovação de contrato)"
                      : null}
                  </p>
                </Col>
              </Row>
            ) : null}
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

export default withRouter(connect(mapStateToProps)(InformacoesBase));
