import React, { Component } from "react";
import { withCookies } from "react-cookie";
import { connect } from "react-redux";

class MenuButtons extends Component {
  render() {
    return (
      <a>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 48 48"
          version="1.1"
          width="48px"
          height="48px"
        >
          <g id="surface1">
            <path
              style={{ fill: this.props.color }}
              d="M 6 22 L 42 22 L 42 26 L 6 26 Z "
            />
            <path
              style={{ fill: this.props.color }}
              d="M 6 10 L 42 10 L 42 14 L 6 14 Z "
            />
            <path
              style={{ fill: this.props.color }}
              d="M 6 34 L 42 34 L 42 38 L 6 38 Z "
            />
          </g>
        </svg>
        <a className="buttonLabels" style={{ color: "#ffffff", margin: "0px 0px 0px 11px" }}>Menu Principal</a>
      </a>
    );
  }
}

const mapStateToProps = state => {
  return {
    isVerified: state.isVerified,
    user: state.user
  };
};

export default withCookies(connect(mapStateToProps)(MenuButtons));
