import React, { Component } from "react";

import "react-table/react-table.css";

import { Row, Col, Container } from "react-bootstrap";

import { FormattedMessage, FormattedNumber } from "react-intl";
import { getPremium } from "../../../../../../actions/myihtpActions";
import { connect } from "react-redux";

class ResumoPremios extends Component {
  constructor(props) {
    super(props);
    this.state = { anoPassadoVolume: 0, anoCurrenteVolume: 0 };
  }

  componentDidMount() {
    getPremium().then(data => {
      if (!data.premios.error) {
        this.setState({
          anoPassadoVolume: data.premios.VolumeAnoPassado,
          anoCurrenteVolume: data.premios.VolumeAtual
        });
      }
    });
  }
  render() {
    const { currencies } = this.props;
    return (
      <Container>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Row>
              <Col>
                <FormattedMessage id="myihtp.vales.resumoPremios.info1" />
                &nbsp;
                <FormattedNumber
                  value={this.state.anoPassadoVolume}
                  style="currency"
                  currency={
                    currencies
                      ? currencies.selected
                        ? currencies.selected
                        : null
                      : null
                  }
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormattedMessage id="myihtp.vales.resumoPremios.info2" />
                &nbsp;
                <FormattedNumber
                  value={this.state.anoCurrenteVolume}
                  style="currency"
                  currency={
                    currencies
                      ? currencies.selected
                        ? currencies.selected
                        : null
                      : null
                  }
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}
const mapStateToProps = state => {
  return {
    currencies: state.currencies
  };
};

export default connect(mapStateToProps)(ResumoPremios);
