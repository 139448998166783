
class Routes {
  constructor() {

    var mainWebsiteURL = process.env.REACT_APP_MAIN_WEBSITE_URL;
    var oldWebsiteURL = process.env.REACT_APP_OLD_WEBSITE_URL;
    var shopWebsiteURL = process.env.REACT_APP_SHOP_WEBSITE_URL;

    this.home = {
      home: "/",
    };

    this.contactos = {
      contactos: mainWebsiteURL + "contactos"
    };

    this.account = {
      login: "/Account/LogOn",
      recuperar: "/Account/Recuperar",
      registo: "/MyIHTP/Create"
    };

    this.myIHTP = {
      home: "/myihtp"
    };

    this.shop = {
      home: shopWebsiteURL + "Loja/",
      loja: shopWebsiteURL + "Loja/",
      subscricoes: "/Loja/Subscricoes",
      packs: "/Loja/PacksEspeciais",
      promocoes: "/Loja/Promocoes",
      novidades: "/Loja/Novidades",
      catalogo: "Loja/Catalogo/",
      detalheproduto: "/Loja/DetalheProduto/:id",
      cart: shopWebsiteURL + "Carrinho/",
      checkout: "/Shop/Checkout"
    };

    this.consultor = {
      consultor: mainWebsiteURL + "ser-consultor/"
    };

    this.empresa = {
      fundador: mainWebsiteURL + "fundador/",
      testemunhos: mainWebsiteURL + "testemunhos-lista/",
      noticias: mainWebsiteURL + "noticias/",
      imprensa: mainWebsiteURL + "tv-radio-e-imprensa-pagina/",
      protecaoDados: mainWebsiteURL + "protecao-de-dados-e-utilizacao/",
      cancelamentoGarantia: mainWebsiteURL + "cancelamento-e-garantia-de-satisfacao/",
      sobreNos: mainWebsiteURL + "empresa/",
      associacao: mainWebsiteURL + "responsabilidade-social/",
    };

    this.recursosGratis = {
      desafios: mainWebsiteURL + "desafios-para-excelencia/",
      afiliados: mainWebsiteURL + "recursos-gratis/",
      frases: mainWebsiteURL + "frases-poderosas/",
    };

    this.nossoorgulho = {
      digupulse: "https://www.digipulse.pt",
      businessconsulting: "http://ihtpconsulting.com",
      wonderlives: shopWebsiteURL + "Loja/Catalogo/SA",
      nascidosparatriunfar: "https://nascidosparatriunfar.webnode.pt"
    };

  }
}

var RoutesObject = new Routes();

export default RoutesObject;
