import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { connect } from "react-redux";
import "react-table/react-table.css";
import { getResumeComissoes } from "../../../../../../actions/myihtpActions";

class Resumo extends Component {
  constructor(props) {
    super(props);
    this.state = { ganhas: 0.0, pagas: 0.0, receber: 0.0, podeReceber: 0.0 };
  }

  componentDidMount() {
    getResumeComissoes()
      .then(data => {
        if (!data.comissoes.error) {

          this.setState({
            ganhas: data.comissoes.ComissionsResume[0].ValorTotal
              ? data.comissoes.ComissionsResume[0].ValorTotal
              : 0,
            pagas: data.comissoes.ComissionsResume[0].ValorPago
              ? data.comissoes.ComissionsResume[0].ValorPago
              : 0,
            receber: data.comissoes.ComissionsResume[0].Receber
              ? data.comissoes.ComissionsResume[0].Receber
              : 0,
            podeReceber: data.comissoes.ComissionsResume[0].PodeReceber
              ? data.comissoes.ComissionsResume[0].PodeReceber
              : 0,
          });

        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    const { currencies } = this.props;
    return (
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <FormattedMessage id="myihtp.comissoes.resumo.title" />{" "}
              <FormattedMessage id="myihtp.comissoes.resumo.detail.title" />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <FormattedNumber
                value={this.state.ganhas}
                style="currency"
                currency={
                  currencies
                    ? currencies.selected
                      ? currencies.selected
                      : null
                    : null
                }
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />{" "}
              {" - "}{" "}
              <FormattedNumber
                value={this.state.pagas}
                style="currency"
                currency={
                  currencies
                    ? currencies.selected
                      ? currencies.selected
                      : null
                    : null
                }
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
              {" = "}
              <FormattedNumber
                value={
                  this.state.receber ? this.state.receber : this.state.ganhas
                }
                style="currency"
                currency={
                  currencies
                    ? currencies.selected
                      ? currencies.selected
                      : null
                    : null
                }
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <FormattedMessage id="myihtp.comissoes.resumo.valorDisponivel.title" />{" "}
              <FormattedNumber
                value={
                  this.state.podeReceber
                }
                style="currency"
                currency={
                  currencies
                    ? currencies.selected
                      ? currencies.selected
                      : null
                    : null
                }
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}
const mapStateToProps = state => {
  return {
    cart: state.cart,
    currencies: state.currencies,
    user: state.user,
  };
};
export default connect(mapStateToProps)(Resumo);
