import React, { Component } from "react";

import { ToastContainer } from "react-toastr";

import { connect } from "react-redux";

import "./RecoverPassword.css";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { recoverPassword } from "../../../actions/miniCartActions";
import Routes from "../../../helpers/Routes";
import CircularProgress from "@material-ui/core/CircularProgress";

let container;

class RecoverPassword extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: ""
    };
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
  }

  handleFormSubmit = async e => {
    //Se a pessoa tiver verificado o captcha
    if (this.props.isVerified) {
      e.stopPropagation();

      this.props.dispatch(recoverPassword(this.state.email))
        .then(data => {
          if (data.success === true) {
            container.clear();
            container.success(`${data.message}`);
          } else {
            container.clear();
            container.error(`${data.message}`);
          }
        });

    } else {
      container.clear();
      container.info(`Tem a certeza que preencheu todos os dados?`);
    }
  };


  handleChangeEmail(event) {
    this.setState({
      email: event.target.value
    });
  }

  render() {
    return (
      <div class="sm-RecoverPassword">
        <ToastContainer
          ref={ref => (container = ref)}
          className="toast-bottom-right"
        />
        <div>
          <div className="login-pageTitle">
            <div class="iniciarsess343o">Recuperar Palavra-passe</div>
          </div>
          <a class="esquecesteapalavra" href={Routes.account.registo}>
            Ainda não tem conta? Registe-se aqui!
          </a>
          <a class="n343otenscontaregi" href={Routes.account.login}>
            Já tem conta? Entre aqui!
          </a>
          <div class="textfield">
            <TextField
              id="email"
              label={this.props.email}
              className="email"
              value={this.state.email}
              onChange={this.handleChangeEmail}
              type="email"
            />
          </div>
          <div class="button">
            {this.props.loading === true ? (
              <Button
                disabled={true}
                style={{
                  backgroundColor: "#CA006A",
                  color: "white",
                  width: "70vw"
                }}
                onClick={this.handleFormSubmit}
              >
                <CircularProgress size={24} className="buttonProgress" />
              </Button>
            ) : (
                <Button
                  style={{
                    backgroundColor: "#CA006A",
                    color: "white",
                    width: "70vw"
                  }}
                  onClick={this.handleFormSubmit}
                >
                  Recuperar Palavra-passe
                </Button>
              )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isVerified: state.isVerified,
    loading: state.loading
  };
};

export default connect(mapStateToProps)(RecoverPassword);
