import React, { Component } from 'react'
import ConsultorSubscreverLeads from './Components/ConsultorSubscreverLeads'
import ProcurarSubscricoes from './Components/ProcurarSubscricoes'

export default class SubscricaoLeads extends Component {
    render() {
        return (
            <div>
                <br />
                <ConsultorSubscreverLeads isTabActive={this.props.active} />
                <br />
                <hr />
                <br />
                <ProcurarSubscricoes isTabActive={this.props.active} />
                <br />
                <br />
            </div>
        )
    }
}
