import { CircularProgress, TextField } from "@material-ui/core";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { FormattedDate, FormattedNumber, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { ToastContainer } from "react-toastr";
import { getOrderDetailsBackOffice, getOrdersBackOffice } from "../../../../../actions/myihtpActions";
import Configs from "../../../../../helpers/Configs";
import CancelarEncomendaBotao from "../Encomendas/CancelarEncomendaBotao";
import "../Encomendas/Encomendas.css";
import FaturaBotao from "../Encomendas/FaturaBotao";
import { cancelOrderBackOffice } from "../Encomendas/Helper";
import NotaCreditoBotao from "../Encomendas/NotaCreditoBotao";
import PagamentoBotao from "../Encomendas/PagamentoBotao";
import ReciboBotao from "../Encomendas/ReciboBotao";

let container;
class Encomendas extends Component {

  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          Header:
            Configs.myIHTP.tableConfig.encomendas.columns.idEncomenda.Header,
          accessor:
            Configs.myIHTP.tableConfig.encomendas.columns.idEncomenda.accessor,
          width: 150
        },
        {
          Header: Configs.myIHTP.tableConfig.encomendas.columns.idCliente.Header,
          accessor:
            Configs.myIHTP.tableConfig.encomendas.columns.idCliente.accessor,
          width: 300,
          Cell: row => {
            return (
              <span>
                {row.original.ID_Cliente} - {row.original.NomeCompleto}
              </span>
            );
          }
        },
        {
          Header:
            Configs.myIHTP.tableConfig.encomendas.columns.idEstadoEncomenda
              .Header,
          accessor:
            Configs.myIHTP.tableConfig.encomendas.columns.idEstadoEncomenda
              .accessor,
          show:
            Configs.myIHTP.tableConfig.encomendas.columns.idEstadoEncomenda.show,
          width: 250
        },
        {
          Header:
            Configs.myIHTP.tableConfig.encomendas.columns.estadoEncomenda.Header,
          accessor:
            Configs.myIHTP.tableConfig.encomendas.columns.estadoEncomenda
              .accessor,
          show: !isMobile,
          width: 250
        },
        {
          Header:
            Configs.myIHTP.tableConfig.encomendas.columns.dataEncomenda.Header,
          accessor:
            Configs.myIHTP.tableConfig.encomendas.columns.dataEncomenda.accessor,
          show: !isMobile,
          width: 150,
          Cell: row => {
            return row.original.DataEncomenda ? (
              <FormattedDate value={new Date(row.original.DataEncomenda)} />
            ) : (
                "-"
              );
          }
        },
        {
          Header: Configs.myIHTP.tableConfig.encomendas.columns.precoFinal.Header,
          accessor:
            Configs.myIHTP.tableConfig.encomendas.columns.precoFinal.accessor,
          width: 124,
          Cell: row => {
            return (
              <FormattedNumber
                value={row.original.PrecoFinal}
                style="currency"
                currency={row.original.ID_Moeda}
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
            );
          }
        },
        {
          Header: "F",
          accessor: "fatura",
          width: 50,
          Cell: row => {
            if (row.original.UrlFatura) {
              return (
                <div>
                  <FaturaBotao url={row.original.UrlFatura} />
                </div>
              );
            } else {
              return <div />;
            }
          },
          resizable: false
        },
        {
          Header: "R",
          accessor: "recibo",
          width: 50,
          Cell: row => {
            if (row.original.UrlRecibo) {
              return <ReciboBotao url={row.original.UrlRecibo} />;
            } else {
              return <div />;
            }
          },
          resizable: false
        },
        {
          Header: "NC",
          accessor: "notaCredito",
          width: 50,
          Cell: row => {
            if (row.original.UrlNotaCredito) {
              return <NotaCreditoBotao url={row.original.UrlNotaCredito} />;
            } else {
              return <div />;
            }
          },
          resizable: false
        },
        {
          Header: "P",
          accessor: "pagamento",
          width: 50,
          Cell: row => {
            if (
              !row.original.UrlFatura &&
              !row.original.UrlRecibo &&
              !row.original.NotaCredito
            ) {
              return <PagamentoBotao hash={row.original.HashEncomenda} />;
            } else {
              return <div />;
            }
          },
          resizable: false
        },
        {
          Header: "C",
          accessor: "cancelarEncomenda",
          width: 50,
          Cell: row => {
            if (row.original.ID_EstadoEncomenda === "AP") {
              return (
                <CancelarEncomendaBotao
                  id={row.original.ID_Encomenda}
                  cancelOrder={this.cancelOrder}
                />
              );
            } else {
              return <div />;
            }
          },
          resizable: false
        }
      ],
      orders: [],
      loadingDetails: false,
      successGetOrderDetails: false,
      orderDetailsErrorMessage: "",
      details: {},
      itens: [],
      estados: null,
      expanded: {},
      filtered: [],
      recordsPerPage: 10,
      currentPage: 0,
      totalPages: 0,
      orderField: null,
      orderFieldAsc: false,
      loading: false,
      searchOrderId: null,
      successGetOrders: true
    };

    this.cancelOrder = this.cancelOrder.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.loadOrders = this.loadOrders.bind(this);
    this.fetchNewInfo = this.fetchNewInfo.bind(this);
  }

  componentDidMount() {
    this.loadOrders(this.state.currentPage, this.state.recordsPerPage, this.state.orderField, this.state.orderFieldAsc, this.state.searchOrderId);
  }

  handleChange(e) {
    let value = e.target.value;
    let name = e.target.name;

    this.setState(prevState => ({
      ...prevState,
      [name]: value,
      expanded: {},
      currentPage: 0
    }), () => {
      this.loadOrders(this.state.currentPage, this.state.recordsPerPage, this.state.orderField, this.state.orderFieldAsc,
        this.state.searchOrderId)
    });
  }

  loadOrders(page, pageSize, orderField, orderFieldAsc, orderId) {

    this.setState(prevState => ({
      ...prevState,
      loading: true
    }));

    getOrdersBackOffice(page, pageSize, orderField, orderFieldAsc, orderId)
      .then(data => {
        if (data.success === true) {
          this.setState({
            totalPages: Math.ceil(data.obj.total / pageSize),
            currentPage: page,
            recordsPerPage: pageSize,
            orders: data.obj.dados,
            loading: false,
            orderField: orderField,
            orderFieldAsc: orderFieldAsc,
            successGetOrders: true,
          });
        } else {
          this.setState({
            successGetOrders: false,
            totalPages: 0,
            currentPage: 0,
            recordsPerPage: pageSize,
            orders: [],
            loading: false,
            orderField: orderField,
            orderFieldAsc: orderFieldAsc
          });
        }
      }).catch(err => {
        this.setState({
          successGetOrders: false,
          totalPages: 0,
          currentPage: 0,
          recordsPerPage: pageSize,
          orders: [],
          loading: false,
          orderField: orderField,
          orderFieldAsc: orderFieldAsc
        });
      });
  }

  //CANCELAR ENCOMENDA
  cancelOrder = id => {
    var confirm = window.confirm(
      this.props.intl.formatMessage({
        id: "myihtp.encomenda.cancelar"
      })
    );
    if (confirm) {

      cancelOrderBackOffice(id).then(result => {

        //Se der sucesso ao cancelar a encomenda
        if (result.success === true) {

          //Vamos buscar as encomendas atualizadas
          this.loadOrders(this.state.currentPage, this.state.recordsPerPage, this.state.orderField, this.state.orderFieldAsc, this.state.searchOrderId);

          container.clear();
          container.success(result.message);
        } else {
          container.clear();
          container.error(result.message);
        }
      });
    }
  };


  handleRowExpanded(rowsState, index) {
    this.setState({
      expanded: {
        [index[0]]: !this.state.expanded[index[0]]
      }
    });
  }

  getOrderDetailsData = id => async e => {

    this.setState(prevState => ({
      ...prevState,
      loadingDetails: true
    }));

    getOrderDetailsBackOffice(id)
      .then(data => {
        if (data.data.details.message !== "Não tem itens associados à encomenda que pediu.") {
          this.setState(prevState => (
            {
              ...prevState,
              details: data.data.details.Detalhes[0],
              itens: data.data.details.Itens,
              estados: data.data.details.Estados,
              successGetOrderDetails: true,
              orderDetailsErrorMessage: "",
              loadingDetails: false
            }
          ));
        } else {
          this.setState(prevState => (
            {
              ...prevState,
              successGetOrderDetails: false,
              orderDetailsErrorMessage: data.data.details.message,
              loadingDetails: false
            }
          ));
        }
      })
      .catch(error => {
        this.setState(prevState => (
          {
            ...prevState,
            successGetOrderDetails: false,
            orderDetailsErrorMessage: "Erro ao carregar informação da Encomenda",
            loadingDetails: false
          }
        ));
      });
  };

  fetchNewInfo(page, pageSize, orderField, orderFieldAsc) {

    if (pageSize !== this.state.recordsPerPage) page = 0;
    if (orderField !== this.state.orderField) page = 0;
    if (orderFieldAsc !== this.state.orderFieldAsc) page = 0;

    this.loadOrders(page, pageSize, orderField, orderFieldAsc, this.state.searchOrderId);
  }

  render() {
    const { user } = this.props;

    return (
      <Row>
        <ToastContainer ref={ref => (container = ref)} className="toast-bottom-right" />

        <Col xs="12" sm="12" md="12" lg="12">

          {/* Input para colocar o id da encomenda que se pretende buscar*/}
          <Row style={{ textAlign: "right", alignItems: "center" }}>
            <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "right", display: "flex", alignItems: "center" }}>
              <span style={{ minHeight: "100%" }}>ID Encomenda: &nbsp;</span>
              <TextField variant="outlined" onChange={this.handleChange} name="searchOrderId" value={this.state.searchOrderId} id="searchOrderId" />
            </Col>
          </Row>

          <br />
          {this.state.successGetOrders === false ?
            <Row>
              <Col xs="12" sm="12" md="12" lg="12">
                <span style={{ color: "red" }}>Erro ao obter informações das Encomendas</span>
              </Col>
            </Row>
            : null}

          {/* Resultados da Pesquisa*/}
          <Row style={{ textAlign: "left" }}>
            <Col xs="12" sm="12" md="12" lg="12">

              <br />
              <ReactTable
                className="-highlight tableReact"
                data={this.state.orders}
                expanded={this.state.expanded}
                filtered={this.state.filtered}
                loading={this.state.loading}
                onExpandedChange={(newExpanded, index, event) =>
                  this.handleRowExpanded(newExpanded, index, event)
                }
                getTheadProps={(state, rowInfo, column) => {
                  return {
                    style: {
                      fontWeight: Configs.myIHTP.tableConfig.fontHeightHeader,
                      fontSize: Configs.myIHTP.tableConfig.fontSizeHeader,
                      height: Configs.myIHTP.tableConfig.heightHeader,
                      color: Configs.myIHTP.tableConfig.colorHeader,
                      backgroundColor:
                        Configs.myIHTP.tableConfig.backgroundColorHeader
                    }
                  };
                }}
                getPaginationProps={state => {
                  return {
                    style: {
                      color: Configs.myIHTP.tableConfig.colorHeader,
                      backgroundColor:
                        Configs.myIHTP.tableConfig.backgroundColorHeader,
                      fontSize: Configs.myIHTP.tableConfig.fontSizeRowBody
                    }
                  };
                }}
                getTrProps={(state, rowInfo, column, instance) => {
                  var id_encomenda = null;
                  if (rowInfo) {
                    id_encomenda = rowInfo.original.ID_Encomenda;
                  }

                  return {
                    onClick: this.getOrderDetailsData(id_encomenda),
                    style: {
                      "text-align": "center",
                      "font-size": Configs.myIHTP.tableConfig.fontSizeRowBody,
                      background:
                        (rowInfo && rowInfo.row.ID_EstadoEncomenda === "AP"
                          ? Configs.myIHTP.tableConfig.encomendas
                            .backgroundColor.yellow
                          : null) ||
                        (rowInfo &&
                          (rowInfo.row.ID_EstadoEncomenda === "PA" ||
                            rowInfo.row.ID_EstadoEncomenda === "PR" ||
                            rowInfo.row.ID_EstadoEncomenda === "EC")
                          ? Configs.myIHTP.tableConfig.encomendas
                            .backgroundColor.green
                          : null) ||
                        (rowInfo &&
                          (rowInfo.row.ID_EstadoEncomenda === "CC" ||
                            rowInfo.row.ID_EstadoEncomenda === "PC")
                          ? Configs.myIHTP.tableConfig.encomendas
                            .backgroundColor.red
                          : null) ||
                        (rowInfo && rowInfo.row.ID_EstadoEncomenda === "FE"
                          ? Configs.myIHTP.tableConfig.encomendas
                            .backgroundColor.cyan
                          : null)
                    }
                  };
                }}
                getTdProps={(state, rowInfo, column, instance) => {
                  return {
                    style: {
                      "text-align": "center",
                      "font-size": Configs.myIHTP.tableConfig.fontSizeRowBody
                    }
                  };
                }}
                defaultSorted={[
                  {
                    id: "ID_Encomenda",
                    desc: true
                  }
                ]}
                collapseOnDataChange={
                  Configs.myIHTP.tableConfig.collapseOnDataChange
                }
                pageSizeOptions={[5, 10, 20]}
                defaultPageSize={10}
                showPageJump={false}
                page={this.state.currentPage}
                pages={this.state.totalPages}
                pageSize={this.state.recordsPerPage}
                showPagination={true}
                previousText={Configs.myIHTP.tableConfig.buttonCopyAnterior}
                nextText={Configs.myIHTP.tableConfig.buttonCopyProximo}
                noDataText={Configs.myIHTP.tableConfig.noDataText}
                pageText={Configs.myIHTP.tableConfig.pageText}
                ofText={Configs.myIHTP.tableConfig.ofText}
                rowsText={Configs.myIHTP.tableConfig.rowsText}
                columns={this.state.columns}
                manual
                onFetchData={(state, instance) => {
                  this.fetchNewInfo(state.page, state.pageSize, state.sorted[0].id, !state.sorted[0].desc);
                }}
                SubComponent={row => {
                  return (
                    <div style={{ "font-size": "14px", padding: "10px", backgroundColor: "#f8f9fa" }}>
                      <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleTitle}>
                        {Configs.myIHTP.tableConfig.orderDetails.title}
                      </p>

                      {
                        this.state.loadingDetails === true
                          ?
                          (
                            <Row style={{ display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                              <Col xs="12" sm="12" md="12" lg="12" style={{ padding: "50px", display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                                <CircularProgress thickness={2} size={35} disableShrink />
                                <p style={{ fontSize: "18px", textAlign: "center", color: "black", fontWeight: "400" }}>A carregar detalhes da Encomenda</p>
                              </Col>
                            </Row>
                          )
                          :
                          (
                            this.state.successGetOrderDetails === false
                              ?
                              (
                                <Row style={{ display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center" }}>
                                  <Col xs="12" sm="12" md="12" lg="12">
                                    <p style={{ fontSize: "18px", textAlign: "center", color: "red", fontWeight: "400", padding: "50px" }}>{this.state.orderDetailsErrorMessage}</p>
                                  </Col>
                                </Row>
                              )
                              :
                              (
                                <div>
                                  {this.state.details.ID_TipoPagamento === "MB" ? (
                                    <Row>
                                      <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center" }}
                                      >
                                        <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleSubtitle}>
                                          Dados Pagamento
                                        </p>
                                        <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleData} />
                                        <Row>
                                          <Col xs="12" sm="12" md="6" lg="6" style={{ textAlign: isMobile ? "center" : "right" }}>
                                            <img src="https://www.ihavethepower.net/Content/imagens/emails/multibanco.png" />
                                          </Col>
                                          <Col xs="12" sm="12" md="6" lg="6" style={{
                                            textAlign: isMobile ? "center" : "left", margin: "auto", top: "0px", bottom: "0px"
                                          }}>
                                            <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleData2}>
                                              <b>Entidade:</b>{" "}
                                              {this.state.details.EntidadeMB}
                                            </p>
                                            <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleData2}>
                                              <b>Referência:</b>{" "}
                                              {this.state.details.ReferenciaMB}
                                            </p>
                                            <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleData2}>
                                              <b>Valor:</b>{" "}
                                              <FormattedNumber
                                                value={this.state.details.PrecoFinal}
                                                style="currency"
                                                currency={this.state.details.ID_Moeda}
                                                minimumFractionDigits={2}
                                                maximumFractionDigits={2}
                                              />
                                            </p>
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                  ) : null
                                  }

                                  {/* Conter Id da Encomenda e Data da Encomenda*/}
                                  <Row>
                                    <Col xs="12" sm="12" md="6" lg="6">
                                      <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleSubtitle}>
                                        {
                                          Configs.myIHTP.tableConfig.orderDetails.data.idEncomenda.title
                                        }
                                      </p>
                                      <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleData}>
                                        {row.original.ID_Encomenda}
                                      </p>
                                    </Col>
                                    <Col xs="12" sm="12" md="6" lg="6">
                                      <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleSubtitle}>
                                        {
                                          Configs.myIHTP.tableConfig.orderDetails.data
                                            .dataEncomenda.title
                                        }
                                      </p>
                                      <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleData}>
                                        {this.state.details.DataEncomenda ? (
                                          <FormattedDate value={new Date(this.state.details.DataEncomenda)} />
                                        ) : (
                                            "-"
                                          )}
                                      </p>
                                    </Col>
                                  </Row>

                                  <br />

                                  {/* Conter Estado da Encomenda e Tipo de Transporte*/}
                                  <Row>
                                    <Col xs="12" sm="12" md="6" lg="6">
                                      <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleSubtitle}>
                                        {
                                          Configs.myIHTP.tableConfig.orderDetails.data.estadoEncomenda.title
                                        }
                                      </p>
                                      <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleData}>
                                        {this.state.details.EstadoEncomenda}
                                      </p>
                                    </Col>
                                    <Col xs="12" sm="12" md="6" lg="6">
                                      <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleSubtitle}>
                                        {
                                          Configs.myIHTP.tableConfig.orderDetails.data.tipoTransporte.title
                                        }
                                      </p>
                                      <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleData}>
                                        {this.state.details.DescricaoTipoTransporte}
                                      </p>
                                    </Col>
                                  </Row>

                                  <br />

                                  {/* Conter Tipo de Pagamento e data de pagamento, data de envio*/}
                                  <Row>
                                    <Col xs="12" sm="12" md="6" lg="6">
                                      <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleSubtitle}>
                                        {
                                          Configs.myIHTP.tableConfig.orderDetails.data.tipoPagamento.title
                                        }
                                      </p>
                                      <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleData}>
                                        {this.state.details.DescricaoTipoPagamento}
                                      </p>
                                    </Col>
                                    <Col xs="12" sm="12" md="3" lg="3">
                                      <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleSubtitle}>
                                        {
                                          Configs.myIHTP.tableConfig.orderDetails.data.dadosPagamento.title
                                        }
                                      </p>
                                      <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleData}>
                                        {this.state.details.DataPagamento ? (
                                          <FormattedDate value={new Date(this.state.details.DataPagamento)} />
                                        ) : ("-")}
                                      </p>
                                    </Col>
                                    <Col xs="12" sm="12" md="3" lg="3">
                                      <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleSubtitle}>
                                        {
                                          Configs.myIHTP.tableConfig.orderDetails.data.dataEnvio.title
                                        }
                                      </p>
                                      <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleData}>
                                        {this.state.details.DataEnvio ? (
                                          <FormattedDate value={new Date(this.state.details.DataEnvio)} />
                                        ) : ("-")}
                                      </p>
                                    </Col>
                                  </Row>
                                  <br />

                                  <Row>
                                    {/* Dados de Envio */}
                                    <Col xs="12" sm="12" md="6" lg="6">
                                      <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleSubtitle}>
                                        {
                                          Configs.myIHTP.tableConfig.orderDetails.data.dadosEnvio.title
                                        }
                                      </p>
                                      <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleData}>
                                        {this.state.details.moradaEnvioMorada ? (
                                          <div>
                                            <p>{this.state.details.moradaEnvioNome}</p>
                                            <p>{this.state.details.moradaEnvioMorada}</p>
                                            <p>
                                              {this.state.details.moradaEnvioCodigoPostal}{" "}
                                              {this.state.details.moradaEnvioLocalidade}
                                            </p>
                                            <p>{this.state.details.moradaEnvioPais}</p>
                                          </div>
                                        ) : (
                                            <p>Encomenda sem morada de envio</p>
                                          )}
                                      </p>
                                    </Col>
                                    {/* Dados de Faturacao */}
                                    <Col xs="12" sm="12" md="6" lg="6">
                                      <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleSubtitle}>
                                        {
                                          Configs.myIHTP.tableConfig.orderDetails.data.dadosFaturacao.title
                                        }
                                      </p>
                                      <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleData}>
                                        {this.state.details.moradaFaturacaoMorada ? (
                                          <div>
                                            <p>{this.state.details.moradaFaturacaoNome}</p>
                                            <p>{this.state.details.moradaFaturacaoNif}</p>
                                            <p>
                                              {this.state.details.moradaFaturacaoMorada}
                                            </p>
                                            <p>
                                              {
                                                this.state.details.moradaFaturacaoCodigoPostal
                                              }{" "}
                                              {this.state.details.moradaFaturacaoLocalidade}
                                            </p>
                                            <p>{this.state.details.moradaFaturacaoPais}</p>
                                          </div>
                                        ) : (
                                            <p>Encomenda sem morada de faturação</p>
                                          )}
                                      </p>
                                    </Col>
                                  </Row>

                                  <br />

                                  <Row>
                                    <Col xs="12" sm="12" md="6" lg="6">
                                      <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleSubtitle}>
                                        {
                                          Configs.myIHTP.tableConfig.orderDetails.data.precoTotal.title
                                        }
                                      </p>
                                      <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleData}>
                                        <FormattedNumber value={this.state.details.PrecoTotal} style="currency"
                                          currency={this.state.details.ID_Moeda}
                                          minimumFractionDigits={2}
                                          maximumFractionDigits={2}
                                        />
                                      </p>
                                    </Col>
                                    <Col xs="12" sm="12" md="6" lg="6">
                                      <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleSubtitle}>
                                        {
                                          Configs.myIHTP.tableConfig.orderDetails.data.descontoTotal.title
                                        }
                                      </p>
                                      <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleData}>
                                        <FormattedNumber value={this.state.details.DescontoTotal} style="currency"
                                          currency={this.state.details.ID_Moeda}
                                          minimumFractionDigits={2}
                                          maximumFractionDigits={2}
                                        />
                                      </p>
                                    </Col>
                                  </Row>

                                  <br />

                                  <Row>
                                    <Col xs="12" sm="12" md="6" lg="6">
                                      <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleSubtitle}>
                                        {
                                          Configs.myIHTP.tableConfig.orderDetails.data.ivaTotal.title
                                        }
                                      </p>
                                      <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleData}>
                                        <FormattedNumber value={this.state.details.IvaTotal} style="currency"
                                          currency={this.state.details.ID_Moeda}
                                          minimumFractionDigits={2}
                                          maximumFractionDigits={2}
                                        />
                                      </p>
                                    </Col>
                                    <Col xs="12" sm="12" md="6" lg="6">
                                      <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleSubtitle}>
                                        {
                                          Configs.myIHTP.tableConfig.orderDetails.data.custoTransporte.title
                                        }
                                      </p>
                                      <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleData}>
                                        <FormattedNumber value={this.state.details.CustoTransporte} style="currency"
                                          currency={this.state.details.ID_Moeda}
                                          minimumFractionDigits={2}
                                          maximumFractionDigits={2}
                                        />
                                      </p>
                                    </Col>
                                  </Row>

                                  <br />

                                  <Row>
                                    <Col xs="12" sm="12" md="6" lg="6">
                                      <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleSubtitle}>
                                        {
                                          Configs.myIHTP.tableConfig.orderDetails.data.descontoCredito.title
                                        }
                                      </p>
                                      <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleData}>
                                        <FormattedNumber value={this.state.details.DescontoCredito} style="currency"
                                          currency={this.state.details.ID_Moeda}
                                          minimumFractionDigits={2}
                                          maximumFractionDigits={2}
                                        />
                                      </p>
                                    </Col>
                                    <Col xs="12" sm="12" md="6" lg="6">
                                      <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleSubtitle}>
                                        {
                                          Configs.myIHTP.tableConfig.orderDetails.data.precoFinal.title
                                        }
                                      </p>
                                      <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleData}>
                                        <FormattedNumber value={this.state.details.PrecoFinal} style="currency"
                                          currency={this.state.details.ID_Moeda}
                                          minimumFractionDigits={2}
                                          maximumFractionDigits={2}
                                        />
                                      </p>
                                    </Col>
                                  </Row>
                                  <br />

                                  <Row>
                                    <Col xs="12" sm="12" md="12" lg="12">
                                      <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleSubtitle}>
                                        {
                                          Configs.myIHTP.tableConfig.orderDetails.data.observacao.title
                                        }
                                      </p>
                                      <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleData}>
                                        {this.state.details.Observacao
                                          ? this.state.details.Observacao
                                          : "Não existem observações"}
                                      </p>
                                    </Col>
                                  </Row>

                                  <br />

                                  <p style={Configs.myIHTP.tableConfig.encomendas.itensDetails.styleTitle}>
                                    {Configs.myIHTP.tableConfig.itensDetails.title}
                                  </p>

                                  <ReactTable
                                    getTheadProps={(state, rowInfo, column) => {
                                      return {
                                        style: Configs.myIHTP.tableConfig.encomendas.itensDetails.styleTable
                                      };
                                    }}
                                    getPaginationProps={state => {
                                      return {
                                        style: {
                                          color: Configs.myIHTP.tableConfig.colorHeader,
                                          backgroundColor: Configs.myIHTP.tableConfig.backgroundColorHeader,
                                          fontSize: Configs.myIHTP.tableConfig.fontSizeRowBody
                                        }
                                      };
                                    }}
                                    data={this.state.itens}
                                    columns={[
                                      {
                                        Header: Configs.myIHTP.tableConfig.itensDetails.columns.nome.header,
                                        accessor: Configs.myIHTP.tableConfig.itensDetails.columns.nome.accessor,
                                        style: { "text-align": "center" },
                                        Cell: row => {
                                          return (
                                            <span
                                              class="fake-link"
                                              id="fake-link-1"
                                              onClick={() => {
                                                var id = row.original.ID_Produto
                                                  ? row.original.ID_Produto
                                                  : row.original.ID_Pack;
                                                window.open(
                                                  "https://www.ihavethepower.net/Loja/DetalheProduto/" +
                                                  id +
                                                  "/" +
                                                  row.original.titleUrl,
                                                  "_blank"
                                                );
                                              }}
                                            >
                                              {row.original.nome}
                                            </span>
                                          );
                                        }
                                      },
                                      {
                                        Header: Configs.myIHTP.tableConfig.itensDetails.columns.iva.header,
                                        accessor: Configs.myIHTP.tableConfig.itensDetails.columns.iva.accessor,
                                        width: 100,
                                        style: { "text-align": "center" },
                                        Cell: row => {
                                          return (
                                            <FormattedNumber
                                              value={row.original.taxaiva}
                                              style="percent"
                                              minimumFractionDigits={2}
                                              maximumFractionDigits={2}
                                            />
                                          );
                                        }
                                      },
                                      {
                                        Header: Configs.myIHTP.tableConfig.itensDetails.columns.quantidade.header,
                                        accessor: Configs.myIHTP.tableConfig.itensDetails.columns.quantidade.accessor,
                                        width: 100,
                                        style: { "text-align": "center" }
                                      },
                                      {
                                        Header: Configs.myIHTP.tableConfig.itensDetails.columns.precoUnitario.header,
                                        accessor: user.userType === "CS"
                                          ? Configs.myIHTP.tableConfig.itensDetails
                                            .columns.precoUnitarioCS.accessor
                                          : Configs.myIHTP.tableConfig.itensDetails
                                            .columns.precoUnitario.accessor,
                                        width: 100,
                                        style: { "text-align": "center" },
                                        Cell: row => {
                                          return (
                                            <FormattedNumber
                                              value={
                                                user.userType === "CS"
                                                  ? row.original.precoconsultorcomiva
                                                  : row.original.precoclientefinalcomiva
                                              }
                                              style="currency"
                                              currency={this.state.details.ID_Moeda}
                                              minimumFractionDigits={2}
                                              maximumFractionDigits={2}
                                            />
                                          );
                                        }
                                      }
                                    ]}
                                    showPagination={Configs.myIHTP.tableConfig.showPagination}
                                    defaultPageSize={Configs.myIHTP.tableConfig.defaultPageSize}
                                  />
                                  <br />
                                  <p style={Configs.myIHTP.tableConfig.encomendas.changeOrderState.styleTitle}>
                                    {Configs.myIHTP.tableConfig.changeOrderState.title}
                                  </p>
                                  {
                                    this.state.estados ? (
                                      <ReactTable
                                        getTheadProps={(state, rowInfo, column) => {
                                          return {
                                            style: Configs.myIHTP.tableConfig.encomendas.changeOrderState.styleTable
                                          };
                                        }}
                                        getPaginationProps={state => {
                                          return {
                                            style: {
                                              color: Configs.myIHTP.tableConfig.colorHeader,
                                              backgroundColor: Configs.myIHTP.tableConfig.backgroundColorHeader,
                                              fontSize: Configs.myIHTP.tableConfig.fontSizeRowBody
                                            }
                                          };
                                        }}
                                        data={this.state.estados}
                                        columns={[
                                          {
                                            Header: Configs.myIHTP.tableConfig.changeOrderState.columns.data.header,
                                            accessor: Configs.myIHTP.tableConfig.changeOrderState.columns.data.accessor,
                                            style: { "text-align": "center" },
                                            Cell: row => {
                                              return row.original.DataMudancaEstado ? (
                                                <FormattedDate value={new Date(row.original.DataMudancaEstado)} />
                                              ) : (
                                                  "-"
                                                );
                                            }
                                          },
                                          {
                                            Header: Configs.myIHTP.tableConfig.changeOrderState.columns.estadoEncomenda.header,
                                            accessor: Configs.myIHTP.tableConfig.changeOrderState.columns.estadoEncomenda.accessor,
                                            style: { "text-align": "center" }
                                          },
                                          {
                                            Header: Configs.myIHTP.tableConfig.changeOrderState.columns.observacao.header,
                                            accessor: Configs.myIHTP.tableConfig.changeOrderState.columns.observacao.accessor,
                                            style: { "text-align": "center" },
                                            Cell: row => {
                                              return row.original.Observacao
                                                ? row.original.Observacao
                                                : "Não existem observações";
                                            }
                                          },
                                          {
                                            Header:
                                              Configs.myIHTP.tableConfig.changeOrderState.columns.emailEnviado.header,
                                            accessor: Configs.myIHTP.tableConfig.changeOrderState.columns.emailEnviado.accessor,
                                            style: { "text-align": "center" }
                                          }
                                        ]}
                                        showPagination={Configs.myIHTP.tableConfig.showPagination}
                                        defaultPageSize={Configs.myIHTP.tableConfig.defaultPageSize}
                                      />
                                    ) : null
                                  }
                                </div>
                              )
                          )
                      }
                    </div>
                  )
                }
                }
              />

            </Col>
          </Row>

          <br />
          <br />
          <br />
        </Col>
      </Row >
    );
  }
}

const mapStateToProps = state => {
  return {
    currencies: state.currencies,
    user: state.user
  };
};

export default injectIntl(withRouter(connect(mapStateToProps)(Encomendas)));
