import React, { Component } from 'react'
import { Row, Col } from "react-bootstrap";
import { TextField, Button } from "@material-ui/core";
import { isMobile } from "react-device-detect";
import { changeLeadConsultant } from "../../../../../../../actions/myihtpActions";
import { ToastContainer } from "react-toastr";

let container
export default class MudarConsultorLead extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id_lead: null,
            id_consultor: null
        }

        this.handleInput = this.handleInput.bind(this);
        this.changeLeadConsultantButtonClick = this.changeLeadConsultantButtonClick.bind(this);
    };

    handleInput(e) {
        let value = e.target.value;
        let name = e.target.name;
        this.setState(
            prevState => ({
                ...prevState,
                [name]: value
            })
        );
    }

    //Função para mudar o Consultor de uma lead
    changeLeadConsultantButtonClick = () => async e => {

        e.stopPropagation();
        changeLeadConsultant(this.state.id_lead, this.state.id_consultor)
            .then(result => {

                container.clear();
                if (result.success === true) {
                    container.success(result.message);
                    this.setState({
                        id_lead: '',
                        id_consultor: ''
                    })
                } else {

                    if (typeof result.message === 'undefined') {
                        container.error("Erro ao comunicar com servidor para mudar Consultor de Lead");
                    } else {
                        container.error(result.message);
                    }
                }
            })
            .catch(err => {
                container.clear();
                container.error("Erro ao comunicar com servidor para mudar Consultor de Lead");
            })
    }


    render() {
        return (
            <div>

                <ToastContainer
                    ref={ref => (container = ref)}
                    className="toast-bottom-right"
                />

                {/* ROW para mudar consultor de lead*/}
                <Row style={{ textAlign: "left" }}>
                    <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center" }}>
                        <span style={{ textAlign: "center" }}>
                            <b>Mudar Consultor de Negócio (Lead)</b>
                        </span>
                        <div className="form-group">
                            <br />
                            <Row xs={12} sm={12} md={12} lg={12}>
                                <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }} >
                                    <TextField
                                        placeholder="ID Negócio (Lead)"
                                        name={"id_lead"}
                                        style={{ maxWidth: isMobile ? "100%" : "50%" }}
                                        type="text"
                                        fullWidth={true}
                                        value={this.state.id_lead}
                                        onChange={this.handleInput}
                                    />
                                </Col>
                            </Row>
                            <br />
                            <Row xs={12} sm={12} md={12} lg={12}>
                                <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }} >
                                    <TextField
                                        placeholder="ID Consultor"
                                        name={"id_consultor"}
                                        style={{ maxWidth: isMobile ? "100%" : "50%" }}
                                        type="text"
                                        fullWidth={true}
                                        value={this.state.id_consultor}
                                        onChange={this.handleInput}
                                    />
                                </Col>
                            </Row>
                            <br />
                            <br />
                            <Row>
                                <Col
                                    xs="12"
                                    sm="12"
                                    md="12"
                                    lg="12"
                                    style={{ textAlign: "center" }}
                                >
                                    <Button variant="contained" color="secondary" style={{ minWidth: "20%" }} onClick={this.changeLeadConsultantButtonClick()}
                                    >
                                        Mudar Consultor
                      </Button>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}
