import {
  deleteOrder,
  deleteOrderBackOffice
} from "../../../../../actions/myihtpActions";

export function cancelOrder(id) {
  return new Promise((resolve, reject) => {
    deleteOrder(id)
      .then(data => {
        resolve(data)
      })
      .catch(error => {

        var response = {
          success: false,
          message: "Erro ao ligar com servidor para cancelar Encomenda"
        };
        resolve(response);
      });
  });
}

export function cancelOrderBackOffice(id) {
  return new Promise((resolve, reject) => {
    deleteOrderBackOffice(id)
      .then(data => {
        resolve(data)
      })
      .catch(error => {

        var response = {
          success: false,
          message: "Erro ao ligar com servidor para cancelar Encomenda"
        };
        resolve(response);
      });
  });
}
