import React, { Component } from "react";
import { withCookies } from "react-cookie";
import { connect } from "react-redux";
import Routes from "../../../helpers/Routes";

class LogoHeader extends Component {
  render() {
    return (
      <a href={Routes.home.home}>
        <img
          alt="logo"
          src="/assets/images/headersm-logoihtp@2x.png"
          className="logoimage"
          width="50%"
        />
      </a>
    );
  }
}

const mapStateToProps = state => {
  return {
    isVerified: state.isVerified,
    user: state.user
  };
};

export default withCookies(connect(mapStateToProps)(LogoHeader));
