import React, { Component } from 'react'
import UploadVitamina from './Components/UploadVitamina'
import UploadFicheiro from './Components/UploadFicheiro'

export default class Multimedia extends Component {
    render() {
        return (
            <div>
                <br />
                <br />
                <UploadVitamina />
                <br />
                <UploadFicheiro />
                <br />
                <br />
            </div>
        )
    }
}
