import React, { Component } from "react";

import "react-table/react-table.css";

import { Row, Col, FormGroup, FormControl } from "react-bootstrap";

import ListagemPremios from "./ListagemPremios/ListagemPremios";
import ResumoPremios from "./ResumoPremios/ResumoPremios";

class Premios extends Component {
  componentDidMount() {}

  render() {
    return (
      <Row>
        <Col xs="12" sm="12" md="12" lg="12">
          <Row style={{ textAlign: "left" }}>
            <Col xs="12" sm="12" md="12" lg="12">
              <ResumoPremios />
              <ListagemPremios />
            </Col>
          </Row>
        </Col>
      </Row>
      // <div className="myaccount-content">
      //   <hr />
      //   <ResumoPremios />
      //   <ListagemPremios />
      // </div>
    );
  }
}
export default Premios;
