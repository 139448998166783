import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
// Traduções
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ReactTableDefaults } from "react-table";
import "react-table/react-table.css";
import PaginationMobile from "../Modules/PaginationMobile";
import Subtitle from "../Modules/Subtitle";
import Agendamentos from "./Agendamentos/Agendamentos";
import Aniversarios from "./Aniversarios/Aniversarios";
import DownlinesExpirar from "./DownlinesExpirar/DownlinesExpirar";
import IndicadoresDashboard from "./IndicadoresDashboard/IndicadoresDashboard";
import InformacoesBase from "./InformacoesBase/InformacoesBase";
import ListaRegistosMaisRecentes from "./ListaRegistosMaisRecentes/ListaRegistosMaisRecentes";
import ListaRegistosNaoConcluidos from "./ListaRegistosNaoConcluidos/ListaRegistosNaoConcluidos";
import Ocorrencias from "./Ocorrencias/Ocorrencias";
import PrevisoesRede from "./PrevisoesRede/PrevisoesRede";
import ProximosCursos from "./ProximosCursos/ProximosCursos";
import RegistosEnc from "./RegistosEnc/RegistosEnc";
import UltimasEncomendas from "./UltimasEncomendas/UltimasEncomendas";
import UltimasInscricoes from "./UltimasInscricoes/UltimasInscricoes";

// Para termos duas paginations diferentes: mobile e desktop
Object.assign(
  ReactTableDefaults,
  isMobile
    ? {
      PaginationComponent: PaginationMobile,
    }
    : null
);

class Dashboard extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { currencies } = this.props;
    return (
      <Row>
        <Col xs="12" sm="12" md="12" lg="12">
          <Row style={{ textAlign: "center" }}>
            <Col xs="12" sm="12" md="12" lg="12">
              <InformacoesBase />
              {this.props.user.userType === "CS" ? (
                <span>
                  <Container>
                    <Subtitle
                      text={<FormattedMessage id="myihtp.ocorrencias.title" />}
                      marginBottom="20px"
                      marginTop="20px"
                    />
                  </Container>
                  <Ocorrencias currency={currencies} />
                  <Container>
                    <Subtitle
                      text={<FormattedMessage id="myihtp.indicadores.title" />}
                      marginBottom="20px"
                      marginTop="20px"
                    />
                  </Container>
                  <IndicadoresDashboard currency={currencies} />
                  <Container>
                    <Subtitle
                      text={
                        <FormattedMessage id="myihtp.agendamentosDia.title" />
                      }
                      marginBottom="20px"
                      marginTop="20px"
                    />
                  </Container>
                  <Agendamentos />
                  <hr />
                  <Container>
                    <Subtitle
                      text={
                        <FormattedMessage id="myihtp.listaAniversarios.title" />
                      }
                      marginBottom="20px"
                      marginTop="20px"
                    />
                  </Container>
                  {<Aniversarios />}
                </span>
              ) : null}
              <Container>
                <Subtitle
                  text={
                    <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.title" />
                  }
                  marginBottom="20px"
                  marginTop="20px"
                />
              </Container>
              <UltimasEncomendas />
              <hr />
              {this.props.user.userType === "CS" ? (
                <span>
                  <Container>
                    <Subtitle
                      text={
                        <FormattedMessage id="myihtp.proximosCursos.title" />
                      }
                      marginBottom="20px"
                      marginTop="20px"
                    />
                  </Container>
                  <ProximosCursos />
                </span>
              ) : null}
              <Container>
                <Subtitle
                  text={
                    <FormattedMessage id="myihtp.ultimasInscricoesCursosEventos.title" />
                  }
                  marginBottom="20px"
                  marginTop="20px"
                />
              </Container>
              <UltimasInscricoes />
              {this.props.user.userType === "CS" ? (
                <span>
                  <hr />
                  <Container>
                    <Subtitle
                      text="Previsões da sua rede"
                      marginBottom="20px"
                      marginTop="20px"
                    />
                  </Container>
                  <PrevisoesRede />
                </span>
              ) : null}
              <Container>
                <Subtitle
                  text={
                    <FormattedMessage id="myihtp.listaRegistosMaisRecentes.title" />
                  }
                  marginBottom="20px"
                  marginTop="20px"
                />
              </Container>
              <ListaRegistosMaisRecentes />
              {this.props.user.userType === "CS" ? (
                <span>
                  <hr />
                  <Container>
                    <Subtitle
                      text="Registos não concluidos pelos clientes"
                      marginBottom="20px"
                      marginTop="20px"
                    />
                  </Container>
                  <ListaRegistosNaoConcluidos />
                  <Container>
                    <Subtitle
                      text="Registos não concluidos pelos clientes com encomendas"
                      marginBottom="20px"
                      marginTop="20px"
                    />
                  </Container>
                  <RegistosEnc />
                </span>
              ) : null}
              {this.props.user.userType === "CS" ? (
                <span>
                  <hr />
                  <Container>
                    <Subtitle
                      text="Downlines com contrato a expirar em 90 dias"
                      marginBottom="20px"
                      marginTop="20px"
                    />
                  </Container>
                  <DownlinesExpirar />
                </span>
              ) : null}
              <br />
              <br />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}
const mapStateToProps = state => {
  return {
    user: state.user,
    currencies: state.currencies,
  };
};

export default withRouter(connect(mapStateToProps)(Dashboard));
