import React, { Component } from 'react'
import { Row, Col } from "react-bootstrap";
import { TextField, Button } from "@material-ui/core";
import { isMobile } from "react-device-detect";
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import { addConsultantUnavailablePeriod } from "../../../../../../../actions/myihtpActions";
import { ToastContainer } from "react-toastr";
let container;

export default class ConsultorPeriodoIndisponivel extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id_consultor: '',
            date: [null, null],
        }

        this.handleInput = this.handleInput.bind(this);
        this.handleChangeAvailablePeriod = this.handleChangeAvailablePeriod.bind(this);
    };

    handleInput(e) {
        let value = e.target.value;
        let name = e.target.name;
        this.setState(
            prevState => ({
                ...prevState,
                [name]: value
            })
        );
    }

    handleChangeAvailablePeriod = date => {
        this.setState(prevState => {
            return {
                ...prevState,
                date: date
            }
        })
    }

    //Função para adicionar o periodo de indisponibilidade a um Consultor 
    addUnavailablePeriodButtonClick = () => async e => {

        //Verificar Id de Consultor preenchido
        if (this.state.id_consultor == null || this.state.id_consultor.trim().length <= 0) {
            container.clear();
            container.error("Id de Consultor não pode estar vazio");
            return;
        }

        //Verificar data vazia
        if (this.state.date[0] == null || this.state.date[1] == null) {
            container.clear();
            container.error("Período de indisponibilidade não pode estar vazio");
            return;
        }

        //Verificar se data fim é menor ou igual a data inicial
        if (this.state.date[1] <= this.state.date[0]) {
            container.clear();
            container.error("Data Final tem que ser superior à Data Inicial");
            return;
        }

        addConsultantUnavailablePeriod(this.state.date[0], this.state.date[1], this.state.id_consultor)
            .then(result => {

                container.clear();
                if (result.success === true) {
                    container.success(result.message);

                    //Limpar dados de adicionar
                    this.setState({
                        date: [null, null],
                        id_consultor: ''
                    });

                } else {

                    if (typeof result.message === 'undefined') {
                        container.error("Erro ao comunicar com servidor para adicionar período de indisponibilidade");
                    } else {
                        container.error(result.message);
                    }
                }
            })
            .catch(err => {
                container.clear();
                container.error("Erro ao comunicar com servidor para adicionar período de indisponibilidade");
            });

    }


    render() {
        return (
            <div>

                <ToastContainer
                    ref={ref => (container = ref)}
                    className="toast-bottom-right"
                />

                {/* ROW para Adicionar Periodo de Indisponibilidade*/}
                <Row style={{ textAlign: "left" }}>
                    <Col
                        xs="12"
                        sm="12"
                        md="12"
                        lg="12"
                        style={{ textAlign: "center" }}
                    >
                        <span style={{ textAlign: "center" }}>
                            <b>Adicionar Período de Indisponibilidade para Receber Leads </b>
                        </span>
                        <div className="form-group">
                            <br />
                            <Row xs={12} sm={12} md={12} lg={12}>
                                <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }} >
                                    <TextField
                                        placeholder="ID Consultor"
                                        name={"id_consultor"}
                                        style={{ maxWidth: isMobile ? "100%" : "50%" }}
                                        type="text"
                                        fullWidth={true}
                                        value={this.state.id_consultor}
                                        onChange={this.handleInput}
                                    />
                                </Col>
                            </Row>
                            <br />
                            <Row xs={12} sm={12} md={12} lg={12}>
                                <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center", display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center" }} >
                                    <label style={{ marginBottom: "0px", marginRight: "10px" }}> Período de Indisponibilidade: </label>
                                    <br /><br />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <DateTimeRangePicker value={this.state.date}
                                        name="date" id="date" onChange={this.handleChangeAvailablePeriod} />
                                </Col>
                            </Row>
                            <br />
                            <br />
                            <Row>
                                <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center" }}>
                                    <Button variant="contained" color="secondary" style={{ minWidth: "20%" }} onClick={this.addUnavailablePeriodButtonClick()}
                                    >
                                        Adicionar
                      </Button>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}
