import React, { Component } from "react";
import { withCookies } from "react-cookie";
import { connect } from "react-redux";

class Instagram extends Component {
  render() {
    return (
      <div
        style={{ display: "inline-flex" }}
        onClick={() => {
          window.open("https://www.youtube.com/user/solfut", "_blank");
        }}
      >
        <div style={{ padding: "5px" }}>
          <svg
            className="youtubeIcon"
            width="30px"
            height="31px"
            viewBox="0 0 30 31"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xlink="http://www.w3.org/1999/xlink"
          >
            <g
              id="Menu"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g id="XS" transform="translate(-189.000000, -584.000000)">
                <g id="Youtube" transform="translate(189.000000, 584.000000)">
                  <g>
                    <path
                      d="M0,15.5 C0,6.93958596 6.71572835,0 15,0 C23.2842716,0 30,6.93958596 30,15.5 C30,24.060414 23.2842716,31 15,31 C6.71572835,31 0,24.060414 0,15.5 Z"
                      id="back"
                      fill="#FF0000"
                      fill-rule="nonzero"
                    />
                    <path
                      d="M22.6656364,11.6722875 C22.4816364,10.9418458 21.9394546,10.3666541 21.251,10.1714417 C20.0033637,9.81666667 15,9.81666667 15,9.81666667 C15,9.81666667 9.99663635,9.81666667 8.7489091,10.1714417 C8.06045455,10.3666541 7.51827275,10.9418458 7.33427275,11.6722875 C7,12.9961292 7,15.7583333 7,15.7583333 C7,15.7583333 7,18.5204417 7.33427275,19.8443792 C7.51827275,20.5748208 8.06045455,21.1500125 8.7489091,21.3453208 C9.99663635,21.7 15,21.7 15,21.7 C15,21.7 20.0033637,21.7 21.251,21.3453208 C21.9394546,21.1500125 22.4816364,20.5748208 22.6656364,19.8443792 C23,18.5204417 23,15.7583333 23,15.7583333 C23,15.7583333 23,12.9961292 22.6656364,11.6722875"
                      id="Fill-1"
                      fill="#FFFFFF"
                      fill-rule="nonzero"
                    />
                    <polygon
                      id="Fill-2"
                      fill="#FF0000"
                      fill-rule="nonzero"
                      points="13.5 18.6 13.5 13.4333333 17.5 16.0167654"
                    />
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isVerified: state.isVerified,
    user: state.user
  };
};

export default withCookies(connect(mapStateToProps)(Instagram));
