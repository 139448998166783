import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import HeaderDesktop from "../../../components/Desktop/Header/Header";
import LoginDesktop from "../../../components/Desktop/Login/Login";
import Footer from "../../../components/Footer/Footer";

class Login extends Component {

  render() {

    var previousInCheckout = false;

    var state = this.props.location.state;
    if (typeof state !== 'undefined') {
      if (typeof state.previousInCheckout !== 'undefined' && state.previousInCheckout === true) {
        previousInCheckout = true;
      }
    }
    return (
      <div className="App">
        <HeaderDesktop />
        <br />
        <Container
          style={{
            position: "relative",
            top: "60px",
            width: "90vw"
          }}
        >
          <Row
            style={{
              textAlign: "center"
            }}
          >
            <Col xs="12" sm="12" md="12" lg="12">
              {" "}
              <h4 align="center" style={{ fontFamily: "Montserrat", fontWeight: "300", marginBottom: "15px" }}><FormattedMessage id="login.titulo" /></h4>

              <LoginDesktop history={this.props.history} previousInCheckout={previousInCheckout} />

              <Footer />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isVerified: state.isVerified,
    user: state.user
  };
};

export default connect(mapStateToProps)(Login);
