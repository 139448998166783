import $ from "jquery";
import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import Sidebar from "react-sidebar";
import { changeCurrencyFE, changeLanguageFE, fetchCartNumberItems, fetchCurrencyFunction, fetchLanguageFunction, fetchUser, sideBarMenu } from "../../../actions/miniCartActions";
import Routes from "../../../helpers/Routes";
import MenuDesktop from "../Menu/Menu";
import CartHeader from "./CartHeader";
import "./Header.css";
import LoginButton from "./LoginButton";
import LogoHeader from "./LogoHeader";
import LogoutButton from "./LogoutButton";
import MenuButton from "./MenuButton";
import ProfileButton from "./ProfileButton";
import SearchButton from "./SearchButton";
import ShopButton from "./ShopButton";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { currencies: [], languages: [] };
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleInputLingua = this.handleInputLingua.bind(this);
  }

  onSetSidebarOpen(open) {
    this.props.dispatch(sideBarMenu(open));
    $("#sidebarMenu").css("height", "100vh");
  }

  componentDidMount() {

    this.props.dispatch(fetchCartNumberItems());
    this.props.dispatch(fetchCurrencyFunction());
    this.props.dispatch(fetchUser());
    this.props.dispatch(fetchLanguageFunction());

  }
  handleInput(e) {
    let value = e.target.value;
    this.props.dispatch(changeCurrencyFE(value));
  }

  handleInputLingua(e) {
    let value = e.target.value;
    this.props.dispatch(changeLanguageFE(value));
    window.location.reload();
  }

  render() {
    const { currencies, user, sidebarmenu, languages } = this.props;
    return (
      <div
        id="sidebarMenu"
        style={{
          width: "100vw",
          position: "fixed",
          height: "60px",
          zIndex: 80
        }}
      >
        <Sidebar
          sidebar={<MenuDesktop />}
          open={sidebarmenu}
          onSetOpen={this.onSetSidebarOpen}
          styles={{ sidebar: { background: "white", zIndex: "10000" } }}
          shadow={true}
        />
        <Container style={{ minWidth: "100vw" }}>
          <Row
            style={{
              maxWidth: "100vw",
              height: "60px",
              backgroundColor:
                //mudar a cor do header para saber se tem impersonate ou nao
                this.props.user.persona != this.props.user.userID
                  ? this.props.user.persona
                    ? "#05ef00"
                    : "rgba(3, 66, 117, 1)"
                  : this.props.user.ConsultantType == 0
                    ? "#e2e220"
                    : "rgba(3, 66, 117, 1)"
            }}
          >
            <Col
              xs="3"
              sm="3"
              md="3"
              lg="4"
              style={{
                display: "inline-flex",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              {" "}
              <span
                style={{ left: "5vw", cursor: "pointer" }}
                onClick={() => this.onSetSidebarOpen(true)}
              >
                {" "}
                <MenuButton color="#ffffff" />
              </span>
              {isMobile ? null : (
                <a class="fake-link" id="fake-link-1" style={{ textDecoration: "none", padding: "15px" }} href={Routes.shop.home}>
                  <ShopButton color="#ffffff" />
                </a>
              )}
            </Col>
            <Col
              xs="6"
              sm="6"
              md="6"
              lg="4"
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <LogoHeader />
            </Col>
            <Col
              xs="3"
              sm="3"
              md="3"
              lg="4"
              style={{
                display: "inline-flex",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                right: isMobile ? "1vw" : "3vw"
              }}
            >
              {isMobile ? null : <SearchButton />}
              {/* <div
                style={{
                  // display: "inline-flex",
                  textAlign: "center",
                  // display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingRight: "10px"
                }}
              >
                <select
                  onChange={this.handleInputLingua}
                  name="language"
                  class="select-css"
                  style={{
                    display: isMobile ? "none" : "block",
                    textAlignLast: "center",
                    // marginRight: "15px",
                    padding: "10px",
                    color: "white",
                    backgroundColor: "rgb(3, 66, 117)",
                    minWidth: "100%",
                    boxShadow: "none"
                  }}
                >
                  {languages.selected ? (
                    <option value={languages.selected}>
                      {languages.selected}{" "}
                    </option>
                  ) : null}
                  {languages.list
                    ? languages.list.map((lingua, j) => {
                        return lingua.ID_Lingua ===
                          languages.selected ? null : (
                          <option value={lingua.ID_Lingua}>
                            {lingua.ID_Lingua}
                          </option>
                        );
                        // );
                      })
                    : null}
                </select>
              </div> */}
              <div
                style={{
                  // display: "inline-flex",
                  textAlign: "center",
                  // display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingRight: "10px"
                }}
              >
                <select
                  onChange={this.handleInput}
                  name="currency"
                  class="select-css"
                  style={{
                    display: isMobile ? "none" : "block",
                    textAlignLast: "center",
                    // marginRight: "15px",
                    padding: "10px",
                    color: "white",
                    //mudar a cor do header para saber se tem impersonate ou nao
                    backgroundColor:
                      this.props.user.persona != this.props.user.userID
                        ? this.props.user.persona
                          ? "#05ef00"
                          : "rgba(3, 66, 117, 1)"
                        : this.props.user.ConsultantType == 0
                          ? "#e2e220"
                          : "rgba(3, 66, 117, 1)",
                    minWidth: "100%",
                    boxShadow: "none"
                  }}
                >
                  {currencies.selected ? (
                    <option value={currencies.selected}>
                      {currencies.selectedDescription}{" "}
                    </option>
                  ) : null}
                  {currencies.list
                    ? currencies.list.map((currency, j) => {
                      return currency.ID_Moeda ===
                        currencies.selected ? null : (
                          <option value={currency.ID_Moeda}>
                            {currency.Descricao}
                          </option>
                        );
                      // );
                    })
                    : null}
                </select>
              </div>
              {user.userID ? (
                !isMobile ? (
                  <a class="fake-link" id="fake-link-1" style={{ textDecoration: "none" }} >
                    <LogoutButton />
                  </a>
                ) : null
              ) : null}
              {user.userID ? (
                <NavLink
                  className="loginIcon"
                  to={
                    user.userType === "CF"
                      ? "/encomendas"
                      : "/Dashboard"
                  }
                  style={{ padding: "15px" }}
                >
                  <ProfileButton />
                </NavLink>
              ) : (
                  <NavLink class="fake-link" id="fake-link-1" style={{ textDecoration: "none", paddingRight: "15px" }} to={Routes.account.login}>
                    <LoginButton />
                  </NavLink>
                )}
              <CartHeader />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    currencies: state.currencies,
    sidebarmenu: state.sideBarMenu,
    cartNumberItems: state.cartNumberItems,
    languages: state.languages
  };
};

export default withRouter(connect(mapStateToProps)(Header));
