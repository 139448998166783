import Chip from "@material-ui/core/Chip";
import $ from "jquery";
import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import MenuButton from "../../Header/MenuButton";
import "./Sidebar.css";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isConsultor: true,
      sideBar: false
    };
    this.elementRef = React.createRef();
  }

  changeWindow = location => () => {
    this.props.history.push("/" + location);
  };

  openSideBar = async e => {
    if (!this.state.sideBar) {
      $(".displaySidebar").css("display", "block");
      this.setState({
        sideBar: true
      });
    } else {
      $(".displaySidebar").css("display", "none");
      this.setState({
        sideBar: false
      });
    }
  };

  render() {
    var page = this.props.page;
    return (
      <div>
        <Row>
          <Col
            xs="9"
            sm="9"
            className="infoCreditosMobile"
            style={{
              textAlign: "center"
            }}
          >
            Olá {this.props.user.userName} <br />
            Créditos disponiveis: {this.props.user.credits}
            <br />
            Tipo de conta: {this.props.user.userType}
          </Col>
          {/* <Col
            style={{
              textAlign: "center",
            }}
          /> */}
          <Col
            xs="3"
            sm="3"
            onClick={() => this.openSideBar()}
            className="buttonMobileMenu"
            style={{
              textAlign: "right"
            }}
          >
            <MenuButton color="#034275" />
          </Col>
        </Row>
        <Container className="infoCreditos">
          <br />
          <Row>
            <Col xs="12" sm="12" md="12" lg="12">
              Olá {this.props.user.userName}
            </Col>
          </Row>
          <Row>
            <Col xs="12" sm="12" md="12" lg="12">
              Créditos disponiveis: {this.props.user.credits}
            </Col>
          </Row>
          {this.props.user.persona === 1 ||
            this.props.user.userID === 1 ||
            this.props.user.persona === 10005 ||
            this.props.user.userID === 10005 ||
            this.props.user.persona === 120164 ||
            this.props.user.userID === 120164 ||
            this.props.user.persona === 108522 ||
            this.props.user.userID === 108522 ||
            this.props.user.persona === 128912 ||
            this.props.user.userID === 128912 ||
            this.props.user.persona === 128549 ||
            this.props.user.userID === 128549 ? (
              <Row>
                <Col xs="12" sm="12" md="12" lg="12">
                  Tipo de conta:{" "}
                  {this.props.user.userType === "CS"
                    ? "Consultor"
                    : this.props.user.userType === "CF"
                      ? "Cliente Final"
                      : this.props.user.userType === "PR"
                        ? "Promotor"
                        : this.props.user.userType === "AF"
                          ? "Afiliado"
                          : null}
                </Col>
              </Row>
            ) : null}
        </Container>
        <hr />
        <Container className="displaySidebar">
          <Row className="myaccount-tab-menu nav">
            <Col xs="12" sm="12" md="12" lg="12">
              <button
                onClick={this.changeWindow("dashboard")}
                style={{
                  minWidth: "100%",
                  backgroundColor:
                    page === "Dashboard" ? "rgb(3, 66, 117)" : null,
                  color: page === "Dashboard" ? "white" : null
                }}
              >
                Dashboard
              </button>
            </Col>
          </Row>
          <Row className="myaccount-tab-menu nav">
            <Col xs="12" sm="12" md="12" lg="12">
              <button
                onClick={this.changeWindow("successbox")}
                style={{
                  display: "block",
                  minWidth: "100%",
                  backgroundColor:
                    page === "SuccessBox" ? "rgb(3, 66, 117)" : null,
                  color: page === "SuccessBox" ? "white" : null
                }}
              >
                SuccessBox <Chip color="secondary" label="Novo" size="small" />
              </button>
            </Col>
          </Row>

          {/* INDICADORES*/}
          {/* {this.props.user.userType === "CS" ? (
            <Row className="myaccount-tab-menu nav">
              <Col xs="12" sm="12" md="12" lg="12">
                <button
                  onClick={this.changeWindow("indicadores")}
                  style={{
                    display: "block",
                    minWidth: "100%",
                    backgroundColor:
                      page === "Indicadores" ? "rgb(3, 66, 117)" : null,
                    color: page === "Indicadores" ? "white" : null
                  }}
                >
                  Indicadores
                </button>
              </Col>
            </Row>
          ) : null} */}

          {/* {this.props.user.userType === "CS" ? (
            <Row className="myaccount-tab-menu nav">
              <Col xs="12" sm="12" md="12" lg="12">
                <button
                  onClick={this.changeWindow("negocios")}
                  style={{ display: "none", minWidth: "100%" }}
                >
                  <i className="fa fa-cart-arrow-down" /> Negócios
                </button>
              </Col>
            </Row>
          ) : null} */}
          {/* <Row className="myaccount-tab-menu nav">
            <Col xs="12" sm="12" md="12" lg="12">
              <button
                onClick={this.changeWindow("gerirconta")}
                style={{
                  display: "block",
                  minWidth: "100%",
                  backgroundColor:
                    page === "GerirConta" ? "rgb(3, 66, 117)" : null,
                  color: page === "GerirConta" ? "white" : null
                }}
                href="my-account.html#download"
              >
                <i className="fa fa-cloud-download" /> Gerir Conta
              </button>
            </Col>
          </Row> */}
          <Row className="myaccount-tab-menu nav">
            <Col xs="12" sm="12" md="12" lg="12">
              <button
                onClick={this.changeWindow("informacoespessoais")}
                style={{
                  display: "block",
                  minWidth: "100%",
                  backgroundColor:
                    page === "InformacoesPessoais" ? "rgb(3, 66, 117)" : null,
                  color: page === "InformacoesPessoais" ? "white" : null
                }}
              >
                Informações Pessoais
              </button>
            </Col>
          </Row>
          {this.props.user.userType === "CS" ? (
            <Row className="myaccount-tab-menu nav">
              <Col xs="12" sm="12" md="12" lg="12">
                <button
                  onClick={this.changeWindow("FerramentasConsultor")}
                  style={{
                    display: "block",
                    minWidth: "100%",
                    backgroundColor:
                      page === "FerramentasConsultor"
                        ? "rgb(3, 66, 117)"
                        : null,
                    color: page === "FerramentasConsultor" ? "white" : null
                  }}
                  href="login-register.html"
                >
                  Ferramentas Consultor
                </button>
              </Col>
            </Row>
          ) : null}

          {this.props.user.persona === 1 ||
            this.props.user.userID === 1 ||
            this.props.user.persona === 10005 ||
            this.props.user.userID === 10005 ||
            this.props.user.persona === 120164 ||
            this.props.user.userID === 120164 ||
            this.props.user.persona === 108522 ||
            this.props.user.userID === 108522 ||
            this.props.user.persona === 128912 ||
            this.props.user.userID === 128912 ||
            this.props.user.persona === 128549 ||
            this.props.user.userID === 128549 ? (
              <Row className="myaccount-tab-menu nav">
                <Col xs="12" sm="12" md="12" lg="12">
                  <button
                    onClick={this.changeWindow("FerramentasBackOffice")}
                    style={{
                      display: "block",
                      minWidth: "100%",
                      backgroundColor:
                        page === "FerramentasBackOffice"
                          ? "rgb(3, 66, 117)"
                          : null,
                      color: page === "FerramentasBackOffice" ? "white" : null
                    }}
                    href="login-register.html"
                  >
                    Ferramentas BackOffice
                </button>
                </Col>
              </Row>
            ) : null}
          {this.props.user.persona === 1 ||
            this.props.user.userID === 1 ||
            this.props.user.persona === 10005 ||
            this.props.user.userID === 10005 ||
            this.props.user.persona === 120164 ||
            this.props.user.userID === 120164 ||
            this.props.user.persona === 108522 ||
            this.props.user.userID === 108522 ||
            this.props.user.persona === 128912 ||
            this.props.user.userID === 128912 ||
            this.props.user.persona === 128549 ||
            this.props.user.userID === 128549 ? (
              <Row className="myaccount-tab-menu nav">
                <Col xs="12" sm="12" md="12" lg="12">
                  <button
                    onClick={this.changeWindow("EncomendasBackOffice")}
                    style={{
                      display: "block",
                      minWidth: "100%",
                      backgroundColor:
                        page === "EncomendasBackOffice"
                          ? "rgb(3, 66, 117)"
                          : null,
                      color: page === "EncomendasBackOffice" ? "white" : null
                    }}
                    href="login-register.html"
                  >
                    Encomendas BackOffice
                </button>
                </Col>
              </Row>
            ) : null}
          <Row className="myaccount-tab-menu nav">
            <Col xs="12" sm="12" md="12" lg="12">
              <button
                onClick={this.changeWindow("premios")}
                style={{
                  display: "block",
                  minWidth: "100%",
                  backgroundColor:
                    page === "Premios" ? "rgb(3, 66, 117)" : null,
                  color: page === "Premios" ? "white" : null
                }}
                href="my-account.html#address-edit"
              >
                Prémios
              </button>
            </Col>
          </Row>
          <Row className="myaccount-tab-menu nav">
            <Col xs="12" sm="12" md="12" lg="12">
              <button
                onClick={this.changeWindow("vales")}
                style={{
                  display: "block",
                  minWidth: "100%",
                  backgroundColor: page === "Vales" ? "rgb(3, 66, 117)" : null,
                  color: page === "Vales" ? "white" : null
                }}
                href="my-account.html#account-info"
              >
                Vales
              </button>
            </Col>
          </Row>
          {/* {this.props.user.userType === "CF" ? ( */}
          {this.props.user.userType !== "CF" ? (
            <Row className="myaccount-tab-menu nav">
              <Col xs="12" sm="12" md="12" lg="12">
                <button
                  onClick={this.changeWindow("comissoes")}
                  style={{
                    display: "block",
                    minWidth: "100%",
                    backgroundColor:
                      page === "Comissoes" ? "rgb(3, 66, 117)" : null,
                    color: page === "Comissoes" ? "white" : null
                  }}
                >
                  Comissões
                </button>
              </Col>
            </Row>
          ) : null}
          <Row className="myaccount-tab-menu nav">
            <Col xs="12" sm="12" md="12" lg="12">
              <button
                onClick={this.changeWindow("encomendas")}
                style={{
                  display: "block",
                  minWidth: "100%",
                  backgroundColor:
                    page === "Encomendas" ? "rgb(3, 66, 117)" : null,
                  color: page === "Encomendas" ? "white" : null
                }}
              >
                Encomendas
              </button>
            </Col>
          </Row>

          <Row className="myaccount-tab-menu nav">
            <Col xs="12" sm="12" md="12" lg="12">
              <button
                onClick={this.changeWindow("meusprodutos")}
                style={{
                  display: "block",
                  minWidth: "100%",
                  backgroundColor:
                    page === "MeusProdutos" ? "rgb(3, 66, 117)" : null,
                  color: page === "MeusProdutos" ? "white" : null
                }}
              >
                Meus Produtos
              </button>
            </Col>
          </Row>

          <Row className="myaccount-tab-menu nav">
            <Col xs="12" sm="12" md="12" lg="12">
              <button
                onClick={this.changeWindow("mediacenter")}
                style={{
                  display: "block",
                  minWidth: "100%",
                  backgroundColor:
                    page === "MediaCenter" ? "rgb(3, 66, 117)" : null,
                  color: page === "MediaCenter" ? "white" : null
                }}
              >
                Mediacenter
              </button>
            </Col>
          </Row>
          <Row className="myaccount-tab-menu nav">
            <Col xs="12" sm="12" md="12" lg="12">
              <button
                onClick={this.changeWindow("notificacoesemail")}
                style={{
                  display: "block",
                  minWidth: "100%",
                  backgroundColor:
                    page === "NotificacoesEmail" ? "rgb(3, 66, 117)" : null,
                  color: page === "NotificacoesEmail" ? "white" : null
                }}
                href="login-register.html"
              >
                Notificações por Email
              </button>
            </Col>
          </Row>

          <Row className="myaccount-tab-menu nav">
            <Col xs="12" sm="12" md="12" lg="12">
              <button
                onClick={this.changeWindow("downloads")}
                style={{
                  display: "block",
                  minWidth: "100%",
                  backgroundColor:
                    page === "Downloads" ? "rgb(3, 66, 117)" : null,
                  color: page === "Downloads" ? "white" : null
                }}
                href="login-register.html"
              >
                Downloads
              </button>
            </Col>
          </Row>

          {this.props.user.userType === "CS" ? (
            <Row className="myaccount-tab-menu nav">
              <Col xs="12" sm="12" md="12" lg="12">
                <button
                  onClick={this.changeWindow("incentivosregras")}
                  style={{
                    display: "block",
                    minWidth: "100%",
                    backgroundColor:
                      page === "IncentivosRegras" ? "rgb(3, 66, 117)" : null,
                    color: page === "IncentivosRegras" ? "white" : null
                  }}
                  href="login-register.html"
                >
                  Incentivos e Regras
                </button>
              </Col>
            </Row>
          ) : null}

          {this.props.user.userType === "CS" ? (
            <Row className="myaccount-tab-menu nav">
              <Col xs="12" sm="12" md="12" lg="12">
                <button
                  onClick={() => {
                    window.open(
                      "https://www.ihavethepower.net/Loja/Catalogo/RV",
                      "_blank"
                    );
                  }}
                  style={{
                    display: "block",
                    minWidth: "100%",
                    backgroundColor: page === "RV" ? "rgb(3, 66, 117)" : null,
                    color: page === "RV" ? "white" : null
                  }}
                  href="login-register.html"
                >
                  Revenda
                </button>
              </Col>
            </Row>
          ) : null}
          {this.props.user.userType === "CS" ? (
            <Row className="myaccount-tab-menu nav">
              <Col xs="12" sm="12" md="12" lg="12">
                <button
                  onClick={() => {
                    window.open("https://crm.ihavethepower.net", "_blank");
                  }}
                  style={{
                    display: "block",
                    minWidth: "100%",
                    backgroundColor: page === "CRM" ? "rgb(3, 66, 117)" : null,
                    color: page === "CRM" ? "white" : null
                  }}
                  href="login-register.html"
                >
                  CRM
                </button>
              </Col>
            </Row>
          ) : null}
          <hr style={{ display: isMobile ? "block" : "none" }} />
        </Container>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    user: state.user
  };
};

export default withRouter(connect(mapStateToProps)(Sidebar));
