export function checkEmail(email, id_consultor) {
  return new Promise((resolve, reject) => {
    return fetch("/lead/checkEmailAssociated", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email: email,
        id_consultor: id_consultor
      }),
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export function checkNIF(nif, pais, id_consultor) {
  return new Promise((resolve, reject) => {
    return fetch("/lead/checkNIFAssociated", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        nif: nif,
        pais: pais,
        id_consultor: id_consultor
      }),
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};


export function getTypesLead() {
  return new Promise((resolve, reject) => {
    return fetch("/lead/getTypesLead", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
};


export function newLead(lead) {
  return new Promise((resolve, reject) => {
    return fetch("/lead/newLead", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        Nome: lead.nome,
        Email: lead.email,
        Telemovel: lead.telemovel,
        Pais: lead.id_pais,
        Localidade: lead.localidade,
        Origem: lead.id_origem,
        Tipo_Lead: lead.id_tipo_lead,
        Empresa: lead.id_empresa,
        Consultor_PreSelecionado: lead.id_consultor,
        NIF: lead.nif,
        Evento: lead.id_estado,
        Nome_Contacto: lead.contacto.nome,
        Email_Contacto: lead.contacto.email,
        Telemovel_Contacto: lead.contacto.telemovel,
        Cargo_Contacto: lead.contacto.cargo,
        Observacoes: lead.observacoes,
        Valor_Previsto: lead.valor
      }),
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}