import React, { Component } from "react";

import "../../MyIHTP.css";

import Sidebar from "../../../../../components/Desktop/MyIHTP/Sidebar/Sidebar";

// import Footer from "../../../components/Footer/Footer";

import { withRouter } from "react-router-dom";

import { Row, Col, Container } from "react-bootstrap";

import Configs from "../../../../../helpers/Configs";
import { connect } from "react-redux";

import { fetchUser } from "../../../../../actions/miniCartActions";

import HeaderDesktop from "../../../../../components/Desktop/Header/Header";
import Dashboard from "../../../../../components/Desktop/MyIHTP/Conteudo/Dashboard/Dashboard";

class MyIHTP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      panelIndex: 0
    };
  }

  componentDidMount() {
    const that = this;
    this.props.dispatch(fetchUser()).then(data => {
      /*if (!that.props.user.token) {
        window.location.href = "/";
      }*/
    });
  }

  getBtnId = e => {
    if (e.target && e.target.nodeName === "BUTTON") {
      this.setState({
        panelIndex: Number(e.target.id)
      });
    }
  };

  render() {
    const { user } = this.props;
    return (
      <div className="App">
        <HeaderDesktop />
        <br />
        <Container
          style={{
            position: "relative",
            top: "60px",
            width: "90vw"
          }}
        >
          <Row
            style={{
              textAlign: "center"
            }}
          >
            <Col xs="12" sm="12" md="12" lg="3">
              <Sidebar page="Dashboard" />
            </Col>
            <Col xs="12" sm="12" md="12" lg="9">
              {" "}
              <h5 align="center">
                {Configs.myIHTP.informacaoBase.dashboard}
                {user.userType === "CF"
                  ? null
                  : user.userType === "CS"
                  ? " - Consultor"
                  : user.userType === "AF"
                  ? " - Afiliado"
                  : user.userType === "PR"
                  ? " - Promotor"
                  : user.userType === "PP"
                  ? " - Prospect"
                  : null}{" "}
              </h5>
              <hr />
              <Dashboard />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

export default withRouter(connect(mapStateToProps)(MyIHTP));
