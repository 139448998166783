import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";

class IncentivosRegras extends Component {
  render() {
    return (
      <Row className="justify incentivos">
        <Col xs={12} sm={12} md={12} lg={12}>
          <h3>
            Para Garantir o seu Êxito siga o I HAVE THE POWER SUCCESS MODEL
          </h3>
          <br />
          <p>
            1 - DEFINA O SEU SONHO, especialmente o seu objectivo para este ano.{" "}
            <br />
            2 - COMPROMETA-SE COM O SEU NEGÓCIO, consumindo 100% dos
            produtos/serviços. <br />
            3 - FAÇA E MANTENHA A SUA LISTA DE CANDIDATOS - consultores e
            clientes. <br />
            4 - CONVIDE AS PESSOAS, e encontre-se pessoalmente com elas, de
            preferência. <br />
            5 - APRESENTE O SEU NEGÓCIO, de forma profissional. <br />
            6 - FAÇA O SEGUIMENTO, esclarecendo eventuais dúvidas, E PATROCINE
            NOVOS CONSULTORES. <br />
            7 - AVALIE MENSALMENTE O SEU NEGÓCIO, com a sua upline. <br />8 -
            ENSINE SISTEMATICAMENTE ESTE MODELO A TODOS OS SEUS CONSULTORES.
          </p>
          <h3>
            Prémios (exclusivos para Consultores - não acumulam com prémios de
            compras pessoais):
          </h3>
          <br />
          <h3>
            &quot;DUPLICATION 2021 – PRÉMIOS TRIMESTRAIS &quot; - para kits
            advanced e professional
          </h3>
          <br />
          <h3>"SALES PERFORMANCE - 2021&quot; – SUPER PRÉMIO ANUAL"</h3>
          <p>
            Todos os Consultores que movimentem mais de 100 000 Eur até 31 de
            Dezembro de 2021, em compras pessoais + compras de clientes/afiliados
            diretos, ganham uma Viagem para duas pessoas, durante uma semana, em
            regime de Alojamento + PA, a BUDAPESTE.
          </p>
          <br />
          <h3>&quot;TOP EXCEL 2021&quot;</h3>
          <p>
            Todos os Consultores que se qualifiquem como TOP EXCEL até 31 de
            Dezembro de 2021 ganham uma Viagem para duas pessoas, durante uma
            semana, em regime de pensão completa, a PARIS.
          </p>
          <p>
            Todos os Consultores que se qualifiquem como TOP EXECUTIVE até 31 de
            Dezembro de 2021 ganham uma Viagem para duas pessoas, durante uma
            semana, em regime de pensão completa, ao RIO DE JANEIRO.
          </p>
          <br />
          <h3>&quot;ONE MILLION 2021&quot;</h3>
          <p>
            Passando a SOLFUT o 1,5 Milhões de eur de faturação até 31 de Dez 2021,
            todas as comissões de venda direta ganhas de Janeiro a Dezembro de 2021
            têm um acréscimo de 20% que será pago no início de 2022 aos consultores que
            estejam activos no dia 1 de Janeiro de 2022.
          </p>
          <br />
          <h3>Calendário de Convenções e Raise Up 2021 / 2022</h3>
          <br />
          <p>Janeiro 2021 - CONVENÇÃO 8, 9 e 10</p>
          <p>Fevereiro dia 6 - RAISE UP</p>
          <p>Março dia 6 - RAISE UP</p>
          <p>Abril CONVENÇÃO 9, 10 e 11</p>
          <p>Maio dia 8 - RAISE UP</p>
          <p>Junho dia 5 - RAISE UP</p>
          <p>Julho CONVENÇÃO  2, 3 e 4</p>
          <p>Agosto dia 7 - RAISE UP</p>
          <p>Setembro dia 4 - RAISE UP</p>
          <p>Outubro CONVENÇÃO dia 1, 2 e 3</p>
          <p>Novembro dia 6 - RAISE UP</p>
          <p>Dezembro dia 4 - RAISE UP</p>
          <p>Janeiro 2022 - CONVENÇÃO 7, 8, 9</p>
          <br />
          <h3>Renovação Anual como Consultor I Have the Power</h3>
          <p>Será renovado automaticamente e sem custos o seu estatuto de Consultor IHTP se:</p>
          <p>
            - no 1º ano de Consultor - faturar anualmente mais de 6 000 eur (em compras pessoais + compras de clientes/afiliados/promotores diretos)
            e tiver pelo menos 1 rede ativa;
          </p>
          <p>
            - nos restantes anos - faturar anualmente mais de 12 000 eur (em compras pessoais + compras de clientes/afiliados/promotores diretos)
            e tiver pelo menos mais 2 redes ativas;
          </p>
          <p> - cumprir o Código de Honra IHTP;</p>
          <p> - cumprir os "Termos e Condições para Consultor Independente I Have the Power".</p>
          <br />

          <h3>Atividade mínima do Consultor</h3>
          <p>
            - <b>Mensal</b>
          </p>
          <p>Um Consultor tem de faturar, em compras pessoais + compras de clientes/afiliados/promotores diretos, no mínimo 100 eur em cada mês para manter o
          estatuto de Consultor.</p>
          <p>No caso de faturar menos de 250 eur (apenas no 1º ano) ou 500 eur
          (nos restantes anos), em compras pessoais + compras de clientes/afiliados/promotores diretos, os descontos de compras
          pessoais, comissões de revenda e comissão de 2º nível passam automaticamente para metade, 10% e 1,25% respetivamente. Se repetir
          no mês seguinte o incumprimento, os descontos de compras pessoais, comissões de revenda e comissão de 2º nível passam automaticamente
          novamente para metade, 5% e 0,625% respetivamente.
          <p>Se o volume de compras dos seus clientes durante o mês ultrapassar os 250 eur / 500 eur, recuperam de imediato o estatuto de Consultor em pleno.</p>
          </p>
          <p>
            - <b>Trimestral</b>
          </p>
          <p>
            No caso de faturar menos de 750 eur (apenas no 1º ano) ou 1500 eur (nos restantes anos) em cada trimestre, em compras pessoais + compras de clientes/afiliados/promotores diretos, aplicam-se os
            mesmos critérios do ponto anterior, ajustados aos valores trimestrais, sendo que terá obrigatoriamente de recuperar o estatuto normal no trimestre seguinte, caso contrário passará a Promotor.
          </p>
          <br />
          <h3>
            Pagamento de Bónus mensais de grupo (Bónus de Grupo, Bónus de Liderança, Bónus Dream, Leader Builder e comissão de 2º nível)
          </h3>
          <p>Os Bónus mensais serão pagos apenas se: </p>
          <p> - faturar no mês mais de 250 eur (no 1º ano) ou 500 eur (restantes anos), em compras pessoais + compras de clientes/afiliados/promotores diretos{" "}</p>
          <p>Para novos consultores esta regra não se aplica no mês de registo como consultor.</p>
          <br />

          <h3>Critérios para ser Trainer IHTP</h3>
          <p> - ser consultor e estar ativo desenvolvendo a atividade com faturação mínima em cada trimestre de 1500 Eur</p>
          <p> - demonstrar conhecimento da área de especialização - demonstrar que aplica o que sabe e que pratica o Código de Honra IHTP</p>
          <p> - estar presente em todas as Convenções IHTP e RAISE UP em cada ano</p>
          <p> - ter CCP ou certificação necessária para a área de especialização</p>
          <p> - ter frequentado o curso TTT (Train the Trainer) do curso que vai lecionar</p>
          <p> - ter 10 consultores em Convenção – ser TEAM LEADER</p>
          <p> - Renovar automaticamente o seu estatuto de Consultor</p>
          <p> - ter frequentado no ano anterior pelo menos 1 curso presencial para melhoria de competências</p>
          <br />


          <h3>Critérios para ser Academy Manager IHTP</h3>
          <p> - ser consultor e estar ativo desenvolvendo a atividade com faturação mínima em cada trimestre de 1500 Eur</p>
          <p> - estar presente em todas as Convenções IHTP e RAISE UP em cada ano </p>
          <p> - ter 10 consultores em Convenção – ser TEAM LEADER</p>
          <p> - renovar automaticamente o seu estatuto de Consultor</p>
          <p> - ter frequentado no ano anterior pelo menos 1 curso presencial para melhoria de competências</p>
          <br />

          <h3>Critérios para ser Fornecedor Preferencial de Serviços IHTP</h3>
          <p>
            Este é o perfil de Consultores fornecedores de serviços de
            Consultoria, Coaching, Terapeutas, etc., para quem a Solfut encaminha
            preferencialmente negócio solicitado pelos seus clientes.{" "}
          </p>
          <p> - ser consultor e estar activo desenvolvendo a actividade com faturação mínima em cada trimestre de 1500 Eur</p>
          <p> - demonstrar conhecimento da área de especialização</p>
          <p> - demonstrar que aplica o que sabe e que pratica o Código de Honra IHTP - estar presente em todas as Convenções IHTP em cada ano</p>
          <p> - ter certificação necessária para a área de especialização</p>
          <br />
        </Col>
      </Row>
    );
  }
}
export default IncentivosRegras;
