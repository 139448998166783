import { deletePrevisao } from "../../../../../../../actions/myihtpActions";

export function removePrevisao(id) {
  return new Promise((resolve, reject) => {
    deletePrevisao(id)
      .then(data => {
        if (data.result.message === "Sucesso ao remover a previsao.") {
          resolve(true);
        } else {
          resolve(false);
        }
      })
      .catch(error => {
        resolve(error);
      });
  });
}
