import { Button } from "@material-ui/core";
import _ from "lodash";
import React, { Component } from "react";
import ReactAudioPlayer from "react-audio-player";
import { Col, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import "react-table/react-table.css";
import "video-react/dist/video-react.css";
// getAllSuccessBoxMedia
import { confirmSelectedProducts, getAllSuccessBoxMedia, getAllSuccessBoxMediaSubscribed, getParams } from "../../../../../actions/successBoxActions";
import "./SuccessBox.scss";

class SuccessBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mediaSuccessBox: [],
      selectedProductsCounter: 0,
      step: null, //"Confirmação" //
      produtosSubscribed: [],
      // Se é uma subscrição mensal ou anual
      TipoSubscricao: null,
      // Controlar se os produtos já foram escolhidos ou não
      ProdutosMensaisSelecionados: null,
      DataInicio: null,
      DataFim: null,
      DataCancelamento: null
    };
    this.selectAudiobook = this.selectAudiobook.bind(this);
    this.avancar = this.avancar.bind(this);
    this.cancelarConfirmacao = this.cancelarConfirmacao.bind(this);
    // Controls
    this.pauseAudio = this.pauseAudio.bind(this);
    this.confirmar = this.confirmar.bind(this);
  }
  componentWillMount() {
    getAllSuccessBoxMedia()
      .then(media => {
        this.setState({ mediaSuccessBox: media });
      })
      .catch(err => {
        console.log(err);
      });
    getAllSuccessBoxMediaSubscribed()
      .then(produtosSubscribed => {
        this.setState({ produtosSubscribed: produtosSubscribed });
      })
      .catch(err => {
        console.log(err);
      });
    getParams()
      .then(params => {
        this.setState({
          ProdutosMensaisSelecionados: params[0].ProdutosMensaisSelecionados,
          TipoSubscricao: params[0].TipoSubscricao
        });
        if (this.state.ProdutosMensaisSelecionados === "N") {
          this.setState({ step: "Por Selecionar" });
        } else if (this.state.ProdutosMensaisSelecionados === "S") {
          this.setState({ step: "Success Box - Produtos Acessiveis" });
        }
        console.log(params);
        //this.setState({ produtosSubscribed: produtosSubscribed });
      })
      .catch(err => {
        console.log(err);
      });
  }

  // Ao selecionar um audiobook - Fase 1
  selectAudiobook = ID_Produto => async e => {
    const newMediaSuccessBox = this.state.mediaSuccessBox.slice();
    var index = _.findIndex(newMediaSuccessBox, { ID_Produto: ID_Produto });
    newMediaSuccessBox[index].Selecionado = !newMediaSuccessBox[index]
      .Selecionado;
    newMediaSuccessBox[index].Selecionado
      ? this.setState({
        selectedProductsCounter: this.state.selectedProductsCounter + 1
      })
      : this.setState({
        selectedProductsCounter: this.state.selectedProductsCounter - 1
      });
    this.setState({ mediaSuccessBox: newMediaSuccessBox });
  };

  // Para avançar
  avancar = () => {
    if (this.state.selectedProductsCounter === 10) {
      this.setState({
        step: "Confirmação"
      });
    }
  };

  // Para voltar a selecionar os produtos
  cancelarConfirmacao = () => {
    if (this.state.selectedProductsCounter === 10) {
      this.setState({
        step: "Por Selecionar"
      });
    }
  };

  // Para confirmar os produtos
  confirmar = () => {
    var produtosSelecionados = _.filter(this.state.mediaSuccessBox, function (
      produto
    ) {
      return produto.Selecionado === true;
    });

    confirmSelectedProducts(produtosSelecionados)
      .then(getAllSuccessBoxMediaSubscribed)
      .then(products => {
        this.setState({ produtosSubscribed: products });
      })
      .catch(err => {
        console.log(err);
      });

    if (this.state.selectedProductsCounter === 10) {
      this.setState({
        step: "Success Box - Produtos Acessiveis"
      });
    }
  };

  // Se pausarem o video
  pauseAudio = e => {
    console.log(e);
  };

  render() {
    if (this.state.step === null) {
      return (
        <Row>
          <Col lg={12}>
            <h5>Bem-vindo à Success Box</h5>
            <p style={{ textAlign: "justify" }}>
              Verificamos que ainda não subscreveu nenhum dos planos
              disponíveis. Por favor vá a{" "}
              <a href={"https://successbox.ihavethepower.net"}>
                {"https://successbox.ihavethepower.net"}
              </a>
              , e comece a mudar a sua vida, agora mesmo.
            </p>

            <img
              style={{ display: isMobile ? "none" : "block", width: "100%" }}
              src={
                "https://doremon.ihavethepower.net/assets/ihtp-success-box/Step1-successbox.png"
              }
            />

            <br />
            <br />
            <br />
          </Col>
        </Row>
      );
    } else if (this.state.step === "Por Selecionar") {
      return (
        <Row style={{ marginTop: "25px" }} className={"successBox"}>
          <Col xs="12" sm="12" md="12" lg="12">
            <Row>
              <Col lg={12}>
                <p
                  style={{
                    textAlign: "center",
                    fontWeight: "400",
                    fontSize: "18px"
                  }}
                >
                  Parabéns pela sua decisão de subscrever o programa IHTP
                  SUCCESS BOX!
                </p>
                <p style={{ textAlign: "justify" }}>
                  Escolha os 10 audiobooks, a que tem direito no âmbito da sua
                  Subscrição. Depois de ter feito a sua escolha, clique no Botão
                  AVANÇAR para começar já a aproveitar dos fantásticos conteúdos
                  a que vai ter acesso durante os próximos 30 dias, e comece
                  logo a colocar em prática tudo o que aprender.
                </p>
                <p style={{ textAlign: "justify" }}>
                  Caso tenha alguma dificuldade neste processo, entre em
                  contacto connosco ou consulte o seu Consultor dedicado.
                </p>
              </Col>
            </Row>
            <br />
            <br />
            <Row
              style={{
                margin: "10px"
              }}
            >
              <Col
                xs={6}
                sm={6}
                md={6}
                lg={6}
                style={{
                  textAlign: "left"
                }}
              >
                <h5 style={{ fontSize: isMobile ? "10px" : null }}>
                  {this.state.mediaSuccessBox.length}
                  {" audiobooks  "}
                  {isMobile ? null : "disponíveis"}
                </h5>
              </Col>
              <Col
                xs={6}
                sm={6}
                md={6}
                lg={6}
                style={{
                  textAlign: "right",
                  color: "rgb(202, 0, 106)"
                }}
              >
                <h5 style={{ fontSize: isMobile ? "10px" : null }}>
                  {this.state.selectedProductsCounter} / 10 selecionados
                </h5>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Button
                  onClick={this.avancar}
                  className={"btn-avancar"}
                  variant="contained"
                  style={{
                    margin: isMobile ? "0px" : "10px",
                    width: isMobile ? "100%" : null,
                    backgroundColor:
                      this.state.selectedProductsCounter === 10
                        ? "#4caf50"
                        : null,
                    color:
                      this.state.selectedProductsCounter === 10
                        ? "#ffffff"
                        : null
                  }}
                  disabled={
                    this.state.selectedProductsCounter === 10 ? false : true
                  }
                >
                  Avançar
                </Button>
              </Col>
            </Row>
            <br />
            {this.state.mediaSuccessBox.map((media, j) => {
              return (
                <Row
                  style={{
                    margin: isMobile ? null : "20px",
                    marginBottom: isMobile ? "10px" : null,
                    boxShadow: isMobile
                      ? null
                      : "10px 10px 9px 0px rgba(204,204,204,1)",
                    padding: "10px",
                    backgroundColor: !media.Selecionado
                      ? this.state.selectedProductsCounter === 10 &&
                        !media.Selecionado
                        ? "#afafaf"
                        : "#e2e2e2"
                      : "#c5d2f3"
                  }}
                >
                  <Col
                    xs={12}
                    sm={12}
                    md={8}
                    lg={8}
                    style={{
                      display: "grid",
                      alignItems: "center",
                      textAlign: "center"
                    }}
                    className={"description"}
                  >
                    {media.Descricao}
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    style={{ textAlign: isMobile ? "center" : "right" }}
                  >
                    <Button
                      className={"btn-selecionar"}
                      variant="contained"
                      style={{
                        margin: "10px",
                        backgroundColor: !media.Selecionado
                          ? "rgb(202, 0, 106)"
                          : "red"
                      }}
                      onClick={this.selectAudiobook(media.ID_Produto)}
                      disabled={
                        this.state.selectedProductsCounter === 10 &&
                          !media.Selecionado
                          ? true
                          : false
                      }
                    >
                      {!media.Selecionado ? "Selecionar" : "Remover"}
                    </Button>
                  </Col>
                </Row>
              );
            })}
            <Row>
              <Col lg={12}>
                <Button
                  onClick={this.avancar}
                  className={"btn-avancar"}
                  variant="contained"
                  style={{
                    margin: isMobile ? "0px" : "10px",
                    width: isMobile ? "100%" : null,
                    backgroundColor:
                      this.state.selectedProductsCounter === 10
                        ? "#4caf50"
                        : null,
                    color:
                      this.state.selectedProductsCounter === 10
                        ? "#ffffff"
                        : null
                  }}
                  disabled={
                    this.state.selectedProductsCounter === 10 ? false : true
                  }
                >
                  Avançar
                </Button>
              </Col>
            </Row>
            <br />
            <br />
            <br />
          </Col>
        </Row>
      );
    } else if (this.state.step === "Confirmação") {
      return (
        <Row style={{ marginTop: "25px" }} className={"successBox"}>
          <Col xs="12" sm="12" md="12" lg="12">
            <Row>
              <Col lg={12}>
                <p
                  style={{
                    textAlign: "center",
                    fontWeight: "400",
                    fontSize: "18px"
                  }}
                >
                  Confirme se são estes os audiobooks que pretende para que
                  possa começar já a ouvi-los!
                </p>
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                sm={12}
                md={6}
                lg={6}
                style={{ textAlign: isMobile ? "center" : "left" }}
              >
                <Button
                  onClick={this.cancelarConfirmacao}
                  className={"btn-cancelarConfirmacao"}
                  variant="contained"
                  style={{
                    margin: isMobile ? "5px" : "10px",
                    backgroundColor:
                      this.state.selectedProductsCounter === 10
                        ? "rgb(202, 0, 106)"
                        : null,
                    color:
                      this.state.selectedProductsCounter === 10
                        ? "#ffffff"
                        : null,
                    fontSize: isMobile ? "10px" : "14px",
                    width: isMobile ? "100%" : null
                  }}
                  disabled={
                    this.state.selectedProductsCounter === 10 ? false : true
                  }
                >
                  Voltar a Selecionar
                </Button>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={6}
                lg={6}
                style={{ textAlign: isMobile ? "center" : "right" }}
              >
                <Button
                  onClick={this.confirmar}
                  className={"btn-avancar"}
                  variant="contained"
                  style={{
                    margin: isMobile ? "5px" : "10px",
                    marginBottom: isMobile ? "10px" : null,
                    backgroundColor:
                      this.state.selectedProductsCounter === 10
                        ? "#4caf50"
                        : null,
                    color:
                      this.state.selectedProductsCounter === 10
                        ? "#ffffff"
                        : null,
                    fontSize: isMobile ? "10px" : "14px",
                    width: isMobile ? "100%" : null
                  }}
                  disabled={
                    this.state.selectedProductsCounter === 10 ? false : true
                  }
                >
                  Confirmação
                </Button>
              </Col>
            </Row>
            {this.state.mediaSuccessBox.map((media, j) => {
              if (media.Selecionado) {
                return (
                  <Row
                    style={{
                      margin: isMobile ? null : "20px",
                      marginBottom: isMobile ? "10px" : null,
                      boxShadow: isMobile
                        ? null
                        : "10px 10px 9px 0px rgba(204,204,204,1)",
                      padding: "10px",
                      backgroundColor: "#e2e2e2"
                    }}
                  >
                    <Col
                      lg={12}
                      style={{
                        display: "grid",
                        alignItems: "center",
                        textAlign: "center"
                      }}
                      className={"description"}
                    >
                      {media.Descricao}
                    </Col>
                  </Row>
                );
              }
            })}
          </Col>
        </Row>
      );
    } else if (this.state.step === "Success Box - Produtos Acessiveis") {
      return (
        <Row style={{ marginTop: "25px" }} className={"successBox"}>
          <Col xs="12" sm="12" md="12" lg="12">
            <Row>
              <Col lg={12}>
                <p
                  style={{
                    textAlign: "center",
                    fontWeight: "400",
                    fontSize: "18px"
                  }}
                >
                  Parabéns pela sua decisão de subscrever o programa IHTP
                  SUCCESS BOX.
                </p>
                <p
                  style={{
                    textAlign: "justify"
                  }}
                >
                  Aproveite destes fantásticos conteúdos durante a sua
                  Subscricao {this.state.TipoSubscricao} e comece logo a colocar
                  em prática tudo o que aprender.
                </p>
                <p
                  style={{
                    textAlign: "justify"
                  }}
                >
                  Caso tenha alguma dificuldade neste processo, entre em
                  contacto connosco ou consulte o seu Consultor dedicado.
                </p>
                <p
                  style={{
                    textAlign: "justify",
                    display: isMobile ? "none" : "block"
                  }}
                >
                  A partir de agora pode também começar já a ganhar 10% nas suas
                  recomendações e, além disso, quando 5 amigos seus subscreverem
                  este programa, você passa a ter descontos de 5% em tudo o que
                  comprar. Quando 10 amigos seus subscreverem este programa,
                  você passa a ter descontos de 10%. Ajude também os seus amigos
                  a melhorar recomendando o IHTP SUCCESS BOX agora mesmo.
                </p>
              </Col>
            </Row>
            {this.state.produtosSubscribed.map(produto => {
              return (
                <Row
                  style={{
                    margin: isMobile ? null : "20px",
                    marginBottom: isMobile ? "10px" : null,
                    boxShadow: isMobile
                      ? null
                      : "10px 10px 9px 0px rgba(204,204,204,1)",
                    backgroundColor: "#e2e2e2",
                    padding: "15px"
                  }}
                >
                  <Col lg={12}>
                    <h3 style={{ fontSize: isMobile ? "15px" : null }}>
                      {produto.Descricao}
                    </h3>
                    <ReactAudioPlayer
                      src={
                        "https://doremon.ihavethepower.net/assets/uploads/Multimedia/" +
                        produto.ConteudoHtml
                      }
                      style={{ width: "100%" }}
                      controls
                      controlsList="nodownload"
                      onPause={this.pauseAudio}
                      onSeeked={this.pauseAudio}
                    />
                  </Col>
                </Row>
              );
            })}
          </Col>
        </Row>
      );
    }
  }
}
export default SuccessBox;
