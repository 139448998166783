import {
  cancelNotification,
  subscribeNotifications
} from "../../../../../actions/myihtpActions";

export function cancelNotificacao(id) {
  return new Promise((resolve, reject) => {
    cancelNotification(id).then(data => {
      if (data) {
        resolve(data);
      } else {
        resolve(data);
      }
    });
  });
}

export function subscribeNotification(type, addInfo) {
  return new Promise((resolve, reject) => {
    subscribeNotifications(type, addInfo.TipoDesafio).then(data => {
      if (data) {
        resolve(data);
      } else {
        resolve(data);
      }
    });
  });
}
