import React, { Component } from 'react'
import ConsultorPeriodoIndisponivel from './Components/ConsultorPeriodoIndisponivel'
import MudarConsultorLead from './Components/MudarConsultorLead'
import MudarPercentagemDesconto from './Components/MudarPercentagemDesconto'

export default class Consultores extends Component {
    render() {
        return (
            <div>
                <br />
                <br />
                <MudarPercentagemDesconto />
                <br />
                <hr />
                <br />
                <MudarConsultorLead />
                <br />
                <hr />
                <br />
                <ConsultorPeriodoIndisponivel />
                <br />
                <br />
            </div>
        )
    }
}
