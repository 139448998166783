import React, { Component } from "react";
import { FormattedDate, FormattedMessage, FormattedNumber } from "react-intl";
import { connect } from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { getPayments } from "../../../../../../actions/myihtpActions";
import Configs from "../../../../../../helpers/Configs";

class Pagamentos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagamentos: [],
      noDataGetPayments: null,
      showPaginationGetPayments: false,
      defaultPageSizeGetPayments: 5
    };
  }

  componentDidMount() {
    getPayments().then(data => {
      if (!data.data.error) {
        this.setState({ pagamentos: data.data.Pagamentos });
      }
    });
  }

  render() {
    const { currencies } = this.props;
    const pagamentos = {
      columns: [
        {
          Header: <FormattedMessage id="myihtp.comissoes.pagamentos.data" />,
          accessor: "DataPagamento",
          resizable: false,
          Cell: row => {
            return (
              <FormattedDate value={new Date(row.original.DataPagamento)} />
            );
          }
        },
        {
          Header: (
            <FormattedMessage id="myihtp.comissoes.pagamentos.tipopagamento" />
          ),
          accessor: "ID_TipoPagamento",
          resizable: false
        },
        {
          Header: (
            <FormattedMessage id="myihtp.comissoes.pagamentos.valorpago" />
          ),
          accessor: "ValorPago",
          resizable: false,
          Cell: row => {
            return (
              <FormattedNumber
                value={row.original.ValorPago}
                style="currency"
                currency={
                  currencies
                    ? currencies.selected
                      ? currencies.selected
                      : null
                    : null
                }
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
            );
          }
        },
        {
          Header: (
            <FormattedMessage id="myihtp.comissoes.pagamentos.observacoes" />
          ),
          accessor: "Observacao",
          resizable: false
        }
      ]
    };
    return (
      <div>
        <hr />
        <ReactTable
          className="-highlight tableReact"
          data={this.state.pagamentos}
          getTheadProps={(state, rowInfo, column) => {
            return {
              style: {
                fontWeight: Configs.myIHTP.tableConfig.fontHeightHeader,
                fontSize: Configs.myIHTP.tableConfig.fontSizeHeader,
                height: Configs.myIHTP.tableConfig.heightHeader,
                color: Configs.myIHTP.tableConfig.colorHeader,
                backgroundColor:
                  Configs.myIHTP.tableConfig.backgroundColorHeader
              }
            };
          }}
          getPaginationProps={state => {
            return {
              style: {
                color: Configs.myIHTP.tableConfig.colorHeader,
                backgroundColor:
                  Configs.myIHTP.tableConfig.backgroundColorHeader,
                fontSize: Configs.myIHTP.tableConfig.fontSizeRowBody
              }
            };
          }}
          getTrProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                "text-align": "center",
                "font-size": Configs.myIHTP.tableConfig.fontSizeRowBody
              }
            };
          }}
          defaultPageSize={Configs.myIHTP.tableConfig.defaultPageSize}
          showPagination={
            this.state.pagamentos.length >
              Configs.myIHTP.tableConfig.defaultPageSize
              ? Configs.myIHTP.tableConfig.showPagination
              : false
          }
          previousText={Configs.myIHTP.tableConfig.buttonCopyAnterior}
          nextText={Configs.myIHTP.tableConfig.buttonCopyProximo}
          noDataText={Configs.myIHTP.tableConfig.noDataText}
          pageText={Configs.myIHTP.tableConfig.pageText}
          ofText={Configs.myIHTP.tableConfig.ofText}
          rowsText={Configs.myIHTP.tableConfig.rowsText}
          columns={pagamentos.columns}
        />
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    currencies: state.currencies
  };
};

export default connect(mapStateToProps)(Pagamentos);
