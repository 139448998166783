export function getPossibleEvents(eventoBase, tipoLead) {
    return new Promise((resolve, reject) => {
        return fetch("/event/getPossibleEvents", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                EventoBase: (typeof eventoBase !== 'undefined') ? eventoBase : null,
                TipoLead: (typeof tipoLead !== 'undefined') ? tipoLead : null
            }),
            credentials: "include"
        })
            .then(res => res.json())
            .then(json => {
                resolve(json);
            })
            .catch(function (error) {
                reject(error);
            });
    });
}