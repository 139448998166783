import React, { Component } from "react";

import ReactTable from "react-table";
import "react-table/react-table.css";
import Configs from "../../../../../../helpers/Configs";
import {
  getUserContacts,
  editContactInfo,
  addContact,
  getTipoContactos
} from "../../../../../../actions/myihtpActions";
import { Col, Row, Container } from "react-bootstrap";
import {
  TextField,
  OutlinedInput,
  Select,
  InputLabel,
  FormControl,
  NativeSelect
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { ToastContainer } from "react-toastr";
import DialogNovoContacto from "./DialogNovoContacto";
import { removeContact } from "../Helper";
import { FormattedMessage } from "react-intl";
import { isMobile } from "react-device-detect";
import { injectIntl } from "react-intl";

let container;

class Contactos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contacts: [],
      noDataGetContacts: null,
      showPaginationGetContacts: false,
      defaultPageSizeGetContacts: 5,
      editInfo: {
        TipoContacto: null,
        Descricao: "",
        Principal: ""
      },
      addInfo: {
        TipoContacto: "",
        Descricao: "",
        Principal: ""
      },
      tipoContactos: [],
      expanded: {}
    };
    this.updateContacts = this.updateContacts;
    this.handleInput = this.handleInput.bind(this);
    this.handleAddInput = this.handleAddInput.bind(this);
  }

  handleRowExpanded(rowsState, index) {
    this.setState({
      expanded: {
        [index[0]]: !this.state.expanded[index[0]]
      }
    });
  }

  componentDidMount() {
    getUserContacts()
      .then(data => {
        if (
          !data.data.error &&
          data.data.message !== "Não tem contactos associados."
        ) {
          this.setState({ contacts: data.data });
          getTipoContactos().then(contactos => {
            if (!contactos.contactos.error) {
              this.setState({ tipoContactos: contactos.contactos });
            }
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  updateContacts = () => {
    getUserContacts()
      .then(data => {
        this.setState({ contacts: data.data });
        getTipoContactos().then(contactos => {
          this.setState({ tipoContactos: contactos.contactos });
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  editContact = (id, idClienteContacto) => async e => {
    e.stopPropagation();
    this.setState(
      prevState => ({
        editInfo: {
          ...prevState.editInfo,
          TipoContacto: id
        }
      }),
      () => console.log(this.state.dadosPrevisao)
    );
    editContactInfo(this.state.editInfo, idClienteContacto)
      .then(data => {
        if (data.data.message === "Sucesso ao editar o seu contacto.") {
          getUserContacts()
            .then(data => {
              this.setState({
                contacts: data.data
              });
            })
            .catch(err => {
              console.log(err);
            });
          container.clear();
          container.success(`Contacto alterado com sucesso`);
        } else {
          container.clear();
          container.warning(`Erro ao alterar contacto`);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  addContact = () => async e => {
    e.stopPropagation();
    addContact(this.state.addInfo)
      .then(data => {
        if (data.data.message === "Sucesso ao adicionar o seu contacto.") {
          getUserContacts()
            .then(data => {
              this.setState({
                contacts: data.data
              });
            })
            .catch(err => {
              console.log(err);
            });
          container.clear();
          container.success(`Contacto adicionado com sucesso`);
        } else {
          container.clear();
          container.warning(`Erro ao adicionar contacto`);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  handleInput(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState(
      prevState => ({
        editInfo: {
          ...prevState.editInfo,
          [name]: value
        }
      }),
      () => console.log(this.state.editInfo)
    );
  }

  handleAddInput(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState(
      prevState => ({
        addInfo: {
          ...prevState.addInfo,
          [name]: value
        }
      }),
      () => console.log(this.state.addInfo)
    );
  }

  handleClickOpen = () => {
    this.setState({
      open: true
    });
  };

  handleClose = value => {
    this.setState({ selectedValue: value, open: false });
  };

  render() {
    return (
      <div>
        <ToastContainer
          ref={ref => (container = ref)}
          className="toast-bottom-right"
        />
        <Row style={{ textAlign: "center" }}>
          <Col xs="12" sm="12" md="12" lg="12">
            <b>
              {" "}
              <FormattedMessage id="myihtp.infopessoal.contactos.titulo" />
            </b>
            <Button color="primary" onClick={this.handleClickOpen}>
              <svg
                height="25px"
                viewBox="0 0 512 512"
                width="25px"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="m256 512c-141.160156 0-256-114.839844-256-256s114.839844-256 256-256 256 114.839844 256 256-114.839844 256-256 256zm0-475.429688c-120.992188 0-219.429688 98.4375-219.429688 219.429688s98.4375 219.429688 219.429688 219.429688 219.429688-98.4375 219.429688-219.429688-98.4375-219.429688-219.429688-219.429688zm0 0" />
                <path d="m256 365.714844c-10.097656 0-18.285156-8.1875-18.285156-18.285156v-182.859376c0-10.097656 8.1875-18.285156 18.285156-18.285156s18.285156 8.1875 18.285156 18.285156v182.859376c0 10.097656-8.1875 18.285156-18.285156 18.285156zm0 0" />
                <path d="m347.429688 274.285156h-182.859376c-10.097656 0-18.285156-8.1875-18.285156-18.285156s8.1875-18.285156 18.285156-18.285156h182.859376c10.097656 0 18.285156 8.1875 18.285156 18.285156s-8.1875 18.285156-18.285156 18.285156zm0 0" />
              </svg>
            </Button>{" "}
          </Col>
        </Row>
        <DialogNovoContacto
          selectedValue={this.state.selectedValue}
          open={this.state.open}
          onClose={this.handleClose}
          updateContactsFunction={this.updateContacts}
        />
        <ReactTable
          className="-highlight tableReact"
          data={this.state.contacts}
          expanded={this.state.expanded}
          onExpandedChange={(newExpanded, index, event) =>
            this.handleRowExpanded(newExpanded, index, event)
          }
          getTheadProps={(state, rowInfo, column) => {
            return {
              style: {
                fontWeight: Configs.myIHTP.tableConfig.fontHeightHeader,
                fontSize: Configs.myIHTP.tableConfig.fontSizeHeader,
                height: Configs.myIHTP.tableConfig.heightHeader,
                color: Configs.myIHTP.tableConfig.colorHeader,
                backgroundColor:
                  Configs.myIHTP.tableConfig.backgroundColorHeader
              }
            };
          }}
          getPaginationProps={state => {
            return {
              style: {
                color: Configs.myIHTP.tableConfig.colorHeader,
                backgroundColor:
                  Configs.myIHTP.tableConfig.backgroundColorHeader,
                fontSize: Configs.myIHTP.tableConfig.fontSizeRowBody
              }
            };
          }}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              onClick: (e, handleOriginal) => {
                if (column.id === "ID_ContactCliente") {
                  var confirm = window.confirm(
                    this.props.intl.formatMessage({
                      id: "myihtp.infopessoal.contactos.eliminar"
                    })
                  );
                  if (confirm) {
                    removeContact(rowInfo.original.ID_ClienteContacto).then(
                      data => {
                        if (data) {
                          getUserContacts()
                            .then(data => {
                              this.setState({ contacts: data.data });
                              getTipoContactos().then(contactos => {
                                this.setState({
                                  tipoContactos: contactos.contactos
                                });
                                container.clear();
                                container.success(
                                  `Contacto removido com sucesso`
                                );
                              });
                            })
                            .catch(err => {
                              console.log(err);
                            });
                        } else {
                          container.clear();
                          container.warning(`Erro na remoção do contacto`);
                        }
                      }
                    );
                  }
                } else {
                  if (handleOriginal) {
                    handleOriginal();
                    this.setState({
                      editInfo: {
                        TipoContacto: rowInfo.original.ID_TipoContacto,
                        Descricao: rowInfo.original.Descricao,
                        Principal: rowInfo.original.Principal
                      }
                    });
                  }
                }
              },
              style: {
                "text-align": "center",
                "font-size": Configs.myIHTP.tableConfig.fontSizeRowBody
              }
            };
          }}
          defaultPageSize={Configs.myIHTP.tableConfig.defaultPageSize}
          showPagination={
            this.state.contacts.length >
            Configs.myIHTP.tableConfig.defaultPageSize
              ? Configs.myIHTP.tableConfig.showPagination
              : false
          }
          previousText={Configs.myIHTP.tableConfig.buttonCopyAnterior}
          nextText={Configs.myIHTP.tableConfig.buttonCopyProximo}
          noDataText={Configs.myIHTP.tableConfig.noDataText}
          pageText={Configs.myIHTP.tableConfig.pageText}
          ofText={Configs.myIHTP.tableConfig.ofText}
          rowsText={Configs.myIHTP.tableConfig.rowsText}
          columns={Configs.myIHTP.tableConfig.contactosinfopessoais.columns}
          SubComponent={row => {
            return (
              <Container
                style={{
                  marginTop: "15px",
                  marginBottom: "15px",
                  marginLeft: "15px",
                  marginRight: "15px",
                  alignItems: "center"
                }}
              >
                <Row>
                  <Col
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    style={{ textAlign: "center" }}
                  >
                    <Row style={{ alignItems: "center" }}>
                      <Col
                        xs="12"
                        sm="12"
                        md="3"
                        lg="3"
                        style={{
                          textAlign: isMobile ? "left" : "center"
                        }}
                      >
                        {this.state.tipoContactos.length > 0 ? (
                          <FormControl
                            variant="outlined"
                            style={{
                              width: isMobile ? "67vw" : "80%",
                              marginTop: "7px"
                            }}
                          >
                            <InputLabel
                              ref={ref => {
                                this.InputLabelRef = ref;
                              }}
                              htmlFor="outlined-age-native-simple"
                            >
                              <FormattedMessage id="myihtp.infopessoal.contactos.tipoContacto" />
                            </InputLabel>
                            <Select
                              native
                              onChange={this.handleInput}
                              input={
                                <OutlinedInput
                                  name="TipoContacto"
                                  labelWidth={127}
                                  id="outlined-age-native-simple"
                                />
                              }
                            >
                              <option value="" />
                              {this.state.tipoContactos.map((contacts, j) => {
                                return (
                                  <option value={contacts.ID_TipoContacto}>
                                    {contacts.Descricao}
                                  </option>
                                );
                              })}
                            </Select>
                          </FormControl>
                        ) : null}
                      </Col>
                      <Col
                        xs="12"
                        sm="12"
                        md="3"
                        lg="3"
                        style={{
                          textAlign: isMobile ? "left" : "center"
                        }}
                      >
                        <TextField
                          id="Descricao"
                          label={this.props.intl.formatMessage({
                            id: "myihtp.infopessoal.contactos.Descricao"
                          })}
                          name="Descricao"
                          value={this.state.editInfo.Descricao}
                          onChange={this.handleInput}
                          variant="outlined"
                          style={{ fontSize: "12px" }}
                          style={{
                            width: isMobile ? "67vw" : "80%",
                            marginTop: "7px"
                          }}
                        />
                      </Col>
                      <Col
                        xs="12"
                        sm="12"
                        md="3"
                        lg="3"
                        style={{
                          textAlign: isMobile ? "left" : "center"
                        }}
                      >
                        <FormControl
                          variant="outlined"
                          style={{
                            width: isMobile ? "67vw" : "80%",
                            marginTop: "7px"
                          }}
                        >
                          <InputLabel
                            ref={ref => {
                              this.InputLabelRef = ref;
                            }}
                            htmlFor="outlined-age-native-simple"
                          >
                            <FormattedMessage id="myihtp.infopessoal.contactos.Principal" />
                          </InputLabel>
                          <Select
                            native
                            onChange={this.handleInput}
                            input={
                              <OutlinedInput
                                name="Principal"
                                labelWidth={127}
                                id="outlined-age-native-simple"
                              />
                            }
                          >
                            <option value="" />
                            {row.original.Principal === "S" ? (
                              <option value={"S"}>Sim</option>
                            ) : (
                              <option value={"N"}>Não</option>
                            )}
                            {row.original.Principal === "S" ? (
                              <option value={"N"}>Não</option>
                            ) : (
                              <option value={"S"}>Sim</option>
                            )}
                          </Select>
                        </FormControl>
                      </Col>
                      <Col
                        xs="12"
                        sm="12"
                        md="3"
                        lg="3"
                        style={{
                          textAlign: isMobile ? "left" : "center",
                          marginTop: isMobile ? "15px" : "0px"
                        }}
                      >
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={this.editContact(
                            row.original.ID_TipoContacto,
                            row.row.ID_ClienteContacto
                          )}
                        >
                          <FormattedMessage id="myihtp.infopessoal.botao.guardar" />
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            );
          }}
        />
      </div>
    );
  }
}
export default injectIntl(Contactos);
