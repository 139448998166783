import React, { Component } from "react";

import "react-table/react-table.css";
import { Row, Col, FormGroup, FormControl, Container } from "react-bootstrap";

import Informacoes from "./Informacoes/Informacoes";
import OutrasInformacoes from "./OutrasInformacoes/OutrasInformacoes";
import Parametrizacoes from "./Parametrizacoes/Parametrizacoes";
import Contactos from "./Contactos/Contactos";
import Moradas from "./Moradas/Moradas";
import { getUserInformacoes } from "../../../../../actions/myihtpActions";
import GerirConta from "../GerirConta/GerirConta";
import { FormattedMessage } from "react-intl";
import Subtitle from "../Modules/Subtitle";
import LoginSocial from "./LoginSocial/LoginSocial";
import GestaoAvisos from "./GestaoAvisos/GestaoAvisos";

class InformacoesPessoais extends Component {
  constructor(props) {
    super(props);
    this.state = {
      informacao: null,
      noDataGetInfoPessoais: null
    };
  }

  render() {
    var infoPessoais = {};

    if (this.state.infoPessoais) {
      infoPessoais = this.state.infoPessoais;
    }

    return (
      <Row>
        <Col xs="12" sm="12" md="12" lg="12">
          <Container>
            <Subtitle
              text={<FormattedMessage id="myihtp.infopessoal.titulo" />}
              marginBottom="20px"
              marginTop="20px"
            />
          </Container>
          <Informacoes />
          <Container>
            <Subtitle
              text={
                <FormattedMessage id="myihtp.infopessoal.outrasinformacoes.titulo" />
              }
              marginBottom="20px"
              marginTop="20px"
            />
          </Container>
          <OutrasInformacoes />
          <Container>
            <Subtitle
              text={
                <FormattedMessage id="myihtp.infopessoal.parametrizacoes.titulo" />
              }
              marginBottom="20px"
              marginTop="20px"
            />
          </Container>
          <Parametrizacoes />
          <Container>
            <Subtitle
              text={
                <FormattedMessage id="myihtp.infopessoal.alterarPassword.titulo" />
              }
              marginBottom="20px"
              marginTop="20px"
            />
          </Container>
          <GerirConta />
          <br />
          <Container>
            <Subtitle
              text={
                <FormattedMessage id="myihtp.infopessoal.loginSocial.titulo" />
              }
              marginBottom="20px"
              marginTop="20px"
            />
          </Container>
          <LoginSocial />
          <br />
          <Row style={{ textAlign: "left" }}>
            <Col xs="12" sm="12" md="12" lg="12">
              <Contactos />
            </Col>
          </Row>
          <br />
          <Row style={{ textAlign: "left" }}>
            <Col xs="12" sm="12" md="12" lg="12">
              <Moradas />
            </Col>
          </Row>
          <br />
          <hr />
          <Row style={{ textAlign: "left" }}>
            <Col xs="12" sm="12" md="12" lg="12">
              <GestaoAvisos />
            </Col>
          </Row>
          <br />
        </Col>
      </Row>
    );
  }
}
export default InformacoesPessoais;
