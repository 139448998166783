import React, { Component } from "react";

// import Footer from "../../../components/Footer/Footer";

import { connect } from "react-redux";
import { withCookies, Cookies } from "react-cookie";

class ReciboBotao extends Component {
  render() {
    return (
      <a href={this.props.url} target="_blank">
        <svg
          height="20px"
          viewBox="0 0 512 512.00115"
          width="25px"
          xmlns="http://www.w3.org/2000/svg"
          id="botaoPagamento"
        >
          <g>
            <g>
              <path
                d="M282.544,464.002h-53.34c-5.522,0-10,4.477-10,10c0,5.523,4.478,10,10,10h53.34c5.522,0,10-4.477,10-10
			C292.543,468.479,288.065,464.002,282.544,464.002z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                d="M501.993,436.003H489.99V181.607c0-19.62-15.44-35.582-34.419-35.582h-37.703V10c0-4.044-2.437-7.691-6.173-9.239
			c-3.737-1.548-8.038-0.692-10.898,2.167L377.47,26.258L354.142,2.93c-1.875-1.876-4.419-2.93-7.071-2.93s-5.195,1.054-7.071,2.929
			l-23.329,23.328L293.344,2.93c-1.876-1.876-4.419-2.93-7.071-2.93c-2.652,0-5.195,1.054-7.071,2.929l-23.329,23.328L232.545,2.929
			c-1.876-1.875-4.419-2.929-7.071-2.929s-5.195,1.054-7.071,2.929l-23.327,23.328L171.747,2.929
			c-1.876-1.875-4.419-2.929-7.071-2.929s-5.196,1.054-7.071,2.929l-23.327,23.328L110.95,2.929
			c-3.877-3.877-10.146-3.903-14.064-0.065c-2.058,2.016-3.059,4.707-2.995,7.381v135.78H56.423
			c-18.978,0-34.419,15.962-34.419,35.582v254.396H10.008c-5.522,0-10,4.477-10,10v17c0,27.018,21.98,48.999,48.999,48.999h413.988
			c27.018,0,48.999-21.98,48.999-48.999v-17C511.993,440.48,507.516,436.003,501.993,436.003z M113.889,34.154l13.317,13.317
			c1.876,1.875,4.419,2.929,7.071,2.929s5.195-1.054,7.071-2.929l23.327-23.328l23.328,23.328c1.876,1.875,4.419,2.929,7.071,2.929
			c2.652,0,5.196-1.054,7.071-2.929l23.327-23.328l23.328,23.328c3.906,3.905,10.236,3.905,14.143,0l23.328-23.328l23.327,23.328
			c1.875,1.875,4.419,2.929,7.071,2.929c2.652,0,5.195-1.054,7.071-2.929l23.328-23.328l23.327,23.328
			c1.876,1.875,4.419,2.929,7.071,2.929c2.652,0,5.195-1.054,7.071-2.929l13.329-13.329v321.162H113.889V34.154z M103.89,375.304
			h303.979v0c5.522,0,10-4.477,10-10v-25.798h12.447c4.305,0,7.807,3.502,7.807,7.807v35.984c0,4.305-3.502,7.807-7.807,7.807
			H81.685c-4.305,0-7.807-3.502-7.807-7.807v-35.984c0-4.305,3.502-7.807,7.807-7.807H93.89v25.798
			C93.89,370.827,98.368,375.304,103.89,375.304z M56.423,166.025H93.89v153.482H81.685c-15.333,0-27.806,12.474-27.806,27.806
			v35.984c0,15.333,12.474,27.806,27.806,27.806h348.631c15.333,0,27.806-12.474,27.806-27.806v-35.984
			c0-15.333-12.474-27.806-27.806-27.806h-12.448V166.025h37.703c7.951,0,14.42,6.991,14.42,15.583v254.396H42.004V181.607h0
			C42.004,173.016,48.472,166.025,56.423,166.025z M491.993,463.002c0,15.99-13.01,28.999-28.999,28.999H49.006
			c-15.99,0-28.999-13.009-28.999-28.999v-7h471.986V463.002z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                d="M255.879,136.42c-7.744,0-14.045-5.303-14.045-11.822s6.301-11.822,14.045-11.822s14.045,5.303,14.045,11.822
			c0,5.523,4.478,10,10,10s10-4.477,10-10c0-14.295-10.137-26.416-24.044-30.418v-4.732c0-5.523-4.478-10-10-10s-10,4.477-10,10
			v4.732c-13.907,4.003-24.044,16.124-24.044,30.418c0,17.546,15.272,31.821,34.044,31.821c7.744,0,14.045,5.303,14.045,11.822
			c0,6.519-6.301,11.822-14.045,11.822s-14.045-5.303-14.045-11.822c0-5.523-4.478-10-10-10c-5.522,0-10,4.477-10,10
			c0,14.295,10.137,26.416,24.044,30.418v5.922c0,5.523,4.478,10,10,10s10-4.477,10-10v-5.922
			c13.907-4.003,24.044-16.124,24.044-30.418C289.923,150.695,274.652,136.42,255.879,136.42z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                d="M342.155,136.42h-16.738c-5.522,0-10,4.477-10,10s4.478,10,10,10h16.738c5.522,0,10-4.477,10-10
			S347.677,136.42,342.155,136.42z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                d="M186.341,136.42h-16.737c-5.522,0-10,4.477-10,10s4.478,10,10,10h16.737c5.522,0,10-4.477,10-10
			S191.864,136.42,186.341,136.42z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                d="M345.416,232.009H210.997c-5.522,0-10,4.477-10,10c0,5.523,4.478,10,10,10h134.419c5.522,0,10-4.477,10-10
			C355.415,236.486,350.939,232.009,345.416,232.009z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                d="M173.416,234.939c-1.86-1.861-4.44-2.93-7.07-2.93c-2.64,0-5.22,1.07-7.07,2.93c-1.869,1.86-2.93,4.44-2.93,7.07
			c0,2.63,1.061,5.21,2.93,7.07c1.86,1.86,4.431,2.93,7.07,2.93c2.63,0,5.2-1.07,7.07-2.93c1.859-1.86,2.93-4.44,2.93-7.07
			C176.346,239.379,175.276,236.799,173.416,234.939z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                d="M345.416,288.007H210.997c-5.522,0-10,4.477-10,10c0,5.523,4.478,10,10,10h134.419c5.522,0,10-4.477,10-10
			C355.415,292.484,350.939,288.007,345.416,288.007z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                d="M173.416,290.937c-1.86-1.861-4.44-2.93-7.07-2.93c-2.64,0-5.22,1.07-7.07,2.93c-1.869,1.86-2.93,4.44-2.93,7.07
			s1.061,5.21,2.93,7.07c1.86,1.86,4.431,2.93,7.07,2.93c2.63,0,5.21-1.07,7.07-2.93c1.859-1.86,2.93-4.44,2.93-7.07
			S175.276,292.797,173.416,290.937z"
              />
            </g>
          </g>
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
        </svg>
      </a>
    );
  }
}

const mapStateToProps = state => {
  return {
    isVerified: state.isVerified,
    user: state.user
  };
};

export default withCookies(connect(mapStateToProps)(ReciboBotao));
