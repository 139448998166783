import React from "react";
import PropTypes from "prop-types";

import { Row, Col, Container } from "react-bootstrap";

const defaultButton = props => <button {...props}>{props.children}</button>;

export default class PaginationMobile extends React.Component {
  constructor(props) {
    super();

    this.changePage = this.changePage.bind(this);

    this.state = {
      visiblePages: this.getVisiblePages(null, props.pages)
    };
  }

  static propTypes = {
    pages: PropTypes.number,
    page: PropTypes.number,
    PageButtonComponent: PropTypes.any,
    onPageChange: PropTypes.func,
    previousText: PropTypes.string,
    nextText: PropTypes.string
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.pages !== nextProps.pages) {
      this.setState({
        visiblePages: this.getVisiblePages(null, nextProps.pages)
      });
    }

    this.changePage(nextProps.page + 1);
  }

  filterPages = (visiblePages, totalPages) => {
    return visiblePages.filter(page => page <= totalPages);
  };

  getVisiblePages = (page, total) => {
    if (total < 7) {
      return this.filterPages([1, 2, 3, 4, 5, 6], total);
    } else {
      if (page % 5 >= 0 && page > 4 && page + 2 < total) {
        return [1, page - 1, page, page + 1, total];
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
        return [1, total - 3, total - 2, total - 1, total];
      } else {
        return [1, 2, 3, 4, 5, total];
      }
    }
  };

  changePage(page) {
    const activePage = this.props.page + 1;

    if (page === activePage) {
      return;
    }

    const visiblePages = this.getVisiblePages(page, this.props.pages);

    this.setState({
      visiblePages: this.filterPages(visiblePages, this.props.pages)
    });

    this.props.onPageChange(page - 1);
  }

  render() {
    const { PageButtonComponent = defaultButton } = this.props;
    const { visiblePages } = this.state;
    const activePage = this.props.page + 1;

    return (
      <div
        className="Table__pagination"
        style={{ marginTop: "10px", marginBottom: "10px" }}
      >
        <Container>
          <Row>
            <Col xs={2} sm={2}>
              <div
                className="Table__prevPageWrapper"
                onClick={() => {
                  if (activePage === 1) return;
                  this.changePage(activePage - 1);
                }}
                disabled={activePage === 1}
              >
                <svg
                  version="1.1"
                  id="Capa_1"
                  x="0px"
                  y="0px"
                  width="25px"
                  height="25px"
                  viewBox="0 0 292.359 292.359"
                  style={{ enableBackground: "new 0 0 292.359 292.359" }}
                  fill="#034275"
                >
                  <g>
                    <path
                      d="M222.979,5.424C219.364,1.807,215.08,0,210.132,0c-4.949,0-9.233,1.807-12.848,5.424L69.378,133.331
		c-3.615,3.617-5.424,7.898-5.424,12.847c0,4.949,1.809,9.233,5.424,12.847l127.906,127.907c3.614,3.617,7.898,5.428,12.848,5.428
		c4.948,0,9.232-1.811,12.847-5.428c3.617-3.614,5.427-7.898,5.427-12.847V18.271C228.405,13.322,226.596,9.042,222.979,5.424z"
                    />
                  </g>
                </svg>
              </div>
            </Col>
            <Col
              xs={8}
              sm={8}
              style={{ top: "0px", bottom: "0px", margin: "auto" }}
            >
              <div className="Table__visiblePagesWrapper">
                {visiblePages.map((page, index, array) => {
                  return (
                    <PageButtonComponent
                      key={page}
                      className={
                        activePage === page
                          ? "Table__pageButton Table__pageButton--active"
                          : "Table__pageButton"
                      }
                      onClick={this.changePage.bind(null, page)}
                    >
                      {array[index - 1] + 2 < page ? `...${page}` : page}
                    </PageButtonComponent>
                  );
                })}
              </div>
            </Col>
            <Col xs={2} sm={2}>
              <div
                className="Table__nextPageWrapper"
                onClick={() => {
                  if (activePage === this.props.pages) return;
                  this.changePage(activePage + 1);
                }}
                disabled={activePage === this.props.pages}
              >
                <svg
                  version="1.1"
                  id="Capa_1"
                  x="0px"
                  y="0px"
                  width="25px"
                  height="25px"
                  viewBox="0 0 292.359 292.359"
                  style={{ enableBackground: "new 0 0 292.359 292.359" }}
                  fill="#034275"
                >
                  <g>
                    <path
                      d="M222.979,133.331L95.073,5.424C91.456,1.807,87.178,0,82.226,0c-4.952,0-9.233,1.807-12.85,5.424
		c-3.617,3.617-5.424,7.898-5.424,12.847v255.813c0,4.948,1.807,9.232,5.424,12.847c3.621,3.617,7.902,5.428,12.85,5.428
		c4.949,0,9.23-1.811,12.847-5.428l127.906-127.907c3.614-3.613,5.428-7.897,5.428-12.847
		C228.407,141.229,226.594,136.948,222.979,133.331z"
                    />
                  </g>
                </svg>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
