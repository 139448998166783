import "moment/locale/pt";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import ReactGA from "react-ga";
import { getDownloads } from "../../../../../actions/myihtpActions";

var moment = require("moment");

class DocumentosTipoEspecifico extends React.Component {
  render() {
    moment.locale("pt");
    var tipoDocumento = this.props.tipoDocumento;
    return (
      <div>
        {this.props.downloads.map(function (item) {
          if (item.tipoDocumento === tipoDocumento) {
            return (
              <p>
                {moment().diff(item.DataVersao, "days") < 30 ? (
                  <span
                    style={{
                      backgroundColor: "#ff008d",
                      borderRadius: "10px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      color: "white",
                      fontWeight: "700"
                    }}
                  >
                    Novo
                  </span>
                ) : null}{" "}
                <span
                  className="fake-linkDownloads"
                  onClick={() => {
                    ReactGA.event({
                      category: "MyIHTP",
                      action: "Downloads - Click",
                      label:
                        "[" +
                        moment(item.DataVersao).format("L") +
                        "] - " +
                        item.Descricao
                    });
                    window.open(
                      "https://doremon.ihavethepower.net/assets/uploads/" +
                      item.NomeFisico,
                      "_blank"
                    );
                  }}
                >
                  {item.Descricao}
                </span>
              </p>
            );
          }
        })}
        <br />
      </div>
    );
  }
}

class Downloads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      seePDF: false,
      downloads: [],
      tiposDoc: []
    };

    var downloads = [];
  }

  componentDidMount() {
    getDownloads()
      .then(data => {
        if (data.downloads) {
          this.setState({ downloads: data.downloads });
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  handleClickVoltar = async e => {
    this.setState(prevState => ({
      seePDF: !prevState.seePDF
    }));
  };

  handleClickFicheiro = param => async e => {
    this.setState(prevState => ({
      file: param,
      seePDF: !prevState.seePDF
    }));
  };

  render() {
    if (this.state.downloads.length > 0) {
      const tiposDocumentos = this.state.downloads
        .map(item => item.tipoDocumento)
        .filter((value, index, self) => self.indexOf(value) === index);
      const downloads = this.state.downloads;
      return (
        <Row>
          <Col xs="12" sm="12" md="12" lg="12">
            <Row style={{ textAlign: "left" }}>
              <Col xs="12" sm="12" md="12" lg="12">
                {tiposDocumentos.map(function (x) {
                  return (
                    <div>
                      <h3>{x}</h3>
                      <DocumentosTipoEspecifico
                        downloads={downloads}
                        tipoDocumento={x}
                      />
                    </div>
                  );
                })}
              </Col>
            </Row>
          </Col>
        </Row>
      );
    } else {
      return <div />;
    }
    // <div id="myaccountContent">
    //   <h3>
    //     {" "}
    //     <FormattedMessage id="myihtp.downloads.title" />
    //   </h3>
    //   <hr />
    //   {this.state.seePDF ? (
    //     <div>
    //       <Button bsStyle="danger" onClick={this.handleClickVoltar}>
    //         <FormattedMessage id="myihtp.downloads.return" />
    //       </Button>
    //       <DocumentPDFReader file="b956f245-1b2a-446f-a6d8-4324c9b43897.pdf" />
    //     </div>
    //   ) : (
    //     <div>
    //       {this.state.tiposDocumentos.map((tiposDocumentos, i) => {
    //         return (
    //           <div>
    //             <h5>{tiposDocumentos.description}</h5>
    //             {this.state.documentosPermitidos.map((documentos, j) => {
    //               return (
    //                 <div>
    //                   {documentos.id_tipo === tiposDocumentos.id_tipo ? (
    //                     <a onClick={this.handleClickFicheiro(documentos.url)}>
    //                       {documentos.description}
    //                     </a>
    //                   ) : null}
    //                 </div>
    //               );
    //             })}
    //             <hr />
    //           </div>
    //         );
    //       })}
    //     </div>
    //   )}
    // </div>
  }
}
export default Downloads;
