import { Button, TextField } from "@material-ui/core";
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { injectIntl } from "react-intl";
import ReactTable from "react-table";
import { ToastContainer } from "react-toastr";
import { addConsultantUnavailablePeriod, getConsultantUnavailablePeriods, removeConsultantUnavailablePeriod, verificarEmail } from "../../../../../actions/myihtpActions";
import Configs from "../../../../../helpers/Configs";
import MinhasEmpresas from "../FerramentasBackOffice/Empresas/Components/MinhasEmpresas";
import VerificarEmpresaAtribuida from "../FerramentasBackOffice/Empresas/Components/VerificarEmpresaAtribuida";

let container;

class FerramentasConsultor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailValidacao: {
        emailValidacao: ""
      },
      unavailable: {
        date: [null, null],
      },
      unavailablePeriods: []
    };
    this.handleInput = this.handleInput.bind(this);
    this.getUnavailablePeriods = this.getUnavailablePeriods.bind(this);
    this.removeUnavailablePeriodButtonClick = this.removeUnavailablePeriodButtonClick.bind(this);
  }

  formatDate(value) {

    var d = new Date(value);
    var month = '' + (d.getMonth() + 1);
    var days = '' + d.getDate();
    var hours = '' + d.getHours();
    var minutes = '' + d.getMinutes();

    if (month.length < 2) month = '0' + month;
    if (days.length < 2) days = '0' + days;
    if (hours.length < 2) hours = '0' + hours;
    if (minutes.length < 2) minutes = '0' + minutes;

    var result = days + "-" + month + "-" + d.getFullYear() + " " + hours + ":" + minutes;
    return result;
  }

  verifyEmail = () => async e => {
    e.stopPropagation();
    verificarEmail(this.state.emailValidacao.emailValidacao)
      .then(data => {
        if (data.message.messageType === "success") {
          container.clear();
          container.success(data.message.message);
        } else {
          container.clear();
          container.error(data.message.message);
        }
      })
      .catch(error => { });
  };

  handleInput(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState(
      prevState => ({
        emailValidacao: {
          ...prevState.emailValidacao,
          [name]: value
        }
      }),
      () => console.log(this.state.emailValidacao)
    );
  }

  addUnavailablePeriodButtonClick = () => async e => {

    //Verificar data vazia
    if (this.state.unavailable.date[0] == null || this.state.unavailable.date[1] == null) {
      container.clear();
      container.error("Período de indisponibilidade não pode estar vazio");
      return;
    }

    addConsultantUnavailablePeriod(this.state.unavailable.date[0], this.state.unavailable.date[1], null)
      .then(result => {

        container.clear();
        if (result.success === true) {
          container.success(result.message);

          //Limpar dados de adicionar
          this.setState({
            unavailable: {
              date: [null, null],
            }
          });

          this.getUnavailablePeriods();
        } else {

          if (typeof result.message === 'undefined') {
            container.error("Erro ao comunicar com servidor para adicionar período de indisponibilidade");
          } else {
            container.error(result.message);
          }
        }
      })
      .catch(err => {
        container.clear();
        container.error("Erro ao comunicar com servidor para adicionar período de indisponibilidade");
      });

  }

  removeUnavailablePeriodButtonClick(id) {


    removeConsultantUnavailablePeriod(id)
      .then(result => {
        console.log(result);
        container.clear();
        if (result.success === true) {
          container.success(result.message);

          this.getUnavailablePeriods();
        } else {
          if (typeof result.message === 'undefined') {
            container.error("Erro ao comunicar com servidor para eliminar período de indisponibilidade");
          } else {
            container.error(result.message);
          }
        }
      })
      .catch(err => {
        container.clear();
        container.error("Erro ao comunicar com servidor para eliminar período de indisponibilidade");
      })
  }

  getUnavailablePeriods() {

    console.log("Entrei aqui");
    getConsultantUnavailablePeriods()
      .then(result => {
        console.log(result);
        container.clear();
        if (result.success === true) {
          this.setState({
            unavailablePeriods: result.obj
          });
        } else {
          if (typeof result.message === 'undefined') {
            container.error("Erro ao comunicar com servidor para buscar períodos de indisponibilidade");
          } else {
            container.error(result.message);
          }
        }
      })
      .catch(err => {
        container.clear();
        container.error("Erro ao comunicar com servidor para buscar períodos de indisponibilidade");
      })
  }

  componentDidMount() {
    this.getUnavailablePeriods();
  }

  onChange = date => {
    this.setState(prevState => {
      return {
        unavailable: {
          ...prevState.unavailable,
          date: date
        }
      }
    })
  }

  render() {
    const periodosIndisponives = {
      columns: [
        {
          Header: "Início de Período",
          accessor: "DataInicio",
          resizable: false,
          Cell: row => {
            return (this.formatDate(row.value))
          }
        },
        {
          Header: "Fim de Período",
          accessor: "DataFim",
          resizable: false,
          Cell: row => {
            return (this.formatDate(row.value))
          }
        },
        {
          Header: "",
          accessor: "Remover",
          width: 50,
          Cell: row => {
            return (
              <div>
                <a>
                  <svg height="15px" viewBox="0 0 512 512.00115" width="15px" xmlns="http://www.w3.org/2000/svg">
                    <path d="m441.378906 70.621094h-105.929687v-34.691406c0-19.808594-16.121094-35.929688-35.929688-35.929688h-140.003906c-19.808594 0-35.929687 16.121094-35.929687 35.929688v34.691406h-105.929688c-9.75 0-17.65625 7.894531-17.65625 17.65625 0 9.757812 7.90625 17.652344 17.65625 17.652344h18.40625l14.652344 336.96875c1.679687 38.757812 33.386718 69.101562 72.175781 69.101562h213.257813c38.785156 0 70.488281-30.34375 72.171874-69.101562l14.652344-336.96875h18.40625c9.75 0 17.65625-7.894532 17.65625-17.652344 0-9.761719-7.90625-17.65625-17.65625-17.65625zm-281.863281-35.3125h140.003906l.617188 35.3125h-141.03125zm213.527344 406.070312c-.863281 19.792969-17.066407 35.3125-36.898438 35.3125h-213.257812c-19.828125 0-36.035157-15.519531-36.898438-35.3125l-14.585937-335.449218h316.226562zm0 0" />
                    <path d="m229.515625 406.070312c-9.75 0-17.652344-7.898437-17.652344-17.65625v-211.863281c0-9.757812 7.902344-17.652343 17.652344-17.652343s17.65625 7.894531 17.65625 17.652343v211.863281c0 9.757813-7.90625 17.65625-17.65625 17.65625zm0 0" />
                    <path d="m300.136719 406.070312c-9.75 0-17.652344-7.898437-17.652344-17.65625v-211.863281c0-9.757812 7.902344-17.652343 17.652344-17.652343s17.65625 7.894531 17.65625 17.652343v211.863281c0 9.757813-7.90625 17.65625-17.65625 17.65625zm0 0" />
                    <path d="m158.898438 406.070312c-9.753907 0-17.65625-7.898437-17.65625-17.65625v-211.863281c0-9.757812 7.902343-17.652343 17.65625-17.652343 9.75 0 17.652343 7.894531 17.652343 17.652343v211.863281c0 9.757813-7.902343 17.65625-17.652343 17.65625zm0 0" />
                  </svg>
                </a>
              </div>
            );
          }
        }
      ]
    };

    return (
      <div>
        <ToastContainer
          ref={ref => (container = ref)}
          className="toast-bottom-right"
        />
        <Row>
          <Col xs="12" sm="12" md="12" lg="12">

            {/* Validação de Email de Utilizador*/}
            <Row style={{ textAlign: "left" }}>
              <Col
                xs="12"
                sm="12"
                md="12"
                lg="12"
                style={{ textAlign: "center" }}
              >
                <span style={{ textAlign: "center" }}>
                  <b>Validação de email do utilizador</b>
                </span>
                <div className="form-group">
                  <br />
                  <Row xs={12} sm={12} md={12} lg={12}>
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      style={{ textAlign: "center" }}
                    >
                      <TextField
                        placeholder="Endereço de email a validar"
                        name={"emailValidacao"}
                        style={{ maxWidth: isMobile ? "100%" : "50%" }}
                        type="text"
                        fullWidth={true}
                        onChange={this.handleInput}
                      />
                    </Col>
                  </Row>
                  <br />
                  <br />
                  <Row>
                    <Col
                      xs="12"
                      sm="12"
                      md="12"
                      lg="12"
                      style={{ textAlign: "center" }}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        style={{ minWidth: "20%" }}
                        onClick={this.verifyEmail()}
                      >
                        Verificar
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <br />
            <hr />
            <br />

            {/* Adicionar Periodo de Indisponibilidade*/}
            <Row style={{ textAlign: "left" }}>
              <Col
                xs="12"
                sm="12"
                md="12"
                lg="12"
                style={{ textAlign: "center" }}
              >
                <span style={{ textAlign: "center" }}>
                  <b>Adicionar Período de Indisponibilidade para Receber Leads </b>
                </span>
                <div className="form-group">
                  <br />
                  <br />
                  <Row xs={12} sm={12} md={12} lg={12}>
                    <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center", display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center" }} >

                      <label style={{ marginBottom: "0px", marginRight: "10px" }}> Período de Indisponibilidade: </label>
                      <DateTimeRangePicker value={this.state.unavailable.date} onChange={this.onChange} />
                    </Col>
                  </Row>
                  <br />
                  <br />
                  <Row>
                    <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center" }}>
                      <Button variant="contained" color="secondary" style={{ minWidth: "20%" }} onClick={this.addUnavailablePeriodButtonClick()} >
                        Adicionar
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <br />


            {/* Mostrar Periodos de Indisponibilidade*/}
            <Row>
              <Col>
                <ReactTable
                  className="-highlight tableReact"
                  data={this.state.unavailablePeriods}
                  getTheadProps={(state, rowInfo, column) => {
                    return {
                      style: {
                        fontWeight: Configs.myIHTP.tableConfig.fontHeightHeader,
                        fontSize: Configs.myIHTP.tableConfig.fontSizeHeader,
                        height: Configs.myIHTP.tableConfig.heightHeader,
                        color: Configs.myIHTP.tableConfig.colorHeader,
                        backgroundColor:
                          Configs.myIHTP.tableConfig.backgroundColorHeader
                      }
                    };
                  }}
                  getPaginationProps={state => {
                    return {
                      style: {
                        color: Configs.myIHTP.tableConfig.colorHeader,
                        backgroundColor:
                          Configs.myIHTP.tableConfig.backgroundColorHeader,
                        fontSize: Configs.myIHTP.tableConfig.fontSizeRowBody
                      }
                    };
                  }}
                  getTdProps={(state, rowInfo, column, instance) => {
                    return {
                      onClick: (e, handleOriginal) => {
                        if (column.id === "Remover") {
                          var confirm = window.confirm("Pretender remover o período de Indisponibilidade?");

                          if (confirm) this.removeUnavailablePeriodButtonClick(rowInfo.original.Id);
                        }
                      },
                      style: {
                        "text-align": "center",
                        "font-size": Configs.myIHTP.tableConfig.fontSizeRowBody
                      }
                    };
                  }}
                  defaultPageSize={5}
                  showPagination={
                    this.state.unavailablePeriods.length >
                      Configs.myIHTP.tableConfig.defaultPageSize
                      ? Configs.myIHTP.tableConfig.showPagination
                      : false
                  }
                  previousText={Configs.myIHTP.tableConfig.buttonCopyAnterior}
                  nextText={Configs.myIHTP.tableConfig.buttonCopyProximo}
                  noDataText={Configs.myIHTP.tableConfig.noDataText}
                  pageText={Configs.myIHTP.tableConfig.pageText}
                  ofText={Configs.myIHTP.tableConfig.ofText}
                  rowsText={Configs.myIHTP.tableConfig.rowsText}
                  columns={periodosIndisponives.columns}
                />

              </Col>
            </Row>
            <br />
            <hr />
            <br />
            <VerificarEmpresaAtribuida />
            <br />
            <hr />
            <br />
            <MinhasEmpresas />
            <br />
            <br />
          </Col>
        </Row>
      </div>
    );
  }
}
export default injectIntl(FerramentasConsultor);
