import React, { Component } from "react";
import { withCookies } from "react-cookie";
import { connect } from "react-redux";

class Instagram extends Component {
  render() {
    return (
      <div
        style={{ display: "inline-flex" }}
        onClick={() => {
          window.open(
            "https://www.linkedin.com/company/solfut-lda---i-have-the-power/",
            "_blank"
          );
        }}
      >
        <div style={{ padding: "5px" }}>
          <svg
            className="linkdinIcon"
            width="30px"
            height="31px"
            viewBox="0 0 30 31"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xlink="http://www.w3.org/1999/xlink"
          >
            <g
              id="Menu"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g id="XS" transform="translate(-146.000000, -584.000000)">
                <g id="linkedin" transform="translate(146.000000, 584.000000)">
                  <g id="LinkedIN">
                    <path
                      d="M0,15.5 C0,6.93958596 6.71572835,0 15,0 C23.2842716,0 30,6.93958596 30,15.5 C30,24.060414 23.2842716,31 15,31 C6.71572835,31 0,24.060414 0,15.5 Z"
                      id="back"
                      fill="#0077B5"
                      fill-rule="nonzero"
                    />
                    <path
                      d="M10.6003388,12.837777 L10.6003388,23.391799 L7.2010107,23.391799 L7.2010107,12.837777 L10.6003388,12.837777 Z M10.8242271,9.57299225 C10.8242271,10.5863728 10.0860337,11.3972454 8.9007426,11.3972454 L8.87901905,11.3972454 C7.737786,11.3972454 7,10.5863728 7,9.57299225 C7,8.53817712 7.7602563,7.75 8.923145,7.75 C10.0860337,7.75 10.8021641,8.53817712 10.8242271,9.57299225 Z M22.859616,17.3404454 L22.8594802,23.391799 L19.460288,23.391799 L19.460288,17.7453913 C19.460288,16.3271348 18.9683854,15.3593553 17.7378142,15.3593553 C16.7986781,15.3593553 16.2392967,16.0117799 15.993617,16.6419293 C15.9037358,16.8677632 15.8816728,17.1824176 15.8816728,17.4978426 L15.8816728,23.3920792 L12.4819375,23.3920792 C12.4819375,23.3920792 12.5267423,13.8283921 12.4819375,12.8380572 L15.8816728,12.8380572 L15.8816728,14.3329459 C16.3328437,13.6143262 17.1408918,12.5899482 18.9457114,12.5899482 C21.1826259,12.5899482 22.859616,14.0987063 22.859616,17.3404454 Z"
                      id="Shape"
                      fill="#FFFFFF"
                      fill-rule="nonzero"
                    />
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isVerified: state.isVerified,
    user: state.user
  };
};

export default withCookies(connect(mapStateToProps)(Instagram));
