import { Button } from "@material-ui/core";
import $ from "jquery";
import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import ReactPlayer from "react-player";
import "react-table/react-table.css";
import "video-react/dist/video-react.css";
import { getMediaCenterAll, getTipoMedia, updateMediacenterPlayed } from "../../../../../actions/myihtpActions";

class MediaCenter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ficheirosMediacenter: [],
      tipoMedia: []
    };
  }
  componentWillMount() {
    getMediaCenterAll()
      .then(data => {
        if (data.mediacenter.length > 0) {
          getTipoMedia()
            .then(dataMedia => {
              this.setState({ tipoMedia: dataMedia.tipoMedia });
              this.setState({ ficheirosMediacenter: data.mediacenter });
            })
            .catch(err => {
              console.log(err);
            });
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  updatePlayed(id) {
    updateMediacenterPlayed(id).then(data => { });
  }

  closeCat(className) {
    $(className).css("display", "none");
    let botaoMaisClass = className + "mais";
    let botaoMenosClass = className + "menos";
    $(botaoMaisClass).css("display", "unset");
    $(botaoMenosClass).css("display", "none");
  }

  openCat(className) {
    $(className).css("display", "block");
    let botaoMaisClass = className + "mais";
    let botaoMenosClass = className + "menos";
    $(botaoMaisClass).css("display", "none");
    $(botaoMenosClass).css("display", "unset");
  }

  getMediaCenterWithMediaType = mediaType => async e => {
    getMediaCenterAll(mediaType)
      .then(data => {
        if (data.mediacenter.length > 0) {
          this.setState({ ficheirosMediacenter: data.mediacenter });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    return (
      <Row style={{ marginTop: "25px" }}>
        <Col xs="12" sm="12" md="12" lg="12">
          <Row xs="12" sm="12" md="12" lg="12">
            <h6 style={{ textAlign: "center", minWidth: "100%" }}>
              <FormattedMessage id="myihtp.mediacenter.tipos" />
            </h6>
          </Row>
          <Row xs="12" sm="12" md="12" lg="12">
            {this.state.tipoMedia.map((media, j) => {
              return (
                <Col xs="6" sm="6" md="3" lg="3" style={{ paddingTop: "15px" }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{ minWidth: "100%", minHeight: "100%" }}
                    onClick={this.getMediaCenterWithMediaType(media.Tipo_ID)}
                  >
                    {media.Tipo_Descricao}
                  </Button>
                </Col>
              );
            })}
            <Col xs="6" sm="6" md="3" lg="3" style={{ paddingTop: "15px" }}>
              <Button
                variant="contained"
                color="secondary"
                style={{ minWidth: "100%", minHeight: "100%" }}
                onClick={this.getMediaCenterWithMediaType()}
              >
                <FormattedMessage id="myihtp.mediacenter.verTudo" />
              </Button>
            </Col>
          </Row>
          <br />
          <Row style={{ textAlign: "left" }}>
            <Col xs="12" sm="12" md="12" lg="12">
              {this.state.ficheirosMediacenter.map((ficheiro, j) => {
                var id_tipo = ficheiro.ID_ConteudoMediaCenter
                  ? ficheiro.ID_ConteudoMediaCenter
                  : ficheiro.ID_ConteudoMediaCenterPai;
                return (
                  <span>
                    {ficheiro.ID_ConteudoMediaCenterPai === null ? (
                      <span>
                        <br />
                        <Row>
                          <Col lg="10">
                            <h3
                              style={{
                                textAlign: "left",
                                maxWidth: "90%"
                              }}
                            >
                              {ficheiro.Descricao}
                            </h3>
                          </Col>
                          <Col lg="2" style={{ textAlign: "right" }}>
                            <span
                              onClick={() => {
                                this.closeCat("." + id_tipo);
                              }}
                            >
                              <svg
                                className={id_tipo + "menos"}
                                style={{ display: "none" }}
                                width="25px"
                                height="25px"
                                viewBox="0 0 60 60"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill="rgb(202, 0, 106)"
                                  d="M0,0v60h60V0H0z M58,58H2V2h56V58z"
                                />
                                <rect
                                  fill="rgb(202, 0, 106)"
                                  x="9"
                                  y="29"
                                  width="42"
                                  height="2"
                                />
                              </svg>
                            </span>
                            <span
                              onClick={() => {
                                this.openCat("." + id_tipo);
                              }}
                            >
                              <svg
                                className={id_tipo + "mais"}
                                style={{ display: "unset" }}
                                width="25px"
                                height="25px"
                                viewBox="0 0 60 60"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill="rgb(202, 0, 106)"
                                  d="M0,0v60h60V0H0z M58,58H2V2h56V58z"
                                />
                                <polygon
                                  fill="rgb(202, 0, 106)"
                                  points="29,51 31,51 31,31 51,31 51,29 31,29 31,9 29,9 29,29 9,29 9,31 29,31 	"
                                />
                              </svg>
                            </span>
                          </Col>
                        </Row>
                        <hr />
                        <Container
                          className="myaccount-content"
                          className={id_tipo}
                          style={{ display: "none" }}
                        >
                          <Row>
                            <Col xs="12" sm="12" md="12" lg="12">
                              <div>
                                {ficheiro.ID_TipoConteudoMediaCenter ===
                                  "AU" ? (
                                    <ReactPlayer
                                      config={{
                                        file: {
                                          attributes: {
                                            controlsList: "nodownload"
                                          }
                                        }
                                      }}
                                      onContextMenu={e => e.preventDefault()}
                                      url={
                                        ficheiro.ID_TipoConteudoMediaCenter ===
                                          "AU"
                                          ? "https://doremon.ihavethepower.net/assets/uploads/Multimedia/" +
                                          ficheiro.ConteudoHtml
                                          : ficheiro.ID_TipoConteudoMediaCenter ===
                                            "V2"
                                            ? ficheiro.ConteudoHtml
                                            : null
                                      }
                                      className="react-player"
                                      style={{
                                        textAlign: "center",
                                        maxHeight: "55px",
                                        widht: "auto",
                                        marginRight: "auto",
                                        marginLeft: "auto"
                                      }}
                                      controls={true}
                                      onStart={() => {
                                        this.updatePlayed(
                                          ficheiro.ID_ConteudoMediaCenter
                                        );
                                      }}
                                    />
                                  ) : (
                                    <ReactPlayer
                                      config={{
                                        file: {
                                          attributes: {
                                            controlsList: "nodownload"
                                          }
                                        },
                                        vimeo: {
                                          heigth: "100%",
                                          width: "100%"
                                        }
                                      }}
                                      onContextMenu={e => e.preventDefault()}
                                      url={
                                        ficheiro.ConteudoHtml
                                          ? ficheiro.ConteudoHtml
                                          : null
                                      }
                                      className="react-player"
                                      style={{
                                        textAlign: "center",
                                        maxWidth: "100%",
                                        height: "100%",
                                        marginRight: "auto",
                                        marginLeft: "auto"
                                      }}
                                      vimeoConfig={{
                                        height: "100%",
                                        width: "100%"
                                      }}
                                      controls={true}
                                      onStart={() => {
                                        this.updatePlayed(
                                          ficheiro.ID_ConteudoMediaCenter
                                        );
                                      }}
                                    />
                                  )}
                              </div>
                            </Col>
                          </Row>
                          <br />
                          {this.state.ficheirosMediacenter.map((filho, j) => {
                            return (
                              <span>
                                {ficheiro.ID_ConteudoMediaCenter ===
                                  filho.ID_ConteudoMediaCenterPai ? (
                                    <div>
                                      <Row style={{ marginBottom: "50px" }}>
                                        <Col xs="12" sm="12" md="12" lg="12">
                                          <Row>
                                            <Col xs="12" sm="12" md="12" lg="12">
                                              <h5>{filho.Descricao}</h5>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col xs="12" sm="12" md="12" lg="12">
                                              <p>
                                                {/*ficheiro.DataDisponibilizacao*/}
                                              </p>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col xs="12" sm="12" md="12" lg="12">
                                              {ficheiro.ID_TipoConteudoMediaCenter ===
                                                "AU" ? (
                                                  <ReactPlayer
                                                    config={{
                                                      file: {
                                                        attributes: {
                                                          controlsList: "nodownload"
                                                        }
                                                      }
                                                    }}
                                                    onContextMenu={e =>
                                                      e.preventDefault()
                                                    }
                                                    url={
                                                      "https://doremon.ihavethepower.net/assets/uploads/Multimedia/" +
                                                      filho.ConteudoHtml
                                                    }
                                                    className="react-player"
                                                    style={{
                                                      textAlign: "center",
                                                      maxHeight: "55px",
                                                      widht: "auto",
                                                      marginRight: "auto",
                                                      marginLeft: "auto"
                                                    }}
                                                    controls={true}
                                                    onStart={() => {
                                                      this.updatePlayed(
                                                        filho.ID_ConteudoMediaCenter
                                                      );
                                                    }}
                                                  />
                                                ) : (
                                                  <ReactPlayer
                                                    config={{
                                                      file: {
                                                        attributes: {
                                                          controlsList: "nodownload"
                                                        }
                                                      },
                                                      vimeo: {
                                                        heigth: "100%",
                                                        width: "100%"
                                                      }
                                                    }}
                                                    onContextMenu={e =>
                                                      e.preventDefault()
                                                    }
                                                    url={
                                                      filho.ConteudoHtml
                                                        ? filho.ConteudoHtml
                                                        : null
                                                    }
                                                    // url={
                                                    //   "https://www.youtube.com/watch?v=KDa9He2GMyU"
                                                    // }
                                                    className="react-player"
                                                    style={{
                                                      textAlign: "center",
                                                      maxWidth: "100%",
                                                      height: "100%",
                                                      marginRight: "auto",
                                                      marginLeft: "auto"
                                                    }}
                                                    controls={true}
                                                    onStart={() => {
                                                      this.updatePlayed(
                                                        filho.ID_ConteudoMediaCenter
                                                      );
                                                    }}
                                                  />
                                                )}
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>
                                    </div>
                                  ) : null}
                              </span>
                            );
                          })}
                        </Container>
                      </span>
                    ) : null}
                  </span>
                );
              })}
            </Col>
            {/*
              /> */}
          </Row>
        </Col>
      </Row>
    );
  }
}
export default MediaCenter;

/*


  <Row>
          <Col xs={9} sm={9} md={9} lg={9}>
            <FormGroup controlId="formControlsSelect">
              <FormControl
                componentClass="select"
                onChange={this.handleChangeContentType}
                value={this.state.contentTypeSelected}
              >
                {this.state.contentsType.map((contentsType, j) => {
                  return (
                    <option key={contentsType.type} value={contentsType.type}>
                      {contentsType.type}
                    </option>
                  );
                })}
              </FormControl>
            </FormGroup>
          </Col>
        </Row>
        <hr />
        <ReactTable
          className="-highlight tableReact"
          data={dataGetContent}
          defaultPageSize={defaultPageSizeGetContent}
          showPagination={showPaginationGetContent}
          previousText={"Anterior"}
          nextText={"Próximo"}
          noDataText={noDataGetContent}
          pageText={"Página"}
          ofText={"de"}
          rowsText={"linhas"}
          columns={tableMediaCenter}
        />

        */
