import React, { Component } from "react";

import { connect } from "react-redux";

import { isVerifiedRecaptcha } from "../../../actions/miniCartActions";

import "./RecoverPassword.css";

import { ReCaptcha } from "react-recaptcha-google";

import RecoverPasswordMobile from "../../../components/Mobile/RecoverPassword/RecoverPassword";
import RecoverPasswordTablet from "../../../components/Tablet/RecoverPassword/RecoverPassword";
import RecoverPasswordDesktop from "../../../components/Desktop/RecoverPassword/RecoverPassword";
import HeaderDesktop from "../../../components/Desktop/Header/Header";
import Footer from "../../../components/Footer/Footer";
let container;

class RecoverPassword extends Component {
  constructor(props) {
    super(props);
    this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
  }

  componentDidMount() {
    if (this.captchaDemo) {
      this.captchaDemo.reset();
      this.captchaDemo.execute();
    }
  }

  verifyCallback(response) {
    this.props.dispatch(isVerifiedRecaptcha(true));
    if (response) {
      this.setState({
        isVerified: true
      });
    } else {
      this.captchaDemo.reset();
      this.captchaDemo.execute();
    }
  }

  onLoadRecaptcha() {
    if (this.captchaDemo) {
      this.captchaDemo.reset();
      this.captchaDemo.execute();
    }
  }

  render() {
    return (
      <div className="App">
        <HeaderDesktop />
        <RecoverPasswordMobile email="Endereço de Email" />
        <RecoverPasswordTablet email="Endereço de Email" />
        <RecoverPasswordDesktop email="Endereço de Email" />
        <ReCaptcha
          ref={el => {
            this.captchaDemo = el;
          }}
          size="invisible"
          render="explicit"
          sitekey="6LcjwKcUAAAAAB0AMiECn6QGrAvSi3VVhGjcGHNH"
          onloadCallback={this.onLoadRecaptcha}
          verifyCallback={this.verifyCallback}
        />
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isVerified: state.isVerified
  };
};

export default connect(mapStateToProps)(RecoverPassword);
