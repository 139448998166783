import React, { Component } from "react";
import { withCookies } from "react-cookie";
import { connect } from "react-redux";

class Instagram extends Component {
  render() {
    return (
      <div
        style={{ display: "inline-flex" }}
        onClick={() => {
          window.open("https://www.facebook.com/IHTPGLOBAL", "_blank");
        }}
      >
        <div style={{ padding: "5px" }}>
          <svg
            className="facebookIcon"
            width="30px"
            height="31px"
            viewBox="0 0 30 31"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xlink="http://www.w3.org/1999/xlink"
          >
            <g
              id="Menu"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g id="XS" transform="translate(-103.000000, -584.000000)">
                <g id="Facebook" transform="translate(103.000000, 584.000000)">
                  <g>
                    <path
                      d="M0,15.5 C0,6.93958596 6.71572835,0 15,0 C23.2842716,0 30,6.93958596 30,15.5 C30,24.060414 23.2842716,31 15,31 C6.71572835,31 0,24.060414 0,15.5 Z"
                      id="back"
                      fill="#3B5998"
                      fill-rule="nonzero"
                    />
                    <path
                      d="M16.563472,24.613662 L16.563472,16.1808437 L18.8162074,16.1808437 L19.1147435,13.2748478 L16.563472,13.2748478 L16.5672993,11.8203689 C16.5672993,11.0624404 16.6369897,10.656325 17.6904776,10.656325 L19.0987961,10.656325 L19.0987961,7.75 L16.8457417,7.75 C14.139461,7.75 13.1869203,9.15972066 13.1869203,11.5304275 L13.1869203,13.2751769 L11.5,13.2751769 L11.5,16.1811728 L13.1869203,16.1811728 L13.1869203,24.613662 L16.563472,24.613662 Z"
                      id="Shape"
                      fill="#FFFFFF"
                      fill-rule="nonzero"
                    />
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isVerified: state.isVerified,
    user: state.user
  };
};

export default withCookies(connect(mapStateToProps)(Instagram));
