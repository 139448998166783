export function getAllSuccessBoxMedia() {
  return new Promise((resolve, reject) => {
    return fetch("/successbox/getAllSuccessBoxMedia", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
        return json;
      })
      .catch(error => console.log(error));
  });
}

export function confirmSelectedProducts(products) {
  console.log(products);
  return new Promise((resolve, reject) => {
    return fetch("/successbox/confirmSelectedProducts", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        SelectedProducts: products
      }),
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json.message);
        return json;
      })
      .catch(error => console.log(error));
  });
}

export function getAllSuccessBoxMediaSubscribed() {
  return new Promise((resolve, reject) => {
    return fetch("/successbox/getAllSuccessBoxMediaSubscribed", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
        return json;
      })
      .catch(error => console.log(error));
  });
}

export function getParams() {
  return new Promise((resolve, reject) => {
    return fetch("/successbox/getParams", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
        return json;
      })
      .catch(error => console.log(error));
  });
}
