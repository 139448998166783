import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import "./index.css";
import $ from "jquery";
import "bootstrap";

import { IntlProvider, addLocaleData } from "react-intl";
import locale_pt from "react-intl/locale-data/pt";
import locale_en from "react-intl/locale-data/en";

import messages_pt from "./translations/pt.json";
import messages_en from "./translations/en.json";

//import App from "./components/App";
import App from "./App/App";
// Para obter cookies
import { CookiesProvider } from "react-cookie";
import * as serviceWorker from "./serviceWorker";

import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";

import thunk from "redux-thunk";

import reducer from "./reducers/index";

import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";

import { getUser } from "./actions/miniCartActions";

const messages = {
  pt: messages_pt,
  en: messages_en
};

addLocaleData([...locale_en]);
addLocaleData([...locale_pt]);

window.jQuery = $;
window.$ = $;

const store = createStore(reducer, applyMiddleware(thunk));

getUser().then(function (user) {
  ReactDOM.render(
    <Provider store={store}>
      <IntlProvider locale={user.language} timeZone="UTC" messages={messages[user.language]}>
        <BrowserRouter>
          <MuiThemeProvider>
            <CookiesProvider>
              <App userID={user.userID} />
            </CookiesProvider>
          </MuiThemeProvider>
        </BrowserRouter>
      </IntlProvider>
    </Provider>,
    document.getElementById("root")
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
